<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.TEACHERS'"
           [redirectLink]="'../../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <a class="pe-none">
                  {{ "NAVIGATION.TEACHERS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ CourseFilterComponent.getLanguageTranslation(chosenCourse) | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <app-course-filter (languageEmitter)="chosenCourse = $event"></app-course-filter>
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-activate-course-modal
      [studentId]="studentId"
      [langCode]="chosenCourse?.filter"
    ></app-activate-course-modal>
    <app-teachers-list [studentId]="studentId" [langCode]="chosenCourse.filter"></app-teachers-list>
  </div>
</div>
