import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginEntryPointComponent } from './login-entry-point/login-entry-point.component';
import { ContactUsEntryPointComponent } from './contact-us-entry-point/contact-us-entry-point.component';
import { BookDetailsEntryPointComponent } from './book-details-entry-point/book-details-entry-point.component';
import { BookListEntryPointComponent } from './book-list-entry-point/book-list-entry-point.component';
import { ForgotPasswordEntryPointComponent } from './forgot-password-entry-point/forgot-password-entry-point.component';



@NgModule({
  declarations: [
    LoginEntryPointComponent,
    ContactUsEntryPointComponent,
    BookDetailsEntryPointComponent,
    BookListEntryPointComponent,
    ForgotPasswordEntryPointComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoginEntryPointComponent,
    ContactUsEntryPointComponent,
    BookDetailsEntryPointComponent,
    BookListEntryPointComponent
  ]
})
export class MobileModule { }
