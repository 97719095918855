import {Observable, Subject} from "rxjs";
import {CallbackRequest, PendingRequestsQueue} from "./js-bridge-task-queue";
import {Injectable, NgZone} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {LogsService} from "../../../utils/services/logs.service";

declare global {
  interface Window {
    WebViewJavascriptBridge: any;
    WVJBCallbacks: any;
  }
}

interface Bridge {
  queue: {
    name: string,
    params: any,
    f: (...args: any[]) => any
  }[];
  registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void );
  callHandler(name: string, params: any, f: (...args: any[]) => any);
}

class TemporaryBridge implements Bridge {

  queue = [];
  log(...text: any[]) {
    if (environment.debug) {
      console.log(...text);
    }
  }
  callHandler(name: string, params: any, f: (...args: any[]) => any) {
    this.log(`ios native - adding the call ${name} to the temporary queue`);
    this.queue.push( {name, params, f} );
  }
  registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void ) {}
}

@Injectable({
  providedIn: 'root'
})
export class IosBridgeBase {
  // queue of callbacks to resolve
  bridge: Bridge = new TemporaryBridge();
  updateEventsSubject = new Subject<void>();
  refreshingSubject = new Subject<Observable<void>>();
  private imoStateSubject = new Subject<any>()
  id = 0;

  setupWebViewJavascriptBridge(callback: (bridge: any) => void): any {

    this.log("setting up the bridge base")
    // got bridge ready, return it
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge as Bridge);
    }

    // callbacks executed during initialization
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }

    // prepare callbacks for initialization
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout( _ => { document.documentElement.removeChild(WVJBIframe); } , 0);
  }

  constructor(
    private taskQueue: PendingRequestsQueue,
    private logger: LogsService,
    private ngZone: NgZone) {
    this.log('creating the iOS bridge service');
    (window as any).bridgeHandler = this;
    // wait after initialization, then replace old bridge with the new one - execute queued tasks if necesarry
    this.setupWebViewJavascriptBridge( bridge => {
      bridge.callHandler('init', {}, () => {
        this.log('replacing temporary bridge with the one provided. Executing enqueued tasks.');
        for (const call of this.bridge.queue) {
          this.log(`executing ${call.name} enqueued using provided bridge`);
          bridge.callHandler(call.name, call.params, call.f);
        }
        this.bridge = bridge;
      });
    });
  }

  callForResponse<T>(name: string, args: any): Observable<T> {
    return Observable.create( observer => {
      const req = new CallbackRequest();
      req.args = args;
      req.id = this.id++;
      this.log(`[ios] requesting for ${name} (${req.id})`);
      this.taskQueue.registerPendingRequest(req.id, observer);
      this.bridge.callHandler(name, req, res => {
        // do nothing, response will be handled by calling back from ios to javascript
      });
    });
  }

  notifyDataUpdate(): void {
    this.ngZone.run(() => {
      this.logger.log('notifying data update');
      this.updateEventsSubject.next(null);
    })
  }

  handlePendingRequest(data: any) {
    this.taskQueue.handlePendingRequest(data);
  }

  listenForDataUpdates(): Observable<void> {
    return this.updateEventsSubject.asObservable();
  }

  log(...value: any[]) {
    if (true) {
      this.logger.log('[Ios-api2]', ...value);
    }
  }

  // #### data management api #####
  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.refreshingSubject.asObservable();
  }

  onImoPressed() {
    this.ngZone.run(() => {
      this.logger.log("imo pressed");
      this.imoStateSubject.next(null);
    })
  }

  listenForImeButton() {
    return this.imoStateSubject.asObservable();
  }
}
