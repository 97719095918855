<div class="row g-0"
  routerLink="{{redirectPathPrefix}}/{{lesson.id}}">
  <div class="col-6 col-xl-5">
    <div class="p-3 rounded-start d-flex flex-column h-100 justify-content-between align-items-start"
         [ngClass]="getScheduleBgClass()">
      <div class="mb-2">
        <p class="card-title text-white schedule-time">
          {{ lesson.lessonMetric.plannedStartDate | date: 'HH:mm' }}
          <span class="text-nowrap schedule-time">- {{ getEndTime() | date: 'HH:mm' }}</span>
        </p>
        <p class="text-white">{{ lesson.lessonMetric.plannedStartDate | dateTranslation: 'E' }} • {{ this.lesson.lessonMetric.plannedStartDate | dateTranslation: 'MMM dd' }}</p>
      </div>
      <span class="badge bg-transparent border border-white border-2 text-white text-uppercase w-fit-content ng-tns-c94-4">
        {{ getScheduleState() | translate }}
      </span>
    </div>
  </div>
  <div class="col-6 col-xl-7">
    <div
      class="p-3 bg-dark rounded-end d-flex flex-column h-100 justify-content-between align-items-end">
      <div class="text-end">
        <h5>{{ this.lesson.course.name }}</h5>
        <p class="text-muted mb-3">{{ getTeacherName() }}</p>
      </div>
      <p class="mb-0">
        <a *ngIf="mayEnterVideoRoom()"
           class="btn btn-primary rounded-pill me-3"
           target="_blank"
           [href]="getRoomUrl()">
          {{ "BUTTON.ENTER" | translate }}
        </a>
        <a *ngIf="mayEnterRoomBySkype()"
          class="btn btn-primary rounded-pill me-3"
          [href]="getRoomUrl()">
          {{ "BUTTON.CALL_SKYPE" | translate }}
        </a>
        <small class="text-muted me-2">{{
          getNumberOfParticipants()
          }}</small>
        <app-icon name="group" class="icon-gray"></app-icon>
      </p>
    </div>
  </div>
</div>
