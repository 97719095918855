<div class="card">
  <div class="card-header">
    <h4>{{ getChapter() }} {{ (isSampleExam ? 'EXAM.START.SAMPLE.TITLE' : 'EXAM.START.TITLE') | translate }}</h4>
  </div>
  <div class="card-body">
    <p class="mb-4" [innerHTML]="(isSampleExam ? 'EXAM.START.SAMPLE.INFO_1' : 'EXAM.START.INFO_1') | translate: {
      stageName: session?.chapterName,
      numOfParts: session?.nbOfParts
    }">
    </p>
    <div class="table-responsive mb-2">
      <table class="table white-text table-borderless text-center">
        <thead>
        <tr class="table-row table-row-head">
          <th class="small">{{ 'EXAM.TABLE_HEADER.PART' | translate}}</th>
          <th class="small">{{ 'EXAM.TABLE_HEADER.TASK' | translate}}</th>
          <th class="small">{{ 'EXAM.TABLE_HEADER.TIME' | translate}}</th>
        </tr>
        </thead>
        <tbody class="table-body">
        <tr *ngFor="let part of session?.parts; index as i" class="table-row">
          <td class="small text-nowrap">{{ i + 1 }}</td>
          <td class="small">{{ part.partName }}</td>
          <td *ngIf="part.timeLimit" class="small">{{ part.timeLimit * 1000 | date:'mm:ss'}}</td>
          <td *ngIf="!part.timeLimit" class="small">-</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p>
      {{ (isSampleExam ? "EXAM.START.SAMPLE.INFO_2" : "EXAM.START.INFO_2") | translate }}
    </p>
  </div>
  <div class="card-footer border-0 text-center px-4 pb-3">
    <button type="button"
            (click)="startExamSessionPart()"
            class="btn btn-primary rounded-pill mx-auto py-2 px-5"
            >
      {{  'BUTTON.START' | translate }}
    </button>
  </div>
</div>
