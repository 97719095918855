import {BehaviorSubject, Observable, of} from "rxjs";
import {flatMap, map} from "rxjs/operators";
import {Recorder} from "../../model/recorder";
import {RecorderState} from "./impl/rest/audio-recorder.service";
import {MobileNativeAudio} from "./cspa.api";
import {environment} from "../../../../environments/environment";

export class MobileNativeAudioRecorderAdapter implements Recorder {
  state = new BehaviorSubject<RecorderState>(RecorderState.Stopped);
  constructor( private mobileApi: MobileNativeAudio) {}
  recording = false;

  log(text: string) {
    if (environment.debug) {
      console.log(text);
    }
  }

  play() {
    this.log('native recorder - play called');
    this.stop().subscribe( _ => {
      this.mobileApi.play2();
    });
  }

  destroy(): void {
    this.log('native recorder - destroy called');
    this.stop().subscribe();
    this.mobileApi.terminate();
  }

  clear(): Observable<void> {
    this.log('native recorder - clear called');
    return this.stop().pipe(
      flatMap( _ => this.mobileApi.clear())
    );
  }

  record(): void {
    this.log('native recorder - record called');
    this.clear().subscribe( _ => {
      this.mobileApi.record();
      this.recording = true;
      this.state.next(RecorderState.Recording);
    });
  }

  stop(): Observable<Blob> {
    this.log('native recorder - stop called');
    if (this.recording) {
      this.recording = false;
      this.state.next(RecorderState.Stopped);
      this.log('native recorder - on stop - calling stop to native api');
      return this.mobileApi.stop().pipe(
        map( () => null)
      );
    }
    return of(null);
  }

  getRecorderState(): Observable<RecorderState> {
    return this.state;
  }

  public init(callback: (state: boolean) => void) {
    this.mobileApi.initAudio(callback);
  }
}
