import { Injectable } from "@angular/core";
import { Observable, defaultIfEmpty, tap, flatMap, of, BehaviorSubject, mergeMap, map, switchMap } from "rxjs";
import { Recorder, EmptyRecorder } from "../../../../model/recorder";
import {NativeServiceApiProvider} from "../../../../../services/native-api-provider.service";
import {LogsService} from "../../../../../utils/services/logs.service";

export enum RecorderState {
  Recording, Stopped
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {

  constructor(private apiProvider: NativeServiceApiProvider, private logs: LogsService) { }

  private createRecorderForStream(stream: MediaStream): Recorder {
    const recorder = new Recorder1(stream, this.logs);
    recorder.init();
    return recorder;
  }

  getUserMedia(handler: (stream: MediaStream) => void, errorHandler: (error: EndOfStreamError) => void) {
    navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(handler).catch(errorHandler);
  }

  prepareRegularWebRecorder(): Observable<Recorder> {
    return new Observable<Recorder>(subscriber => {
      let subscriberRecorder: Recorder = null;
      if (!navigator.mediaDevices.getUserMedia) {

      }
      this.getUserMedia(stream => {
        try {
          subscriberRecorder = this.createRecorderForStream(stream);
          subscriber.next(subscriberRecorder);
        } catch (e) {
          subscriber.error(e);
        }
      }, error => {
        subscriber.error(error);
      });

      return {unsubscribe: () => {
          if (subscriberRecorder) {
            subscriberRecorder.destroy();
          }
        }};
    });
  }
// choose the device first, the create the recorder as observable
  init(): Observable<Recorder> {
    return this.apiProvider.getAlternativeAudioApi().pipe(
      defaultIfEmpty(new EmptyRecorder()),
      tap( alternative => {
        if (!(alternative instanceof EmptyRecorder)) {
          console.log('audio recorder - got alternative recorder (native)');
        } else {
          console.log('audio recorder - alternative recorder (native) not found - using javascript implementation');
        }
      }),
      switchMap( alternative => {
        if(alternative instanceof EmptyRecorder)
          return this.prepareRegularWebRecorder()
        else
          return of(alternative)
      })
    )}
}

class Recorder1 implements Recorder {

  private audioContext: AudioContext;
  private internalRecorder: InternalRecorder;

  constructor(private stream: MediaStream, private logger: LogsService) {
  }

  public getRecorderState() {
    return this.internalRecorder.recordingState;
  }

  public terminate() {
    this.internalRecorder.terminate();
  }

  play() {

    this.log("playing")
    // not supported
  }

  public clear(): Observable<void> {
    this.internalRecorder.clear();
    return of(null);
  }

  log(...args: any[]){
    this.logger.log(...args)
  }

  public record() {
    this.log("recording")
    this.internalRecorder.recordingState.next(RecorderState.Recording);
  }

  public stop(): Observable<Blob> {
    this.log("stopping")
    return new Observable( observer => {
      this.internalRecorder.recordingState.next(RecorderState.Stopped);
      observer.next(this.internalRecorder.exportToWav('audio/wav'));
      observer.complete();
      return { unsubscribe: () => {}};
    });
  }

  public init() {
    this.audioContext =  this.createAudioContext();
    const source = this.audioContext.createMediaStreamSource(this.stream);
    this.internalRecorder = new InternalRecorder(source, this.audioContext);
  }

  createAudioContext(): AudioContext {
    const AudioContextClass = this.findAudioContextClass();
    return new AudioContextClass();
  }

  findAudioContextClass() {
    return (window as any).AudioContext || (window as any).webkitAudioContext;
  }

  destroy() {
    this.terminate();
  }
}

class InternalRecorder {

  private processor: ScriptProcessorNode;
  private recording = false;
  private sampleRate: number;
  private recBufferLeft: Float32Array[] = [];
  private recBufferRight: Float32Array[] = [];
  recordingState = new BehaviorSubject<RecorderState>(RecorderState.Stopped);

  constructor(input: AudioNode, audioContext: AudioContext) {
    this.sampleRate = audioContext.sampleRate;
    this.processor = input.context.createScriptProcessor(4096, 2, 2);
    this.processor.onaudioprocess = event => {
      this.record(event.inputBuffer.getChannelData(0), event.inputBuffer.getChannelData(1));
    };
    input.connect(this.processor);
    this.processor.connect(audioContext.destination);
    this.recordingState.subscribe( state => this.recording = state === RecorderState.Recording);
  }

  terminate() {
    this.processor.disconnect();
    this.recordingState.complete();
  }

  record(left: Float32Array, right: Float32Array) {
    if (!this.recording) {
      return;
    }
    this.recBufferLeft.push(new Float32Array(left));
    this.recBufferRight.push(new Float32Array(right));
  }

  clear() {
    this.recBufferLeft = [];
    this.recBufferRight = [];
  }

  exportToWav(type: string) {
    const bufferL = this.mergeBuffers(this.recBufferLeft);
    const bufferR = this.mergeBuffers(this.recBufferRight);
    const interleaved = this.interleave(bufferL, bufferR);
    const dataview = this.encodeWav(interleaved);
    const audioBlob = new Blob([dataview], { type});
    return audioBlob;
  }
  encodeWav(samples: Float32Array) {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    /* RIFF identifier */
    this.writeString(view, 0, 'RIFF');
    /* file length */
    view.setUint32(4, 32 + samples.length * 2, true);
    /* RIFF type */
    this.writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    this.writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, 1, true);
    /* channel count */
    view.setUint16(22, 2, true);
    /* sample rate */
    view.setUint32(24, this.sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, this.sampleRate * 4, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, 4, true);
    /* bits per sample */
    view.setUint16(34, 16, true);
    /* data chunk identifier */
    this.writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, samples.length * 2, true);

    this.floatTo16BitPCM(view, 44, samples);

    return view;
  }

  floatTo16BitPCM(output: DataView, off: number, input: Float32Array) {
    let offset = off;
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  }

  writeString(view: DataView, offset: number, text: string) {
    for (let i = 0; i < text.length; i++) {
      view.setUint8(offset + i, text.charCodeAt(i));
    }
  }

  interleave(bufferL: Float32Array, bufferR: Float32Array) {
    const len = bufferL.length + bufferR.length;
    const result = new Float32Array(len);
    let index = 0;
    let inputIndex = 0;
    while ( index < len ) {
      result[index++] = bufferL[inputIndex];
      result[index++] = bufferR[inputIndex];
      inputIndex++;
    }
    return result;
  }

  mergeBuffers(recBuffer: Float32Array[]) {
    let len = 0;
    for (const buf of recBuffer) {
      len += buf.length;
    }

    const res = new Float32Array(len);
    let offset = 0;

    for (const buf of recBuffer) {
      res.set(buf, offset);
      offset += buf.length;
    }

    return res;
  }

}
