<ul class="nav nav-pills pb-3 pb-md-2 nav-pills-overflow">
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../progress" routerLinkActive="active">{{ 'COL.COURSES.NAVIGATION.PROGRESS' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../credits" routerLinkActive="active">{{ 'COL.COURSES.NAVIGATION.CREDITS' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../lessons" routerLinkActive="active">{{ 'COL.COURSES.NAVIGATION.LESSONS' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../exercises" routerLinkActive="active">{{ 'COL.COURSES.NAVIGATION.EXERCISES' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../teachers" routerLinkActive="active">{{ 'COL.COURSES.TEACHERS.TITLE' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../reserve" routerLinkActive="active">{{ 'BUTTON.RESERVE_LESSON' | translate }}</a>
  </li>
  <li class="nav-item me-2">
    <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../product" routerLinkActive="active">{{ 'BUTTON.SEE_PLANS' | translate}}</a>
  </li>
</ul>

<div class="row position-relative mb-2 mb-md-3">
  <spinner class="w-100 spinner"></spinner>
</div>

<ng-template loadComponent></ng-template>

