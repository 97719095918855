import { Component, Input, OnInit } from '@angular/core';
import { ItemAvailability } from 'src/app/cspa/model/cspa/personal';
import { Chapter, Exercise, Section } from 'src/app/cspa/model/cspa/struct';
import { LangProductMapper } from '../../../utils/lang-mappers';


@Component({
  selector: 'app-chapter-details',
  templateUrl: './chapter-details.component.html',
  styleUrls: ['./chapter-details.component.scss'],
})
export class ChapterDetailsComponent implements OnInit {
  private _langCode: string
  @Input()
  chapter: Chapter;
  @Input()
  availabilities: Map<string, ItemAvailability>;
  @Input() set langCode(langCode: string) {
    this._langCode = LangProductMapper.mapLangCodeToLangCodeCspa(langCode)
  }
  get langCode() {
    return this._langCode
  }

  constructor() {}

  ngOnInit(): void {}


  isReady() {
    return this.chapter && this.availabilities;
  }

  selectedChapterSections() {
    return this.chapter.sections;
  }

  getSectionExercises(section: Section) {
    return section.exercises;
  }

  getExerciseAvailability(section: Section, exercise: Exercise) {
    const fullExercisePath = [
      this.chapter.path,
      section.path,
      exercise.path,
    ].join('_');
    return this.availabilities.get(fullExercisePath);
  }

  getSectionPath(section: Section) {
    return section.type.toLocaleLowerCase();
  }
}
