import {PlatformLocation} from "@angular/common";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, of, Subject, switchMap, tap, throwError} from "rxjs";
import {AuthResponse} from "../../../model/prototypes";
import {EnvVariablesService} from "../../../../services/env-variables.service";
import {Injectable} from "@angular/core";
import {
  AuthorizationServiceInternals, EmulatedAuthService,
  UserRole, WebAuthService
} from "../authorization.api";
import {DeviceIdService} from "../../device-id.service";
import {AppRestService} from "../../../../books/services/api/impl/app-rest.service";
import {ContactUs, PasswordChangeRequest} from "../../../../books/model/book-model";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationServiceImpl implements AuthorizationServiceInternals, EmulatedAuthService, WebAuthService {


    // local storage field names for tokens
    private readonly accessTokenLocalStorageName = 'callan_accessToken';
    private readonly refreshTokenLocalStorageName = 'callan_refreshToken';
    private clientId = this.env.casaClientId;
    private serverBase = this.env.serverBase;
    private redirectUrl = `${this.serverBase}/oauth`;
    private oauthEndpoint = this.env.authEndpoint;
    private callanAppUrl = this.env.callanAppUrl;
    private isEmulated = false;
    private noSignal = new Subject<any>();

    private accessTokenValue: string;
    private refreshTokenValue: string;

    constructor(private http: HttpClient,
                private platformLocation: PlatformLocation,
                private env: EnvVariablesService,
                private deviceIdService: DeviceIdService,
                private appRestService: AppRestService
    ){}

    public initialize() {
       this.accessTokenValue = localStorage.getItem(this.accessTokenLocalStorageName);
       this.refreshTokenValue = localStorage.getItem(this.refreshTokenLocalStorageName);
       if (!this.isTokenValid()) {
          this.clear();
          return;
       }

       this.extractTokenData();
    }

    private extractTokenData() {
      this.tokenClaims = JSON.parse(atob(this.accessTokenValue.split("\.")[1]));
    }

    tokenClaims: any = null;

    /*
   clear authorization resources
   */
    clear(): void {
       localStorage.removeItem(this.accessTokenLocalStorageName);
       localStorage.removeItem(this.refreshTokenLocalStorageName);
       this.tokenClaims = null;
    }

    private static decomposeToken(token: string): any {
       const res: any = {};
       if (!token) {
          return null;
       }
       const splitted = token.split('.');
       res.alg = JSON.parse(atob(splitted[0]));
       res.data = JSON.parse(atob(splitted[1]));
       return res;
    }

    assignDeviceId(): Observable<any> {
      return this.appRestService.requestDeviceId().pipe(
        tap(device => this.deviceIdService.storeDeviceId(device.deviceId)),
        switchMap( _ => this.appRestService.assignDeviceId())
      )
    }

    public isTokenValid(): boolean {
       const accessToken = AuthorizationServiceImpl.decomposeToken(this.accessTokenValue);
       if (!accessToken) {
          return false;
       }
       return accessToken.data.sub;

    }

    private prepareServerBase() {
       let baseHref  = this.platformLocation.getBaseHrefFromDOM();
       baseHref = baseHref.slice(0, -1);
       const separator = environment.platform.useHash ? "#" : ""
       return `${this.serverBase}${separator}${baseHref}/logout`;
    }

    public logout(): void {
       const currentUrl = `${this.prepareServerBase()}`;
       const backUrl = this.callanAppUrl ? encodeURIComponent(this.callanAppUrl) : encodeURIComponent(currentUrl);
       const logoutUrl = `${this.oauthEndpoint}/oauth/logout?redirect_uri=${backUrl}`;

       this.clear();
       window.location.href = logoutUrl;
    }

    private constructOauth(stateUrl: string) {
       const stateStr = encodeURIComponent(btoa(stateUrl));
       const redirectUrl = encodeURIComponent(this.redirectUrl);
       const role = encodeURIComponent("student|teacher|user");

       return `response_type=code&client_id=${this.clientId}&state=${stateStr}&redirect_uri=${redirectUrl}&role=${role}`;
    }

    public startLogin(stateUrl: string) {
       window.location.href = this.oauthEndpoint
               + '/oauth/v2/authorize?' + this.constructOauth(stateUrl);
    }

    /*
    do the authorization with the given code and
    load authorization data
    */
    public authorizeCode(code: string): Observable<AuthResponse> {
       this.clear();
       return this.askForAccessToken(code)
               .pipe(
                       tap(() => this.extractTokenData())
               );
    }

    private askForAccessToken(code: string): Observable<AuthResponse> {
       const codeRequest = {
          code: code,
          client_id: this.clientId,
          redirect_uri: this.redirectUrl,
         grant_type: "authorization_code"
       };

       const formData = new HttpParams({ fromObject: codeRequest}).toString()
       return this.http.post<AuthResponse>(
         `${this.oauthEndpoint}/oauth/v2/token`,
         formData,
         {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
               .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
    }

    refreshToken(): Observable<AuthResponse> {

      const codeRequest = {
        refresh_token: this.refreshTokenValue,
        client_id: this.clientId,
        grant_type: "refresh_token"
      };

      const formData = new HttpParams({ fromObject: codeRequest}).toString()
      return this.http.post<AuthResponse>(
        `${this.oauthEndpoint}/oauth/v2/token`,
        formData,
        {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
        .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
    }


  private saveToken(resp: AuthResponse): void {
       this.accessTokenValue = resp.access_token;
       localStorage.setItem(this.accessTokenLocalStorageName, this.accessTokenValue);
       this.refreshTokenValue = resp.refresh_token;
       localStorage.setItem(this.refreshTokenLocalStorageName, this.refreshTokenValue);
    }

    public resolveState(state: string): string {
       return state;
    }

    public getAccessToken(): string {
       return this.accessTokenValue;
    }

    public loginWithPlainToken(token: any) {
       this.clear();
       this.accessTokenValue = token.token
       localStorage.setItem(this.accessTokenLocalStorageName, this.accessTokenValue)
       this.extractTokenData()
    }

  isNativeAuthorization(): boolean {
    return this.isEmulated;
  }

  private getSubjectFromClaims(): string[] {
      return this.tokenClaims["sub"].split("/");
  }

  getSelfStudentId(): Observable<number> {
    const subject: string[] = this.getSubjectFromClaims();
    if (subject[0] !== 'school' || subject[2] !== 'student') throw Error("is not student role");
    return of(Number(subject[3]));
  }

  getSelfSchoolId(): Observable<number> {
    const subject: string[] = this.getSubjectFromClaims();
    if (subject[0] !== 'school' ) throw Error("is not student role");
    return of(Number(subject[1]));
  }

  getSelfTeacherId(): Observable<number> {
    const subject: string[] = this.getSubjectFromClaims();
    if (subject[0] !== 'school' || subject[2] !== 'teacher') throw Error("is not teacher role");
    return of(Number(subject[3]));
  }

  getUserRole(): Observable<UserRole> {
    if (!this.isTokenValid()) return of(UserRole.Unauthorized);
    const subject = this.getSubjectFromClaims();
    if (subject[2] === 'student')  return of(UserRole.Student);
    if (subject[2] === 'teacher')  return of(UserRole.Teacher);
    if (subject[2] === 'manager')  return of(UserRole.Manager);
    if (subject[0] === 'user') return of(UserRole.Unaffiliated);
    return of(UserRole.Unknown);
  }

  changePassword(request: PasswordChangeRequest, lang?: string): Observable<void> {
    return this.appRestService.changePassword(request, lang);
  }

  contactUs(request: ContactUs): Observable<void> {
    return this.appRestService.contactUs(request)
  }

  getDeviceId(): Observable<string> {
    const deviceId = localStorage.getItem('X-Device-Id')
    if(!deviceId)
      this.storeDeviceId('none')
    if (!this.isEmulated) return of('none')
    return of(deviceId)
  }

  getUserName(): Observable<string> {
    return of("Static User Name (very_long_user_email.long_server_name.long_domain_name");
  }

  getUserEmail(): Observable<string> {
    if(!this.tokenClaims) return of(null)
    return of(this.tokenClaims["userEmail"])
  }

  getSubject(): Observable<string> {
    if(!this.tokenClaims) return of(null)
    return of(this.tokenClaims["sub"])
  }

  ssoLogin(): Observable<any> {
    return throwError(() => new Error("unsupported operation"))
  }

  storeDeviceId(deviceId: string) {
    localStorage.setItem('X-Device-Id', deviceId);
  }

  setEmulated() {
    this.isEmulated = true;
  }

  getAccessTokenObservable(): Observable<string> {
    return of(this.accessTokenValue);
  }

  closeApp(): Observable<any> {
      //do nothing
    return of(null);
  }

  hideKeyboard(): Observable<any> {
    return of(null);
  }

  listenOnBackButton(): Observable<any> {
    return this.noSignal;
  }

  listenOnImeButton(): Observable<any> {
    return this.noSignal;
  }

  getNativeApiVersion(): Observable<string> {
    return of("");
  }
}
