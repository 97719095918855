<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'PROFILE.TITLE'"
           [redirectLink]="profileOption === 'password-change' ? '../' : '../../'"
           [redirectTranslationCodes]="'BUTTON.BACK'">
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item"
            *ngIf="profileOption === 'password-change'"
          >
            <a routerLink="../">{{ "PROFILE.TITLE" | translate }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{
              profileOption === "profile-form"
                ? ("PROFILE.FORM.TITLE" | translate)
                : ("PROFILE.FORM.PERSONAL_INFO.CHANGE_PASSWORD" | translate)
            }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="container">
    <ng-container *ngIf="profile" [ngSwitch]="profileOption">
      <personal-profile-form
        [profile]="profileUnderEdit"
        [busy]="busy"
        [saved]="saved"
        (savePerson)="saveProfile($event)"
        [role]="'student'"
        [email]="profile.registrationEmail"
        *ngSwitchCase="'profile-form'"
      >
      </personal-profile-form>
      <personal-profile-password-change
        (changePassword)="changePassword($event)"
        [email]="profile.registrationEmail"
        [busy]="busy"
        [saved]="saved"
        *ngSwitchCase="'password-change'"
      >
      </personal-profile-password-change>
    </ng-container>
  </div>
</div>
