import { Injectable } from "@angular/core";
import {catchError, iif, map, Observable, of, ReplaySubject, switchMap, take} from "rxjs";
import {AuthorizationServiceProvider} from "../auth_profile/services/authorization-service.provider";
import packageJson from 'package.json';
import {share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {


  constructor(private authProvider: AuthorizationServiceProvider) {
  }

  private versionShared: ReplaySubject<string>;

  getVersion(): Observable<string> {
    if (!!this.versionShared) return this.versionShared;
    this.versionShared = new ReplaySubject<string>(1);
    this.authProvider.getMobile().pipe(
      switchMap( api => iif(() => !!api, api.getNativeApiVersion(), of(""))),
      catchError(() => of("unk")),
      map( nativeVer => `${nativeVer}:${packageJson.version}`),
      take(1)
    ).subscribe(v => this.versionShared.next(v));
    return this.versionShared;
   }
}
