<div class="card mb-5">
  <div class="card-header d-flex align-items-center">
    <h5 class="mb-0">{{ 'LESSONS.LESSON_LIST.TITLE' | translate }}</h5>
    <app-access-lessons-button class="ms-auto"></app-access-lessons-button>
  </div>
  <div class="card-body">
    <div *ngIf="scheduleRows?.length; else noResults" class="table-responsive">
      <table class="table white-text">
        <thead class="small">
          <tr class="table-row table-row-head">
            <th>{{ 'LESSONS.LESSON_LIST.DATE_TIME' | translate }}</th>
            <th>{{ 'LESSONS.LESSON_LIST.DURATION' | translate }}</th>
            <th>{{ 'LESSONS.LESSON_LIST.GROUP_NAME' | translate }}</th>
            <th>{{ 'LESSONS.LESSON_LIST.STATUS' | translate }}</th>
            <th style="width: 36px;"></th>
          </tr>
        </thead>
        <tbody class="table-body small">
          <tr *ngFor="let scheduleRow of scheduleRows" class="table-row cursor-pointer" routerLink="../schedule/{{scheduleRow.schedule.id}}">
            <td>{{ getScheduleStartTime(scheduleRow.schedule) | date: 'E dd.MM.yy, HH:mm'}} - {{ getScheduleEndTime(scheduleRow.schedule) | date : 'HH:mm'}}</td>
            <td>{{ scheduleRow.schedule.details.durationMin}}</td>
            <td>{{ scheduleRow.template.details.name}}</td>
            <td>
                <span [ngClass]="getBadgeColorClassByStatus(scheduleRow)" class="badge rounded px-2 py-1 text-white w-fit-content xsmall mb-2">
                  {{ getBadgeTranslationByStatus(scheduleRow) | translate }}
                </span>
            </td>
            <td>
              <button class="btn btn-outline-secondary rounded-circle" type="button">
                <app-icon name="angle-right" class="icon-blue icon-lg"></app-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noResults>
      <div class="row">
        <p class="text-center fs-5 text-muted m-0">
          {{ "LESSONS.NO_RESULTS" | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
