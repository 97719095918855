import { Component, OnInit } from '@angular/core';
import {NativeServiceApiProvider} from "./services/native-api-provider.service";
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationServiceProvider } from './auth_profile/services/authorization-service.provider';
import { ActivatedRoute, Router } from '@angular/router';
import {AppVersionService} from "./services/app-version.service";
import {take} from "rxjs";
import {LogsService} from "./utils/services/logs.service";
import { SentryService } from './services/sentry-service.service';
import { environment } from 'src/environments/environment';
import { OffcanvasMenuService } from './services/offcanvas-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'student_area';
  theme: string
  constructor(
    public translate: TranslateService,
    private provider: NativeServiceApiProvider,
    private authProvider: AuthorizationServiceProvider,
    private router: Router,
    private versionService: AppVersionService,
    private route: ActivatedRoute,
    private sentry: SentryService,
    private logger: LogsService,
    private offcanvasMenuService: OffcanvasMenuService
    ) {}

  ngOnInit(): void {
    // initialize the service on startup
    this.provider.books().subscribe(gotApi => window.nativeBridge && window.nativeBridge.begin());
    this.authProvider.getMobile().subscribe( mobileApi => {
      if (mobileApi) mobileApi.listenOnBackButton().subscribe(
        backEvent => {
          if (this.hasBackState()) this.navigateBack();
          else mobileApi.closeApp().subscribe();
        }
      );
    });
    this.versionService.getVersion().pipe(take(1)).subscribe( ver => console.log(`app-ver: ${ver}`))
    document.body.classList.add(environment.styleClass.toLowerCase())
    this.offcanvasMenuService.setMenu("defaultHamburger")
  }

  hasBackState() {
    return this.route.snapshot["_routerState"].url.split('/').length > 3
  }

  async navigateBack(n: number = 1) {
    let url: Array<string> = this.router.url
      .split('/')
      .slice(0, -n)
    try {
      this.logger.log("navigating back to the", url);
      await this.router.navigate(url)
    } catch {
      this.navigateBack(n+1)
    }
  }

  // back() {
  //   if (this.hasBackState()) this.navigateBack();
  //   else console.log('close app')
  // }
}
