import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-list-entry-point',
  templateUrl: './book-list-entry-point.component.html',
  styleUrls: ['./book-list-entry-point.component.scss']
})
export class BookListEntryPointComponent implements OnInit {

  constructor(private router: Router) {
    this.router.navigate(['student/ebooks'])
  }

  ngOnInit(): void {
  }

}
