import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';
import { BookListComponent } from './pages/book-list/book-list.component';
import { BookDetailsComponent } from './pages/book-details/book-details.component';
import { BookListItemComponent } from './components/book-list-item/book-list-item.component';

@NgModule({
  declarations: [
    BookListComponent,
    BookDetailsComponent,
    BookListItemComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule
  ],
  exports: [
    BookListComponent,
    BookDetailsComponent,
    BookListItemComponent
  ]
})
export class BooksModule { }
