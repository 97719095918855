import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-dashboard-lessons-details-page',
  templateUrl: './teacher-dashboard-lessons-details-page.component.html',
  styleUrls: ['./teacher-dashboard-lessons-details-page.component.scss'],
})
export class TeacherDashboardLessonsDetailsPageComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
