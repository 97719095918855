import {ApiLearningUnitTeacher, ApiLessonInstance, ApiLessonStatus, ApiPersonalProfile} from '../model/rest-model';

export class Utils {
  public static jsonClone<T>(entry: T): T {
    if (!entry) {
      return entry;
    }
    return JSON.parse(JSON.stringify(entry));
  }

  public static getWeekStartDate(focusDate: Date) {
    let weekStart = new Date((focusDate).getTime() - new Date().getDay() * 24 * 60 * 60 * 1000)
    weekStart.setHours(0)
    weekStart.setMinutes(0)
    weekStart.setSeconds(0)
    weekStart.setMilliseconds(0)
    return weekStart
  }
  static getWeekEndDate(focusDate: Date) {
    const weekEnd = new Date(focusDate?.getTime() + (6 - focusDate?.getDay())*24*60*60*1000);
    weekEnd.setHours(23)
    weekEnd.setMinutes(59)
    weekEnd.setSeconds(59)
    weekEnd.setMilliseconds(999)
    return weekEnd
  }
}

export class LessonTypeUtils {
  public static getStudentLessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
    switch (lessonStatus) {
      case ApiLessonStatus.Booked:
        return 'COL.LESSONS.LIST.LESSON_STATUS.BOOKED';
      case ApiLessonStatus.Complete:
        return 'COL.LESSONS.LIST.LESSON_STATUS.COMPLETE';
      case ApiLessonStatus.Due:
        return 'COL.LESSONS.LIST.LESSON_STATUS.DUE';
      case ApiLessonStatus.InProgress:
        return 'COL.LESSONS.LIST.LESSON_STATUS.IN_PROGRESS';
      case ApiLessonStatus.InvalidatedByTeacher:
      case ApiLessonStatus.Cancel:
      case ApiLessonStatus.InvalidatedByStudent:
        return 'COL.LESSONS.LIST.LESSON_STATUS.CANCELLED';
      default:
        return '';
    }
  }

  public static getStudentLessonColorClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
    switch (lessonStatus) {
      case ApiLessonStatus.Booked:
        return 'booked';
      case ApiLessonStatus.Complete:
        return 'complete';
      case ApiLessonStatus.Due:
        return 'due';
      case ApiLessonStatus.InProgress:
        return 'in-progress';
      case ApiLessonStatus.InvalidatedByTeacher:
      case ApiLessonStatus.Cancel:
      case ApiLessonStatus.InvalidatedByStudent:
        return 'student-invalidate';
      default:
        return '';
    }
  }
}
