import { Injectable } from "@angular/core";
import {Observable, map, switchMap, of} from "rxjs";
import { NativeServiceApiProvider } from "src/app/services/native-api-provider.service";
import {AuthorizationServiceProvider} from "./authorization-service.provider";

@Injectable({
    providedIn: 'root'
})
export class DeviceIdService {
    cachedDeviceId: string;

    constructor(private auth: AuthorizationServiceProvider) {
    }


    hasDeviceId(): Observable<boolean> {
        return this.getDeviceId().pipe(
            map( devId => !!devId)
        );
    }

    getDeviceId(): Observable<string> {
        return this.auth.getMobile().pipe(
          switchMap( api => {
            if (!api) {
              return of("none")
            } else {
              return api.getDeviceId();
            }
          })
        )
    }

    storeDeviceId(deviceId: string) {
      this.auth.getEmulatedAuthService().subscribe(
        api => api.storeDeviceId(deviceId)
      )
    }
}
