export enum LessonType {
  Upcoming, Incomplete, Past
}

export class CalendarEntry {
  dateFrom: Date;
  dateTo: Date;
  id: any;
  eventId: number;
  title: () => string;
  subtitle: string;
  relatedObject: any;
  classes: string[];
  _narrowIntersected = false;

  constructor(id: any, eventId: number, dateFrom: Date, dateTo: Date,
    title: () => string , subtitle: string,  relatedObject: any, ...classes: string[]) {
    this.id = id;
    this.eventId = eventId;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.title = title;
    this.subtitle = subtitle;
    this.classes = classes;
    this.relatedObject = relatedObject;
  }

  public narrowIntersected() {
    this._narrowIntersected = true;
    return this;
  }
}

export class ClickEvent {
  entry: CalendarEntry;
  clickDate: Date;

  constructor(entry: CalendarEntry, date: Date) {
    this.entry = entry;
    this.clickDate = date;
  }
}

export class EventInternal {
  start: number;
  height: number;
  title: () => string;
  subtitle: string;
  relatedEntry: CalendarEntry;
  classes: string;
  left: number;
  width: number;

  constructor( start: number, height: number, entry: CalendarEntry) {
    this.start = start;
    this.height = height;
    this.title = entry.title;
    this.subtitle = entry.subtitle;
    this.relatedEntry = entry;
    this.classes = entry.classes.join(' ');
  }
}


export class Range< D> {
  data: D;
  from: number;
  to: number;

  constructor(from: number, to: number, data?: D ) {
      this.from = from;
      this.to = to;
      this.data = data;
  }

  clone() {
      return new Range( this.from, this.to, this.data);
  }

  intersect(other: Range<D>): Range<D> {
      const from = Math.max(this.from, other.from);
      const to = Math.min(this.to, other.to);

      return new Range<D>(from, to);
  }

  isEmpty(): boolean {
      return this.from >= this.to;
  }

}

export class RangeSet<D> {
  ranges: Range<D>[] = [];
  groupsNumber: number;
  assigments: number[] = null;

  public getAsigmentByData(data: D) {
      if (this.assigments === null) { return 0; }
      for (let i = 0 ; i < this.ranges.length ; i ++) {
          if (this.ranges[i].data === data ) {
              return this.assigments[i];
          }
      }
      return 0;

  }

  public addRange(from: number, to: number, data: D) {
      this.ranges.push(new Range<D>(from, to, data));
  }

  private sortRanges() {
     this.ranges = this.ranges.sort( (l, r) => l.from - r.from);
  }

  private findColisioningGroups() {
      const result: Array<number[]> = [];

      for (let i = 0 ; i < this.ranges.length ; i ++ ) {
          const currentGroup = [i];
          let intersection = this.ranges[i].clone();
          for ( let j = i + 1 ; j < this.ranges.length ; j++ ) {
              intersection = intersection.intersect(this.ranges[j]);
              if (intersection.isEmpty()) { break; }
              currentGroup.push(j);
          }
          if (currentGroup.length > 1) {
              result.push(currentGroup);
          }
      }

      return result;
  }

  private maximumNumberOfColisions(colisionGroups: Array<number[]>) {
      return Math.max(...colisionGroups.map ( g => g.length ));
  }

  public reassingGroups() {
      this.assigments = null;
      this.sortRanges();
      const colisioningGroups = this.findColisioningGroups();
      this.groupsNumber = this.maximumNumberOfColisions(colisioningGroups);
      if (this.groupsNumber <= 0 ) {
          this.groupsNumber = 1;
      }
      if (this.groupsNumber === 1 ) {
          return;
      }
      const assigments: number[] = [];
      let assigmentsNumber = 0;
      for (let i = 0 ; i < colisioningGroups.length ; i ++ ) {
          const availability = Array.from({length: this.groupsNumber}, (v, k) => k );
          const colisioningGroup = colisioningGroups[i];
          for (let j = 0 ; j < colisioningGroup.length ; j++ ) {
              const colisioningElement = colisioningGroup[j];
              const groupAssigned = assigments[colisioningElement];
              if (groupAssigned !== undefined ) {
                  availability.splice(availability.indexOf(groupAssigned), 1);
              }
          }
          for (let j = 0 ; j < colisioningGroup.length ; j++ ) {
              const colisioningElement = colisioningGroup[j];
              let groupAssigned = assigments[colisioningElement];
              if (groupAssigned !== undefined) { continue; }
              groupAssigned = availability[0];
              assigments[colisioningElement] = groupAssigned;
              availability.splice(0, 1 );
              assigmentsNumber++;
          }
      }

      this.assigments = assigments;
  }
}

export class DateRange {
  dateFrom: Date;
  dateTo: Date;

  contains(date: Date): boolean {
  if (date.getTime() < this.dateFrom.getTime() || date.getTime() > this.dateTo.getTime()) {return false; }
      return true;
  }

  isAfter(date: Date) {
      return date.getTime() < this.dateFrom.getTime();
  }

  isBefore(date: Date) {
      return date.getTime() > this.dateTo.getTime();
  }
}

export class DateRangeSet {
  orderedRanges: DateRange[] = [];

  add(range: DateRange) {
      let leftContainingIndex: number = null;
      let rightContainingIndex: number = null;
      let leftInsertIndex = 0;
      let rightInsertIndex = 0;

      for (let i = 0; i < this.orderedRanges.length; i++)  {
          const examItem = this.orderedRanges[i];
          if (!leftContainingIndex && examItem.contains(range.dateFrom)) {
              leftContainingIndex = i;
          }
          if (!rightContainingIndex && examItem.contains(range.dateTo)) {
              rightContainingIndex = i;
          }
          if (examItem.isBefore(range.dateFrom)) {
              leftInsertIndex = i + 1;
          }
          if (rightContainingIndex || examItem.isAfter(range.dateTo)) {
              break;
          }
          rightInsertIndex = i + 1;
      }

      const leftIdx = leftContainingIndex != null ? leftContainingIndex : leftInsertIndex;
      const rightIdx = rightContainingIndex != null ? rightContainingIndex + 1 : rightInsertIndex;

      const toJoin = this.orderedRanges.slice(leftIdx, rightIdx);
      toJoin.push(range);
      const joined = this.join(toJoin);
      this.orderedRanges.splice(leftIdx, rightIdx - leftIdx, joined);
      return this;
  }

  sub(range: DateRange) {
      let leftContainingIndex: number = null;
      let rightContainingIndex: number = null;
      let leftInsertIndex = 0;
      let rightInsertIndex = 0;

      for (let i = 0; i < this.orderedRanges.length; i++)  {
          const examItem = this.orderedRanges[i];
          if (!leftContainingIndex && examItem.contains(range.dateFrom)) {
              leftContainingIndex = i;
          }
          if (!rightContainingIndex && examItem.contains(range.dateTo)) {
              rightContainingIndex = i;
          }

          if (examItem.isBefore(range.dateFrom)) {
              leftInsertIndex = i + 1;
          }
          if (rightContainingIndex || examItem.isAfter(range.dateTo)) {
              break;
          }
          rightInsertIndex = i + 1;
      }

      if (leftContainingIndex != null) {
          this.orderedRanges.splice(leftContainingIndex, 1, ...this.split(this.orderedRanges[leftContainingIndex], range.dateFrom));
          leftInsertIndex = leftContainingIndex + 1;
          // because previous block was divided into 2, right indexes should be moved
          if (rightContainingIndex != null) {
              rightContainingIndex++;
          }
          rightInsertIndex ++;
      }
      if (rightContainingIndex != null) {
          this.orderedRanges.splice(rightContainingIndex, 1, ...this.split(this.orderedRanges[rightContainingIndex], range.dateTo));
          rightInsertIndex = rightContainingIndex + 1;
      }

      this.orderedRanges.splice(leftInsertIndex, rightInsertIndex - leftInsertIndex);
      this.orderedRanges = this.orderedRanges.filter( r => r.dateFrom.getTime() !== r.dateTo.getTime());
      return this;
  }
  split(range: DateRange, onDate: Date): DateRange[] {
      const res = [new DateRange(), new DateRange()];
      res[0].dateFrom = range.dateFrom;
      res[0].dateTo = onDate;
      res[1].dateFrom = onDate;
      res[1].dateTo = range.dateTo;
      return res;
  }

  private join(toJoin: DateRange[]): DateRange {
      const res = new DateRange();
      const minDate = new Date(Math.min(...toJoin.map( r => r.dateFrom).map( d => d.getTime())));
      const maxDate = new Date(Math.max(...toJoin.map( r => r.dateTo).map( d => d.getTime())));
      res.dateFrom = minDate;
      res.dateTo = maxDate;
      return res;
  }
}
