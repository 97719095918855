import { Component, OnInit } from '@angular/core'
import { Subject, debounceTime } from 'rxjs'
import { QuestionComponentBase } from 'src/app/model/QuestionComponentBase'
import {CompareStream, BlockComparator, TextBlockChanges} from 'src/app/utils/text-compare'
import { environment } from 'src/environments/environment'
import { Correction, CorrectionAnswer } from '../../../../model/cspa/questions'
import { QuestionService } from '../../../../services/api/impl/rest/question.service'
import {LogsService} from "../../../../../utils/services/logs.service";
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider'

@Component({
  selector: 'app-correction',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.scss']
})
export class CorrectionComponent extends QuestionComponentBase<CorrectionAnswer, Correction> implements OnInit {

  content: string
  originalStream: CompareStream
  correctStream: CompareStream
  answerStream: CompareStream
  answerValueSubject = new Subject<string>()
  compareResult: TextBlockChanges[]
  changedContent: string

  constructor(
    private questionService: QuestionService,
    logs: LogsService,
    authProvider: AuthorizationServiceProvider) {
    super(logs, authProvider);
    this.answerValueSubject.pipe(
      debounceTime(300)
    ).subscribe( currentValue => {
      if(!currentValue) return
      this.answerStream = BlockComparator.tokenize(currentValue)
      const result = BlockComparator.compare(this.answerStream, this.originalStream)
      this.compareResult = BlockComparator.compressBlocksChanges(BlockComparator.divideToBlocksChanges(result, this.originalStream))
      this.changedContent = this.prepareChangedContent()
    })
  }

  ngOnInit() {
    this.prepareQuestionData()
    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer =>
      sendAnswer &&
      sendAnswer['answerType'] === 'cor' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    )
  }

  getAnswerForSubmit() {
    this.applyContentOnAnswer()
    this.sendAnswer()
  }

  applyContentOnAnswer() {
    if (!this.answer) {
      return
    }
    this.answer.correct = this.content
  }

  setUpAnswer(answer: CorrectionAnswer) {
    if (!answer) {
      this.answer = new CorrectionAnswer()
    } else {
      this.answer = answer
      this.applyAnswerOnContent()
    }
  }

  applyAnswerOnContent() {
    if (!this.answer || !this.answer.correct) {
      this.answerValueSubject.next(this.content)
      return
    }
    this.content = this.answer.correct
    this.answerValueSubject.next(this.content)
  }

  hasImage() {
    if (!this.question) {
      return false
    }
    return this.question.question.definition.img != null
  }

  getImageSrc() {
    return  environment.awsBase + this.question.question.definition.img
  }

  prepareQuestionData(): void {
    this.content = this.question.question.definition.incorrect
    this.originalStream = BlockComparator.tokenize(this.content)
    this.correctStream = BlockComparator.tokenize(this.question.question.definition.answer.correct)
  }

  answerChanged(current: string) {
    this.inProgressEmitter.emit(false)
    this.answerValueSubject.next(current)
  }

  prepareChangedContent() {
    let content = []
    if(this.compareResult.length <= 1) return null
    this.compareResult?.forEach(block => {
      if(!block.state) {
        content.push(block.changedContent)
      }
      if(block.state == "removed") {
        content.push(this.createSpan(block.originalContent, "removed"))
        content.push(this.createSpan(block.changedContent, "added"))
      }
      if(block.state == "added") {
        content.push(this.createSpan(block.originalContent, "added"))
      }
    })
    return content.join("&nbsp")
  }

  createSpan(content: string, className?: string) {
    return `<span class="col-auto ${className}">${content}</span>`
  }
}
