import {Component, Input, OnInit} from '@angular/core';
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";
import {filter, Observable, of, switchMap, zip} from "rxjs";
import {AuthorizedUserDetailsService, UserRole} from "../../auth_profile/services/api/authorization.api";
import {Event, Router, RouterEvent} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  private _redirectTranslationCode: string
  private _defaultRedirectTranslationCodes = false
  private activePath: string
  private activeSegments: string[]
  private userRole: UserRole
  private schoolId: number

  @Input() titleTranslationCode: string
  @Input()
  set defaultRedirectTranslationCodes(isDefault: boolean) {
    this._defaultRedirectTranslationCodes = isDefault
    if(isDefault)
      this.setDefaultTranslations()
  }
  @Input()
  set redirectTranslationCodes(code: string) {
    if(!code) return
    this._redirectTranslationCode = code
  }
  get redirectTranslationCode() {
    return this._redirectTranslationCode
  }
  @Input() redirectLink: string

  constructor(private authService: AuthorizationServiceProvider, private router: Router) {
    router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      this.activePath = e['urlAfterRedirects']
      this.activePath? this.activeSegments = this.activePath.split('/') : ''
    });

    this.authService.getAuthDetailsService().pipe(
      switchMap(auth => zip(auth.getUserRole(), this.getSelfSchoolId(auth)))
    ).subscribe(([role, schoolId]) => {
        this.userRole = role
        this.schoolId = schoolId
        this.setDefaultTranslations()
      })
  }

  ngOnInit(): void {
  }

  isHighestLevel() {
    /*
      when col functionality is on:
        col student - 'dashboard',
        col teacher - 'panel'
       when col functionality is off or school != callan online school
        student - 'exercises'
        teacher - 'lessons'
       for unaffiliated users - 'ebooks'
     */
    switch (this.userRole) {
      case UserRole.Student:
        if(this.activeSegments[1] !== 'student') return false
        return (this.schoolId == 1
          && environment.colFunctionality
          && this.activeSegments[2].includes('dashboard'))
        || ((!environment.colFunctionality || this.schoolId != 1)
            && this.activeSegments[2].includes('exercises'))

      case UserRole.Teacher:
        if(this.activeSegments[1] !== 'teacher') return false
        return (this.schoolId == 1
            && environment.colFunctionality
            && this.activeSegments[2].includes('panel'))
          || ((!environment.colFunctionality || this.schoolId != 1)
            && this.activeSegments[2].includes('lessons'))

      case UserRole.Unaffiliated:
        if(this.activeSegments[1] !== 'unaffiliated') return false
        return this.activeSegments[2].includes('ebooks')
      default:
        return false
    }
  }

  private setDefaultTranslations() {
    switch (this.userRole) {
      case UserRole.Student:
        this._redirectTranslationCode = this.schoolId == 1 && environment.colFunctionality
          ? 'NAVIGATION.DASHBOARD' : 'NAVIGATION.EXERCISES'
        break
      case UserRole.Teacher:
        this._redirectTranslationCode = this.schoolId == 1 && environment.colFunctionality
          ? 'NAVIGATION.CALENDAR' : 'NAVIGATION.LESSONS'
        break
      case UserRole.Unaffiliated:
        this._redirectTranslationCode = 'NAVIGATION.EBOOKS'
        break
    }
  }

  private getSelfSchoolId(auth: AuthorizedUserDetailsService): Observable<any> {
    try {
      return auth.getSelfSchoolId().pipe(
        switchMap(_ => auth.getSelfSchoolId())
      )
    } catch (e) {
        return of(null); // Return observable with null value to continue the flow
    }
  }
}
