<div class="row my-4 mx-auto justify-content-center">
  <div class="col bubble teacher-bubble tri-right">
    <div class="row">
      <div class="col-5 col-sm-4 ps-0" *ngIf="isCFK()">
        <img [src]="getCFKImageSrc()" class="rounded-2 w-100" alt="">
      </div>
      <div class="col-auto p-0">
        <span class="fs-3 fw-bolder text-white">Q</span>
        <p class="text-white">{{ isQuestionHidden ? "..." : getQuestion() }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-end">
      <audio #audioTarget></audio>
      <app-icon
        (click)="playQuestion()"
        name="play-arrow-filled"
        class="me-3"
      ></app-icon>
      <app-icon
        (click)="toggleQuestion()"
        [name]="isQuestionHidden ? 'text' : 'text-hide'"
        class="icon-white"
      ></app-icon>
    </div>
  </div>
</div>
<div class="row mx-auto justify-content-center">
  <div class="col bubble student-bubble tri-right">
    <span class="fs-3 fw-bolder answer-symbol d-flex flex-row-reverse">A</span>
    <p class="text-black">{{ isAnswerHidden ? getPrompt() : getAnswer() }}</p>
    <div class="d-flex justify-content-start align-items-center">
      <app-icon
        (click)="canPlayAnswer ? playAnswer() : ''"
        name="play-arrow-filled"
        [class]="canPlayAnswer ? 'me-3 icon-filled-blue' : 'me-3 icon-filled-gray tooltip'"
      >
        <span *ngIf="!canPlayAnswer" class="tooltip-text">
          {{ 'EXERCISES.HOST.TOOLTIP_INFO_Q' | translate }}
        </span>
      </app-icon>
      <app-icon
        (click)="canPlayAnswer ? toggleAnswer() : ''"
        [name]="isAnswerHidden ? 'text' : 'text-hide'"
        [class]="canPlayAnswer ? 'icon-filled-blue' : 'icon-filled-gray tooltip'"
      >
        <span *ngIf="!canPlayAnswer" class="tooltip-text">
          {{ 'EXERCISES.HOST.TOOLTIP_INFO_Q' | translate }}
        </span>
      </app-icon>
    </div>
  </div>
</div>
