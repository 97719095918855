import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { Subscriber } from "rxjs/internal/Subscriber";

@Injectable({
    providedIn: 'root'
  })
  export class ConfirmDialogService {
    title: string;
    message: string;
    private subscriber: Subscriber<boolean>;
    classes: string;
    confirmText: string
    cancelText: string
  
    constructor() { }
    show = new BehaviorSubject<boolean>(false);
  
    public askConfirmation(message: string, title?: string, classes?: string, confirmText: string = 'Yes', cancelText: string = 'No'): Observable<boolean> {
      this.cancel();
      return new Observable<boolean>(subscriber => {
          this.show.next(true);
          this.title = title;
          this.message = message;
          this.classes = classes;
          this.subscriber = subscriber
          this.confirmText = confirmText
          this.cancelText = cancelText
          return () => {
            this.show.next(false);
            subscriber = null;
          }
        }
      );
    }
  
    public doOnConfirm(message: string, action: () => void, title?: string, classes?: string,confirmText: string = 'Yes', cancelText: string = 'No') {
      this.askConfirmation(message, title, classes, confirmText, cancelText).subscribe(
        response => {
          if (response) action()
        }
      )
    }
  
    confirm() {
      this.show.next(false);
      if (this.subscriber) {
        this.subscriber.next(true);
        this.subscriber.complete();
        this.subscriber = null;
      }
    }
  
    cancel() {
      this.show.next(false);
      if (this.subscriber) {
        this.subscriber.next(false);
        this.subscriber.complete();
        this.subscriber = null;
      }
    }
  }