import { Injectable } from "@angular/core";
import {Subject, Observable, ReplaySubject, tap, switchMap, map} from "rxjs";
import { Person, School, PersonDetails } from "../../model/casa/casa-model";
import { AuthorizationServiceProvider } from "../authorization-service.provider";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private _selfPerson: Subject<Person<PersonDetails>>;

    constructor(
      private provider: NativeServiceApiProvider,
      private authService: AuthorizationServiceProvider ) {}
    /**
     * getting the profile you may subscribe for updates too
     */
    getSelfPerson(): Observable<Person<PersonDetails>> {
        // if not initialized yet - load data
        if (!this._selfPerson) {
            this._selfPerson = new ReplaySubject<Person<PersonDetails>>(1);
            this.reloadSelfPerson().subscribe();
        }
        return this._selfPerson;
    }

    /**
     * may be used to reload profile data
     */
    reloadSelfPerson(): Observable<Person<PersonDetails>> {
        if (!this._selfPerson) {
            this._selfPerson = new ReplaySubject<Person<PersonDetails>>(1);
        }
      return this.provider.casa().pipe(
        switchMap(api => api.getSelf()),
        tap( person => this._selfPerson.next(person))
      )
    }

    /**
     * Save self profile data after profile form update
     * @param details
     */
    updateSelf(details: PersonDetails): Observable<Person<PersonDetails>> {
      return this.provider.casa().pipe(
        switchMap(api => api.updateSelfTeacherPersonalProfile(details)),
        switchMap( _ => this.reloadSelfPerson())
      )
    }

    getSelfSchool(): Observable<School> {
      return this.authService.getAuthDetailsService()
        .pipe(
          switchMap( auth => auth.getSelfTeacherId()),
          switchMap( teacherId =>
            this.provider.casa().pipe(
              switchMap( casaApi => casaApi.getTeachersSchool(teacherId))
            )
          )
        );
    }
}
