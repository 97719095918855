import { Injectable } from '@angular/core';
import {ScheduleRowSimplified} from "../models/video.model";

export class ConflictPair {
  constructor(public left: ScheduleRowSimplified, public right: ScheduleRowSimplified) {
  }
}

export class ConflictResults {
  constructor(
    public byClass: ConflictPair[],
    public byTeacher: ConflictPair[],
    public conflictedTeacherEmails: string[],
    public conflictedClassRooms: string[]){}
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleSearchService {

  constructor() { }

  groupByHash<T>(dataRows: T[], hashExtractor: (T) => string): Map<string, T[]> {
    return dataRows?.reduce((result: Map<string, T[]>, row: T) => {
      const rowHash = hashExtractor(row);
      if (!rowHash || rowHash.length === 0) return result;
      let hashElements = result.get(rowHash);
      if (!hashElements) {
        hashElements = [];
        result.set(rowHash, hashElements);
      }
      hashElements.push(row);
      return result;
    }, new Map<string, T[]>()) as Map<string, T[]>;
  }

  teacherMailExtractor = (row: ScheduleRowSimplified) => {
    const teacher = row.schedule.details.participants.find(p => p.role === 'Teacher');
    if (!teacher) return '';
    const teacherMail = teacher.email;
    if (teacherMail) {
      return teacherMail.trim().toLocaleLowerCase();
    }
    return "";
  }

  findInTimeRange(dataSet: ScheduleRowSimplified[], startingPos: number, timeFrameStart: number, timeFrameEnd: number) {
    const result = [];
    let index = startingPos;
    while(index < dataSet.length) {
      const element = dataSet[index];
      const elementStartDate = element.schedule.details.startDate;
      let elementDuration = element.schedule.details.durationMin;
      if (!elementDuration) elementDuration = 25;
      elementDuration *= 60 * 1000;
      const elementFinishDate = elementStartDate + elementDuration;
      if (elementStartDate > timeFrameEnd) return result;

      /* expression below is tricky because it uses the
      because it uses the result of the previous expression.

      In previous we are checking if element is not after window at
      all. In the condition below we are checking if starts
      or at least ends in the frame.
       */

      if (elementStartDate > timeFrameStart
        || elementFinishDate > timeFrameStart
      ) {
        result.push(element);
      }
      index++;
    }
    return result;
  }

  findConflicts(dataRowsSorted: ScheduleRowSimplified[]) {
    const conflicts: ConflictPair[] = [];
    for (let i = 0 ; i < dataRowsSorted.length ; i++) {
      const element = dataRowsSorted[i];
      const elementStart = element.schedule.details.startDate;
      let elementDuration = element.schedule.details.durationMin;
      if (!elementDuration) elementDuration = 25;
      elementDuration *= 60000;
      const elementEnd = elementStart + elementDuration;
      const conflicted = this.findInTimeRange(dataRowsSorted, i + 1, elementStart, elementEnd);
      if (conflicted.length > 0) {
        conflicts.push(
          ...conflicted.map( it => new ConflictPair(element, it))
        )
      }
      i += conflicted.length;
    }
    return conflicts;
  }

  searchForConflicts(dataSorted: ScheduleRowSimplified[]) {

    const byTeacher = this.groupByHash(dataSorted, this.teacherMailExtractor);

    const byRoom = this.groupByHash(dataSorted, (row: ScheduleRowSimplified) => {
      let classroom = row.schedule.details.place;
      if (!classroom) classroom = row.template.details.place;
      if (classroom) classroom = classroom.trim().toLocaleLowerCase();
      else  classroom = "";
      return classroom;
    });

    const conflictsByTeacher: ConflictPair[] = [];
    const conflictedTeachers: string[] = [];


    byTeacher?.forEach((rows, teacherMail) => {
      const conflicts = this.findConflicts(rows);
      if (!conflicts || conflicts.length === 0) return;
      conflictsByTeacher.push(...conflicts);
      conflictedTeachers.push(teacherMail);
    });

    const conflictsByRoom: ConflictPair[] = [];
    const conflictedRooms: string[] = [];

    byRoom?.forEach((rows, roomName) => {
      const conflicts = this.findConflicts(rows);
      if (!conflicts || conflicts.length === 0) return;
      conflictsByRoom.push(...conflicts);
      conflictedRooms.push(roomName);
    });

    return new ConflictResults(
      conflictsByRoom,
      conflictsByTeacher,
      conflictedTeachers,
      conflictedRooms
    );
  }
}
