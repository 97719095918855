import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, finalize } from 'rxjs';
import { LoadComponentDirective } from 'src/app/utils/load-component/load-component.directive';
import { ContactUsResultComponent } from '../contact-us-result/contact-us-result.component';
import { ContactUsService } from '../contact-us.service';
import { ContactUsComponent } from '../contact-us/contact-us.component';

@Component({
  selector: 'app-teacher-contact-us-page',
  templateUrl: './teacher-contact-us-page.component.html',
  styleUrls: ['./teacher-contact-us-page.component.scss']
})
export class TeacherContactUsPageComponent implements OnInit {

  busy = false
  isResult = false
  @ViewChild(LoadComponentDirective, { static: true}) loadComponent!: LoadComponentDirective

  constructor(private contactUsService: ContactUsService, 
    private router: Router,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.isResult = this.router.url.includes('result')
    this.onLoadComponent(this.isResult)
  }
  
  onLoadComponent(isResult: boolean) {
    this.loadComponent.viewContainerRef.clear()
    let componentRef;
    if(!isResult) {
      componentRef = this.loadComponent.viewContainerRef.createComponent(ContactUsComponent)
      componentRef.instance.msgEmitter.subscribe( event => {
        this.contactUsService.sendContactUs(event).pipe(
          tap(_ => this.busy = true),
          finalize( () => {
            this.busy = false
          })
        )
        .subscribe( _ => {
          this.router.navigate(
            ['./result'],
            {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            }
          )
          this.onLoadComponent(true)
        })      
      })
      componentRef.instance.busy = this.busy
    }
    else if(isResult) {
      componentRef = this.loadComponent.viewContainerRef.createComponent(ContactUsResultComponent)
      componentRef.instance.isLoggedIn = true
    }
  }
}