<div class="question" [ngClass]="getAccentColor()">
  <div class="d-flex justify-content-between">
    <div class="d-md-none row mb-2">
      <h4>{{ getShortName() }}</h4>
      <h4>{{ getStage() }}</h4>
    </div>
    <h4 class="d-none d-md-block">{{ getFullType() }}</h4>
    <button
      type="button"
      class="btn btn-outline-secondary question-mark rounded-pill btn-sm mb-3"
      (click)="instructionModal.show()"
    >
      <app-icon class="" name="question-mark"></app-icon>
    </button>
  </div>
  <p class="text-muted xsmall d-none d-md-block">
    {{
      "EXERCISES.DETAILS.QUESTION_SET.DESCRIPTION." + getDescriptionCode()
        | translate
    }}
  </p>
  <div class="d-flex flex-column justify-content-md-between gap-2">
    <div class="row mb-2 me-md-3 g-2">
      <exercise-square
        *ngFor="let exercise of getExercises()"
        [availability]="getAvailability(exercise)"
        [exercise]="exercise"
        [section]="section"
        class="col-auto"
      ></exercise-square>
    </div>
    <button
      type="button"
      class="d-flex flex-row btn btn-primary medium rounded-pill px-3 ms-auto align-items-center align-self-end"
      (click)="onContinue()"
    >
      {{ "BUTTON.CONTINUE" | translate }}
    </button>
  </div>
</div>


<app-instruction-modal #instructionModal [sectionPath]="section?.path" [exerciseName]="getShortName()">
</app-instruction-modal>
