import { Component, OnInit } from '@angular/core';
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {Router} from "@angular/router";

@Component({
  selector: 'app-order-fail',
  templateUrl: './order-fail.component.html',
  styleUrls: ['./order-fail.component.scss']
})
export class OrderFailComponent implements OnInit {

  constructor(private authService: AuthorizationServiceProvider,
              private router: Router) { }

  ngOnInit(): void {}

  public logout() {
    this.authService.getCommonRoutines().subscribe(api => api.logout());
  }

  routeToUserPage() {
    this.authService.getAuthDetailsService().subscribe(api => {
      if (api.isTokenValid())
        this.router.navigate( ['/'] ).then();
      else
        this.authService.getCommonRoutines().subscribe(api => api.logout());
    })
  }
}
