<div class="container-fluid bg-radial my-0 bg-watermark">
  <div
    class="container d-flex flex-column justify-content-around mt-0 pt-5 vh-100"
  >
    <div>
      <div class="row justify-content-center mb-5">
        <app-icon name="callan-logo" class="mb-3 logo-lg"></app-icon>
      </div>
      <div class="row text-center">
        <div class="col-10 mx-auto">
          <p class="fs-4">{{ 'COL.ORDER.SUCCESS.TITLE' | translate }}</p>
          <p class="text-muted position-relative mb-4">
            <span>{{ 'COL.ORDER.SUCCESS.DETAILS' | translate }}</span>
            <a href="mailto:zibi.wisniewski@callan.co.uk">{{ 'COL.ORDER.FAIL.CONTACT_US' | translate }}</a>.
          </p>
        </div>
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto">
          <button (click)="routeToUserPage()" type="button"
                  class="btn btn-primary btn-lg w-100 position-relative text-uppercase">
            {{ 'COL.ORDER.FAIL.TAKE_HOME' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <span class="text-muted small"> © 2021 Callan. All rights reserved. </span>
    </div>
  </div>
</div>
