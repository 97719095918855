import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FilterDefinition} from "../../../../model/CourseFilters";
import {AuthorizationServiceProvider} from "../../../../auth_profile/services/authorization-service.provider";
import {of, switchMap, tap} from "rxjs";
import { CourseFilterComponent } from 'src/app/utils/course-filter/course-filter.component';
import {ActivatedRoute, Router} from "@angular/router";
import {SpinnerService} from "../../../../utils/services/spinner.service";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {ApiProductContext} from "../../../model/rest-model";
import {ReserveLessonComponent} from "../../../components/student/reserve-lesson/reserve-lesson.component";
import {ModalComponent} from "../../../../utils/modal/modal.component";

@Component({
  selector: 'app-reserve-lesson-page',
  templateUrl: './reserve-lesson-page.component.html',
  styleUrls: ['./reserve-lesson-page.component.scss']
})
export class ReserveLessonPageComponent implements OnInit, AfterViewInit {

  @ViewChild("reserveLessonComponent", {static: true}) reserveLessonComponent: ReserveLessonComponent
  @ViewChild('selectCourseModal', {static: true}) selectCourseModal: ModalComponent
  productContext: ApiProductContext = new ApiProductContext()
  scheduleDate: Date
  studentId: number
  CourseFilterComponent = CourseFilterComponent;
  private _chosenCourse: FilterDefinition
  activeCourse: FilterDefinition
  set chosenCourse(lang: FilterDefinition) {
    this._chosenCourse = lang
    if(this.chosenCourse.code == 'ALL' && this.selectCourseModal.modal)
      this.selectCourseModal.show(false)
    this.getProductContext().subscribe()
  }
  get chosenCourse() {
    return this._chosenCourse
  }

  constructor(private authService: AuthorizationServiceProvider,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: SpinnerService,
              private cdr: ChangeDetectorRef,
              private studentRest: StudentRestServiceImpl) {}

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfStudentId()),
      tap(studentId => this.studentId = studentId),
      switchMap(_ => this.getProductContext())
    ).subscribe()
    if(!this.chosenCourse || this.chosenCourse.code == 'ALL')
      this.spinner.trace(
        of(null)
      ).subscribe()
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if(this.chosenCourse.code == 'ALL' && this.selectCourseModal.modal)
      this.selectCourseModal.show(false)
  }

  getProductContext() {
    if (!this.studentId || !this.chosenCourse?.filter)
      return of(null);

    return this.studentRest.getProductContext(this.studentId, this.chosenCourse.filter).pipe(
      tap(productContext => this.productContext = productContext)
    )
  }

  applyCourse() {
    this.chosenCourse = this.activeCourse
    if(this.chosenCourse && this.chosenCourse?.code != 'ALL')
      this.selectCourseModal.hide()
  }
}
