import { Component, Input, OnInit } from '@angular/core';
import { ItemAvailability } from '../../model/cspa/personal';
import { Chapter, ExerciseSet } from '../../model/cspa/struct';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() chapter?: Chapter
  @Input() exerciseSet?: ExerciseSet
  @Input() availability?: ItemAvailability

  constructor() { }
  forKids: boolean
  ngOnInit(): void {
    this.forKids = !!this.availability.path.startsWith('encfk')
  }

  getName() {
    return this.exerciseSet.name
  }
  getShortName() {
    return this.chapter.shortName
  }

  getProgress() {
    return this.availability?.progress == 1 ? `complete` : `${(this.availability?.progress * 100).toFixed(0)}%`
  }

  getProgressPosition() {
    return this.availability?.progress == 1 ? {left: `73%`} : {left: `${this.availability?.progress * 100 - 5}%`}
  }

  isCompleted() {
    return this.availability?.progress == 1 ? true : false
  }

  getCardStyle() {
    let style = `card lang-${this.exerciseSet?.path} stg-${this.chapter?.path.split('_')[1]}`
    if(this.forKids)
      style += ' text-black'
    return style
  }

  getImgSrc() {
    if(this.availability.path.startsWith('enbus'))
      return "../../../assets/exercise_covers_-_Business_English.svg"
    else if(this.availability.path.startsWith('sp'))
      return "../../../assets/exercise_covers_-_Spanish.svg"
    else if(this.availability.path.startsWith('encfk'))
      return "../../../assets/exercise_covers_-_Kids__English.svg"
    else if(this.availability.path.startsWith('en'))
      return "../../../assets/exercise_covers_-_English.svg"
    return null
  }

  getImgClass() {
    if(this.availability.path.startsWith('enbus'))
      return 'img-enbus'
    else if(this.availability.path.startsWith('sp'))
      return 'img-sp'
    else if(this.availability.path.startsWith('encfk'))
      return 'img-kids'
    else if(this.availability.path.startsWith('en'))
      return 'img-en'
    return null
  }

  getRouterLink() {
    return `../exercises/${this.exerciseSet?.path}/${this.chapter?.path}`
  }
}
