<div class="offcanvas offcanvas-end offcanvas-cart"
     [class]="isCartEmpty()? 'justify-content-between pb-7' : ''"
     data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" id="offcanvasCart">
  <div class="offcanvas-header">
    <h5 class="mb-0">Cart summary</h5>
    <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="offcanvas"></button>
  </div>
  <!-- EMPTY -->
  <div class="offcanvas-body flex-grow-0" *ngIf="isCartEmpty()">
    <h5 class="text-center text-empty">
      Your cart is empty.
    </h5>
  </div>
  <div class="offcanvas-footer text-center">
    <button type="button"
            class="btn btn-cart-secondary fs-6"
            *ngIf="isCartEmpty()"
            data-bs-dismiss="offcanvas">
      <app-icon name="arrow-left-long" class="me-2"></app-icon>
      Back to store
      <app-icon name="arrow-right-long" class="me-2 icon-transparent"></app-icon>
    </button>
  </div>
  <!-- NOT EMPTY -->
  <div class="d-flex justify-content-between flex-column cart-height"
       *ngIf="!isCartEmpty()">
    <div class="offcanvas-body">
      <div class="d-flex flex-column">
        <app-shopping-cart-product
          *ngFor="let product of products"
          [product]="product"
          class="border-bottom border-secondary py-4">
        </app-shopping-cart-product>
      </div>
    </div>
    <div class="offcanvas-footer text-start">
      <div class="d-flex flex-column">
        <p class="fw-bold mb-0">Total</p>
        <p class="fw-bold">{{ getTotal() }}</p>
        <button *ngIf="!isLoading"
                type="button"
                class="btn btn-cart-primary border-0 fs-6 py-3 mb-4"
                (click)="checkout()"
        >
          <app-icon name="arrow-left-long" class="me-2 icon-transparent"></app-icon>
          <span class="fw-bold text-white">Go to checkout</span>
          <app-icon name="arrow-right-long" class="ms-2"></app-icon>
        </button>
        <button *ngIf="isLoading"
                type="button"
                class="btn btn-cart-primary border-0 py-3 mb-4"
                disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    </div>
  </div>
</div>
