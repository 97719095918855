<div class="row border-top border-2 border-secondary mb-2 px-2 py-3">
  <div class="col-12 mb-2 d-flex d-lg-none">
    <div
      *ngIf="getPersonPhoto(comingLessonDetails.teacher?.person)"
      class="profile-photo rounded-circle initials-circle-lg my-auto"
      [style.background-image]="
        'url(' +
        getPersonPhoto(comingLessonDetails.teacher?.person) +
        ')'
      "
    ></div>
    <div
      *ngIf="!getPersonPhoto(comingLessonDetails.teacher?.person)"
      class="d-flex rounded-circle text-center bg-gray me-2 initials-circle-lg my-auto"
    >
      <span class="text-muted m-auto">
        {{ getInitials() }}
      </span>
    </div>
    <div id="coming-lesson-dialog" class="d-lg-none ms-auto my-auto">
        <p
          *ngIf="isLessonGoingToStartRegularly()"
          class="text-center mb-0"
        >
          <span>{{
            "COL.COURSES.COMING_LESSON.WILL_START" | translate
          }}</span
          ><br />
          <strong class="text-primary">{{ timePhrase }}</strong>
        </p>

        <p class="text-center" *ngIf="isTeacherMissingToStartLesson()">
          <span>{{
            "COL.COURSES.COMING_LESSON.WAITING_FOR_TEACHER" | translate
          }}</span
          ><br />
          <strong class="text-danger">{{ timePhrase }}</strong>
        </p>

        <p class="text-center" *ngIf="isLessonReadyToStart()">
          <span>{{
            "COL.COURSES.COMING_LESSON.TEACHER_READY" | translate
          }}</span
          ><br />
          <span *ngIf="mayEnterRoomBySkype()"
            ><span>{{
              "COL.COURSES.COMING_LESSON.CALL_ME_BY" | translate
            }}</span>
            <strong> {{ getLessonSkypeAddress() }}</strong></span
          >
          <span *ngIf="mayEnterVideoRoom()">{{
            "COL.COURSES.COMING_LESSON.JOIN_ROOM" | translate
          }}</span>
        </p>
      </div>
    </div>
  <div class="col-12 col-lg-4 col-xl-3 mb-2 mb-lg-0 d-flex border-lg-right border-secondary">
    <div
      *ngIf="getPersonPhoto(comingLessonDetails.teacher?.person)"
      class="profile-photo rounded-circle initials-circle-lg d-none d-lg-block me-lg-3"
      [style.background-image]="
        'url(' +
        getPersonPhoto(comingLessonDetails.teacher?.person) +
        ')'
      "
    ></div>
    <div
      *ngIf="!getPersonPhoto(comingLessonDetails.teacher?.person)"
      class="d-none d-lg-flex rounded-circle text-center bg-gray me-2 initials-circle-lg"
    >
      <span class="text-muted m-auto">
        {{ getInitials() }}
      </span>
    </div>
    <div>
      <p class="text-muted small mb-0">Teacher:</p>
      <h6 class="my-0">{{ getTeacherName() }}</h6>
    </div>
  </div>
  <div
    class="col-12 col-lg-2 col-xl-3 mb-2 mb-lg-0 border-lg-right border-secondary"
    *ngIf="hasComingDetails()"
  >
    <p class="text-muted small mb-0">Course:</p>
    <span *ngIf="!isProva()">{{ getCourseName() }}</span>
    <span *ngIf="isProva()">
      <span>{{ getCourseName() }} </span>
      <span>{{ "COL.LESSONS.DETAILS.PROVA" | translate }}</span>
    </span>
  </div>
  <div class="col-12 col-lg-2 col-xl-3 mb-2 mb-lg-0 border-lg-right border-secondary" *ngIf="hasComingDetails()">
    <p class="text-muted small mb-0">{{ "COL.COURSES.COMING_LESSON.PLANNED_TO_START" | translate }}:</p>
    <span>
        {{getPlannedStartDate() | date: 'dd MMM yyyy'}}
      {{getPlannedStartDate() | date: 'HH:mm'}}
    </span>
  </div>
  <div class="col-4 col-lg-3 d-none d-lg-block">
    <div id="coming-lesson-dialog_lg" class="text-muted">
      <p *ngIf="isLessonGoingToStartRegularly()">
        <span class="small">{{ "COL.COURSES.COMING_LESSON.WILL_START" | translate }}</span
        ><br />
        <strong class="text-white">{{ timePhrase }}</strong>
      </p>

      <p *ngIf="isTeacherMissingToStartLesson()">
        <span class="small">{{
          "COL.COURSES.COMING_LESSON.WAITING_FOR_TEACHER" | translate
        }}</span
        ><br />
        <strong class="text-white">{{ timePhrase }}</strong>
      </p>

      <p *ngIf="isLessonReadyToStart()">
        <span>{{
          "COL.COURSES.COMING_LESSON.TEACHER_READY" | translate
        }}</span
        ><br />
        <span *ngIf="mayEnterRoomBySkype()"
          ><span>{{
            "COL.COURSES.COMING_LESSON.CALL_ME_BY" | translate
          }}</span>
          <strong> {{ getLessonSkypeAddress() }}</strong></span
        >
        <span *ngIf="mayEnterVideoRoom()">{{
          "COL.COURSES.COMING_LESSON.JOIN_ROOM" | translate
        }}</span>
      </p>
    </div>
  </div>
  <div class="col-12 col-lg-3 mx-auto mt-4">
    <div id="coming-lesson-actions" class="d-flex text-center flex-column">
      <div
        class="text-lg-right mx-auto mx-lg-0 mt-4 mt-lg-0 me-lg-3"
        *ngIf="mayEnterVideoRoom()"
      >
        <a
          class="btn btn-primary rounded-pill"
          id="coming-lesson-actions-classroom"
          [target]="getTarget()"
          tabindex=""
          [href]="getRoomUrl()"
        >
          {{ "COL.COURSES.COMING_LESSON.ENTER_ROOM" | translate }}
        </a>
        <a
          (click)="openSkypeHelp()"
          tabindex=""
          class="d-block text-decoration-none small"
          [target]="getTarget()"
          >{{ "COL.COURSES.COMING_LESSON.CONNECTION_PROBLEMS" | translate }}
        </a>
      </div>
      <div
        class="text-lg-right mx-auto mx-lg-0 mt-4 mt-lg-0 me-lg-3"
        *ngIf="mayEnterRoomBySkype()"
      >
        <a
          id="coming-lesson-actions-skype"
          class="btn btn-primary rounded-pill"
          [href]="getRoomUrl()"
          tabindex=""
          >{{ "BUTTON.CALL_SKYPE" | translate }}</a
        >
      </div>
      <span
        class="d-block w-100 text-muted mt-3"
        *ngIf="!mayEnterClassroom()"
        tabindex=""
        >{{ "COL.COURSES.COMING_LESSON.ROOM_CLOSED" | translate }}</span
      >
    </div>
  </div>
</div>

<app-modal #classroomFallback>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        {{ "COL.COURSES.COMING_LESSON.CONNECTION_PROBLEMS" | translate }}
      </h5>
      <button
        type="button"
        class="btn-close"
        (click)="classroomFallback.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <p>
        <span>{{ "COL.COURSES.COMING_LESSON.ABOUT_REFRESH" | translate }}</span>
        <i class="fas fa-redo ms-2"></i>
      </p>
      <p>
        <span>
          {{ "COL.COURSES.COMING_LESSON.ABOUT_TEACHER_SKYPE" | translate }}
        </span>
        <a class="ms-2" [href]="getTeacherSkypeUrl()">
          {{ getTeacherSkype() }}
        </a>
      </p>
      <div class="text-end">
        <button
          type="button"
          class="btn btn-primary"
          (click)="classroomFallback.hide()"
        >
          {{ "BUTTON.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal>
