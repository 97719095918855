<div class="container-xl">
  <div class="row align-items-center">
    <div class="col-10 p-0">
      <h5 class="font-weight-bold mb-0">{{ getName() }}</h5>
    </div>
    <div class="col-2 p-0 text-end">
      <button class="btn" (click)="delete()">
        <app-icon name="delete-2" class="icon-danger"></app-icon>
      </button>
    </div>
  </div>
  <div class="row align-items-center justify-content-between">
    <div class="col px-0">
      <label class="text-muted xsmall d-block">Amount</label>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn py-2 px-3" (click)="decrement()">-</button>
        <button type="button" class="btn py-2 px-3 pe-none">{{ getQuantity() }}</button>
        <button type="button" class="btn py-2 px-3" (click)="increment()">+</button>
      </div>
    </div>
    <div class="col text-end mt-4">
      <span>{{ getSum() }}</span>
    </div>
  </div>
</div>
