import { Observable } from "rxjs";
import {AuthResponse} from "../../model/prototypes";
import {ContactUs, PasswordChangeRequest} from "../../../books/model/book-model";

export enum UserRole {
  // user is unauthorized
  Unaffiliated = "Unaffiliated",
  Unauthorized = "Unauthorized",
  Student = "Student",
  Teacher = "Teacher",
  Admin = "Admin",
  Manager = "Manager",
  // Role is unknown for the application or impossible to recognize, threat it as Unauthorized
  Unknown = "Unknown",
}

/**
 * WebAuthorization is responsible for managing authorization token by itself.
 * Methods won't be used when application works in mobile mode.
 */
export interface WebAuthService extends CommonAuthorizationRoutines {
  /** clear authorization context **/
  clear(): void;
  startLogin(stateUrl: string);
  authorizeCode(code: string): Observable<AuthResponse>;
  refreshToken(): Observable<AuthResponse>;
  getAccessToken(): string;
  resolveState(state: string): string;
}

/**
 * Authorization interface extension, used only when emulator service is detected (invoked by oauth to
 * finish device id assigment)
 */
export interface EmulatedAuthService extends MobileAuthorizationApiInterface {
  assignDeviceId(): Observable<any>;
  storeDeviceId(deviceId: string);
  loginWithPlainToken(token: string);
}

/**
 * Expected authorization api for regular functionality - implemented on all platforms, providing
 * authorized user details
 */
export interface AuthorizedUserDetailsService {
  isTokenValid(): boolean;
  getUserRole(): Observable<UserRole>;
  getUserName(): Observable<string>;
  getUserEmail(): Observable<string>;
  getSubject(): Observable<string>;
  getSelfStudentId(): Observable<number>;
  getSelfTeacherId(): Observable<number>;
  getSelfSchoolId(): Observable<number>;
}

/**
 * This is the most common and required calls supported by all possible implementations as minimum
 */
export interface CommonAuthorizationRoutines {
  logout();
  isTokenValid(): boolean;
  isNativeAuthorization(): boolean;
  changePassword(request: PasswordChangeRequest, lang?: string): Observable<void>;
  contactUs(request: ContactUs): Observable<void>;
}

/**
 * Expected mobile functionalities
 */
export interface MobileAuthorizationApiInterface
  extends CommonAuthorizationRoutines, AuthorizedUserDetailsService
{
  ssoLogin(): Observable<any>;
  getDeviceId(): Observable<string>;
  getAccessTokenObservable(): Observable<string>
  closeApp(): Observable<any>;
  hideKeyboard(): Observable<any>;
  listenOnBackButton(): Observable<any>;
  listenOnImeButton(): Observable<any>;
  getNativeApiVersion(): Observable<string>;
}

 // internals are used by the provider internally
export interface AuthorizationServiceInternals {
   initialize();
}
