import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LogsService {
    log(...value: any[]) {
        if (environment.debug) {
            console.log(...value);
        }
    }
    forceLog(...value: any[]) {
      console.log(...value);
    }
    error(...value: any[]) {
      console.error(value);
    }
}
