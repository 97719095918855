import {Component, Input, OnInit} from '@angular/core';
import {auditTime, interval, Subscription, switchMap, tap, zip} from "rxjs";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile
} from "../../../model/rest-model";
import {TimeUnits} from "../../../../utils/calendar-utils";
import {WebSocketService} from "../../../services/web-socket.service";
import {environment} from "../../../../../environments/environment";
import {SpinnerService} from "../../../../utils/services/spinner.service";

@Component({
  selector: 'app-coming-lesson-list',
  templateUrl: './coming-lesson-list.component.html',
  styleUrls: ['./coming-lesson-list.component.scss']
})
export class ComingLessonListComponent implements OnInit {

  comingLessons: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[];
  private _studentId: number;
  reloadLessonIntervalSubscription: Subscription;
  wsSubscription: Subscription

  @Input()
  _introductionState;
  @Input()
  set studentId(studentId: number) {
    if (!studentId) return;
    this._studentId = studentId;
    this.loadComingLesson();
    this.listenToWebSocket();
  }
  get studentId(): number {
    return this._studentId;
  }

  constructor(
    private studentRest: StudentRestServiceImpl,
    private webSocket: WebSocketService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.reloadLessonIntervalSubscription = interval(
      TimeUnits.Minutes(1).toMilis()
    ).subscribe(() => this.loadComingLesson());
  }

  ngOnDestroy(): void {
    this.reloadLessonIntervalSubscription.unsubscribe();
    this.wsSubscription.unsubscribe();
  }

  listenToWebSocket(): any {
    if (!this._studentId) {return; }
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint).pipe(
      switchMap(connection => zip(
        connection.subscribe(
          `/col/student/${this._studentId}/LessonListUpdate`
        ),
        connection.subscribe(
          `/col/student/${this._studentId}/LessonUpdate`
        )
      )),
      auditTime(1000)
    ).subscribe(() => {
      this.loadComingLesson()
    })
  }

  loadComingLesson() {
    if (!this.studentId) {
      return;
    }
    this.spinner
      .trace<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(
        this.studentRest.findUpcomingNextLessonsMultiple(this.studentId).pipe(
          tap((lesson) => {
            this.comingLessons = lesson;
          })
        )
      ).subscribe();
  }
}
