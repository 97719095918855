import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import {ExamSession, ExerciseSession} from '../cspa/model/cspa/personal';

@Injectable({
  providedIn: 'root'
})

export class SessionInfo {
  questionNb: string
  session: ExerciseSession | ExamSession
  progressPercent?: number
}

export class SessionInfoService {

  private sessionInfo = new ReplaySubject<SessionInfo>(1);

  constructor() { }

  setSessionInfo(progressInfo: SessionInfo): void{
    this.sessionInfo.next(progressInfo)
  }

  getSessionInfo(): Observable<SessionInfo>{
    return this.sessionInfo.asObservable()
  }
}
