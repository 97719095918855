import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { LangProductMapper } from '../../utils/lang-mappers';

@Component({
  selector: 'app-student-course-lesson-details-page',
  templateUrl: './student-course-lesson-details-page.component.html',
  styleUrls: ['./student-course-lesson-details-page.component.scss'],
})
export class StudentCourseLessonDetailsPageComponent implements OnInit {
  private langCode: string;
  studentId: number;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthorizationServiceProvider
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap((params) => (this.langCode = params.get('langCode'))),
      switchMap((_) => this.auth.getAuthDetailsService()),
      switchMap((auth) => auth.getSelfStudentId()),
      tap((id) => (this.studentId = id))
    ).subscribe();
  }

  getCourseName() {
    return LangProductMapper.mapLangCodeToLangName(this.langCode);
  }
}
