<table class="table white-text mb-0" *ngIf="hasRooms(); else noResults">
  <thead class="small">
    <tr class="table-row table-row-head">
      <th scope="col">{{ "LESSONS.ROOM_LIST.NAME" | translate }}</th>
      <th scope="col">{{ "LESSONS.ROOM_LIST.STATUS" | translate }}</th>
      <th scope="col">
        {{ "LESSONS.ROOM_LIST.ATTENDEES" | translate }}
      </th>
    </tr>
  </thead>
  <tbody class="table-body small">
    <tr
      class="table-row table-row-body table-row-hover align-middle"
      [routerLink]="getPathToDetails(room)"
      *ngFor="let room of getRooms()"
    >
      <td>
        <span>{{ getRoomName(room) }}</span>
        <p class="text-muted mb-0">
          {{ 'GROUPS.LESSON_LIST.OPENED_AT' | translate }}  {{ room.createDate | date: 'HH:mm (MMM dd)' }}
        </p>
        <p *ngIf="room.provisioning.created" class="text-muted mb-0">{{ 'GROUPS.LESSON_LIST.STARTED_AT' | translate }} {{ room.provisioning.created | date: 'HH:mm (MMM dd)' }}
        <p *ngIf="room.provisioning.endTime" class="text-muted mb-0">{{ 'GROUPS.LESSON_LIST.FINISHED_AT' | translate }} {{ room.provisioning.endTime | date: 'HH:mm (MMM dd)' }}
        <p *ngIf="room.provisioning.duration" class="text-muted mb-0">{{ 'GROUPS.LESSON_LIST.DURATION' | translate: { duration: getRoomDuration(room) }  }}
      </td>
      <td>
        <span class="text-capitalize">
          {{ getRoomStatus(room) | translate }}
        </span>
      </td>
      <td>
        <p class="mb-1">{{ getRoomTeacher(room) }}</p>
        <p class="text-muted">
          {{ getRoomAttendees(room) }}
          {{ "LESSONS.ROOM_LIST.STUDENTS" | translate }}
        </p>
      </td>
    </tr>
  </tbody>
</table>
<ng-template #noResults>
  <div class="row">
    <p class="text-center fs-5 text-muted m-0">
      {{ "LESSONS.NO_RESULTS" | translate }}
    </p>
  </div>
</ng-template>
