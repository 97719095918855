<div class="card mb-4 mb-md-5">
  <app-week-calendar
    class="mb-2"
    [loading]="loading"
    [events]="calendarEntries"
    (dateUpdate)="focusDate = $event"
    (timeSelect)="onCalendarClick($event)"
  ></app-week-calendar>
</div>

<app-modal #createWorktimeModal [scrollable]="false">
  <div class="modal-content modal-create-worktime mt-0">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        {{ 'COL.TEACHER.CREATE_WORKTIME.TITLE' | translate }} {{ newWorktimeDate | dateTranslation : "EEE, dd MMM yyyy" }}
      </h5>
      <button
        type="button"
        class="btn-close"
        (click)="createWorktimeModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form
      #g="ngForm"
      (ngSubmit)="g.form.valid && saveWorktime()"
      class="overflow-scroll"
    >
      <div class="modal-body pb-0">
        <div class="row">
          <div class="col-12">
            <div class="form-floating mb-3">
              <input
                type="text"
                name="worktimeStartTime"
                class="form-control"
                [(ngModel)]="newWorktimeStartTime"
                placeholder="hh:mm"
                pattern="^((\d)|([0,1]\d)|(2[0-3])):[0,3][0]$"
                #startTime="ngModel"
                [ngClass]="{ 'is-invalid': g.submitted && startTime.invalid }"
                required="required"
              />
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.START' | translate }}</label>
              <span class="small text-muted"
                >{{ 'COL.TEACHER.CREATE_WORKTIME.MINIMUM_TIME' | translate }}</span
              >
              <div *ngIf="startTime.invalid" class="invalid-feedback">
                <div *ngIf="startTime.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
                <div *ngIf="startTime.errors?.['pattern']">
                  {{ 'COL.TEACHER.CREATE_WORKTIME.ERROR.TIME_PATTERN' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <input
                name="worktimeFinish"
                type="text"
                class="form-control"
                [(ngModel)]="newWorktimeFinishTime"
                placeholder="hh:mm"
                pattern="^((\d)|([0,1]\d)|(2[0-3])):[0,3][0]$"
                #worktimeFinish="ngModel"
                [ngClass]="{
                  'is-invalid': g.submitted && worktimeFinish.invalid
                }"
                required="required"
              />
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.END' | translate }}</label>
              <span class="text-muted small"
                >{{ 'COL.TEACHER.CREATE_WORKTIME.SHORTEST_TIME' | translate }}</span
              >
              <div *ngIf="worktimeFinish.invalid" class="invalid-feedback">
                <div *ngIf="worktimeFinish.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
                <div *ngIf="worktimeFinish.errors?.['pattern']">
                  {{ 'COL.TEACHER.CREATE_WORKTIME.ERROR.TIME_PATTERN' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <select
                required
                class="form-select select-not-empty"
                [(ngModel)]="newWorktimeOvertake"
                name="worktimeOvertake"
                #worktimeOvertake="ngModel"
                [ngClass]="{
                  'is-invalid': g.submitted && worktimeOvertake.invalid
                }"
              >
                <option value="0">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.ALWAYS' | translate }}</option>
                <option value="1800000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.0_5' | translate }}</option>
                <option value="3600000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.1' | translate }}</option>
                <option value="5400000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.1_5' | translate }}</option>
                <option value="7200000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.2' | translate }}</option>
                <option value="10800000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.3' | translate }}</option>
                <option value="14400000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.4' | translate }}</option>
                <option value="18000000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.5' | translate }}</option>
                <option value="21600000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.6' | translate }}</option>
                <option value="25200000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.7' | translate }}</option>
                <option value="28800000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.8' | translate }}</option>
                <option value="57600000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.16' | translate }}</option>
                <option value="86400000">{{'COL.TEACHER.CREATE_WORKTIME.HOURS.24' | translate }}</option>
              </select>
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.BOOKING_TIME' | translate }}</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <select
                required
                class="form-select"
                [(ngModel)]="newWorktimeProduct"
                name="newWorktimeProduct"
                [ngClass]="{ 'is-invalid': g.submitted && product.invalid, 'select-not-empty' : newWorktimeProduct }"
                #product="ngModel"
              >
                <option [value]="allProductsCode">{{ 'COL.TEACHER.CREATE_WORKTIME.ALL_PRODUCTS' | translate }}</option>
                <option *ngFor="let prod of availableProducts" [value]="prod">
                  {{ getProductName(prod) | translate }}
                </option>
              </select>
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.CHOOSE_COURSE' | translate }}</label>
              <div
                *ngIf="g.submitted && product.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="product.errors?.['required']">
                 {{'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <select
                required
                class="form-select select-not-empty"
                name="recurring"
                [(ngModel)]="newWorktimeRecurring"
                [ngClass]="{ 'is-invalid': g.submitted && recurring.invalid}"
                #recurring="ngModel"
              >
                <option *ngFor="let rec of recurringUtils.values" [value]="rec">
                  {{ recurringUtils.getName(rec) | translate }}
                </option>
              </select>
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.RECURRING_TYPE' | translate }}</label>
              <div
                *ngIf="g.submitted && recurring.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="recurring.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="form-floating mb-3"
              *ngIf="newWorktimeRecurring != recurringTypes.Single"
            >
              <input
                type="date"
                required
                name="recurringFinish"
                class="form-control"
                [(ngModel)]="newRecurringFinishDate"
                #recurringFinish="ngModel"
                [appAfterDate]="minRecurring"
                [ngClass]="{
                  'is-invalid': g.submitted && recurringFinish.invalid
                }"
              />
              <label>{{ 'COL.TEACHER.CREATE_WORKTIME.RECURRING_FINISH_DATE' | translate }}</label>
              <div *ngIf="recurringFinish.invalid" class="invalid-feedback">
                <div *ngIf="recurringFinish.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
                <div
                  *ngIf="!recurringFinish.errors?.['required'] && recurringFinish.errors?.['invalidDate']"
                >
                  {{ 'COL.TEACHER.CREATE_WORKTIME.INVALID_DATE' | translate }}
                </div>
              </div>
            </div>
            <div
              *ngIf="
                newWorktimeDays && newWorktimeRecurring == recurringTypes.Daily
              "
            >
              <div
                class="form-check form-check-inline"
                *ngFor="let weekDay of weekDays"
              >
                <input
                  class="form-check-input"
                  name="weekdays"
                  type="checkbox"
                  value="weekDay"
                  [(ngModel)]="newWorktimeDays[weekDay]"
                />
                <label class="form-check-label">{{
                  getWeekDayName(weekDay) | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-secondary rounded-pill"
          (click)="createWorktimeModal.hide()"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button type="submit" class="btn btn-primary rounded-pill">
          {{ "BUTTON.SAVE" | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
<app-modal #deleteWorktimeModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        {{ "COL.TEACHER.DELETE_WORKTIME.TITLE" | translate }}
      </h5>
      <button
        type="button"
        class="btn-close"
        (click)="deleteWorktimeModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form #wdf="ngForm" (ngSubmit)="deleteWorktime()">
      <div class="modal-body pb-0">
        <p>
          {{ "COL.TEACHER.DELETE_WORKTIME.ARE_YOU_SURE" | translate }}
          {{ eventToDelete?.dateFrom | dateTranslation : "EEE, dd MMM yyyy HH:mm" }}?
        </p>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="deleteRecurring"
              [(ngModel)]="deleteRecurring"
            />
            <label class="form-check-label">{{
              "COL.TEACHER.DELETE_WORKTIME.DELETE_RECURRING_PERIODS" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-secondary rounded-pill"
          (click)="deleteWorktimeModal.hide()"
        >
          {{ "BUTTON.CLOSE" | translate }}
        </button>
        <button type="submit" class="btn btn-danger rounded-pill">
          {{ "BUTTON.DELETE" | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
