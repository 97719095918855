import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiCountry} from "src/app/auth_profile/model/casa/casa-model";
import {LessonType} from "src/app/col/model/calendar";
import {ApiProduct} from "src/app/col/model/products";
import {
  ApiCompetence,
  ApiCourse,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonBundle,
  ApiLessonCommit,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiProductGift, ApiProvaContext,
  ApiStudentProductContext, ApiStudentSchedule,
  ApiTeacherProfile,
  ApiTeacherWorktime, ApiWorktimeDefinition,
  EntityRelatedRow,
  LessonBundleFilter,
  StudentCommitsFilter
} from "src/app/col/model/rest-model";
import {ItemAvailability} from "src/app/cspa/model/cspa/personal";
import {Chapter, ExerciseSet} from "src/app/cspa/model/cspa/struct";
import {Page, Pageable} from "src/app/utils/pageable";
import {ColNativeServiceApi} from "../col-native-api";
import {
  ApiLessonEventModel,
  LessonScheduleEventReference,
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleLessonScheduleRequest,
  SimpleProductAvailability,
  SimpleScheduleEvents,
  SimpleTeacherProductAvailability
} from "../../../model/rest-model-v2";

@Injectable({
  providedIn: 'root'
})
export class UnsupportedColNativeServiceApi implements ColNativeServiceApi {
  reserveScheduleV2(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents> {
      throw new Error("Method not implemented.");
  }
  listLessonTypesReportForTeacher(teacherId: number, dateFrom: Date, dateTo: Date, productCode: string): Observable<EntityRelatedRow<ApiTeacherProfile>[]> {
    throw new Error("Method not implemented.");
  }
  getProfile(teacherId: number): Observable<ApiTeacherProfile> {
    throw new Error("Method not implemented.");
  }
  queryForStudentsById(teacherId: number, studentIds: number[]): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
    throw new Error("Method not implemented.");
  }
  listLessonHistory(teacherId: number, lessonId: number, studentId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    throw new Error("Method not implemented.");
  }
  registerLessonFlag(teacherId: number, lessonId: number, lessonFlag: ApiLessonFlag): Observable<ApiLessonFlag> {
    throw new Error("Method not implemented.");
  }
  findStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<ApiProductGift> {
    throw new Error("Method not implemented.");
  }
  giveStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<void> {
    throw new Error("Method not implemented.");
  }
  updateLessonType(teacherId: number, lessonId: number, lessonType: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  finishLesson(teacherId: number, lessonId: number, progress: ApiLessonProgress, finishDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  updateLessonProgress(teacherId: number, lessonId: number, progress: ApiLessonProgress): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  saveStudentProductContext(teacherId: number, studentId: number, productCode: string, context: ApiProductContext): Observable<ApiProductContext> {
    throw new Error("Method not implemented.");
  }
  postLessonComment(teacherId: number, lessonId: number, message: ApiLessonMessage): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  postLessonMessageForStudent(teacherId: number, lessonId: number, studentId: number, message: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  cancelLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  squanderLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  getStudentTechnicalProfile(teacherId: number, studentId: number): Observable<ApiPersonTechnicalProfile> {
    throw new Error("Method not implemented.");
  }
  getCountries(locale?: string): Observable<ApiCountry[]> {
    throw new Error("Method not implemented.");
  }
  createVideoClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  createSkypeClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  notifyStudentAboutLessonStart(teacherId: number, lessonId: number): Observable<void> {
    throw new Error("Method not implemented.");
  }
  commitLessonBooking(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  startLesson(teacherId: number, lessonId: number, startDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  getLessonById(teacherId: number, lessonId: number, studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  estimateNextProgress(teacherId: number, studentId: number, baseProgress: ApiLessonProgress, limit?: number): Observable<ApiLessonProgress[]> {
    throw new Error("Method not implemented.");
  }
  getStudentProductContext(teacherId: number, student: number, productCode: string): Observable<ApiStudentProductContext> {
    throw new Error("Method not implemented.");
  }
  getProductCourses(productCode: string): Observable<ApiCourse[]> {
    throw new Error("Method not implemented.");
  }
  getCourseAllowedCompetences(courseCode: string): Observable<ApiCompetence[]> {
    throw new Error("Method not implemented.");
  }
  getProductProgressEstimation(studentId: number, productCode: string): Observable<ApiLessonProgress[]> {
    throw new Error("Method not implemented.");
  }
  getTeachers(studentId: number, teacherIds?: number[]): Observable<ApiTeacherProfile[]> {
    throw new Error("Method not implemented.");
  }
  getProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    throw new Error("Method not implemented.");
  }
  getCommits(studentId: number, filter: StudentCommitsFilter, pageable: Pageable): Observable<Page<ApiLessonCommit>> {
    throw new Error("Method not implemented.");
  }
  getStudentLessonBundles(studentId: number, filter: LessonBundleFilter, pageable: Pageable): Observable<Page<ApiLessonBundle>> {
    throw new Error("Method not implemented.");
  }
  getExerciseSets(): Observable<ExerciseSet[]> {
    throw new Error("Method not implemented.");
  }
  getChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    throw new Error("Method not implemented.");
  }
  getAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    throw new Error("Method not implemented.");
  }
  getProductsList(lang: string, currency: string): Observable<ApiProduct[]> {
    throw new Error("Method not implemented.");
  }
  getLessons(studentId: number, pageable: Pageable, lessonType: LessonType, isTeacher: boolean, lang?: string): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    throw new Error("Method not implemented.");
  }
  getStudentProgress(studentId: number): Observable<ApiLessonProgress[]> {
    throw new Error("Method not implemented.");
  }
  findUpcomingNextLesson(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  getLesson(studentId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  cancelLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }
  squanderLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    throw new Error("Method not implemented.");
  }

  findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    return undefined;
  }

  updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string): Observable<ApiStudentProductContext> {
    return undefined;
  }

  createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference> {
    return undefined;
  }

  deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void> {
    return undefined;
  }

  getLessonScheduleEventReference(studentId: number, scheduleId: number): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
    return undefined;
  }

  listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    return undefined;
  }

  listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    return undefined;
  }

  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<SimpleScheduleEvents[]> {
    return undefined;
  }

  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]> {
    return undefined;
  }

  reserveSchedule(studentId: number, schedule: ApiStudentSchedule): Observable<ApiStudentSchedule> {
    return undefined;
  }

  createWorktime(teacherId: number, definition: ApiWorktimeDefinition): Observable<ApiWorktimeDefinition> {
    return undefined;
  }

  deleteWorktime(teacherId: number, worktimeId: number, applyRecurring: boolean): Observable<void> {
    return undefined;
  }

  listStudentWeekSchedules(studentId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    return undefined;
  }

  listTeachersWeekAvailability(studentId: number, focusDate: Date, productCode: string): Observable<ApiTeacherWorktime[]> {
    return undefined;
  }

  listWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    return undefined;
  }

  listWorktimeWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiTeacherWorktime[]> {
    return undefined;
  }

  getProvaContext(studentId: number, productCode?: string): Observable<ApiProvaContext[]> {
    throw new Error("Method not implemented.");
  }

  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    throw new Error("Method not implemented.");
  }

  chargeFreeCredit(studentId: number, productCode: string): Observable<ApiLessonBundle> {
    throw new Error("Method not implemented.");
  }
}
