import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

export class PictureCardItem {
  public selected = false;
  public reversed = false;
  constructor(public word: string, public image: string) {}
}

@Component({
  selector: 'picture-card',
  templateUrl: './picture-card.component.html',
  styleUrls: ['./picture-card.component.scss'],
  animations: [
    trigger('inverse', [
      state('aversed', style({
        animation: 'flip-vertical-right 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both'
      })),
      state('inversed', style({
        animation: 'flip-vertical-left 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both'
      })),
    ])
  ]
})
export class PictureCardComponent{

  @Input()
  data: PictureCardItem;
  @Output()
  cardClick = new EventEmitter<PictureCardItem>();

  constructor() { }

  isPicture() {
    return this.data && this.data.image;
  }

  isSelected() {
    return this.data && this.data.selected;
  }

  isWord() {
    return this.data && this.data.word;
  }

  isReversed() {
    return this.data && this.data.reversed;
  }

  getImageSrc() {
    return this.data ? environment.awsBase + this.data.image : null;
  }

  getWord() {
    return this.data ? this.data.word : null;
  }

  onClick() {
    this.cardClick.emit(this.data);
  }
}
