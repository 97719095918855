<div
  class="row justify-content-center justify-content-md-start row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4"
>
  <div
    class="col d-flex mb-3 mb-md-4 position-relative cursor-pointer"
    *ngFor="let teacherProfile of contextTeachers"
  >
    <div class="square mx-auto w-100">
      <a
        *ngIf="hasImage(teacherProfile)"
        (click)="showTeacherDetails(teacherProfile)"
      >
        <div
          class="teacher-profile-photo"
          [style.background-image]="'url(' + getPhoto(teacherProfile) + ')'"
        ></div>
      </a>
      <a
        *ngIf="!hasImage(teacherProfile)"
        (click)="showTeacherDetails(teacherProfile)"
        class="teacher-initials-box"
      >
        <span class="position-absolute teacher-initials">{{
          getInitials(teacherProfile)
        }}</span>
      </a>
      <h4 class="teacher-name mb-0">
        {{ getName(teacherProfile) }}
      </h4>
    </div>
  </div>
</div>

<app-modal #teacherDetailsModal>
  <div class="modal-content mt-0" *ngIf="selectedTeacher">
    <div class="modal-header border-0">
      <h5 class="modal-title mb-0">{{ 'COL.COURSES.TEACHERS.TEACHER_PROFILE' | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="teacherDetailsModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div
          *ngIf="hasImage(selectedTeacher)"
          class="col-6 col-md-6 col-lg-5"
        >
          <div class="mx-auto w-100">
            <a (click)="showTeacherDetails(selectedTeacher)">
              <div
                class="teacher-profile-photo"
                [style.background-image]="
                  'url(' + getPhoto(selectedTeacher) + ')'
                "
              ></div>
            </a>
          </div>
        </div>
        <div
          [class]="hasImage(selectedTeacher)? 'col-6 col-lg-7':  'col-12'"
          class="d-flex flex-column justify-content-between"
        >
          <div>
            <h4>{{ getName(selectedTeacher) }}</h4>
            <p *ngIf="getCompetence()" class="text-muted">
              {{ getCompetence().productCompetenceDescription }}
            </p>
          </div>
          <p *ngIf="getCompetence()" class="text-primary text-end mb-0">
            <span>{{ 'COL.COURSES.TEACHERS.ACCENT' | translate }}</span>
            {{ getCompetence().accent }}
          </p>
        </div>
      </div>
      <div *ngIf="hasSafeVideoUrl()" class="row mt-3 mt-md-5">
        <div class="col-12">
          <div class="ratio ratio-16x9">
            <iframe
              [src]="getVideoUrl()"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
