<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.LESSONS'"
           [redirectLink]="'../../'"
           [redirectTranslationCodes]="'NAVIGATION.LESSONS'"
           class="accent">
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../..">
                  {{ "NAVIGATION.LESSONS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../..">
                  {{ "LESSONS.WEEKLY_PLANNED_LESSONS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getScheduleName() || "Room not created" }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <section *ngIf="!room" class="text-center mt-5">
      <p class="fs-4 text-muted mb-1">
        {{ "LESSONS.SCHEDULE_DETAILS.CONTENT_1" | translate }}
      </p>
      <p class="fs-2 mb-1">{{ getScheduleDatePlanned() }}</p>
      <p class="fs-4 text-muted">
        {{ "LESSONS.SCHEDULE_DETAILS.CONTENT_2" | translate }}
      </p>
    </section>
    <room-details *ngIf="room" [room]="room"></room-details>
  </div>
</div>
