<div class="card mb-4 mb-md-5" *ngIf="lessonDetails">
  <div class="card-header">
    <h6 class="mb-0">
      {{ getLessonDate(lessonDetails) | dateTranslation : "EEEE, dd MMM yyyy" }} at
      {{ getLessonDate(lessonDetails) | date : "HH:mm" }}
      <strong *ngIf="getLessonStartedDate(lessonDetails)"
        >(started at:
        {{ getLessonStartedDate(lessonDetails) | date : "HH:mm" }})</strong
      >
    </h6>
    <span class="small text-muted">
      {{ lessonDetails.course.name }} • {{ lessonDetails.lessonType }} •
      {{ getLessonStatusTranslation() | translate }}
    </span>
    <span *ngIf="getLessonProductVersion()" class="ms-1" [ngClass]="{'badge bg-danger': isLessonInOldVersion()}">
        {{getLessonProductVersion()}}
    </span>
  </div>
  <div class="card-body">
    <div class="row mb-sm-3">
      <div class="col-12 col-sm-6 col-lg-auto ms-lg-auto me-lg-5 order-sm-1">
        <div class="row">
          <div class="col-auto">
            <div
              *ngIf="getLessonStudentPhotoUrl(lessonDetails)"
              class="profile-photo rounded-circle initials-circle-lg my-auto"
              [style.background-image]="
                'url(' + getLessonStudentPhotoUrl(lessonDetails) + ')'
              "
            ></div>
            <div
              *ngIf="!getLessonStudentPhotoUrl(lessonDetails)"
              class="d-flex rounded-circle text-center bg-gray initials-circle-lg my-auto"
            >
              <span class="text-muted m-auto">
                {{ getInitials(lessonDetails) }}
              </span>
            </div>
          </div>
          <div class="col">
            <p class="mb-0">
              <span>{{getStudentName(lessonDetails)}} </span>
              <span *ngIf="getProductVersion()" class="ms-1" [ngClass]="{'badge bg-danger': isRedLabel()}">
                ({{getProductVersion()}})
              </span>
            </p>
            <p
              class="small text-muted mb-0"
              *ngIf="studentTechnicalProfile && studentTechnicalProfile.skype"
            >
              {{ 'PROFILE.FORM.CONTACT_INFO.SKYPE' | translate }}: {{ studentTechnicalProfile.skype }}
            </p>
            <p class="small text-muted mb-0" *ngIf="getStudentNationality()">
              {{ 'PROFILE.FORM.PERSONAL_INFO.NATIONALITY' | translate }}: {{ getStudentNationality() }}
            </p>
            <p class="small text-muted mb-0" *ngIf="getStudentCountry()">
              {{ 'PROFILE.FORM.PERSONAL_INFO.COUNTRY' | translate }}: {{ getStudentCountry() }}
            </p>
            <p class="small text-muted mb-0" *ngIf="getStudentGender()">
              {{ 'PROFILE.FORM.PERSONAL_INFO.GENDER' | translate }}: {{ getStudentGender() | translate }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="currentProgress" class="col-12 col-sm my-4 my-sm-0">
        <!--Lesson type:<strong>{{lessonDetails.lessonType}}</strong>, status: <strong>{{lessonDetails.lessonStatus}}</strong>-->
        <p>{{ 'COL.TEACHER.LESSON_DETAILS.RECOMMENDED_PLAN' | translate }}</p>
        <dl class="row">
          <dd class="col-5 col-lg-3 small text-muted my-auto">{{ 'COL.TEACHER.LESSON_DETAILS.REVISION' | translate }}</dd>
          <dt class="col-7 col-lg-9">{{ getRevisionPage() }}</dt>
          <dd
            *ngIf="currentProgress.reading"
            class="col-5 col-lg-3 small text-muted my-auto"
          >
            {{ 'COL.TEACHER.LESSON_DETAILS.READING' | translate }}
          </dd>
          <dt *ngIf="currentProgress.reading" class="col-7 col-lg-9">
            {{ getRecommendedReading() }}
          </dt>
          <dd class="col-5 col-lg-3 small text-muted my-auto">{{ 'COL.TEACHER.LESSON_DETAILS.NW' | translate }}</dd>
          <dt class="col-7 col-lg-9">
            {{ currentProgress.nwp }}
          </dt>
          <dd class="col-5 col-lg-3 small text-muted my-auto">{{ 'COL.TEACHER.LESSON_DETAILS.AFTER_HW' | translate }}</dd>
          <dt class="col-7 col-lg-9">
            {{ currentProgress.hw }}
          </dt>
        </dl>
      </div>
    </div>

    <div *ngIf="currentProgress && roomUrl && isRunning" class="text-md-end">
      <a
        class="btn btn-primary rounded-pill me-2 mb-2 mb-md-0"
        [href]="roomUrl"
        target="_blank"
        *ngIf="!isSkype"
        >{{ 'BUTTON.ENTER_ROOM' | translate }}</a
      >
      <button
        class="btn btn-secondary rounded-pill me-2 mb-2 mb-md-0"
        *ngIf="isSkype"
        (click)="reserveVideoClassroom()"
      >
        {{ 'BUTTON.SWITCH_TO_VIDEO' | translate }}
      </button>
      <button
        class="btn btn-secondary rounded-pill me-2 mb-2 mb-md-0"
        [attr.disabled]="allowNotification ? null : ''"
        (click)="sendStudentStartNotification()"
      >
        {{ 'BUTTON.SEND_REMINDER' | translate }}
      </button>
    </div>
    <div *ngIf="needConfirmation || readyToStart || isComing">
      <button
        class="btn btn-primary rounded-pill me-2 mb-2 mb-md-0"
        (click)="confirm()"
        *ngIf="needConfirmation"
      >
        {{ 'BUTTON.CONFIRM' | translate }}
      </button>
      <button
        class="btn btn-primary rounded-pill me-2 mb-2 mb-md-0"
        *ngIf="
          (isComing || readyToStart) &&
          !lessonDetails.roomUrl &&
          !needConfirmation &&
          mayBeStartedTime
        "
        (click)="reserveVideoClassroom()"
      >
        {{ 'BUTTON.START_VIDEO' | translate }}
      </button>
      <a
        class="btn btn-success rounded-pill me-2 mb-2 mb-md-0"
        *ngIf="(isRunning || readyToStart) && roomUrl && !isSkype"
        [href]="roomUrl"
        (click)="enterTheRoom()"
        tabindex=""
        target="_blank"
      >
      {{ 'BUTTON.ENTER_ROOM' | translate }}</a
      >
      <button
        class="btn btn-success rounded-pill me-2 mb-2 mb-md-0"
        (click)="startLesson()"
        *ngIf="readyToStart && roomUrl && isSkype"
      >
        {{ 'BUTTON.START' | translate }}
      </button>
      <button
        class="btn btn-danger rounded-pill me-2 mb-2 mb-md-0"
        *ngIf="cancelable || (isComing && !cancelable)"
        (click)="showCancelLessonModal()"
      >
      {{ 'BUTTON.CANCEL_LESSON' | translate }}
      </button>
      <button
        class="btn btn-secondary rounded-pill me-2 mb-2 mb-md-0"
        [attr.disabled]="allowNotification ? null : ''"
        (click)="sendStudentStartNotification()"
      >
      {{ 'BUTTON.SEND_REMINDER' | translate }}
      </button>
    </div>
  </div>
</div>
<app-modal #notificationConfirmationModal>
  <div class="modal-content">
    <div class="modal-header border-0">
       <h5 class="modal-title">{{ "COL.TEACHER.MODAL.REMINDER.TITLE" | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="notificationConfirmationModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
       <h6 class="mb-0">{{ "COL.TEACHER.MODAL.REMINDER.CONTENT" | translate }}</h6>
    </div>
    <div class="modal-footer border-0">
      <button
        type="submit"
        class="btn btn-primary rounded-pill m-0"
        (click)="notificationConfirmationModal.hide()"
      >
      {{ 'BUTTON.OK' | translate }}
      </button>
    </div>
  </div>
</app-modal>
