import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {finalize, map, Observable, of, switchMap, tap} from 'rxjs';
import {
  ApiCourse,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiLessonStatus,
  LessonStatusUtils, ApiPersonalProfile, ApiLearningUnitTeacher, ApiStudentProductContext,
} from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import {ModalComponent} from "../../../../utils/modal/modal.component";

@Component({
  selector: 'app-lesson-commited-progress',
  templateUrl: './lesson-commited-progress.component.html',
  styleUrls: ['./lesson-commited-progress.component.scss'],
})
export class LessonCommitedProgressComponent implements OnInit {
  @ViewChild('migrateToNewEnglishConfirmationModal', {static: true}) migrateToNewEnglishConfirmationModal: ModalComponent;
  private _lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  private _studentId: number;
  private _lessonId: number;
  private courseCodeChecked: string = null;
  private hasGift = false;
  private _currentProgress: ApiLessonProgress;

  productCourses: ApiCourse[];
  studentContext: ApiStudentProductContext;
  courseLoaded: ApiCourse;
  isComplete: boolean;
  isRunning: boolean;
  isComing: boolean;
  shouldBeStarted: boolean;
  newLessonType: string;
  cancelable: boolean;
  plannedLessonStart: Date;
  shouldBeFinished: boolean;
  plannedLessonFinish: Date;
  progressCommitLimit: Date;
  isMigratingInProgress: boolean = false;

  @Input()
  set lessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (lessonDetails) {
      this._lessonDetails = lessonDetails;
      this._studentId = lessonDetails.students[0].id;
      this._lessonId = lessonDetails.id;
      this.loadStudentContext().subscribe();
      this.setLessonDetails();
    }
  }

  get lessonDetails() {
    return this._lessonDetails;
  }

  @Input()
  set currentProgress(progress: ApiLessonProgress) {
    this._currentProgress = progress;
  }

  get currentProgress() {
    return this._currentProgress;
  }
  @Input() teacherId: number;
  @Input() canUpdateProgress: boolean = false;
  @Output() showCancelLesson = new EventEmitter();
  @Output() showFlags = new EventEmitter();
  @Output() updateLessonDetails = new EventEmitter();

  constructor(private teacherRest: TeacherRestServiceImpl) {}

  ngOnInit(): void {}

  showCancelLessonModal() {
    this.showCancelLesson.emit();
  }
  showFlagsModal() {
    this.showFlags.emit();
  }

  getFlagDescription(type: string) {
    return ApiLessonFlag.getFLagDescription(type);
  }

  getStudentAvailableCourses(): Observable<ApiCourse[]> {
    return this.teacherRest
      .getProductCourses(this.lessonDetails.course.product.code)
      .pipe(tap((courses) => (this.productCourses = courses)));
  }

  loadStudentContext() {
    return this.getStudentAvailableCourses().pipe(
      switchMap(() =>
        this.teacherRest.getStudentProductContext(
          this.teacherId,
          this._studentId,
          this.lessonDetails.course.product.code
        )
      ),
      map((context) => (context ? context : new ApiStudentProductContext())),
      tap((context) => (this.studentContext = context)),
      tap((context) => {
        if (!context.currentCourse) {
          return;
        }
        context.currentCourse = this.productCourses.find(
          (c) => c.code === context.currentCourse.code
        );
        this.courseLoaded = context.currentCourse;
      })
    );
  }

  setLessonDetails() {
    if (!this.lessonDetails.message) {
      this.lessonDetails.message = new ApiLessonMessage();
    }
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];
    this.isComplete = lessonStatus === ApiLessonStatus.Complete;
    this.isRunning = lessonStatus === ApiLessonStatus.InProgress;
    this.isComing = LessonStatusUtils.isComing.indexOf(lessonStatus) >= 0;
    this.plannedLessonStart = new Date(
      this.lessonDetails.lessonMetric.plannedStartDate
    );
    this.shouldBeStarted =
      this.plannedLessonStart.getTime() < new Date().getTime() && this.isComing;
    this.newLessonType = this.lessonDetails.lessonType;
    let mayBeStartedTime =
      Dates.diff(new Date(), this.plannedLessonStart) <
      TimeUnits.Minutes(15).toMilis();
    this.cancelable =
      LessonStatusUtils.cancelable.indexOf(lessonStatus) >= 0 &&
      !this.shouldBeStarted &&
      !mayBeStartedTime;
    this.plannedLessonFinish = new Date(
      this.plannedLessonStart.getTime() +
        this.lessonDetails.lessonMetric.plannedDuration
    );
    this.progressCommitLimit = new Date(
      this.plannedLessonStart.getTime() + 1000 * 60 * 60 * 6
    );
    this.shouldBeFinished =
      this.plannedLessonFinish.getTime() < new Date().getTime() &&
      this.shouldBeStarted;
    this.canUpdateProgress =
      this.shouldBeFinished ||
      this.isRunning ||
      this.shouldBeStarted ||
      (this.isComplete &&
        this.progressCommitLimit.getTime() > new Date().getTime());
  }

  private prepareToStart() {
    let observable: Observable<any> = of(null);
    const startDate = this.shouldBeFinished ? this.plannedLessonStart : null;

    if (this.lessonDetails.lessonStatus === 'Booked') {
      observable = observable.pipe(
        switchMap(() =>
          this.teacherRest.commitLessonBooking(this.teacherId, this._lessonId)
        )
      );
    }
    if (this.isComing) {
      observable = observable.pipe(
        switchMap(() =>
          this.teacherRest.startLesson(
            this.teacherId,
            this._lessonId,
            startDate
          )
        )
      );
    }
    return observable;
  }

  commitLesson() {
    let observable = this.prepareToStart();

    if (this.newLessonType !== this.lessonDetails.lessonType) {
      observable = observable.pipe(
        switchMap(() =>
          this.teacherRest.updateLessonType(
            this.teacherId,
            this._lessonId,
            this.newLessonType
          )
        )
      );
    }

    if (this.isComing || this.isRunning) {
      observable = observable.pipe(
        switchMap(() =>
          this.finishLesson(
            this.lessonDetails.progressCommited
          )
        )
      );
    } else {
      observable = observable.pipe(
        switchMap(() =>
          this.updateLessonProgress(this.lessonDetails.progressCommited)
        )
      );
    }

    observable
      .pipe(
        switchMap(() =>
          this.teacherRest.saveStudentProductContext(
            this.teacherId,
            this._studentId,
            this.lessonDetails.course.product.code,
            this.studentContext
          )
        ),
        switchMap((_) => this.postLessonMessages()),
        tap(() => this.updateLessonDetails.emit())
      )
      .subscribe();
  }

  private updateLessonProgress(progress: ApiLessonProgress) {
    return this.teacherRest.updateLessonProgress(
      this.teacherId,
      this._lessonId,
      progress
    );
  }

  private finishLesson(progress: ApiLessonProgress) {
    let finishDate =
    !this.isRunning && this.shouldBeFinished
      ? this.plannedLessonFinish
      : null;
      return this.teacherRest.finishLesson(
        this.teacherId,
        this._lessonId,
        progress,
        finishDate
      )
  }

  private postLessonMessages() {
    let observable: Observable<null | ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> = of(null);
    if (this.lessonDetails.message) {
      observable = observable.pipe(
        switchMap(() =>
          this.teacherRest.postLessonComment(
            this.teacherId,
            this._lessonId,
            this.lessonDetails.message
          )
        )
      );
    }

    if (this.lessonDetails.studentMessage) {
      observable = observable.pipe(
        switchMap(() =>
          this.teacherRest.postLessonMessageForStudent(
            this.teacherId,
            this._lessonId,
            this._studentId,
            this.lessonDetails.studentMessage
          )
        )
      );
    }

    return observable;
  }

  public studentNoShow() {
    let observable = this.prepareToStart();

    if (this.isComing || this.isRunning) {
      observable = observable.pipe(
        switchMap(() =>
          this.finishLesson(
            this.currentProgress,
          )
        )
      );
    } else {
      observable = observable.pipe(
        switchMap(() => this.updateLessonProgress(this.currentProgress))
      );
    }

    observable
      .pipe(
        switchMap(() => this.registerNoStudentFlag()),
        tap(() => this.updateLessonDetails.emit())
      )
      .subscribe();
  }

  haveGift() {
    if (
      !this.isComplete ||
      !this.studentContext ||
      !this.studentContext.currentCourse
    )
      return false;
    if (
      !this.courseLoaded ||
      this.studentContext.currentCourse.code !== this.courseLoaded.code
    )
      return false;
    if (this.lessonDetails.lessonType !== 'Prova') return false;
    if (
      !this.courseCodeChecked ||
      this.courseCodeChecked !== this.courseLoaded.code
    ) {
      this.courseCodeChecked = this.courseLoaded.code;
      this.hasGift = false;
      if (!this.courseCodeChecked) return false;
      this.teacherRest
        .findStudentProductGift(
          this.teacherId,
          this._studentId,
          this.courseCodeChecked
        )
        .subscribe((gift) => {
          this.hasGift = gift == null;
        });
    }
    return this.hasGift;
  }

  giveGift() {
    if (this.haveGift()) {
      this.teacherRest
        .giveStudentProductGift(
          this.teacherId,
          this._studentId,
          this.courseCodeChecked
        )
        .subscribe((_) => (this.hasGift = false));
    }
  }

  getGiftEbookName() {
    return { ebookName: this.studentContext?.currentCourse.name || '' }
  }

  private registerNoStudentFlag() {
    const flag = new ApiLessonFlag();
    flag.flagType = ApiLessonFlag.FtStudentNotShow;
    return this.teacherRest.registerLessonFlag(
      this.teacherId,
      this._lessonId,
      flag
    );
  }
  isOldEnglishVersion() {
    return this.lessonDetails.course.code.startsWith('en.') && this.studentContext?.productVersion == null
  }

  migrateToNewEnglish() {
    this.isMigratingInProgress = true
    this.teacherRest.updateProductVersion(this.teacherId, this._studentId, this.lessonDetails.course.product.code, "3").pipe(
      finalize(() => {
        this.isMigratingInProgress = false
        this.migrateToNewEnglishConfirmationModal.hide()
      })
    ).subscribe()
  }
}
