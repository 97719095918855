import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'contact-us-result',
  templateUrl: './contact-us-result.component.html',
  styleUrls: ['./contact-us-result.component.scss']
})
export class ContactUsResultComponent implements OnInit {

  @Input() isLoggedIn: boolean

  constructor() { }

  ngOnInit(): void {
  }

  getDestination() {
    return this.isLoggedIn? 'DASHBOARD' : 'LOGIN'
  }

  getPath() {
    return this.isLoggedIn? '../../' : '/entry-points/login'
  }
}