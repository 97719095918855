<div class="row position-relative mb-5">
  <div
    *ngIf="hasImage()"
    class="col-6 col-sm-5 col-md-4 col-xl-4 mx-auto mb-3 mb-sm-4 mb-md-0"
  >
    <div class="question-image rounded p-2">
      <img [src]="getImageSrc()" class="card-img-top pe-none" />
    </div>
  </div>
  <div class="col-12 my-auto" [ngClass]="hasImage() ? 'col-md-7' : 'mt-md-5'">
    <div class="text-center" id="scrollPoint">
      <span *ngFor="let token of tokens" class="lh-lg">
        <span
          *ngIf="!token.editable"
          [innerHtml]="token.content"
          class="fs-5 user-select-none"
        ></span>
        <span *ngIf="token.editable" class="position-relative d-inline-block">
          <span
            class="help text-muted mx-2 lh-1"
            unselectable="on"
            onselectstart="return false;"
            onmousedown="return false;"
            *ngIf="token.editable && token.help"
            [ngStyle]="getInputStyle(token)"
          >
            {{ token.help }}
          </span>
          <input
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            [ngStyle]="getInputStyle(token)"
            class="token-input mx-2 lh-1 fs-5"
            [ngClass]="token.editable && token.help ? 'mt-2' : 'mt-0'"
            [(ngModel)]="token.content"
            #inputToken
          />
        </span>
      </span>
    </div>
  </div>
</div>
