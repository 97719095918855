export class FilterDefinition {
  code: string
  fullName: string
  category: string
  filter: string
  cspaFilter: string
  translationCode: string
  constructor(code: string, fullName: string, category: string, filter: string, cspaFilter: string, translationCode: string) {
    this.code = code
    this.fullName = fullName
    this.category = category
    this.filter = filter
    this.cspaFilter = cspaFilter
    this.translationCode = translationCode
  }
}
export class CourseFilter {
  static filters = [
    new FilterDefinition('ALL', '', '', '', '', 'ALL'),
    new FilterDefinition('EN3', 'English 3rd Edition', 'English-r3', 'en3', 'en3', 'EN3'),
    new FilterDefinition('EN', 'English 2nd Edition', 'English-r2', 'en', 'en', 'EN2'),
    new FilterDefinition('ES', 'Spanish', 'Spanish', 'sp', 'sp', 'ES'),
    new FilterDefinition('ENBUS', 'Business', 'Business', 'en.bs', 'enbus', 'ENBUS'),
    new FilterDefinition('ENCFK', 'Kids', 'Kids', 'en.ch', 'encfk', 'ENCFK')
  ]
  static filtersShort = [
    new FilterDefinition('ALL', '', '', '', '', 'ALL'),
    new FilterDefinition('EN', 'English', 'English', 'en', 'en', 'EN'),
    new FilterDefinition('ES', 'Spanish', 'Spanish', 'sp', 'sp', 'ES'),
    new FilterDefinition('ENBUS', 'Business', 'Business', 'en.bs', 'enbus', 'ENBUS'),
  ]
}

