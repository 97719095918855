<div *ngIf="progressHistory?.content.length; else noResults" class="table-responsive text-center">
  <table class="table table-sm white-text small">
    <thead>
      <tr class="table-row table-row-head">
        <th class="text-start">{{ 'LESSONS.PROGRESS.LESSON' | translate}}</th>
        <th>{{ 'LESSONS.PROGRESS.START_END' | translate}}</th>
        <th>{{ 'LESSONS.PROGRESS.HEADWORD' | translate}}</th>
        <th>{{ 'LESSONS.PROGRESS.READING' | translate}}</th>
        <th>{{ 'LESSONS.PROGRESS.DICTATION' | translate}}</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let historyItem of progressHistory.content" class="table-row">
        <td class="text-start">
          {{getHistoryItemHeader(historyItem)}}<br/>
          <small class="text-muted" *ngIf="getLessonType(historyItem)">
            {{getLessonType(historyItem)}},
            {{getCreated(historyItem) | date: 'EEE dd MMM HH:mm'}},
            {{getAttendance(historyItem)}}
          </small>
          <small class="text-muted" *ngIf="historyItem.details.comment">
            <br/>{{ 'LESSONS.PROGRESS.COMMENT' | translate}}:
            {{historyItem.details.comment}}
          </small>
        </td>
        <td>
          {{getStart(historyItem)}} / {{getPara(historyItem)}}
        </td>
        <td>
          <span class="rounded border border-secondary border-2 py-1 px-2">{{getHw(historyItem)}}</span>
        </td>
        <td>
          <span *ngIf="getReadingLesson(historyItem)">{{getReadingLesson(historyItem)}}</span>
          <small *ngIf="getReading(historyItem)"><br/>({{getReading(historyItem)}})</small>
        </td>
        <td>
          {{getDict(historyItem)}}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noResults>
  <div class="row">
    <p class="text-center fs-5 text-muted m-0">
      {{ "LESSONS.NO_RESULTS" | translate }}
    </p>
  </div>
</ng-template>
