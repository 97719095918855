import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {share} from "rxjs/operators";
import { Authorization } from '../../../../auth_profile/model/prototypes';
import { OwnershipLocalState, PasswordChangeRequest, ContactUs } from '../../../model/book-model';
import { LogsService } from '../../../../utils/services/logs.service';
import {BooksNativeServiceApi} from "../books-native-api";
import {PendingRequestsQueue} from "../../../../mobile/api-services/bridge/js-bridge-task-queue";

declare global {
    interface Window {
        nativeBridge: any;
        webBridge: any;
    }
}

class WaitingCall {
    constructor(public id: number,
                private nativeCall: (id: number) => void) {}
    public doCall() {
        this.nativeCall(this.id);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DesktopNativeApi implements BooksNativeServiceApi {


    updateEventsSubject = new Subject<void>();
    id = 0;
    initialized = false;
    waitingCallsQueue: WaitingCall[] = [];
    private refreshingSubject = new Subject<Observable<void>>();

    constructor(private taskQueue: PendingRequestsQueue, private logger: LogsService) {
        logger.log('creating desktop native bridge');
        window.webBridge = this;
    }

    initialize() {
        this.logger.log(`initializing with ${this.waitingCallsQueue.length} waiting tasks.`);
        this.initialized = true;
        for (const waitingCall of this.waitingCallsQueue) {
            this.logger.log(`calling native during init [${waitingCall.id}]`);
            waitingCall.doCall();
        }
        this.waitingCallsQueue = [];
    }

    handlePendingRequest(data: any) {
        this.taskQueue.handlePendingRequest(JSON.parse(data));
    }

    notifyDataUpdate(): void {
        this.logger.log('notifying data update');
        this.updateEventsSubject.next(null);
    }

    private callForResponse<T>(nativeCall: (id: number) => void): Observable<T> {
        return Observable.create(observer => {
            const id = this.id++;
            this.taskQueue.registerPendingRequest(id, observer);
            if (this.initialized) {
                this.logger.log(`calling native [${id}]`);
                nativeCall(id);
            } else {
                this.logger.log(`adding to waiting tasks [${id}]`);
                this.waitingCallsQueue.push(new WaitingCall(id, nativeCall));
            }
        });
    }

    login(auth: Authorization, state?: string): Observable<void> {
        return this.callForResponse<void>(id => window.nativeBridge.login(id, JSON.stringify(auth)));
    }
    storeDeviceId(deviceId: string) {
        // not supported
    }
    isNativeBasedSecurity(): boolean {
        return true;
    }
    getProducts(): Observable<OwnershipLocalState[]> {
        this.logger.log('listProducts()');
        return this.callForResponse<OwnershipLocalState[]>(
            id => window.nativeBridge.listProducts(id)
        );
    }
    getProductById(productId: number): Observable<OwnershipLocalState> {
        this.logger.log(`productDetails(${productId})`);
        return this.callForResponse<OwnershipLocalState>(
            id => window.nativeBridge.productDetails(id, productId)
        );
    }

    ssoLogin(): Observable<any> {
      this.logger.log(`ssoLogin()`)
      return this.callForResponse<void>( id => window.nativeBridge.ssoLogin());
    }

    register(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`register(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.register(id, productId, releaseId)
        );
    }
    download(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`download(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.download(id, productId, releaseId)
        );
    }
    open(productId: number, releaseId: number): Observable<void> {
        this.logger.log(`open(${productId}, ${releaseId})`);
        return this.callForResponse<void>(
            id => window.nativeBridge.open(id, productId, releaseId)
        );
    }
    getImageUrl(productId: number, releaseId: number): Observable<string> {
        this.logger.log(`getImageUrl(${productId}, ${releaseId})`);
        return this.callForResponse<string>(
            id => window.nativeBridge.getImageUrl(id, productId, releaseId)
        );
    }
    downloadAudio(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`downloadAudio(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.downloadAudio(id, productId, releaseId)
        );
    }
    deleteAudio(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`deleteAudio(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.deleteAudio(id, productId, releaseId)
        );
    }
    downloadDict(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`downloadDict(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.downloadDict(id, productId, releaseId)
        );
    }
    deleteDict(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`deleteDict(${productId}, ${releaseId})`);
        return this.callForResponse<number>(
            id => window.nativeBridge.deleteDict(id, productId, releaseId)
        );
    }

    downloadingAudioProgress(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`downloadingAudioProgress(${productId}, ${releaseId}`);
        return this.callForResponse<number>(
            id => window.nativeBridge.downloadingAudioProgress(id, productId, releaseId)
        );
    }

    downloadingDictationProgress(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`downloadingDictationProgress(${productId}, ${releaseId}`);
        return this.callForResponse<number>(
            id => window.nativeBridge.downloadingDictationProgress(id, productId, releaseId)
        );
    }

    getDeviceId(): Observable<string> {
        return this.callForResponse<string>(
            id => window.nativeBridge.getDeviceId(id)
        );
    }

    listenForDataUpdates(): Observable<void> {
        return this.updateEventsSubject.asObservable();
    }

    close(): Observable<void> {
        return this.callForResponse<void>( id => window.nativeBridge.close(id));
    }

    doLogout(): Observable<void> {
        return this.callForResponse<void>( id => window.nativeBridge.logout(id));
    }

    getUserName(): Observable<string> {
        return this.callForResponse<string>( id => window.nativeBridge.getUserName(id));
    }

    openCspa(): Observable<void> {
        return this.callForResponse<void>( id => window.nativeBridge.openCspa(id));
    }

    refreshData(frequencyMs: number): Observable<void> {
        const result = this.callForResponse<void>( id => window.nativeBridge.refresh(id, frequencyMs))
            .pipe(
                share<void>()
            )
        this.refreshingSubject.next(result);
        return result;
    }

    subscribeForRefreshingEvents(): Observable<Observable<void>> {
        return this.refreshingSubject.asObservable();
    }

    changePassword(request: PasswordChangeRequest, lang?: string): Observable<void> {
        return this.callForResponse<void>(id => window.nativeBridge.changePassword(id, JSON.stringify(request), lang));
    }

    contactUs(request: ContactUs): Observable<void> {
        return this.callForResponse<void>(id => window.nativeBridge.contactUs(id, JSON.stringify(request)));
    }
}
