import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {QuestionComponentBase} from 'src/app/model/QuestionComponentBase';
import {ModalComponent} from 'src/app/utils/modal/modal.component';
import {BlockComparator, TextBlock} from 'src/app/utils/text-compare';
import {environment} from 'src/environments/environment';
import {Dictation, DictationAnswer} from '../../../model/cspa/questions';
import {QuestionService} from '../../../services/api/impl/rest/question.service';
import {LogsService} from "../../../../utils/services/logs.service";
import {AuthorizationServiceProvider} from 'src/app/auth_profile/services/authorization-service.provider';
import {QuestionActionRequest, QuestionActionRequestWithAnimation, QuestionActionRequestWithValue} from "../../../../model/prototypes";
import {Subscription} from "rxjs";
import {ShortcutService} from "../../../services/shortcut.service";

@Component({
  selector: 'app-dict',
  templateUrl: './dict.component.html',
  styleUrls: ['./dict.component.scss']
})
export class DictComponent extends QuestionComponentBase<DictationAnswer, Dictation> implements OnInit, OnDestroy, AfterViewInit {

  summary: TextBlock[];
  score: number;
  isAnswerShown: boolean = false
  isSpanish: boolean = false
  spanishLetters = ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ', '¿', '¡']
  private cursorPosition: Array<number>
  showEvents = ['click', 'keydown'];
  videoSource: String = null
  loading = true
  isMobileView: boolean = false;
  qaRequestStart: (QuestionActionRequest | QuestionActionRequestWithValue | QuestionActionRequestWithAnimation)[]
  qaRequestStop: (QuestionActionRequest | QuestionActionRequestWithValue)[]
  private shortcutButtonSubscription: Subscription;
  @ViewChild('summaryModal', {static: true}) summaryModal: ModalComponent;
  @ViewChild('answer', {static: false, read: ElementRef}) answerTextarea: ElementRef

  private _videoPlayer: ElementRef<HTMLVideoElement>

  @ViewChild('videoPlayer', {static: false})
  get videoPlayer(): ElementRef<HTMLVideoElement> {
    return this._videoPlayer
  }

  set videoPlayer(videoPlayer: ElementRef<HTMLVideoElement>) {
    if (!videoPlayer) return
    this._videoPlayer = videoPlayer;
    (<QuestionActionRequestWithValue>this.qaRequestStart[0]).value = videoPlayer.nativeElement.volume * 100;
    (<QuestionActionRequestWithValue>this.qaRequestStop[0]).value = videoPlayer.nativeElement.volume * 100
    this.sendRequest(this.qaRequestStart)
  }

  @Input() isExam: boolean
  @Output() audioProgressEmitter = new EventEmitter<number>()

  private _audioPlayer: ElementRef<HTMLVideoElement>

  @ViewChild('audioPlayer', {static: false})
  get audioPlayer(): ElementRef<HTMLVideoElement> {
    return this._audioPlayer
  }

  set audioPlayer(audioPlayer: ElementRef<HTMLVideoElement>) {
    if (!audioPlayer) return
    this._audioPlayer = audioPlayer;
    (<QuestionActionRequestWithValue>this.qaRequestStart[0]).value = audioPlayer.nativeElement.volume * 100;
    (<QuestionActionRequestWithValue>this.qaRequestStop[0]).value = audioPlayer.nativeElement.volume * 100
    this.sendRequest(this.qaRequestStart)
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileView = document.body.clientWidth < 768;
    this.stop()
  }
  
  constructor(
    private questionService: QuestionService,
    private shortcut: ShortcutService,
    logs: LogsService,
    authProvider: AuthorizationServiceProvider) {
    super(logs, authProvider);
  }

  ngOnInit() {
    this.createQaRequests()
    this.shortcutButtonSubscription = this.shortcut.btnRequest.subscribe(btn => {
      btn.includes('speaker') && this.setAudioVolume(+btn.split(':')[1])
      btn === 'stop' && this.stop()
      btn === 'play' && this.play()
    })
    this.isSpanish = this._question.question.path.startsWith('sp')
    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer => {
      sendAnswer &&
      sendAnswer['answerType'] === 'dict' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    })
    this.videoSource = this.getMedia('.mp4');
    this.isMobileView = document.body.clientWidth < 768;
  }

  ngAfterViewInit() {
    this.showEvents.forEach((event) => {
      this.answerTextarea?.nativeElement.addEventListener(event, () => {
        setTimeout(() => {
          this.setCursorPosition()
        }, 1);
      });
    });

    this.loading = false
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.summaryModal.hide()
    if (this.shortcutButtonSubscription) this.shortcutButtonSubscription.unsubscribe()
  }

  sendRequest(qaRequest: (QuestionActionRequest | QuestionActionRequestWithValue)[]) {
    this.updateActionMenu.emit(qaRequest)
  }

  play() {
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.play().then(_ => this.sendRequest(this.qaRequestStop))
    }
    if (this.videoPlayer)
      this.videoPlayer.nativeElement.play().then(_ => this.sendRequest(this.qaRequestStop))
  }

  stop() {
    if (this.audioPlayer) this.audioPlayer.nativeElement.pause()
    if (this.videoPlayer) this.videoPlayer.nativeElement.pause()
    this.sendRequest(this.qaRequestStart)
  }

  updateProgress(data: any) {
    if(data.target.currentTime == data.target.duration)
      this.audioProgressEmitter.emit(-1)
    this.audioProgressEmitter.emit(data.target.currentTime/data.target.duration * 100)
  }

  getAnswerForSubmit(){
      this.isExam ? this.submitAnswer() : this.openDictationDialog();
  }

  getScore() {
    return this.score;
  }

  mayCorrect() {
    return this.getScore() !== 100
  }

  getSummary() {
    return this.summary
  }

  closeHostContract() {
    this.summaryModal.hide();
  }

  submitAnswer() {
    this.summaryModal.hide()
    this.answer = this._question.answer
    this.sendAnswer()
  }

  openDictationDialog() {
    this.prepareSummaryReport();
    this.summaryModal.show();
  }

  prepareSummaryReport() {
    const correctAnswerStream = BlockComparator.tokenize(this.question.question.definition.answer.answer);
    const userAnswer = BlockComparator.tokenize(this.question.answer.answer);
    const result = BlockComparator.compare(correctAnswerStream, userAnswer);
    this.score = Math.round((1 - (result[result.length - 1].score / correctAnswerStream.content.length)) * 100);
    const blocks = BlockComparator.divideToBlocks(result, userAnswer);
    this.summary = BlockComparator.compressBlocks(blocks);
  }

  getMedia(suffix: string) {
    if (!this.question) {
      return null;
    }
    return environment.awsBase + this.question.question.definition.src + suffix;
  }

  setUpAnswer(answer: DictationAnswer) {
    this.logs.log("setting up dictation answer ", JSON.stringify(answer))
    this.answer = answer ? answer : new DictationAnswer();
    if (!this._question.answer) {
      this._question.answer = new DictationAnswer()
      this._question.answer.answer = ''
    }
  }

  getAudioSource() {
    return this.getMedia('.mp3');
  }

  getAnswer() {
    return this._question.question.definition.answer.answer
  }

  toggleAnswer() {
    this.isAnswerShown = !this.isAnswerShown
  }

  setCursorPosition() {
    let start = this.answerTextarea.nativeElement.selectionStart;
    let end = this.answerTextarea.nativeElement.selectionEnd;
    this.cursorPosition = [start, end];
  }

  addLetter(letter: string) {
    let start = this.cursorPosition[0] != 0? this.cursorPosition[0]  : 0
    let end = this.cursorPosition[1] != 0? this.cursorPosition[1] : 0
    let currentValue = this.answerTextarea.nativeElement.value;
    this._question.answer.answer = currentValue.substr(0, start) + letter + currentValue.substr(end);
    this.answerTextarea.nativeElement.focus()
    setTimeout(() => {
      this.answerTextarea.nativeElement.setSelectionRange(this.cursorPosition[0] + 1, this.cursorPosition[1] + 1)
      this.setCursorPosition()
    }, 1);
  }

  errorMessageVideo() {
    this.videoSource = null
    this.loading = false
  }

  setAudioVolume(volume: number) {
    if (!volume) return
    (<QuestionActionRequestWithValue>this.qaRequestStart[0]).value = volume;
    (<QuestionActionRequestWithValue>this.qaRequestStop[0]).value = volume;
    if (this.audioPlayer) this.audioPlayer.nativeElement.volume = volume / 100
    if (this.videoPlayer) this.videoPlayer.nativeElement.volume = volume / 100
  }

  private createQaRequests() {
    this.qaRequestStart = [
      {
        text: 'speaker',
        assetUrl: 'volume-up',
      }
    ]
    this.qaRequestStop = [
      {
        text: 'speaker',
        assetUrl: 'volume-up'
      }
    ]
    this.qaRequestStart.push({
      text: 'play',
      assetUrl: 'play-arrow-filled',
      animation: 'pulsating'
    })
    
    if (this.isExam) {
      this.qaRequestStop.push({
        text: '',
        assetUrl: 'play-arrow-disabled'
      })
    }
    else {
      this.qaRequestStop.push({
        text: 'stop',
        assetUrl: 'stop-circle-blue'
      })
    }
  }
}
