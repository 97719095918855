<section class="mb-5">
  <div class="course-enbus">
    <div class="course d-flex justify-content-between align-items-center">
      <h4 class="text-white my-1">{{ getLangName() | translate }}</h4>
      <div class="col-auto d-flex gap-2 gap-md-3">
        <app-activate-course-modal
          [studentId]="studentId"
          [langCode]="chosenCourse?.filter"
          [onlyButton]="true"
        ></app-activate-course-modal>
        <a class="btn btn-primary rounded-pill"
           routerLink="/student/courses"
           [queryParams]="{language: 'en.bs'}"
        >
          {{ "BUTTON.DETAILS" | translate }}
        </a>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <nav>
    <div
      class="nav nav-tabs justify-content-center text-uppercase"
      id="nav-tab"
      role="tablist"
    >
      <button
        class="nav-link text-uppercase px-2 px-md-4 active"
        id="nav-upfront-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-upfront"
        type="button"
        role="tab"
        aria-controls="nav-upfront"
        aria-selected="true"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.UPFRONT.TITLE" | translate }}
      </button>
      <button
        class="nav-link text-uppercase px-2 px-md-4"
        id="nav-instalment-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-instalment"
        type="button"
        role="tab"
        aria-controls="nav-instalment"
        aria-selected="false"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.INSTALMENTS.TITLE" | translate }}
      </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div
      class="tab-pane fade show active"
      id="nav-upfront"
      role="tabpanel"
      aria-labelledby="nav-upfront-tab"
    >
      <div class="row text-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
          <p class="text-muted small my-4">
            {{ "COL.COURSES.PRODUCTS.PLANS.UPFRONT.DESC" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center g-3">
        <div
          class="col-10 col-sm-8 col-md-5 col-lg-4 col-xl-3 mb-3 h-100"
          *ngFor="let product of upfront"
        >
          <app-product-box [product]="product" desc=""></app-product-box>
        </div>
      </div>
    </div>

    <div
      class="tab-pane fade"
      id="nav-instalment"
      role="tabpanel"
      aria-labelledby="nav-instalment-tab"
    >
      <div class="row text-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
          <p class="text-muted small my-4">
            {{ "COL.COURSES.PRODUCTS.PLANS.INSTALMENTS.DESC" | translate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center g-3">
        <div
          class="col-10 col-sm-8 col-md-5 col-lg-4 col-xl-3 mb-3 h-100"
          *ngFor="let product of instalment"
        >
          <app-product-box [product]="product" desc=""></app-product-box>
        </div>
      </div>
    </div>
  </div>
</section>
