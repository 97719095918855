<div
  [ngClass]="getCardStyle()"
  class="card-accent"
  routerLink="{{ getRouterLink() }}"
>
  <div class="card-header justify-content-between d-flex flex-column">
    <p class="mb-3">{{ getName() }}</p>
    <h4>{{ getShortName() }}</h4>
    <span
      [ngClass]="isCompleted() ? 'completed' : 'progress-percentage'"
      [ngStyle]="getProgressPosition()"
      class="small"
    >
      {{ getProgress() }}
    </span>
  </div>
  <img [src]="getImgSrc()" [class]="getImgClass()" class="position-absolute"/>
</div>
