import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { AuthorizationServiceProvider } from '../../services/authorization-service.provider';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {

  constructor(private authServiceProv: AuthorizationServiceProvider) { }

  ngOnInit(): void {
  }

  onLogin() {
    this.authServiceProv.getOnWebAuthorization().pipe(
      map(auth =>
        auth.startLogin('')
      )
    ).subscribe()
  }

}
