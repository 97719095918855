import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map, Observable, of, switchMap, tap} from 'rxjs';
import {AuthorizationServiceProvider} from 'src/app/auth_profile/services/authorization-service.provider';
import {
  ApiLearningUnitTeacher,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiLessonStatus,
  ApiPersonalProfile,
  LessonStatusUtils
} from 'src/app/col/model/rest-model';
import {TeacherRestServiceImpl} from 'src/app/col/services/teacher/teacher-rest-impl.service';
import {Dates, TimeUnits} from 'src/app/utils/calendar-utils';
import {ModalComponent} from 'src/app/utils/modal/modal.component';
import {SpinnerService} from 'src/app/utils/services/spinner.service';

@Component({
  selector: 'app-teacher-lesson-details',
  templateUrl: './teacher-lesson-details.component.html',
  styleUrls: ['./teacher-lesson-details.component.scss']
})
export class TeacherLessonDetailsComponent implements OnInit {
  @ViewChild('cancelLessonModal', { static: true })
  cancelLessonModal: ModalComponent;
  @ViewChild('flagModal', { static: true })
  flagModal: ModalComponent;

  lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  teacherId: number;
  studentId: number;
  lessonId: number;
  canUpdateProgress;
  _clReason: string;
  _fFlags: { [name: string]: boolean };
  _fDescription: string;
  _fExistingFlags: string[];
  _fAllFlags = ApiLessonFlag.flagsTypes;
  mayBeStartedTime: boolean;
  shouldBeStarted: boolean;
  currentProgress: ApiLessonProgress;

  constructor(
    private auth: AuthorizationServiceProvider,
    private route: ActivatedRoute,
    private teacherRest: TeacherRestServiceImpl,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.auth
      .getAuthDetailsService()
      .pipe(
        switchMap((authDetailsApi) => authDetailsApi.getSelfTeacherId()),
        tap((teacherId) => (this.teacherId = teacherId)),
        switchMap((_) => this.route.paramMap),
        tap((params) => {
          this.studentId = +params.get('studentId');
          this.lessonId = +params.get('lessonId');
        })
      )
      .subscribe((_) => this.loadLessonDetails().subscribe());
  }

  loadLessonDetails(): Observable<ApiLessonProgress> {
    return this.spinner.trace<ApiLessonProgress>(
      this.teacherRest
        .getLessonById(this.teacherId, this.lessonId, this.studentId)
        .pipe(
          tap((lessonDetails) => this.setLessonDetails(lessonDetails)),
          switchMap((_) => this.loadProgressData())
        )
    );
  }

  private setLessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lessonDetails.message) {
      lessonDetails.message = new ApiLessonMessage();
    }
    this.lessonDetails = lessonDetails;
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];
    let isComing = LessonStatusUtils.isComing.indexOf(lessonStatus) >= 0;
    let plannedLessonStart = new Date(
      lessonDetails.lessonMetric.plannedStartDate
    );
    this.mayBeStartedTime =
      Dates.diff(new Date(), plannedLessonStart) <
      TimeUnits.Minutes(15).toMilis();
    this.shouldBeStarted =
      plannedLessonStart.getTime() < new Date().getTime() && isComing;

    this.fillMissingLessonDetails(lessonDetails);
  }

  fillMissingLessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): void {
    if (
      lessonDetails != null && (lessonDetails.progressCommited == null)
    ) {
      lessonDetails.progressCommited = new ApiLessonProgress();
    }
  }

  reloadLessonDetails() {
    this.loadLessonDetails().subscribe();
  }

  cancelLesson() {
    let cancellationObservable: Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
    if (
      (this.lessonDetails.lessonStatus === 'Booked' ||
        this.lessonDetails.lessonStatus === 'Initializing') &&
      !this.mayBeStartedTime
    ) {
      cancellationObservable = this.teacherRest.cancelLesson(
        this.teacherId,
        this.lessonId,
        this._clReason
      );
    } else if (
      this.lessonDetails.lessonStatus === 'Due' ||
      this.lessonDetails.lessonStatus === 'Booked'
    ) {
      cancellationObservable = this.teacherRest.squanderLesson(
        this.teacherId,
        this.lessonId,
        this._clReason
      );
    }

    if (cancellationObservable) {
      cancellationObservable
        .pipe(switchMap(() => this.loadLessonDetails()))
        .subscribe();
    }

    this.cancelLessonModal.hide();
  }

  showCancelLessonModal() {
    this._clReason = null;
    this.cancelLessonModal.show();
  }

  loadProgressData(): Observable<ApiLessonProgress> {
    if (
      LessonStatusUtils.plannedLessons.indexOf(
        ApiLessonStatus[this.lessonDetails.lessonStatus]
      ) < 0
    ) {
      this.currentProgress = null;
      return of(null);
    }
    return this.teacherRest
      .getStudentProgress(this.teacherId, this.studentId)
      .pipe(
        // find related progress
        map((allProgresses) =>
          allProgresses.find(
            (progress) =>
              progress.productCode === this.lessonDetails.course.product.code
          )
        ),
        // create new if is first lesson
        map((currentProductProgress) => {
          if (currentProductProgress) {
            return currentProductProgress;
          }
          const newProgress = new ApiLessonProgress();
          newProgress.courseCode = this.lessonDetails.course.code;
          newProgress.productCode = this.lessonDetails.course.product.code;
          newProgress.hw = '';
          newProgress.nwp = 0;
          newProgress.start = 0;
          return newProgress;
        }),
        // keep it
        tap((currentProgress) => (this.currentProgress = currentProgress))
      );
  }
  //flag modal
  showFlagsModal() {
    this._fFlags = {};
    this._fDescription = null;
    this._fExistingFlags = this.lessonDetails.flags.map((f) => f.flagType);
    this._fExistingFlags.forEach((f) => (this._fFlags[f] = true));
    this.flagModal.show();
  }

  hasFlag(type: string) {
    return this._fExistingFlags.some((f) => f === type);
  }

  getFlagDescription(type: string) {
    return ApiLessonFlag.getFLagDescription(type);
  }

  postFlag() {
    let postObservable: Observable<null | ApiLessonFlag> = of(null);
    for (const flagType in this._fFlags) {
      if (this._fFlags.hasOwnProperty(flagType)) {
        if (
          !this._fFlags[flagType] ||
          this._fExistingFlags.indexOf(flagType) >= 0
        ) {
          continue;
        }
        const newFlag = new ApiLessonFlag();
        newFlag.date = new Date();
        if (
          ApiLessonFlag.descriptionRequiringFlags.some((f) => f === flagType)
        ) {
          newFlag.description = this._fDescription;
        }
        newFlag.flagType = flagType;
        newFlag.status = ApiLessonFlag.StatusSubmitted;
        postObservable = postObservable.pipe(
          switchMap(() =>
            this.teacherRest.registerLessonFlag(
              this.teacherId,
              this.lessonDetails.id,
              newFlag
            )
          )
        );
      }
    }
    postObservable.pipe(switchMap(() => this.loadLessonDetails())).subscribe();
    this.flagModal.hide();
  }

  askForComment(): boolean {
    let res = false;
    for (const key in this._fFlags) {
      if (this._fFlags.hasOwnProperty(key)) {
        if (
          this._fFlags[key] &&
          ApiLessonFlag.descriptionRequiringFlags.some((f) => f === key)
        ) {
          res = true;
          break;
        }
      }
    }
    return res;
  }
}
