import { Component, OnInit } from '@angular/core';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor(private authService: AuthorizationServiceProvider) { }

  ngOnInit(): void {
  }

  onLogout() {
    this.authService.getCommonRoutines().subscribe(service => service.logout())
  }
}
