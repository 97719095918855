import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContactUs } from 'src/app/books/model/book-model';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUs = new ContactUs()
  @Input() busy: boolean
  @Output() msgEmitter = new EventEmitter<ContactUs>()
  @Output() cancel = new EventEmitter<any>()
  @ViewChild('email', { static: false }) email: ElementRef;
  @ViewChild('msg', { static: false }) message: ElementRef;

  constructor() { }

  ngOnInit(): void {
    }

  onSubmit() {
  this.msgEmitter.emit(this.contactUs)
  }

  isBusy() {
    return this.busy
  }
}
