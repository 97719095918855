<div class="row mb-4">
  <div class="d-flex align-items-center mb-3 mb-sm-0">
    <app-icon
      name="view-week"
      class="icon-bg bg-gray icon-blue ms-2"
    ></app-icon>
    <h6 class="m-0">
      {{ "LESSONS.WEEKLY_PLANNED_LESSONS" | translate }}
    </h6>
    <app-access-lessons-button class="ms-auto"></app-access-lessons-button>
  </div>
</div>
<section class="card mb-5 accent-info px-3 py-4">
  <div class="row mx-2 mb-4">
    <h3>{{ getScheduleName() }}</h3>
    <span class="text-muted small"
      >{{ "LESSONS.ROOM_DETAILS.PLANNED_TO_START" | translate
      }}{{ getScheduleDatePlanned() }}</span
    >
  </div>
  <div class="mx-2 bg-blue rounded-3 p-3 py-md-4 mb-4">
    <!-- teacher -->
    <div class="row">
      <div class="col-6 d-flex">
        <div class="d-flex rounded-circle text-center bg-black me-2 initials-circle">
          <span class="text-muted m-auto">
            {{ getInitials(getTeacher()) }}
          </span>
        </div>
        <div class="my-auto">
          <p class="text-muted xsmall mb-0">
            {{ "LESSONS.ROOM_DETAILS.TEACHER" | translate }}
          </p>
          <p class="small mb-0">{{ getName(getTeacher()) }}</p>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end">
        <button
          type="button"
          class="btn btn-primary rounded-pill py-2 d-flex"
          (click)="copyToClipboard(createLink(getSelf()))"
          [disabled]="isCopied"
        >
          <span class="d-flex align-items-center">
            <app-icon name="copy" class="icon-white"></app-icon>
            {{ getButtonContent() | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="row mx-2 mb-4 pb-4 border-1 border-bottom border-secondary">
    <p>{{ "LESSONS.ROOM_DETAILS.REPORTED_TIMINGS" | translate }}</p>
    <div class="col-4">
      <p class="text-muted xsmall mb-0">
        {{ "LESSONS.ROOM_DETAILS.CLASSROOM_CREATED" | translate }}
      </p>
      <span class="small">{{ getScheduleDateCreated() }}</span>
    </div>
    <div class="col-4">
      <p class="text-muted xsmall mb-0">
        {{ "LESSONS.ROOM_DETAILS.PLANNED_TO_START" | translate }}:
      </p>
      <span class="small">{{ getScheduleDatePlanned() }}</span>
    </div>
    <div class="col-4">
      <p class="text-muted xsmall mb-0">
        {{ "LESSONS.ROOM_DETAILS.DURATION" | translate }}
      </p>
      <span class="small"
        >{{ getScheduleDuration() }}
        {{ "LESSONS.ROOM_DETAILS.MINUTES" | translate }}</span
      >
    </div>
  </div>
  <div *ngIf="getScheduleState()" class="row mx-2 mb-2">
    <p>{{ "LESSONS.ROOM_DETAILS.SYSTEM_DETAILS" | translate }}</p>
    <div class="col">
      <p class="text-muted xsmall mb-0">
        {{ "LESSONS.ROOM_DETAILS.STATUS" | translate }}
      </p>
      <span class="small">{{ getScheduleState() | translate }}</span>
    </div>
  </div>
</section>
