import { Component, OnInit } from '@angular/core';
import {combineLatest, forkJoin, Observable, of, switchMap, take, tap} from "rxjs";
import {ActivatedRoute, ParamMap, Router, UrlSegment} from "@angular/router";
import {School} from "../../../auth_profile/model/casa/casa-model";
import {FileRepoEntry, FolderRepoEntry, RepoEntry, RepoEntryType} from "../../models/documents";
import {CasaRestService} from "../../../auth_profile/services/api/impl/rest/casa-rest.service";
import {DocumentRestService} from "../../services/document-rest.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
  selector: 'app-documents-browser-page',
  templateUrl: './documents-browser-page.component.html',
  styleUrls: ['./documents-browser-page.component.scss']
})
export class DocumentsBrowserPageComponent implements OnInit {

  private school: School;
  schoolId: number;
  teacherId: number;
  documentRoot: RepoEntry;
  documentsPath: RepoEntry[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private casaRest: CasaRestService,
    private authProvider: AuthorizationServiceProvider,
    private documentsService: DocumentRestService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    authProvider.getAuthDetailsService().pipe(
      switchMap(api => forkJoin([api.getSelfSchoolId(), api.getSelfTeacherId()])),
      tap(([schoolId, teacherId]) => {
        this.schoolId = schoolId
        this.teacherId = teacherId
    }),
      switchMap(_ => this.loadSchool()),
      switchMap(_ => combineLatest(
        [activatedRoute.url,
          activatedRoute.queryParamMap
        ]
      )),
      switchMap(([url, queryParams]) =>
        combineLatest([
          of(this.buildDocumentPath(url)),
          this.obtainData(!this.isDownloadAction(queryParams)),
          of(this.isDownloadAction(queryParams))
        ])),
      tap<[string[], RepoEntry, boolean]>( ([documentPath, repoData, downloadIt]) =>
        this.prepareView(repoData, documentPath, downloadIt))
    ).subscribe()
  }

  ngOnInit(): void { }

  private loadSchool() {
    return this.casaRest.getTeacherSchool(this.teacherId).pipe(
      tap(school => this.school = school)
    )
  }

  private buildDocumentPath(segments: UrlSegment[]): string[] {
    if (segments.length === 0) return [];
    return segments.map(segment => segment.path);
  }

  private isDownloadAction(queryParams: ParamMap): boolean {
    return queryParams.has("download");
  }

  private obtainData(reloadIfEmpty: boolean): Observable<RepoEntry> {
    if (!reloadIfEmpty) return of(this.documentRoot);
    if (this.documentRoot) return of(this.documentRoot);
    return this.spinnerService.trace(
      this.documentsService.listDocuments(this.schoolId).pipe(
        tap(documentRoot => this.documentRoot = documentRoot)
      )
    )
  }

  private prepareView(repoData: RepoEntry, documentPath: string[], downloadIt: boolean) {
    if (downloadIt) {
      this.documentsService.getDocumentLink(this.schoolId, documentPath.join("/")).subscribe(
        {
          next: link => location.href = link,
          error: _ => this.routeToNotFound()
        });
      return;
    }
    // if path is empty redirect to the correct folder
    if (documentPath.length === 0) {
      this.router.navigate([repoData.relativeKey], {relativeTo: this.activatedRoute}).then();
      return;
    }

    this.documentsPath = [];
    let currentItemKids: RepoEntry[] = [repoData];
    for (const pathSegment of documentPath) {
      const nextItem = currentItemKids.find(item => item.relativeKey === pathSegment)
      if (!nextItem) {
        this.routeToNotFound();
        return;
      }
      this.documentsPath.push(nextItem)
      currentItemKids = (nextItem as FolderRepoEntry).innerEntries;
    }
  }

  private routeToNotFound() {
    this.router.navigate(["404"]).then();
  }

  last() {
    if (this.documentsPath.length == 0) return null;
    return this.documentsPath[this.documentsPath.length - 1];
  }

  lastAsDir() {
    if (this.isDir(this.last())) return this.last() as FolderRepoEntry;
    return null;
  }

  isDir(item: RepoEntry) {
    if (!item) return false;
    return item["@type"] === RepoEntryType.Dir;
  }

  download(item: RepoEntry) {
    this.documentsService.getDocumentLink(this.schoolId, item.key).subscribe(
      {
        next: link => window.open(link, "_blank"),
        error: _ => this.routeToNotFound()
      });
  }

  up() {
    this.router.navigate([".."], {relativeTo: this.activatedRoute}).then()
  }

  getSize(item: RepoEntry) {
    if(!(item as FileRepoEntry).size) return null
    if (item["@type"] === RepoEntryType.File) {
      let size = (item as FileRepoEntry).size;
      if (size < 1024) return size.toString();
      size = Math.round(size / 1024);
      if (size < 1024) return `${size} KB`;
      size = Math.round(size / 1024);
      return `${size} MB`;
    }
    return "";
  }

  getExtension(item: RepoEntry) {
    if(!(item as FileRepoEntry).extension) return null
    if (this.isDir(item)) return "";
    return (item as FileRepoEntry).extension;
  }

  refresh() {
    this.spinnerService.trace(
      combineLatest([
        this.documentsService.refreshDocumentsRepository(this.schoolId).pipe(
          switchMap( _ => this.documentsService.listDocuments(this.schoolId)),
          tap<RepoEntry>(documentRoot => this.documentRoot = documentRoot)
        ),
        this.activatedRoute.url
      ]).pipe(
        take(1),
        tap<[RepoEntry, UrlSegment[]]>( ([repoData,url]) => this.prepareView(repoData, this.buildDocumentPath(url), false)))
    ).subscribe()
  }
}
