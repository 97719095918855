<section class="mb-5" *ngIf="!isLoading">
  <div class="row mb-4">
    <div class="col-auto d-flex align-items-center mb-3 mb-sm-0">
      <app-icon name="group" class="icon-bg bg-gray icon-blue ms-2"></app-icon>
      <h6 class="m-0">{{ getLessonTypeName() | translate }}</h6>
    </div>
    <div class="col-auto ms-auto">
      <paginator
        [data]="lessons"
        [pageable]="pageable"
        (newPage)="onPageChange($event)"
      ></paginator>
    </div>
  </div>
  <div>
    <div class="card">
      <div class="card-body">
        <p
          *ngIf="hasResults() && getTotalResults() == 0"
          class="text-muted text-center my-3"
        >
          {{ "COL.LESSONS.LIST.NO_RESULTS_FOUND" | translate }}
        </p>
        <ul *ngIf="hasResults()" class="list-unstyled lessons-list">
          <li
            class="row gy-2 gx-2 gx-xl-3 px-xl-2 py-3"
            *ngFor="let lesson of getLessonsToDisplay()"
          >
            <div class="col">
              <div class="row gx-2 gx-xl-3">
                <div
                  class="col-12 col-lg-3 col-xl-2 mb-2 mb-lg-0 d-flex ps-lg-0"
                >
                  <div
                    *ngIf="getLessonStudentPhotoUrl(lesson)"
                    class="profile-photo rounded-circle initials-circle-lg my-auto"
                    [style.background-image]="
                      'url(' + getLessonStudentPhotoUrl(lesson) + ')'
                    "
                  ></div>
                  <div
                    *ngIf="!getLessonStudentPhotoUrl(lesson)"
                    class="d-flex rounded-circle text-center bg-gray me-2 initials-circle-lg my-auto"
                  >
                    <span class="text-muted m-auto">
                      {{ getInitials(lesson.students[0]?.person) }}
                    </span>
                  </div>
                  <div class="d-flex flex-column d-lg-none ms-auto gap-1">
                    <span
                      *ngIf="lesson.lessonStatus"
                      class="badge lesson-status"
                      [ngClass]="lessonBadgeClass(lesson)"
                      >{{ getLessonStatus(lesson) | translate }}</span>
                    <span class="badge" [ngClass]="{'bg-danger': isRedLabel(lesson), 'bg-secondary': !isRedLabel(lesson)}">
                        {{getProductVersion(lesson)}}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                  <p class="text-muted small mb-0">
                    {{ "COL.LESSONS.LIST.STUDENT_LABEL" | translate }}
                  </p>
                  <h6 class="my-0">{{ getLessonStudent(lesson) }}</h6>
                </div>
                <div class="col-12 col-lg-5 col-xl-6 mb-2 mb-lg-0">
                  <p class="text-muted small mb-0">
                    {{ "COL.LESSONS.LIST.DATE_LABEL" | translate }}
                  </p>
                  <p class="mb-0">
                    {{ getLessonDate(lesson) | dateTranslation : "EEEE, dd MMM yyyy" }}
                    {{ getLessonDate(lesson) | date : "HH:mm" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 col-lg-2 d-none d-lg-block">
              <div class="d-flex flex-column m-auto gap-1">
                <span
                  *ngIf="lesson.lessonStatus"
                  class="badge lesson-status"
                  [ngClass]="lessonBadgeClass(lesson)"
                  >{{ getLessonStatus(lesson) | translate }}</span>
                <span [ngClass]="{'badge bg-danger': isRedLabel(lesson)}">
                  {{getProductVersion(lesson)}}
                </span>
              </div>
            </div>
            <div class="col-12 col-lg-3 col-xl-2 d-flex flex-column">
              <button
                (click)="openDetails(lesson)"
                class="btn btn-outline-secondary text-white rounded-pill d-block w-100 my-auto"
              >
                {{ "BUTTON.VIEW_DETAILS" | translate }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
