import { Injectable } from "@angular/core";
import { DeviceIdService } from "src/app/auth_profile/services/device-id.service";
import { OwnershipLocalState } from "../model/book-model";

@Injectable({
    providedIn: 'root'
})
export class BookDetailsService {

    ACTION_OPEN = 'open';
    ACTION_DOWNLOAD = 'download';
    ACTION_REGISTER_DOWNLOAD = 'register & download';
    deviceId: string;

    constructor(deviceIdService: DeviceIdService) {
        deviceIdService.getDeviceId().subscribe(deviceId => this.deviceId = deviceId);
    }

    hasAudio(localState: OwnershipLocalState) {
        return localState && localState.ownership.eBook.release.hasAudio;
    }

    getAudioSize(localState: OwnershipLocalState) {
        if (!localState || !localState.ownership.eBook.release.audioSize) {
            return null;
        }
        return (localState.ownership.eBook.release.audioSize / 1024 / 1024).toFixed(2);
    }

    getAudioDownloaded(localState: OwnershipLocalState) {
        if (!localState) {
            return null;
        }
        return localState.audioDownloaded;
    }

    hasDictations(localState: OwnershipLocalState) {
        return localState && localState.ownership.eBook.release.hasDictation;
    }

    getDictationSize(localState: OwnershipLocalState) {
        if (!localState || !localState.ownership.eBook.release.dictationSize) {
            return null;
        }
        return (localState.ownership.eBook.release.dictationSize / 1024 / 1024).toFixed(2);
    }

    getDictationDownloaded(localState: OwnershipLocalState) {
        return localState ? localState.dictDownloaded : null;
    }

    getAvailableShares(localState: OwnershipLocalState) {
        return localState ? localState.ownership.availableShares : 0;
    }

    getTotalShares(localState: OwnershipLocalState) {
        return localState ? 
          localState.ownership.availableShares + localState.ownership.devicesShared.length
          : 0;
    }

    getUsedShares(localState: OwnershipLocalState) {
        return localState && localState.ownership.devicesShared ?
        localState.ownership.devicesShared.length : 0;
    }

    mayOpen(localState: OwnershipLocalState): boolean {
        return !!(localState && localState.downloaded);
    }

    mayRegister(localState: OwnershipLocalState): boolean {
        return !this.deviceIsAssigned(localState) && this.getAvailableShares(localState) > 0;
    }

    mayDownload(localState: OwnershipLocalState): boolean {
        return this.deviceIsAssigned(localState);
    }

    deviceIsAssigned(localState: OwnershipLocalState) {
        if (!this.deviceId) {
            return false;
        }
        return localState && localState.ownership.devicesShared
        ? localState.ownership.devicesShared.findIndex( d => d.deviceId === this.deviceId) >= 0 : false;
    }

    getAction(localState: OwnershipLocalState): string {
        if (this.mayOpen(localState)) {
            return this.ACTION_OPEN;
        }
        if (this.mayRegister(localState)) {
            return this.ACTION_REGISTER_DOWNLOAD;
        }
        if (this.mayDownload(localState)) {
            return this.ACTION_DOWNLOAD;
        }
        return null;
    }

  hasAction(localState: OwnershipLocalState) {
      return !!this.getAction(localState)
  }
}
