import { Component } from '@angular/core';
import { ProductsUtils } from 'src/app/col/model/products';
import { StudentRestServiceImpl } from 'src/app/col/services/student/student-rest-impl.service';
import { CoreProductsBase } from 'src/app/col/utils/core-products-base';
import { LangProductMapper } from 'src/app/col/utils/lang-mappers';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import {StudentShoppingCartService} from "../../../../services/student-shopping-cart.service";

@Component({
  selector: 'app-spanish-products',
  templateUrl: './spanish-products.component.html',
  styleUrls: ['./spanish-products.component.scss'],
})
export class SpanishProductsComponent extends CoreProductsBase {
  constructor(studentRest: StudentRestServiceImpl,
              spinner: SpinnerService,
              shoppingCart: StudentShoppingCartService) {
    super(studentRest, spinner, shoppingCart);
  }

  getLangName() {
    return LangProductMapper.mapLangCodeToLangName('sp');
  }
  getEbookCategoryLabel(): string {
    return 'category:ebook.sp';
  }
  getPaygCategoryLabel(): string {
    return 'category:lessons.sp.pag';
  }
  getIntensiveCategoryLabel(): string {
    return 'category:lessons.sp.int';
  }
  getStdCategoryLabel(): string {
    return 'category:lessons.sp.std';
  }
  getLightCategoryLabel(): string {
    return 'category:lessons.sp.light';
  }
  getProductsLabel(): string {
    return ProductsUtils.SP_PRODUCT_LABEL;
  }
}
