/** 
 * OAuth token request response
 * It's a generic response format typical for "OAuth"
 **/
 export class AuthResponse {
   access_token: string;
   expiries_in: number;
   refresh_token: string;
   token_type: string;
 }
 
 /**
  * User profile informations which may be extracted directly from the token 
  */
 export class TokenBasedServerProfile {
    studentId?: number; // user is in student role
    managerId?: number; // user is in manager role
    teacherId?: number; // user is in teacher role
    schoolId?: number; // if user is in role related to the school, here is the school id
    roles: string[]; // auth roles on the user
 
    isSchoolAdmin() {return !!this.managerId;}
    isStudent() {return !!this.studentId;}
    isTeacher() {return !!this.teacherId;}
 }

 export class Authorization {
  username: string;
  password: string;
}