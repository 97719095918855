<div *ngIf="progress"
     class="mb-3 overflow-hidden" [class]="getCourseClass()">
  <div class="course row px-4 py-2 justify-content-center justify-content-sm-between">
    <div class="col my-auto">
      <span class="text-white text-nowrap fs-6 fs-sm-5">{{ getCourseName() | translate }} {{ getStage() }}, {{ getProgress() | number : "1.0-0" }}%</span>
    </div>
    <div class="col-auto d-flex">
      <a class="btn btn-outline-primary rounded-pill my-auto"
         routerLink="/student/progress"
         [queryParams]="{language: langCode}"
      >
        {{ "BUTTON.DETAILS" | translate }}
      </a>
    </div>
  </div>
</div>
