import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from "rxjs/operators";
import { Authorization } from '../../../../auth_profile/model/prototypes';
import { OwnershipLocalState, PasswordChangeRequest, ContactUs } from '../../../model/book-model';
import {LogsService} from "../../../../utils/services/logs.service";
import {BooksNativeServiceApi} from "../books-native-api";
import {IosBridgeBase} from "../../../../mobile/api-services/bridge/ios-bridge-base";

@Injectable({
    providedIn: 'root'
})
export class IosNativeApi
  implements BooksNativeServiceApi {

    constructor(private bridgeBase: IosBridgeBase,private logs: LogsService) {
    }

    getProducts(): Observable<OwnershipLocalState[]> {
        this.bridgeBase.log('listing products');
        return this.bridgeBase.callForResponse<OwnershipLocalState[]>('listProducts', {});
    }

    getProductById(productId: number): Observable<OwnershipLocalState> {
        return this.bridgeBase.callForResponse<OwnershipLocalState>('productDetails', {productId});
    }

    register(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('register', {productId, releaseId});
    }

    download(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('download', {productId, releaseId});
    }

    open(productId: number, releaseId: number): Observable<void> {
        return this.bridgeBase.callForResponse<any>('open', {productId, releaseId});
    }

    getImageUrl(productId: number, releaseId: number): Observable<string> {
        return this.bridgeBase.callForResponse<string>('getImageUrl', {productId, releaseId});
    }

    downloadAudio(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('downloadAudio', {productId, releaseId});
    }

    deleteAudio(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('deleteAudio', {productId, releaseId});
    }

    downloadDict(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('downloadDict', {productId, releaseId});
    }

    deleteDict(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('deleteDict', {productId, releaseId});
    }

    /**
     * check if media downloading is in progress. If yes update with the progress if no
     * return 0 or 1 depending on the download state
     * @param productId
     * @param releaseId
     */
    downloadingAudioProgress(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('getAudioDownloadProgress', {productId, releaseId})
    }

    /**
     * as above
     * @param productId
     * @param releaseId
     */
    downloadingDictationProgress(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>('getDictDownloadProgress', {productId, releaseId})
    }

    /**
     * call for refresh. Return complete only if refresh ends. Required.
     */
    refreshData(frequencyMs: number): Observable<void> {
        const result = this.bridgeBase.callForResponse<any>("refresh", { syncFrequencyMs: frequencyMs })
        .pipe(
            share<void>()
        )
        this.bridgeBase.refreshingSubject.next(result);
        return result;
    }

  listenForDataUpdates(): Observable<void> {
    return this.bridgeBase.listenForDataUpdates();
  }

  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.bridgeBase.subscribeForRefreshingEvents();
  }


}
