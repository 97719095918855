import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ScheduleRow } from '../../models/video.model';
import {Observable, Subscription} from "rxjs";
import {SpinnerService} from "../../../utils/services/spinner.service";

export interface SchedulesListProvider {
  listSchedules(focusDate: number): Observable<ScheduleRow[]>
}

@Component({
  selector: 'schedules-list',
  templateUrl: './schedules-list.component.html',
  styleUrls: ['./schedules-list.component.scss']
})
export class SchedulesListComponent implements OnInit, OnDestroy {

  schedules: ScheduleRow[]
  _provider: SchedulesListProvider
  lastReloaded = 0;
  private loadSubscription: Subscription

  @Input() redirectPathPrefix: string
  @Input()
  set dayStart(value: Date ){
    this._dayStart = value;
    this.reloadData();
  }
  private _dayStart: Date
  @Input()
  set dayEnd(value: Date) {
    this._dayEnd = value;
  }
  private _dayEnd: Date
  @Input()
  set schedulesProvider(provider: SchedulesListProvider) {
    this._provider = provider;
    this.reloadData();
  }

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.loadSubscription?.unsubscribe()
  }

  private reloadData() {
    const now = new Date().getTime();
    if (!this._provider || !this._dayStart || now - this.lastReloaded < 1000) return;
    this.lastReloaded = now;
    this.loadSubscription = this.spinnerService.trace<ScheduleRow[]>( this._provider.listSchedules(this._dayStart.getTime()))
      .subscribe(schedules =>
        this.schedules = schedules)
  }

  hasSchedules() {
    return !!this.getSchedules()?.length
  }

  getSchedules() {
    return this.schedules?.filter(schedule => {
      if(schedule.schedule.details.startDate < this._dayStart.getTime())
        return false
      else return !this._dayEnd || schedule.schedule.details.startDate <= this._dayEnd.getTime();
    })
  }

  getScheduleState(schedule: ScheduleRow) {
    return `LESSONS.LESSON_STATUS.${schedule.schedule.state}`
  }

  getScheduleBgClass(schedule: ScheduleRow) {
    if(schedule.schedule.state === 'PLANED')
      return 'bg-warning'
    else if(schedule.schedule.state === 'PENDING')
      return 'bg-info'
    else if(schedule.schedule.state === 'IN_PROGRESS')
      return 'bg-info'
    else if(schedule.schedule.state === 'COMPLETE')
      return 'bg-success'
    return null
  }

  getNumberOfParticipants(schedule: ScheduleRow) {
    return schedule.schedule.details.participants.length
  }

  getScheduleName(schedule: ScheduleRow) {
    return schedule.template.details.name
  }

  getScheduleDate(schedule: ScheduleRow) {
    return new Date(schedule.schedule.details.startDate)
    .toLocaleString('en-us', { day: '2-digit', month: 'long' })
  }

  getTeacherName(schedule: ScheduleRow) {
    return schedule.schedule.details.participants
      .find(participant => participant.role === 'Teacher').name
  }
  getScheduleEndTime(schedule: ScheduleRow) {
    return schedule.schedule.details.startDate + schedule.schedule.details.durationMin * 60 * 1000
  }
}
