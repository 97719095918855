
export class Constraints {
  public static androidInternalApiEndpoint = 'http://iternal-api-calls';
  public static androidGenericInternalApiEndpoint = 'http://iternal-api-calls';
  public static cacheNameHeader = 'X-Cache-Name';
  public static syncTimeHeader = 'X-Sync-Time';
  public static syncMinute = 1000 * 60;
  public static syncHour = Constraints.syncMinute * 60;
  public static syncDay = Constraints.syncHour * 24;
  public static syncWeek = Constraints.syncDay * 7; //two weeks
}

export enum Platform {
  Lessons = 'lessons',
  Booking = 'booking',
  Casa = 'casa',
  Cspa = 'cspa',
  App = 'app',
  Video = 'video'
}

export enum HttpMethod {
  GET = 'GET', POST = 'POST', PUT = 'PUT', PATCH = 'PATCH', DELETE = 'DELETE', OPTIONS = 'OPTIONS'
}

export class HttpRequest {
  constructor(
    public platform: Platform,
    public method: HttpMethod,
    public path: string,
    public headers: Map<string, string>,
    public body: string
  ) {}
}

export class CachedHttpRequest extends HttpRequest {
  constructor(
    platform: Platform,
    method: HttpMethod,
    path: string,
    headers: Map<string, string>,
    body: string,
    public cacheName: string,
    public syncTime: number
  ) {
    super(platform, method, path, headers, body);
  }
}

export class HttpResponse {
  public responseCode: number;
  public body: string;
}
