import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

export class ToastNotification {
    constructor(
        public title: string, 
        public message: string, 
        public isMsgShown: boolean = false,
        public timeout: number = 0,
        public toastClasses?: string,
        public eventDate?: Date
        ) { }
}
    
@Injectable({
    providedIn: 'root'
})
export class ToastNotificationsService {

    toDisplay = new Subject<ToastNotification>();

    display(notification: ToastNotification) {
        this.toDisplay.next(notification);
    }
}