<div class="row" *ngIf="hasTemplates(); else noResults">
  <div class="col-12">
    <section class="card accent-info">
      <div class="p-4 mb-2">
        <table class="table white-text mb-0">
          <thead class="small">
            <tr class="table-row table-row-head">
              <th scope="col">
                {{ "LESSONS.TEMPLATE_LIST.GROUP" | translate }}
              </th>
              <th scope="col">
                {{ "LESSONS.TEMPLATE_LIST.TEACHER" | translate }}
              </th>
            </tr>
          </thead>
          <tbody class="table-body small">
            <tr
              class="table-row table-row-body align-middle"
              *ngFor="let template of getTemplates()"
            >
              <td>{{ getTemplateName(template) }}</td>
              <td>{{ getTemplateTeacher(template) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
<ng-template #noResults>
  <div class="row">
    <p class="text-center fs-5 text-muted m-0">
      {{ "LESSONS.NO_RESULTS" | translate }}
    </p>
  </div>
</ng-template>
