import { Component, Input, OnInit } from '@angular/core';
import { map, tap } from 'rxjs';
import { ApiTeacherProfile, EntityRelatedRow } from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { LangProductMapper } from 'src/app/col/utils/lang-mappers';
import { Dates } from 'src/app/utils/calendar-utils';

class ReportData {
  complete = 0;
  studentNoShow = 0;
  invalidatedByStudent = 0;
  invalidatedByTeacher = 0;
  cancelled = 0;
}

@Component({
  selector: 'app-teacher-quick-lesson-counter',
  templateUrl: './teacher-quick-lesson-counter.component.html',
  styleUrls: ['./teacher-quick-lesson-counter.component.scss']
})
export class TeacherQuickLessonCounterComponent implements OnInit {

  langCodes = LangProductMapper.activeLangCodes;
  productCode: string;
  dateFrom: Date;
  dateTo: Date;
  reportData: ReportData;
  preselectedPeriod = '0';
  private _teacherId: number

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
  }

  constructor(private teacherRest: TeacherRestServiceImpl) { }

  ngOnInit(): void {
  }

  getLangName(langCode: string) {
    return LangProductMapper.mapLangCodeToLangName(langCode);
  }

  mapResults(reportRows: EntityRelatedRow<ApiTeacherProfile>[]) {
    const res = new ReportData();
    for (const row of reportRows) {
      switch (row.values[0] as number) {
        case 4:
         res.complete = row.values[1]; break;
        case 5:
         res.cancelled = row.values[1]; break;
        case 6:
         res.invalidatedByTeacher = row.values[1]; break;
        case 7:
         res.invalidatedByStudent = row.values[1]; break;
        case 8:
         res.studentNoShow = row.values[1]; break;
      }
    }
    return res;
  }

  calculateDates(): any {
    const preselectedPeriod = parseInt(this.preselectedPeriod, 0);
    this.dateFrom = Dates.monthStartDate( preselectedPeriod);
    this.dateTo = new Date();
    if (preselectedPeriod !== 0) {
      this.dateTo = Dates.monthFinishDate(preselectedPeriod);
    }
  }

  updateReport(): any {
    this.calculateDates();
    this.teacherRest.listLessonTypesReportForTeacher(this._teacherId,
      this.dateFrom, this.dateTo, this.productCode)
      .pipe(
        map( rows => this.mapResults(rows)),
        tap( reportData => this.reportData = reportData)
      )
      .subscribe();
  }


}
