<div class="container-fluid bg-radial my-0 bg-watermark">
  <div
    class="container d-flex flex-column justify-content-around mt-0 pt-5 vh-100"
  >
    <div>
      <div class="row justify-content-center mb-5">
        <app-icon name="callan-logo" class="mb-3 logo-lg"></app-icon>
      </div>
      <div class="row text-center">
        <span class="text-danger fs-5 f-sanchez">{{
          "FORBIDDEN.TITLE" | translate
        }}</span>
        <span class="fs-4 mb-4">{{ "FORBIDDEN.CONTENT" | translate }}</span>
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto">
          <button
            type="button"
            class="btn btn-primary btn-lg w-100 position-relative text-uppercase"
            (click)="onLogout()"
          >
            {{ "BUTTON.LOG_OUT" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <span class="text-muted small"> © 2021 Callan. All rights reserved. </span>
    </div>
  </div>
</div>
