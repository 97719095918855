<!-- multi bundle layout -->
<div *ngIf="!isLoading
    && activeCoursesBundles.size >= 1; else noCredits"
class="table-responsive">
  <table class="table white-text table-borderless">
    <thead>
    <tr class="table-row table-row-head">
      <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.PLAN" | translate }}</th>
      <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.CREDITS" | translate }}</th>
      <th class="small"></th>
    </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let langBundles of activeCoursesBundles.entries()" class="table-row">
        <td class="small text-nowrap">{{ getLanguageTranslation(langBundles[0].product.code) | translate }}</td>
        <td class="small">{{ langBundles[1] }}</td>
        <td class="small">
          <a class="btn btn-primary rounded-pill float-end text-nowrap"
             routerLink="/student/plans"
             [queryParams]="{language: langBundles[0].product.code}"
          >
            {{ "BUTTON.BUY_PLAN" | translate }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- no bundle layout -->
<ng-template #noCredits>
  <strong>{{ "COL.COURSES.CREDITS.NO_CREDITS" | translate }}</strong>
</ng-template>

<div class="row">
  <div class="col-auto ms-auto">
    <a class="btn btn-outline-primary rounded-pill float-end"
       routerLink="/student/credits"
       [queryParams]="{language: 'all'}"
    >
      {{ "BUTTON.DETAILS" | translate }}
    </a>
  </div>
</div>
