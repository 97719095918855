/**
 * contact us form
 */
 export class ContactUs {
    email: string;
    content: string;
}

/**
 * device info
 */
export class Device {
    deviceId: string;
}

export class ApiBase {
    id: number;
}

/**
 * Ebook description.
 * release is a reference to the concret instance of an epub.
 * Release is unmodifable - it means that any change will result with
 * the next release with another id.
 */
export class Ebook extends ApiBase {
    name: string;
    description: string;
    release: EpubRelease;
    categories: string[];
    order: number;
}

/**
 * Epub release - some fields are not yet developed - may be missing
 */
export class EpubRelease extends ApiBase {
    cover: string;
    size: number;
    audioSize: number;
    dictationSize: number;
    hasAudio: boolean;
    hasDictation: boolean;
}

/**
 * Ownership tells about a relationship of the product
 * to the device which is asking for
 */
export class Ownership {
    eBook: Ebook;
    // product is/was abailable for the device owner
    hasProduct: boolean;
    // number of available (not used) ebook instances
    availableShares: number;
    // list of devices which has a share
    devicesShared: Device[];
}

/**
 * Local device informations about the product
 */
export class OwnershipLocalState {
    ownership: Ownership;
    // download date in a ms time format
    // has to be null if en release is not downloaded
    downloaded: number;
    // last open date in ms format
    lastOpened: number;
    // audio download date or null if not downloaded
    audioDownloaded: number;
    // dictation download date or null if not downloaded
    dictDownloaded: number;
}

/**
 * result of the product reservation
 */
export class ProductShare {
    release: EpubRelease;
    // important value which will be necessary to decrypt the book
    magicNumber: string;
}

export class AppNotification {
    id: string;
    localisedTitle: { [lang: string]: string};
    localisedMessage: { [lang: string]: string};
    instant: boolean;
    source: string;
    date: number;
}

export class PasswordChangeRequest {
    email: string;
    password: string;
}