<div class="container-fluid vh-100 bg-radial bg-watermark">
  <div class="row mx-auto">
    <app-icon name="callan-logo" class="logo mx-auto my-5"></app-icon>
  </div>
  <div class="row mx-auto my-3" *ngIf="!passwordIsChanged()">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto">
      <div class="text-center m-auto p-4">
        <h3 class="mb-4">
          {{ "PROFILE.CHANGE_PASSWORD.TITLE" | translate }}
        </h3>
        <span
          *ngIf="getErrorMessage()"
          class="d-block invalid-feedback text-start fs-6 mb-4"
        >
          <app-icon class="sm-icon me-2" name="warning"></app-icon>
          {{ getErrorMessage() }}
        </span>
        <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
          <div class="form-floating mb-4">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Email"
              #email="ngModel"
              email
              required
              name="email"
              [(ngModel)]="formData.username"
              [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
              [disabled]="isInProgress()"
            />
            <label for="email">
              {{ "PROFILE.FORM.PERSONAL_INFO.EMAIL" | translate }}
            </label>
            <div *ngIf="f.submitted && email.invalid">
              <span
                *ngIf="email.errors?.['email']"
                class="d-block invalid-feedback xsmall float-start text-start"
              >
                <app-icon class="sm-icon me-2" name="warning"></app-icon>
                {{ "FORM_ERROR.EMAIL_INCORRECT" | translate }}
              </span>
              <span
                *ngIf="email.errors?.['required']"
                class="d-block invalid-feedback xsmall float-start text-start"
              >
                <app-icon class="sm-icon me-2" name="warning"></app-icon>
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </span>
            </div>
          </div>
          <div class="form-floating mb-4">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Password"
              #password="ngModel"
              required
              name="password"
              [(ngModel)]="formData.password"
              minlength="8"
              [disabled]="isInProgress()"
            />
            <label for="password">{{
              "PROFILE.CHANGE_PASSWORD.NEW" | translate
            }}</label>
            <div *ngIf="f.submitted && password.invalid">
              <span
                *ngIf="password.errors?.['password']"
                class="d-block invalid-feedback xsmall float-start text-start"
              >
                <app-icon class="sm-icon me-2" name="warning"></app-icon>
                {{ "FORM_ERROR.PASSWORD_INCORRECT" | translate }}
              </span>
              <span
                *ngIf="password.errors?.['required']"
                class="d-block invalid-feedback xsmall float-start text-start"
              >
                <app-icon class="sm-icon me-2" name="warning"></app-icon>
                {{ "FORM_ERROR.FIELD_REQUIRED" | translate }}
              </span>
              <span
                *ngIf="password.errors?.['minlength']"
                class="d-block invalid-feedback xsmall float-start text-start"
              >
                <app-icon class="sm-icon me-2" name="warning"></app-icon>
                {{ "FORM_ERROR.PASSWORD_MIN_8_CHARACTERS" | translate }}
              </span>
            </div>
          </div>
          <div class="row position-relative">
            <div class="col-6">
              <button
                type="button"
                class="btn btn-primary btn-lg w-100 fs-6"
                (click)="onCancel()"
              >
                {{ "BUTTON.CANCEL" | translate }}
              </button>
            </div>
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-secondary btn-lg w-100 fs-6 text-uppercase"
                [disabled]="isInProgress()"
              >
                {{ "BUTTON.RESET" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row mx-auto justify-content-center" *ngIf="passwordIsChanged()">
    <div
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto text-center p-4"
    >
      <h3 class="mb-4">
        {{ "PROFILE.CHANGE_PASSWORD.RESULT.HEADER" | translate }}
      </h3>
      <p class="mb-4">
        {{ "PROFILE.CHANGE_PASSWORD.RESULT.CONTENT" | translate }}
      </p>
      <button
        class="btn btn-primary btn-lg w-100 fs-6 position-relative"
        (click)="onCancel()"
      >
        {{ "BUTTON.CLOSE" | translate }}
      </button>
    </div>
  </div>
</div>
