import { AnswerDefinitionBase, QuestionDefinitionBase, Question } from './questions';

export class ItemAvailability {
    path: string;
    assigned: boolean;
    available: boolean;
    score: number;
    progress: number;
    lastUpdate: number;
}

export class ChapterAvailability extends ItemAvailability {
    lastSubmit: number;
}

export class ExerciseSetAvailability extends ItemAvailability {
    lastSubmit: number;
}

export class ExerciseSessionQuestion<A extends AnswerDefinitionBase, Q extends QuestionDefinitionBase<A>> {
    question: Question<A, Q>;
    answer: A;
    startDate: number;
    updateDate: number;
    timeSpent: number;
    submitDate: number;
    answered = false;
    correct = false;
    score = 0.0;
}

export class ExerciseSession {
    id: number;
    personId: number;
    path: string;
    score: number;
    baseQuestionNumber: number;
    startDate: string;
    finishDate: number;
    deviceUUID: string;
    lastUpdateDate: number;
    chapterName: string;
    exerciseName: string;
    sectionName: string;
    prevSession: ExerciseSession;
    questions: ExerciseSessionQuestion<any, any> [];
}

export class ExamSession {
  uuid: string;
  personId: number;
  path: string;
  startDate: Date;
  finishDate: Date;
  lastUpdateDate: Date;
  chapterName: string;
  sectionName: string;
  examName: string;
  nbOfParts: number;
  currentPart: ExamSessionPart;
  partsDone: number;
  parts: ExamSessionPart[];
}

export class ExamSessionPart {
  path: string;
  partName: string;
  startDate: Date;
  finishDate: Date;
  localStartDate: Date;
  timeLimitDate: Date;
  timeLimit: number;
  score: number;
  questions: ExerciseSessionQuestion<any, any>[];
}
