import { Injectable } from "@angular/core";
import { Observable, finalize, of, BehaviorSubject } from "rxjs";
import {LogsService} from "./logs.service";

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private isLoading = new BehaviorSubject(true);
    isLoading$ = this.isLoading.asObservable();
    private tasksNumber = 0
    constructor(private logger: LogsService) {}

    public trace<T>(task: Observable<T>): Observable<T> {
        this.isLoading.next(true)
        this.increasePendingTasksNumber();
        try {
          return task.pipe(
            finalize(() => this.decreasePendingTasksNumber() )
          )
        } catch(anything: any) {
           this.decreasePendingTasksNumber();
           return of(null)
        }
      }

    private increasePendingTasksNumber() {
        this.tasksNumber++
    }

    private decreasePendingTasksNumber() {
        this.tasksNumber--
        this.logger.log(`decrease spinner tasks to ${this.tasksNumber}`)
        if(this.tasksNumber === 0) this.isLoading.next(false)
    }
}
