<div class="card mb-5" *ngIf="!isLoading">
  <div class="card-header">
    <div class="row">
      <div class="col d-flex">
        <h4 class="my-auto">
          {{ "COL.COURSES.CREDITS.AVAILABLE_CREDITS" | translate }}
        </h4>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-primary rounded-pill"
            routerLink="/student/plans"
            [queryParams]="{language: _langCode}"
        >
          {{ "BUTTON.BUY_MORE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-auto ms-auto">
        <paginator
        [data]="bundles"
        [pageable]="pageable"
        (newPage)="onPageChange($event)"
         class="mb-4"
      ></paginator>
      </div>
    </div>
    <!-- no bundle layout -->
    <div *ngIf="getBundles() && getBundles().length == 0">
      <strong>{{ "COL.COURSES.CREDITS.NO_CREDITS" | translate }}</strong>
    </div>

    <!-- multi bundle layout -->
    <div *ngIf="getBundles() && getBundles().length >= 1">
      <table class="table white-text table-borderless pe-none">
        <thead>
          <tr class="table-row table-row-head">
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.PLAN" | translate }}</th>
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.CREDITS" | translate }}</th>
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.EXPIRY_DATE" | translate }}</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let bundle of getBundles()" class="table-row">
            <td class="small">{{ bundle.name }}</td>
            <td class="small">{{ bundle.available }}</td>
            <td class="small">{{ bundle.expiryDate | dateTranslation : "dd MMM yyyy" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
