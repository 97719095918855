<app-modal #filterTeacherModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        <p class="small mb-0">
          {{ "COL.COURSES.CALENDAR.MODAL.FILTER_TEACHER.TITLE" | translate }}
        </p>
      </h5>
      <button
        type="button"
        class="btn-close"
        (click)="filterTeacherModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="list-group overflow-scroll gap-2 teacher-list">
        <a
          tabindex=""
          (click)="selectTeacher(null)"
          class="d-flex align-items-center p-1 p-sm-2 text-decoration-none gap-3 list-group-item"
          [ngClass]="{ 'active-teacher': getSelectedTeachers()?.length === 0 }"
        >
          <div
            class="d-flex rounded-circle text-center bg-gray initials-circle"
          ></div>
          <span class="text-break teacher-name">
            {{ "COL.COURSES.RESERVE_LESSON.ALL_TEACHERS" | translate }}
          </span>
        </a>
        <a
          tabindex=""
          (click)="selectTeacher(teacher)"
          class="d-flex align-items-center p-1 p-sm-2 text-decoration-none gap-3 list-group-item"
          *ngFor="let teacher of teachers"
          [ngClass]="{ 'active': isActive(teacher) }"
        >
          <div
            *ngIf="getTeacherPhoto(teacher)"
            class="profile-photo rounded-circle initials-circle"
            [style.background-image]="'url(' + getTeacherPhoto(teacher) + ')'"
          ></div>
          <div
            *ngIf="!getTeacherPhoto(teacher)"
            class="d-flex rounded-circle text-center bg-gray initials-circle"
          >
            <span class="m-auto text-muted">
              {{ getInitials(teacher) }}
            </span>
          </div>
          <span class="text-break teacher-name">
            {{ getTeacherName(teacher) }}
          </span>
        </a>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button
        class="btn btn-secondary rounded-pill"
        (click)="clearFilters()"
      >
        {{ "BUTTON.CLEAR" | translate }}
      </button>
      <button
        class="btn btn-primary rounded-pill"
        (click)="applyFilters()"
      >
        {{ "BUTTON.SHOW" | translate }}
      </button>
    </div>
  </div>
</app-modal>
