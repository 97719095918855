<app-modal  #instructionModal>
  <div class="modal-content top-0">
    <div class="modal-header border-0">
      <h5 class="modal-title" >{{exerciseName}}</h5>
      <button type="button"
          class="btn btn-close btn-close-white"
          (click)="instructionModal.hide()"
          aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <h5 class="modal-title mb-2">
        {{ "EXERCISES.INSTRUCTIONS.COLOUR_KEY" | translate}}
      </h5>
      <div class="row row-cols-2">
        <div
          class="col my-2 d-flex flex-row align-items-center"
          *ngFor="let exState of exStates"
        >
          <div
            class="btn colour-key-item mb-0 me-2"
            [ngClass]="exState"
          >
          </div>
          <span class="xsmall text-capitalize">
            {{
              "EXERCISES.DETAILS.QUESTION_SET.TOOLTIP." + exState.toUpperCase()
                | translate
            }}
          </span>
        </div>
      </div>
      <div class="my-4">
        <span>{{ "EXERCISES.DETAILS.QUESTION_SET.DESCRIPTION." + sectionPath.toUpperCase() | translate}}</span>
      </div>
      <app-instruction [exerciseType]="sectionPath" [withSettings]="false" [isOffcanvas]="false" class="mt-4"></app-instruction>
    </div>
  </div>
</app-modal>
