<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <button
          type="button"
          routerLink="../../../"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3">{{
            "NAVIGATION.DASHBOARD" | translate
          }}</span>
        </button>
        <div class="text-center">
          <h4>{{ "LESSONS.TITLE" | translate }}</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LESSONS.TITLE" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row pt-3 text-center">
      <!-- <p class="fs-3 text-muted">
        {{ "LESSONS.WORK_IN_PROGRESS" | translate }}
      </p> -->
      <p class="text-muted">
        {{ "LESSONS.WORK_IN_PROGRESS_INFO" | translate }}
      </p>
      <!-- <a class="btn-link text-decoration-none fs-3 pe-none">callan.app</a> -->
    </div>
  </div>
</div>
