import {CasaNativeApi, UploadEvent} from "../casa-native-api";
import {LogsService} from "../../../../utils/services/logs.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AndroidBridgeBase} from "../../../../mobile/api-services/bridge/android-bridge-base";
import {Constraints, Platform} from "../../../../constraints";
import {Observable, throwError} from "rxjs";
import {
  ApiCountry,
  FileItem,
  Person,
  PersonDetails,
  School,
  StudentPersonDetails
} from "../../../model/casa/casa-model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CasaAndroidService implements CasaNativeApi {

  constructor(
    private logger: LogsService,
    private http: HttpClient,
    private bridgeBase: AndroidBridgeBase
  ) {}

  deleteSelf(locale?: string) {
        throw new Error("Method not implemented.");
    }

  private apiEndpoint = Constraints.androidGenericInternalApiEndpoint;

  private buildPath(path: string): string {
    return `${this.apiEndpoint}/platform/${Platform.Casa}${path}`
  }

  private static buildRequestIdHeader(id: number){
    return new HttpHeaders({"X-Request-Id" : id.toString()});
  }

  private nextRequestIdHeader(){
    return CasaAndroidService.buildRequestIdHeader(this.bridgeBase.getNextRequestId());
  }

  getCountries(): Observable<ApiCountry[]> {
    this.logger.log("getting countries list")
    return this.http.get<ApiCountry[]>(this.buildPath(`/public/countries`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, "casa-countries")
        .append(Constraints.syncTimeHeader, (Constraints.syncWeek * 2).toString())
    });
  }

  getSelf(): Observable<Person<PersonDetails>> {
    this.logger.log("getting self")
    return this.http.get<Person<PersonDetails>>(this.buildPath('/v2/self'), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, "casa-self")
        .append(Constraints.syncTimeHeader, (Constraints.syncDay).toString())
    })
  }

  getSelfStudent(): Observable<Person<StudentPersonDetails>> {
    this.logger.log("getting self student")
    return this.http.get<Person<StudentPersonDetails>>(this.buildPath('/v2/self'), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, "casa-self-student")
        .append(Constraints.syncTimeHeader, (Constraints.syncDay).toString())
    })
  }

  getStudentsSchool(studentId: number): Observable<School> {
    this.logger.log(`getting school of student with id=${studentId}`)
    return this.http.get<School>(this.buildPath(`/v2/self/student/${studentId.toString()}/school`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, `casa-student-${studentId}-school`)
        .append(Constraints.syncTimeHeader, (Constraints.syncDay).toString())
    })
  }

  getTeachersSchool(teacherId: number): Observable<School> {
    this.logger.log(`getting school of teacher with id=${teacherId}`)
    return this.http.get<School>(this.buildPath(`/v2/self/teacher/${teacherId.toString()}/school`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, `casa-student-${teacherId}-school`)
        .append(Constraints.syncTimeHeader, (Constraints.syncDay).toString())
    })
  }

  isImplemented(): boolean {
    return true;
  }

  supportsFileSending(): boolean {
    return false;
  }

  updateSelfStudentPersonalProfile(details: StudentPersonDetails): Observable<StudentPersonDetails> {
    this.logger.log(`updating student personal profile`)
    return this.http.put<StudentPersonDetails>(
      this.buildPath(`/v2/self/student-role`),
      details,
      { headers: this.nextRequestIdHeader()}
    )
  }

  updateSelfTeacherPersonalProfile(details: PersonDetails): Observable<PersonDetails> {
    this.logger.log(`updating teacher personal profile`)
    return this.http.put<PersonDetails>(
      this.buildPath(`/v2/self`),
      details,
      { headers: this.nextRequestIdHeader()}
    )
  }

  uploadFile(file: File): Observable<UploadEvent<FileItem>> {
    return throwError(() => new Error("Unsupported API"));
  }

  isSupportsProfileEdit(): Boolean {
    return false;
  }

}
