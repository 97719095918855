<ng-container *ngIf="!isHidden">
  <div class="d-flex justify-content-end align-items-center mb-4" *ngIf="!isCourseActive() && !onlyButton">
    <small class="text-wrap text-end me-2">{{"COL.ACTIVATE.INFO" | translate}}</small>
    <button class="btn btn-primary rounded-pill"
            (click)="activateCourseModal.show()">
      {{ "BUTTON.ACTIVATE" | translate}}
    </button>
  </div>
  <button *ngIf="!isCourseActive() && onlyButton"
          class="btn btn-primary rounded-pill"
          (click)="activateCourseModal.show()">
    {{ "BUTTON.ACTIVATE" | translate}}
  </button>
</ng-container>
<app-modal #activateCourseModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title" *ngIf="defaultContent">{{"COL.ACTIVATE.TITLE" | translate}} - <span>{{getLanguageTranslation(langCode) | translate}}</span></h5>
      <ng-content select="[header]"></ng-content>
      <button
        type="button"
        class="btn-close"
        (click)="activateCourseModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body text-start">
      <span *ngIf="defaultContent">
        {{"COL.ACTIVATE.MODAL_CONTENT" | translate}}
      </span>
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-secondary rounded-pill" (click)="activateCourseModal.hide()">{{"BUTTON.CANCEL" | translate}}</button>
      <button type="submit" class="btn btn-primary rounded-pill" [disabled]="isActivatingInProgress" (click)="activateCourse()">
        {{ "BUTTON.ACTIVATE_NOW" | translate }}
        <div *ngIf="isActivatingInProgress" class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</app-modal>
