<div class="card">
  <div class="card-body row align-items-center justify-content-between">
    <div class="col-12 col-md-6 col-lg-5 mb-3 mb-lg-0 border-md-right border-secondary">
      <p class="text-muted">{{ 'CART.CHOOSE_CURRENCY' | translate }}</p>
      <div class="d-inline-block text-md-center">
        <button class="btn rounded-circle ms-2 mb-md-2 px-3 py-2 lh-lg"
                [class]="cur === _currency? 'btn-primary pe-none' : 'btn-outline-primary'"
                *ngFor="let cur of currencies" (click)="currency = cur">
          {{ getCurrencyIcon(cur) }}
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-between">
      <div class="d-inline-flex flex-column">
        <span>
          <span class="text-muted me-2">{{ 'CART.ITEMS_AMOUNT' | translate }}</span>
          <span>{{getItems()}}</span>
        </span>
        <span>
          <span class="text-muted me-2">{{ 'CART.TOTAL' | translate }}</span>
          <span>{{getTotal()}}</span>
        </span>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
      <button
        class="btn btn-primary d-block w-100 rounded-pill"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasCart">
        {{ 'CART.CHECKOUT' | translate }}
      </button>
    </div>
  </div>
</div>
