import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {defaultIfEmpty, share} from "rxjs/operators";
import {BooksNativeServiceApi} from "../books-native-api";
import {LogsService} from "../../../../utils/services/logs.service";
import {ContactUs, OwnershipLocalState, PasswordChangeRequest} from "../../../model/book-model";
import {Authorization} from "../../../../auth_profile/model/prototypes";
import {AndroidBridgeBase} from "../../../../mobile/api-services/bridge/android-bridge-base";


@Injectable({
    providedIn: 'root'
})
export class AndroidBooksNativeApi
    implements BooksNativeServiceApi {

    constructor(private bridgeBase: AndroidBridgeBase, private logger: LogsService) {
    }

    getProducts(): Observable<OwnershipLocalState[]> {
        this.logger.log('listProducts()');
        return this.bridgeBase.callForResponse<OwnershipLocalState[]>(
            "listProducts",
            id => window.nativeBridge.listProducts(id)
        );
    }

    getProductById(productId: number): Observable<OwnershipLocalState> {
        this.logger.log(`productDetails(${productId})`);
        return this.bridgeBase.callForResponse<OwnershipLocalState>(
          `productDetails(${productId})`,
            id => window.nativeBridge.productDetails(id, productId)
        );
    }

    register(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`register(${productId}, ${releaseId})`);
        return this.bridgeBase.callForResponse<number>(
          `register(${productId},${releaseId})`,
            id => window.nativeBridge.register(id, productId, releaseId)
        );
    }

    download(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`download(${productId}, ${releaseId})`);
        return this.bridgeBase.callForResponse<number>(
          `download(${productId},${releaseId})`,
            id => window.nativeBridge.download(id, productId, releaseId)
        );
    }

    open(productId: number, releaseId: number): Observable<void> {
        this.logger.log(`open(${productId}, ${releaseId})`);
        return this.bridgeBase.callForResponse<void>(
          `open(${productId}, ${releaseId})`,
            id => window.nativeBridge.open(id, productId, releaseId)
        );
    }

    getImageUrl(productId: number, releaseId: number): Observable<string> {
        return this.bridgeBase.callForResponse<string>(
          `getImageUrl(${productId}, ${releaseId})`,
            id => window.nativeBridge.getImageUrl(id, productId, releaseId)
        );
    }

    downloadAudio(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `downloadAudio(${productId}, ${releaseId})`,
            id => window.nativeBridge.downloadAudio(id, productId, releaseId)
        );
    }

    deleteAudio(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `deleteAudio(${productId}, ${releaseId})`,
            id => window.nativeBridge.deleteAudio(id, productId, releaseId)
        );
    }

    downloadDict(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `downloadDict(${productId}, ${releaseId})`,
            id => window.nativeBridge.downloadDict(id, productId, releaseId)
        );
    }

    deleteDict(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `deleteDict(${productId}, ${releaseId})`,
            id => window.nativeBridge.deleteDict(id, productId, releaseId)
        );
    }

    downloadingAudioProgress(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `downloadingAudioProgress(${productId}, ${releaseId}`,
            id => window.nativeBridge.downloadingAudioProgress(id, productId, releaseId)
        );
    }

    downloadingDictationProgress(productId: number, releaseId: number): Observable<number> {
        return this.bridgeBase.callForResponse<number>(
          `downloadingDictationProgress(${productId}, ${releaseId}`,
            id => window.nativeBridge.downloadingDictationProgress(id, productId, releaseId)
        );
    }

    refreshData(frequencyMs: number): Observable<void> {
        const result = this.bridgeBase.callForResponse<void>(
          `refresh(${frequencyMs})`,
          id => window.nativeBridge.refresh(id, frequencyMs))
            .pipe(
              defaultIfEmpty(""),
                share<void>()
            )
        this.bridgeBase.refreshingSubject.next(result);
        return result;
    }

  listenForDataUpdates(): Observable<void> {
    return this.bridgeBase.listenForDataUpdates();
  }

  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.bridgeBase.subscribeForRefreshingEvents();
  }
}
