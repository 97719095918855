import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseSession, ExerciseSessionQuestion } from '../../model/cspa/personal';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {switchMap} from "rxjs";

@Component({
  selector: 'app-question-set-summary',
  templateUrl: './question-set-summary.component.html',
  styleUrls: ['./question-set-summary.component.scss']
})
export class QuestionSetSummaryComponent implements OnInit {

  @Input() session: ExerciseSession;
  @Input() uuid: string
  resultState = {
    unknown: 'unknown',
    bad: 'bad',
    poor: 'poor',
    good: 'good'
  };
  exerciseSet: string;
  chapter: string;
  section: string;
  exercise: string;

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.exerciseSet = params.get('exerciseSet');
        this.chapter = params.get('chapter');
        this.section = params.get('section');
        this.exercise = params.get('exerciseNb');
      }
    );
  }

  recreateSession() {
    this.provider.cspa().pipe(
      switchMap( api => api.restartSession(this.session.deviceUUID))
    ).subscribe(
      newSession => {
        this.router.navigate(
          ['student/exercises', this.exerciseSet, this.chapter, this.section,
            this.exercise, 'session', newSession.deviceUUID, '1'])
      }
    )
  }

  getScore() {
    if (!this.session) {
      return null;
    }
    return Math.round(this.session.score * 100);
  }

  getFractionOfScore(){
    const questionsLength = this.session.questions.length
    let scoreWage = 100/questionsLength
    let numerator = (this.getScore()/scoreWage).toFixed()
    return `${numerator}/${questionsLength}`
  }

  mayCorrect() {
    if (!this.session) {
      return false;
    }
    return this.session.questions.find( q => !q.correct) != null;
  }

  getQuestionScoreClass(question: ExerciseSessionQuestion<any, any>) {
    if (!question.answered || question.score < 0.5) {
      return 'bad';
    } else if (question.score < 0.95) {
      return 'enough';
    } else {
      return 'perfect';
    }
  }

  getSessionName() {
    if (!this.session) {
      return null;
    }
    return this.session.exerciseName;
  }

  getResultState() {
    if (!this.session) {
      return this.resultState.unknown;
    }
    if (this.session.score < 0.5) {
      return this.resultState.bad;
    } else if (this.session.score < 0.95) {
      return this.resultState.poor;
    } else {
      return this.resultState.good;
    }
  }

  getQuestions() {
    return this.session.questions
  }

  hasErrors() {
    if (!this.session) {
      return false;
    }
    return this.session.questions.find( sq => sq.score < 0.5) != null;
  }

  onFinish() {
    this.router.navigate(
      [`../../../../../`],
      {
        relativeTo: this.route,
        queryParamsHandling: 'merge'
      }
    )
  }
}
