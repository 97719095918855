import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class EnvVariablesService {

    casaClientId: string
    callanonlineId: number
    serverBase: string
    authEndpoint: string
    casaEndpoint : string
    cspaEndpoint: string
    appEndpoint: string
    apiBase: string
    environment: any
    videoEndpoint: string
    callanAppUrl: string;
    constructor(@Inject('config') config)
    {
        if (config) {
            this.environment = config;
            this.casaClientId = this.environment.casaClientId
            this.callanonlineId = this.environment.callanonlineId
            this.serverBase = this.environment.serverBase
            this.authEndpoint = this.environment.authEndpoint
            this.casaEndpoint = this.environment.casaEndpoint
            this.cspaEndpoint = this.environment.cspaEndpoint
            this.appEndpoint = this.environment.appEndpoint
            this.videoEndpoint = this.environment.videoEndpoint
            this.apiBase = this.environment.apiBase
            this.callanAppUrl = this.environment.callanAppUrl
       }
    }
  }
