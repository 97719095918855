import {Observable, Observer, of, Subject} from "rxjs";
import {PendingRequestsQueue} from "./js-bridge-task-queue";
import {Injectable, NgZone} from "@angular/core";
import {LogsService} from "../../../utils/services/logs.service";

declare global {
  interface Window {
    nativeBridge: any;
    webBridge: any;
  }
}

export interface AndroidWebBridge {
  /**
   * call after you provide native bridge implementation
   * to the WebView
   */
  initialize();

  /**
   * call to return the invocation response from
   * Native to the Web application
   * @param data stringified CallbackResponse<T> invocation
   * response
   */
  handlePendingRequest(data: string);

  /**
   * Call if data state has been changed and you
   * expect the current View data should be reloaded
   */
  notifyDataUpdate();

  /**
   * Android lets to know if back is pressed
   */
  onBackPressed();
  onImoPressed();
}

export interface WaitingCall {
  doCall();
  id: number
  name: string
}

class SimpleWaitingCall implements WaitingCall {
  constructor(public id: number,
              public name: string,
              private nativeCall: (id: number) => void) {}
  public doCall() {
    this.nativeCall(this.id);
  }
}

class HttpWaitingCall<T> implements WaitingCall {
  constructor(
    public id: number,
    public name: string,
    private nativeCall: (id: number) => T,
    private observer: Observer<T>
  ) {}
  public doCall() {
    try {
      const result = this.nativeCall(this.id);
      this.observer.next(result);
      this.observer.complete;
    } catch (e) {
      this.observer.error(e);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AndroidBridgeBase implements AndroidWebBridge {
  initialized = false;
  updateEventsSubject = new Subject<void>();
  waitingCallsQueue: WaitingCall[] = [];
  refreshingSubject = new Subject<Observable<void>>();
  private backStateSubject = new Subject<void>();
  private imoSateSubject = new Subject<void>();
  id = 0;

  constructor(
    private taskQueue: PendingRequestsQueue,
    protected logger: LogsService,
    private ngZone: NgZone) {
    logger.log('creating android native bridge');
    window.webBridge = this;
  }

  listenToBackButton(): Observable<any> {
    return this.backStateSubject.asObservable();
  }

  listenToIMOButton(): Observable<any> {
    return this.imoSateSubject.asObservable();
  }
  onBackPressed() {
    this.ngZone.run(() => {
      this.logger.log("got back button event from Android");
      this.backStateSubject.next(null);
    })
  }

  /**
   * When root component starts it calls begin method on nativeBridge to notify the
   * device that is ready for initialization. Next, native calls this initialize()
   * method.
   */
  initialize() {
    if (this.initialized) return;
    this.logger.log(`initializing with ${this.waitingCallsQueue.length} waiting tasks.`);
    this.initialized = true;
    for (const waitingCall of this.waitingCallsQueue) {
      this.logger.log(`calling native during init [${waitingCall.id} - ${waitingCall.name}]`);
      waitingCall.doCall();
    }
    this.waitingCallsQueue = [];
  }

  handlePendingRequest(data: string) {
    this.taskQueue.handlePendingRequest(JSON.parse(data));
  }

  callForResponse<T>(name: string, nativeCall: (id: number) => void): Observable<T> {
    return Observable.create(observer => {
      const id = this.id++;
      this.taskQueue.registerPendingRequest(id, observer);
      if (this.initialized) {
        this.logger.log(`calling native [${id} - ${name}]`);
        nativeCall(id);
      } else {
        this.logger.log(`adding to waiting tasks [${id} - ${name}]`);
        this.waitingCallsQueue.push(new SimpleWaitingCall(id, name, nativeCall));
      }
    });
  }

  notifyDataUpdate(): void {
    this.ngZone.run(() => {
      this.logger.log('notifying data update');
      this.updateEventsSubject.next(null);
    });
  }

  listenForDataUpdates(): Observable<void> {
    return this.updateEventsSubject.asObservable();
  }

  log(text: string) {
    this.logger.log(`[android-api2] - ${text}`);
  }

  // #### data management api #####
  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.refreshingSubject.asObservable();
  }

  /**
   * internal implementation of native calls which will take care for
   * waiting calls stored before initialization
   * @param nativeCall
   * @private
   */
  callNative<T>(nativeCall: (id: number) => T): Observable<T> {
    const id = this.getNextRequestId();
    if (this.initialized) {
      this.logger.log(`calling native [${id}] directly`);
      return of(nativeCall(id));
    }
    this.logger.log(`adding to waiting tasks [${id}]`)
    return new Observable<T>(observer => {
      this.waitingCallsQueue.push(new HttpWaitingCall(id, "http", nativeCall, observer));
    });
  }

  // #### data management implementation #####
  getNextRequestId() {
    return this.id++;
  }

  onImoPressed() {
    this.ngZone.run(() => {
      this.logger.log("IMO pressed");
      this.imoSateSubject.next(null);
    })
  }
}
