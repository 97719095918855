import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUsService } from '../contact-us.service';
import { finalize, tap } from 'rxjs'
import { LoadComponentDirective } from 'src/app/utils/load-component/load-component.directive';
import { ContactUsResultComponent } from '../contact-us-result/contact-us-result.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import {Location} from "@angular/common";

@Component({
  selector: 'app-student-contact-us-page',
  templateUrl: './student-contact-us-page.component.html',
  styleUrls: ['./student-contact-us-page.component.scss']
})
export class StudentContactUsPageComponent implements OnInit {

  busy = false
  isResult = false
  @ViewChild(LoadComponentDirective, { static: true}) loadComponent!: LoadComponentDirective

  constructor(private contactUsService: ContactUsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {}

  ngOnInit(): void {
    this.isResult = this.router.url.includes('result')
    this.onLoadComponent(this.isResult)
  }

  onLoadComponent(isResult: boolean) {
    this.loadComponent.viewContainerRef.clear()
    let componentRef;
    if(!isResult) {
      componentRef = this.loadComponent.viewContainerRef.createComponent(ContactUsComponent)
      componentRef.instance.msgEmitter.subscribe( event => {
        console.log(event)
        this.contactUsService.sendContactUs(event).pipe(
          tap(_ => this.busy = true),
          finalize( () => {
            this.busy = false
          })
        )
        .subscribe( _ => {
          this.router.navigate(
            ['./result'],
            {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            }
          )
          this.onLoadComponent(true)
        })
      })
      componentRef.instance.busy = this.busy
      componentRef.instance.cancel.subscribe( _ => {
        this.location.back();
      })
    }
    else if(isResult) {
      componentRef = this.loadComponent.viewContainerRef.createComponent(ContactUsResultComponent)
      componentRef.instance.isLoggedIn = true
    }
  }
}
