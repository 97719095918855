<section class="mb-5">
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">
        {{ "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.TITLE" | translate }}
      </h4>
    </div>
    <div class="card-body">
      <p class="small">
        {{ "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXPLANATION" | translate }}
      </p>
      <div class="table-responsive">
        <table class="table white-text pe-none mt-3">
          <thead>
          <tr class="table-row table-row-head">
            <th scope="col">CEFR Level<sup>1</sup></th>
            <th scope="col">Trinity GESE<sup>2</sup></th>
            <th scope="col">Trinity ISE<sup>3</sup></th>
            <th scope="col">TOEFL iBT<sup>4</sup></th>
            <th scope="col">Cambridge English<sup>5</sup></th>
            <th scope="col">IELTS<sup>6</sup></th>
            <th scope="col">Callan stages</th>
          </tr>
          </thead>
          <tbody class="table-body">
          <tr class="table-row">
            <td>A1</td>
            <td>Grade 2</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>3/4</td>
          </tr>
          <tr class="table-row">
            <td>A2</td>
            <td>Grade 3 & 4</td>
            <td>ISE 0</td>
            <td>-</td>
            <td>KET</td>
            <td>3.0-3.5</td>
            <td>4/5</td>
          </tr>
          <tr class="table-row">
            <td>B1</td>
            <td>Grade 5 & 6</td>
            <td>ISE I</td>
            <td>57-86</td>
            <td>PET</td>
            <td>4.0-4.5</td>
            <td>end of 6</td>
          </tr>
          <tr class="table-row">
            <td>B2</td>
            <td>Grade 7, 8 & 9</td>
            <td>ISE II</td>
            <td>87-109</td>
            <td>FCE</td>
            <td>5.0-6.5</td>
            <td>end of 10</td>
          </tr>
          <tr class="table-row">
            <td>C1</td>
            <td>Grade 10 & 11</td>
            <td>ISE III</td>
            <td>110-120</td>
            <td>CAE</td>
            <td>7.0-8.0</td>
            <td>end of 12</td>
          </tr>
          <tr class="table-row">
            <td>C2</td>
            <td>Grade 12</td>
            <td>ISE IV</td>
            <td>-</td>
            <td>CPE</td>
            <td>8.5-9.0</td>
            <td>-</td>
          </tr>
          </tbody>
        </table>
      </div>
      <ol class="text-muted ps-3">
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.CEFR.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.CEFR.DESC"
              | translate
            }}
          </p>
        </li>
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TRINITY_GESE.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TRINITY_GESE.DESC"
              | translate
            }}
          </p>
        </li>
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TRINITY_ISE.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TRINITY_ISE.DESC"
              | translate
            }}
          </p>
        </li>
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TOEFL_IBT.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.TOEFL_IBT.DESC"
              | translate
            }}
          </p>
        </li>
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.CAMBRIDGE_ENG.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.CAMBRIDGE_ENG.DESC"
              | translate
            }}
          </p>
        </li>
        <li>
          <p class="fw-bold small mb-1">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.IELTS.TITLE"
              | translate
            }}
          </p>
          <p class="xsmall">
            {{
            "COL.COURSES.PRODUCTS.LEVEL_COMPARISON.EN.EXAMINATIONS_LIST.IELTS.DESC"
              | translate
            }}
          </p>
        </li>
      </ol>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">{{ "COL.COURSES.PRODUCTS.HOW_LONG.TITLE" | translate }}</h4>
    </div>
    <div class="card-body">
      <p class="small">
        {{ "COL.COURSES.PRODUCTS.HOW_LONG.DESC" | translate }}
      </p>
      <div class="table-responsive">
        <table class="table white-text pe-none mt-3">
          <thead>
          <tr class="table-row table-row-head">
            <th scope="col">
              {{ "COL.COURSES.PRODUCTS.HOW_LONG.STAGE" | translate }}
            </th>
            <th scope="col">1</th>
            <th scope="col">2</th>
            <th scope="col">3</th>
            <th scope="col">4</th>
            <th scope="col">5</th>
            <th scope="col">6</th>
            <th scope="col">7</th>
            <th scope="col">8</th>
            <th scope="col">9</th>
            <th scope="col">10</th>
            <th scope="col">11</th>
            <th scope="col">12</th>
          </tr>
          </thead>
          <tbody class="table-body">
          <tr class="text-muted table-row">
            <th scope="row" class="xsmall">
              {{ "COL.COURSES.PRODUCTS.HOW_LONG.N_LESSONS" | translate }}
            </th>
            <td class="xsmall">22-32</td>
            <td class="xsmall">37-47</td>
            <td class="xsmall">38-48</td>
            <td class="xsmall">51-61</td>
            <td class="xsmall">48-58</td>
            <td class="xsmall">42-52</td>
            <td class="xsmall">45-55</td>
            <td class="xsmall">44-54</td>
            <td class="xsmall">61-71</td>
            <td class="xsmall">59-69</td>
            <td class="xsmall">65-75</td>
            <td class="xsmall">64-74</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <p class="text-center mb-5 h4">
    {{ "COL.COURSES.PRODUCTS.PROGRESS.TITLE" | translate }}
  </p>
  <div class="row text-center justify-content-center">
    <div class="col-12 col-md-6 col-xl-4">
      <p>{{ "COL.COURSES.PRODUCTS.PROGRESS.EN.FREQUENCY.TITLE" | translate }}</p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.PROGRESS.EN.FREQUENCY.DESC" | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <p>{{ "COL.COURSES.PRODUCTS.PROGRESS.EN.CONNECTION.TITLE" | translate }}</p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.PROGRESS.EN.CONNECTION.DESC" | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <p>{{ "COL.COURSES.PRODUCTS.PROGRESS.EN.EXERCISES.TITLE" | translate }}</p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.PROGRESS.EN.EXERCISES.DESC" | translate }}
      </p>
    </div>
  </div>
</section>
