import {Chapter, Exercise, Section} from "../../model/cspa/struct";
import {Question} from "../../model/cspa/questions";
import {
  ChapterAvailability,
  ExerciseSession,
  ExerciseSessionQuestion,
  ItemAvailability
} from "../../model/cspa/personal";

export class ScoreAvailabilityApplyService {


  private static buildFullPaths(paths: string[]): string[] {
    const result: string[] = [];
    let currentPath = `${paths[0]}_${paths[1]}`;
    result.push(currentPath);
    for (let i = 2 ; i < paths.length ; i++) {
      currentPath = `${currentPath}_${paths[i]}`;
      result.push(currentPath);
    }
    return result;
  }

  updateAvailabilityWithScoredSession(
    chapters: Chapter[],
    questions: Question<any, any>[],
    availability: ItemAvailability[],
    topAvailabilities: ItemAvailability[],
    session: ExerciseSession): ItemAvailability[] {

    const [exerciseSetPath, chapterPath, sectionPath, exercisePath] = session.path.split("_");
    const [chapterFullPath, sectionFullPath, exerciseFullPath]
      = ScoreAvailabilityApplyService.buildFullPaths([exerciseSetPath, chapterPath, sectionPath, exercisePath]);

    const [chapterDefinition, sectionDefinition] =
      this.findDefinitions(chapters, chapterFullPath, sectionPath, exercisePath);
    const exerciseQuestionsDefinition = questions.filter( q => q.path.startsWith(session.path));
    const [chapterAvailability, sectionAvailability, exerciseAvailability] =
      ScoreAvailabilityApplyService.findOrCreateAvailabilities(availability, [chapterFullPath, sectionFullPath, exerciseFullPath]);

    const sessionQuestionsToCount = this.filterAnsweredSessionQuestionsByDefinition(session.questions, exerciseQuestionsDefinition);

    exerciseAvailability.score = Math.max(sessionQuestionsToCount
      .reduce((acc, item) => acc + item.score, 0)
      / exerciseQuestionsDefinition.length, exerciseAvailability.score);
    exerciseAvailability.progress = sessionQuestionsToCount.length / exerciseQuestionsDefinition.length;
    exerciseAvailability.lastUpdate = new Date().getTime();

    const exercisePathsFoundInDefinition = sectionDefinition.exercises.map(e => `${sectionFullPath}_${e.path}`);
    const countedExerciseAvailabilities = this.updateAvailabilityByChildResults(availability, exercisePathsFoundInDefinition, sectionAvailability);

    const sectionPathsFundInDefinition = chapterDefinition.sections.map(s => `${chapterFullPath}_${s.path}`);
    const countedSectionAvailabilities = this.updateAvailabilityByChildResults(availability, sectionPathsFundInDefinition, chapterAvailability);

    countedSectionAvailabilities.forEach( av => {
      av.available = true;
      av.assigned = true;
    })

    this.setupExerciseAvailabilitiesByScore(sectionDefinition, sectionFullPath, countedExerciseAvailabilities, availability);
    (chapterAvailability as ChapterAvailability).lastSubmit = new Date().getTime();

    // update topAvailabilities
    const chapterTopAvailabilityArray = topAvailabilities.filter(av => av.path == chapterFullPath)
    if (!chapterTopAvailabilityArray || chapterTopAvailabilityArray.length == 0) {
      topAvailabilities.push(chapterAvailability)
    } else {
      const chapterTopAvailability = chapterTopAvailabilityArray[0] as ChapterAvailability
      chapterTopAvailability.lastSubmit = new Date().getTime()
      chapterTopAvailability.score = chapterAvailability.score
      chapterTopAvailability.progress = chapterAvailability.progress
      chapterTopAvailability.available = chapterAvailability.available
      chapterTopAvailability.assigned = chapterAvailability.assigned
      chapterTopAvailability.lastUpdate = chapterAvailability.lastUpdate
    }
    return availability;
  }

  private setupExerciseAvailabilitiesByScore(sectionDefinition: Section, sectionFullPath: string, countedExerciseAvailabilities: ItemAvailability[], availability: ItemAvailability[]) {
    let enableNext = true;
    for (const exerciseToVerify of sectionDefinition.exercises) {
      const exerciseToVerifyFullPath = `${sectionFullPath}_${exerciseToVerify.path}`;
      let exerciseAvailability = countedExerciseAvailabilities.find(av => av.path === exerciseToVerifyFullPath);
      if (enableNext) {
        if (!exerciseAvailability) {
          exerciseAvailability = ScoreAvailabilityApplyService.createNewAvailabilityItemItem(exerciseToVerifyFullPath);
          availability.push(exerciseAvailability);
        }
        exerciseAvailability.assigned = true;
        exerciseAvailability.available = true;
      } else {
        if (exerciseAvailability) {
          exerciseAvailability.available = false;
          exerciseAvailability.assigned = true;
        }
      }
      enableNext = ScoreAvailabilityApplyService.isExercisePassed(exerciseAvailability);
    }
  }

  private updateAvailabilityByChildResults(availabilities: ItemAvailability[], childrenPaths: string[], availabilityToUpdate: ItemAvailability) {
    let existingAvailabilitiesToCount = this.findAvailabilities(availabilities, childrenPaths);
    existingAvailabilitiesToCount = this.sortAvailabilities(existingAvailabilitiesToCount, childrenPaths);

    availabilityToUpdate.score = Math.max(existingAvailabilitiesToCount
        .reduce((acc, item) => acc + item.score, 0)
      / childrenPaths.length, availabilityToUpdate.score);
    availabilityToUpdate.progress = existingAvailabilitiesToCount
      .reduce((acc, item) => acc + item.progress, 0) / childrenPaths.length;
    availabilityToUpdate.lastUpdate = new Date().getTime();
    return existingAvailabilitiesToCount;
  }

  private static findOrCreateAvailabilities(availability: ItemAvailability[], paths: string[]): ItemAvailability[] {
    const result: ItemAvailability[] = new Array(paths.length);
    for (const itemAvailability of availability) {
      let verify = false;
      for (let i = 0 ; i < paths.length; i++) {
        if (itemAvailability.path === paths[i]) {
          result[i] = itemAvailability;
          verify = true;
        }
      }

      if (verify) {
        let hasAllElements = true;
        for (let i = 0 ; i < result.length; i++) {
            if (!result[i]) {
              hasAllElements = false;
              break;
            }
        }
        if (hasAllElements) return result;
      }
    }
    for (let i = 0 ; i < result.length ; i++) {
      if (result[i]) continue;
      result[i] = ScoreAvailabilityApplyService.createNewAvailabilityItemItem(paths[i]);
      availability.push(result[i]);
    }
    return result;
  }

  private static createNewAvailabilityItemItem(path: string) {
    const result = new ItemAvailability();
    result.path = path;
    result.assigned = true;
    result.available = true;
    result.score = 0;
    return result;
  }

  private findDefinitions(chapters: Chapter[], chapterPath: string, sectionPath: string, exercisePath: string): [Chapter, Section, Exercise] {
    const chapter = chapters.find(c => c.path === chapterPath);
    const section = chapter.sections.find(s => s.path === sectionPath);
    const exercise = section.exercises.find( e => e.path === exercisePath);
    return [chapter, section, exercise];
  }

  private filterAnsweredSessionQuestionsByDefinition(
    sessionQuestions: ExerciseSessionQuestion<any, any>[],
    exerciseQuestionsDefinition: Question<any, any>[]): ExerciseSessionQuestion<any, any>[] {
    const acceptedQuestionIds = new Set(exerciseQuestionsDefinition
      .map( q => q.id)
    );
    return sessionQuestions
      .filter( sq => sq.answered)
      .filter( sq => acceptedQuestionIds.has(sq.question.id))
  }

  private findAvailabilities(availability: ItemAvailability[], paths: string[]): ItemAvailability[] {
    const pathsSet = new Set(paths);

    return availability.filter( av => pathsSet.has(av.path));
  }

  private sortAvailabilities(availabilities: ItemAvailability[], pathsSorted: string[]) {
    return availabilities.sort(
      (l, r) =>
        pathsSorted.indexOf(l.path) - pathsSorted.indexOf(r.path));
  }

  private static isExercisePassed(exerciseAvailability: ItemAvailability) {
    return exerciseAvailability && exerciseAvailability.score > 0.5;
  }
}
