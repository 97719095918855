import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContactUs } from "src/app/books/model/book-model";
import { environment } from "src/environments/environment";
import {NativeServiceApiProvider} from "../../services/native-api-provider.service";
import {switchMap} from "rxjs";
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    constructor(
                private provider: AuthorizationServiceProvider) {}

    sendContactUs(msg: ContactUs) {
      return this.provider.getCommonRoutines().pipe(
        switchMap( api => api.contactUs(msg))
      )
    }
}
