import { Injectable } from '@angular/core';
import {VideoApi} from "../../../video-rest.service";
import {Page, Pageable} from "../../../../../utils/pageable";
import {IdentifiedRoomTemplate, RoomDef, RoomProgressReport, ScheduleRow} from "../../../../models/video.model";
import {iif, map, Observable, of, switchMap, throwError} from "rxjs";
import {CachedHttpRequest, Constraints, HttpMethod, HttpResponse, Platform} from "../../../../../constraints";
import {IosBridgeBase} from "../../../../../mobile/api-services/bridge/ios-bridge-base";
import {LogsService} from "../../../../../utils/services/logs.service";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class VideoIosService implements VideoApi {

  constructor(private bridge: IosBridgeBase,
              private logger: LogsService) {}

  private studentUrl(schoolId: number, studentId: number, path: string): string {
    return `/sec/school/${schoolId}/student/${studentId}${path}`;
  }

  private teacherUrl(schoolId: number, teacherId: number, path: string) {
    return `/sec/school/${schoolId}/teacher/${teacherId}${path}`;
  }

  private cachePageNumber(pageable: Pageable): number {
    if (!pageable || !pageable.page) return 0
    return pageable.page
  }

  private appendParams(url: string, key: string, value: string, isFirst: boolean): string {
    if (isFirst) {
      url = url + "?"
    } else {
      url = url + "&"
    }
    if (!value) value = "";
    url = `${url}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    return url;
  }

  private params(url: string, params: HttpParams) {
    if (!params || params.keys().length == 0) return url;
    let isFirst = true;
    const keys = params.keys();
    for (const key of keys) {
      const values = params.get(key);
      if (!values) continue;
      url = this.appendParams(url, key, values, isFirst);
      isFirst = false;
    }

    return url;
  }

  getProgressHistory(schoolId: number, templateId: number, pageable: Pageable): Observable<Page<RoomProgressReport>> {
    this.logger.log(`getting progress history for templateId=${templateId}`)
    const params: HttpParams = pageable ? pageable.getHttpParams() : null;
    const page = this.cachePageNumber(pageable)

    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(`/sec/school/${schoolId}/templates/${templateId}/progress-history`, params),
      null,
      null,
      `template-${templateId}-video-progress-history-${page}`,
      Constraints.syncMinute
    )
    return this.bridge.callForResponse<Page<RoomProgressReport>>('exchange',{request});
  }

  getStudentRoomDetails(schoolId: number, studentId: number, roomUuid: string): Observable<RoomDef> {
    this.logger.log(`getting student room details for roomUuid=${roomUuid}`)
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.studentUrl(schoolId, studentId, `/rooms/${roomUuid}`),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<RoomDef>('exchange',{request});
  }

  getStudentScheduleDetails(schoolId: number, studentId: number, scheduleId: number): Observable<ScheduleRow> {
    this.logger.log(`getting student schedule details for scheduleId=${scheduleId}`)
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.studentUrl(schoolId, studentId, `/schedules/${scheduleId}`),
      null,
      null,
      `student-schedule-${scheduleId}-details`,
      Constraints.syncDay
    )
    return this.bridge.callForResponse<ScheduleRow>('exchange',{request});
  }

  getTeacherRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef> {
    this.logger.log(`getting teacher room details for roomUuid=${roomUuid}`)
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.teacherUrl(schoolId, teacherId, `/rooms/${roomUuid}`),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<RoomDef>('exchange',{request});
  }

  getTeacherScheduleDetails(schoolId: number, teacherId: number, scheduleId: number): Observable<ScheduleRow> {
    this.logger.log(`getting teacher schedule details for scheduleId=${scheduleId}`)
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.teacherUrl(schoolId, teacherId, `/schedules/${scheduleId}`),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<ScheduleRow>('exchange',{request});
  }

  getTeacherTemplateDetails(schoolId: number, teacherId: number, templateId: number): Observable<IdentifiedRoomTemplate> {
     this.logger.log(`getting teacher template details for templateId=${templateId}`)
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      `/sec/school/${schoolId}/templates/${templateId}`,
      null,
      null,
      `teacher-template-${templateId}-details`,
      Constraints.syncMinute
    )
    return this.bridge.callForResponse<IdentifiedRoomTemplate>('exchange',{request});
  }

  listStudentGroups(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    this.logger.log(`getting list of student groups for studentId=${studentId}`)
    const page = this.cachePageNumber(pageable)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.studentUrl(schoolId, studentId, "/templates"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<Page<IdentifiedRoomTemplate>>('exchange',{request});
  }

  listStudentRooms(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of student rooms for studentId=${studentId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.studentUrl(schoolId, studentId, "/rooms"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<Page<RoomDef>>('exchange',{request});
  }

  listStudentSchedules(schoolId: number, studentId: number, focusDate: number): Observable<ScheduleRow[]> {
    this.logger.log(`getting list of student schedules for studentId=${studentId}`)
    const params = new HttpParams().append("focusDate", focusDate.toString());
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.studentUrl(schoolId, studentId, "/schedules"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<ScheduleRow[]>('exchange',{request});
  }

  listTeacherGroups(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    this.logger.log(`getting list of teacher groups for teacherId=${teacherId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.teacherUrl(schoolId, teacherId, "/templates"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<Page<IdentifiedRoomTemplate>>('exchange',{request});
  }

  listTeacherRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of teacher rooms for teacherId=${teacherId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.teacherUrl(schoolId, teacherId, "/rooms"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<Page<RoomDef>>('exchange',{request});
  }

  listTeacherSchedules(schoolId: number, teacherId: number, focusDate: number): Observable<ScheduleRow[]> {
    this.logger.log(`getting list of teacher schedules for teacherId=${teacherId}`)
    const params = new HttpParams().append("focusDate", focusDate.toString());
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(this.teacherUrl(schoolId, teacherId, "/schedules"), params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<ScheduleRow[]>('exchange',{request});
  }

  listTemplateRooms(schoolId: number, teacherId: number, templateId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of template rooms for templateId=${templateId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    const request = new CachedHttpRequest(
      Platform.Video, HttpMethod.GET,
      this.params(`/sec/school/${schoolId}/templates/${templateId}/rooms`, params),
      null,
      null,
      null,
      null
    )
    return this.bridge.callForResponse<Page<RoomDef>>('exchange',{request});
  }
}
