<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.RESERVE_LESSON'"
           [redirectLink]="'../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a class="pe-none">
                  {{ "NAVIGATION.RESERVE_LESSON" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ CourseFilterComponent.getLanguageTranslation(chosenCourse) | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <app-course-filter
      [chosenCourseUpdate]="chosenCourse"
      (languageEmitter)="chosenCourse = $event"></app-course-filter>
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-activate-course-modal
      [studentId]="studentId"
      [langCode]="chosenCourse?.filter"
      [contextUpdate]="productContext"
    ></app-activate-course-modal>
    <app-reserve-lesson
      #reserveLessonComponent
      [studentId]="studentId"
      [langCode]="chosenCourse?.filter"
      (scheduleDateEmitter)="scheduleDate = $event"
      (activationEmitter)="productContext = $event"
    ></app-reserve-lesson>
  </div>
</div>

<app-modal #selectCourseModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h6 class="modal-title">{{ "COL.COURSES.CALENDAR.MODAL.SELECT_COURSE" | translate }}</h6>
    </div>
    <div class="modal-body">
      <div class="list-group list-group-horizontal-sm gap-3">
        <a *ngFor="let lang of CourseFilterComponent.getFiltersShort().slice(1)"
          (click)="activeCourse = lang"
           [ngClass]="{ 'active': activeCourse?.code == lang.code }"
           class="list-group-item rounded-1 px-4">
          {{ CourseFilterComponent.getLanguageTranslation(lang) | translate }}
        </a>
      </div>
    </div>
    <div class="modal-footer text-right border-0 rounded-bottom">
      <button type="button" class="btn btn-primary rounded-pill" (click)="applyCourse()"
        [disabled]="activeCourse == null">
        {{ "BUTTON.SHOW" | translate }}
      </button>
    </div>
  </div>
</app-modal>
