import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription, switchMap} from 'rxjs';
import { Page, Pageable } from 'src/app/utils/pageable';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ApiLessonBundle } from '../../../model/rest-model';
import { AppEventsService } from '../../../services/app-events.service';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import {environment} from "../../../../../environments/environment";
import {WebSocketService} from "../../../services/web-socket.service";

@Component({
  selector: 'app-student-credits',
  templateUrl: './student-credits.component.html',
  styleUrls: ['./student-credits.component.scss'],
})
export class StudentCreditsComponent implements OnInit, OnDestroy {
  bundles: Page<ApiLessonBundle>;
  pageable: Pageable = Pageable.of(0, 5, null);
  creditsTotal: number;
  private _studentId: number;
  _langCode: string;
  isLoading = true
  wsSubscription: Subscription
  scheduleSubscription: Subscription;

  @Input() set studentId(id: number) {
    this._studentId = id;
    this.listenToWebSocketCreditsUpdate()
  }
  @Input() set langCode(langCode: string) {
    this._langCode = langCode;
    this.initQuery();
  }

  constructor(
    private appEvents: AppEventsService,
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService,
    private webSocket: WebSocketService
  ) {}

  ngOnInit(): void {
    this.initQuery();
    this.scheduleSubscription = this.appEvents.creditsUpdate.subscribe((_) => {
      this.initQuery();
    });
  }

  ngOnDestroy(): void {
    if (this.scheduleSubscription) {
      this.scheduleSubscription.unsubscribe();
    }
    if(this.wsSubscription) this.wsSubscription.unsubscribe()
  }

  private initQuery() {
    this.spinner
      .trace<Page<ApiLessonBundle>>(this.getAvailableBundles())
      .subscribe((result) => {
        this.bundles = result;
        this.creditsTotal = 0;
        this.bundles?.content.forEach(
          (bundle) => (this.creditsTotal += bundle.available)
        );
        this.appEvents.credits(this._langCode).next(this.creditsTotal);
        this.isLoading = false
      });
  }

  private getAvailableBundles(): Observable<Page<ApiLessonBundle>> {
    return this.studentRest.getStudentLessonBundles(
            this._studentId,
            this._langCode,
            this.pageable
      )
  }

  getBundles() {
    return this.bundles?.content;
  }

  onPageChange($event) {
    this.pageable = $event;
    this.initQuery();
  }

  listenToWebSocketCreditsUpdate(): any {
    if (!this._studentId) {return; }
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint)
      .pipe(
        switchMap(connection => connection.subscribe(
            `/col/student/${this.studentId}/creditsUpdate`
          )
        )
      ).subscribe(() =>
        this.initQuery()
      )

  }
}
