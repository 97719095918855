import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import {ScheduleRow, RoomDef, RoomProgressReport, IdentifiedRoomTemplate} from '../../models/video.model';
import {SpinnerService} from "../../../utils/services/spinner.service";
import {Page, Pageable} from "../../../utils/pageable";

@Component({
  selector: 'app-teacher-schedule-details-page',
  templateUrl: './teacher-schedule-details-page.component.html',
  styleUrls: ['./teacher-schedule-details-page.component.scss']
})
export class TeacherScheduleDetailsPageComponent implements OnInit {

  teacherId: number;
  schoolId: number;
  scheduleId: number;
  schedule: ScheduleRow
  room: RoomDef
  progressPageable = new Pageable(0, 5, ['createDate,desc'])
  progressHistory: Page<RoomProgressReport>
  groupId: number

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private spinnerService: SpinnerService) {
    this.route.paramMap.subscribe(params => {
      this.teacherId = +params.get('teacherId')
      this.schoolId = +params.get('schoolId')
      this.scheduleId = +params.get('scheduleId')
    })
  }

  ngOnInit(): void {
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getTeacherScheduleDetails(
          this.schoolId, this.teacherId, this.scheduleId)
        ),
        tap(schedule => {
          this.schedule = schedule
          this.room = schedule.room
          this.groupId = this.schedule.template.id
        }),
        tap(_ => this.loadTemplateProgressHistory(null))
      )
    ).subscribe()
  }

  loadTemplateProgressHistory(pageable?: Pageable) {
    if(pageable) this.progressPageable = pageable
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getProgressHistory(this.schoolId, this.groupId, this.progressPageable))
      )
    ).subscribe(progressHistory => this.progressHistory = progressHistory)
  }

  getScheduleDatePlanned() {
    if(this.schedule)
      return new Date(this.schedule.schedule.details.startDate)
      .toLocaleString('en-uk',
        { day: '2-digit', month: 'short', year: 'numeric',
          hour: 'numeric', minute: 'numeric' })
    else
      return ''
  }

  getScheduleName() {
    return this.room?.name
  }
}
