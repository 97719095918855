<app-teacher-lesson-details-header
[lessonDetails]="lessonDetails"
[currentProgress]="currentProgress"
[teacherId]="teacherId"
(showCancelLesson)="showCancelLessonModal()"
(updateLessonDetails)="reloadLessonDetails()"
(canUpdateProgress)="canUpdateProgress = $event"
></app-teacher-lesson-details-header>
<app-lesson-commited-progress
[lessonDetails]="lessonDetails"
[currentProgress]="currentProgress"
[teacherId]="teacherId"
[canUpdateProgress]="canUpdateProgress"
(updateLessonDetails)="reloadLessonDetails()"
(showCancelLesson)="showCancelLessonModal()"
(showFlags)="showFlagsModal()"
></app-lesson-commited-progress>
<app-lesson-history
[teacherId]="teacherId"
[lessonId]="lessonDetails?.id"
[studentId]="lessonDetails?.students[0].id"
></app-lesson-history>

<app-modal #flagModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">{{ 'COL.TEACHER.MODAL.POST_FLAG.TITLE' | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="flagModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form #f="ngForm" (ngSubmit)="postFlag()">
      <div class="modal-body pb-0" *ngIf="_fFlags">
        <p>{{ 'COL.TEACHER.MODAL.POST_FLAG.FLAG_TYPE' | translate }}</p>
        <div class="input-group" *ngFor="let flagType of _fAllFlags">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="{{ flagType }}"
              [(ngModel)]="_fFlags[flagType]"
              [disabled]="hasFlag(flagType)"
            />
            <label class="form-check-label">
              {{ getFlagDescription(flagType) }}
            </label>
          </div>
        </div>
        <div class="row" *ngIf="askForComment()">
          <div class="col-12">
            <div class="form-floating">
              <textarea
                #description="ngModel"
                name="description"
                placeholder="description"
                class="form-control textarea-height"
                required
                [(ngModel)]="_fDescription"
                [ngClass]="{ 'is-invalid': f.submitted && description.invalid }"
              ></textarea>
              <label for="description">{{ 'COL.TEACHER.MODAL.POST_FLAG.FLAG_DESCRIPTION' | translate }}</label>
              <div
                *ngIf="f.submitted && description.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="description.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-danger rounded-pill">
          {{ 'BUTTON.SAVE_FLAG' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
<app-modal #cancelLessonModal>
  <div class="modal-content" *ngIf="lessonDetails">
    <div class="modal-header border-0">
      <h5 class="modal-title">{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.TITLE' | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="cancelLessonModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
      <div class="modal-body">
        <p>{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.INFO' | translate }}</p>
        <p
          *ngIf="lessonDetails.lessonStatus == 'Due' || shouldBeStarted"
          class="text-danger fw-bold"
        >
          {{ 'COL.TEACHER.MODAL.CANCEL_LESSON.INFO_DUE' | translate }}
        </p>
        <div class="row">
          <div class="col-12">
            <div class="form-floating">
              <textarea
                #reason="ngModel"
                name="reason"
                placeholder="reason"
                class="form-control textarea-height"
                required
                [(ngModel)]="_clReason"
                [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
              ></textarea>
              <label for="reason">{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.REASON' | translate }}</label>
              <div
                *ngIf="f.submitted && reason.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="reason.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-danger rounded-pill">
          {{ 'BUTTON.CANCEL_LESSON' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
