import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { switchMap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { ProfileService } from 'src/app/auth_profile/services/teacher/profile.service';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { AppVersionService } from 'src/app/services/app-version.service';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'unaffiliated-main-nav',
  templateUrl: './unaffiliated-main-nav.component.html',
  styleUrls: ['./unaffiliated-main-nav.component.scss'],
})
export class UnaffiliatedMainNavComponent implements OnInit {
  @ViewChild('main', { static: true })
  public mainMenuRef: ElementRef;

  @Input() activeSegments: string[];
  @Input() isOffcanvas: boolean = false;
  @Input()
  set theme(value: string) {
    this._theme = value;
  }
  get theme() {
    return this._theme;
  }
  @Input() profilePhoto?: string;
  studentFullName: string;
  links = document.getElementsByClassName('nav-item');
  private _theme: string;

  constructor(
    private authService: AuthorizationServiceProvider,
    private profileService: ProfileService,
    public themeService: ThemeService,
    private provider: NativeServiceApiProvider,
    public appVersionService: AppVersionService
  ) {}

  /**
   * if native supports the casa profile use profile to get student name and school
   * @private
   */
  private loadProfileData() {
    this.profileService.getSelfPerson().subscribe((self) => {
      this.studentFullName = PersonUtils.getPersonName(self.details);
    });
  }

  /**
   * if native doesn't support casa just load in an old way
   * @private
   */
  private loadAppData() {
    this.authService
      .getAuthDetailsService()
      .pipe(switchMap((api) => api.getUserName()))
      .subscribe((userName) => {
        this.studentFullName = userName;
      });
  }

  ngOnInit(): void {
    this.provider.casa().subscribe((api) => {
      if (api.isImplemented()) {
        this.loadProfileData();
      } else {
        this.loadAppData();
      }
    });
  }

  getStudentName() {
    return this.studentFullName;
  }

  getDismiss() {
    return this.isOffcanvas && 'offcanvas';
  }

  isActivePath(segment: string) {
    return this.activeSegments[1] === 'unaffiliated' &&
      this.activeSegments[2].includes(segment)
      ? true
      : false;
  }

  onLogout() {
    this.authService
      .getCommonRoutines()
      .subscribe((service) => service.logout());
  }

  getAvatar() {
    return {
      background: this.profilePhoto ? `url('${this.profilePhoto}')` : null,
    };
  }
}
