import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExamSession, ExamSessionPart} from "../../model/cspa/personal";
import { FontSizeService } from 'src/app/services/font-size.service';

@Component({
  selector: 'app-exam-summary',
  templateUrl: './exam-summary.component.html',
  styleUrls: ['./exam-summary.component.scss']
})
export class ExamSummaryComponent implements OnInit, OnDestroy {
  private _session: ExamSession
  sessionParts: ExamSessionPart[]
  @Input() isSampleExam: boolean
  @Input()
  get session(): ExamSession {
    return this._session
  }
  set session(session: ExamSession) {
    this._session = session
    this.sessionParts = session?.parts
  }

  constructor(
    public fontSizeService: FontSizeService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.fontSizeService.setToDefault()
  }
}
