<div *ngIf="isReady()">
  <div class="mt-4" *ngFor="let section of selectedChapterSections()">
    <p class="lead mb-0">
      <span *ngIf="section.type == 'QA'">Question & Answer</span>
      <span *ngIf="section.type == 'DICT'">Dictation</span>
      <span *ngIf="section.type == 'LANG'">Extra Practice</span>
    </p>
    <p>
      <span
        class="text-muted small d-none d-md-block"
        *ngIf="section.type == 'QA'"
        >{{ "EXERCISES.DETAILS.QUESTION_SET.DESCRIPTION.QA" | translate }}</span
      >
      <span
        class="text-muted small d-none d-md-block"
        *ngIf="section.type == 'DICT'"
        >{{ "EXERCISES.DETAILS.QUESTION_SET.DESCRIPTION.DICT" | translate }}</span
      >
      <span
        class="text-muted small d-none d-md-block"
        *ngIf="section.type == 'LANG'"
        >{{ "EXERCISES.DETAILS.QUESTION_SET.DESCRIPTION.LANG" | translate }}</span
      >
    </p>
    <div class="my-2 d-flex flex-wrap gap-2 justify-content-center">
      <exercise-square
        *ngFor="let exercise of getSectionExercises(section)"
        [availability]="getExerciseAvailability(section, exercise)"
        [exercise]="exercise"
        [section]="section"
        [chapter]="chapter"
        [exerciseSetPath]="langCode"
      ></exercise-square>
    </div>
  </div>
</div>
