<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.GROUPS'"
           [redirectLink]="'../../../'"
           [defaultRedirectTranslationCodes]="true"
           class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "NAVIGATION.GROUPS" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="row mb-4">
      <div class="col-auto mb-3 mb-sm-0">
        <h4 class="mb-0">{{ "NAVIGATION.GROUPS" | translate }} <span class="text-primary">( {{getGroupsNumber()}} )</span>
        </h4>
      </div>
<!--      <div class="col-auto ms-auto">-->
<!--        <a class="btn btn-primary px-4 py-2 rounded-pill" routerLink="/school/{{schoolId}}/groups/new">Add new group</a>-->
<!--      </div>-->
    </div>
    <div  class="card">
      <div class="card-header">
        <form class="row flex-column flex-md-row" *ngIf="currentState" (ngSubmit)="restartSearch()">
          <div class="col-12 col-md-6 col-lg-5" *ngIf="currentState">
            <select name="groups-sorting" [(ngModel)]="currentState.sortingRule" (ngModelChange)="restartSearch()" class="form-select p-3"
                    [ngClass]="{ 'select-not-empty': currentState.sortingRule }">
              <option [ngValue]="sorting" *ngFor="let sorting of sortingRules">{{ sorting.name | translate }}</option>
            </select>
          </div>
          <div class="col-auto d-flex ms-auto">
            <paginator
              class="my-auto"
              [pageable]="currentState?.page"
              [data]="groupsPage"
              (newPage)="loadNewPage($event)"
            ></paginator>
          </div>
        </form>
      </div>
      <div class="card-body">
        <div class="table-responsive"  *ngIf="groupsPage?.content?.length; else noResults">
          <table class="table white-text small">
            <thead>
              <tr class="table-row table-row-head">
                <th>{{ "GROUPS.GROUP" | translate }}</th>
                <th></th>
                <th style="width: 36px;"></th>
              </tr>
            </thead>
            <tbody class="table-body cursor-pointer">
              <tr *ngFor="let template of groupsPage?.content" class="table-row" routerLink="./group/{{template.id}}">
                <td>{{getName(template)}}</td>
                <td>{{getTeacherName(template)}}<br>
                  <small class="text-muted xsmall">{{getNbOfStudents(template)}} {{ "GROUPS.STUDENTS" | translate }}</small>
                </td>
                <td>
                  <button class="btn btn-outline-secondary rounded-circle" type="button" routerLink="./group/{{template.id}}">
                    <app-icon name="angle-right" class="icon-blue icon-lg"></app-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noResults>
          <div class="row">
            <p class="text-center fs-5 text-muted m-0">
              {{ "LESSONS.NO_RESULTS" | translate }}
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
