import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class ShortcutService {

    btnRequest = new Subject<string>();
    hintRequest = new Subject()

    updateRequest(btnRequest: string): void {
      this.btnRequest.next(btnRequest);
    }

    getHint(): void {
      this.hintRequest.next(1)
    }

    constructor() { }
  }
