<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.LESSONS'"
           [redirectLink]="'../../upcoming'"
           [defaultRedirectTranslationCodes]="true"
           class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../upcoming">
                  {{ "NAVIGATION.LESSONS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../upcoming">
                  {{ "LESSONS.WEEKLY_PLANNED_LESSONS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getScheduleName() }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <teacher-room-details [room]="room"></teacher-room-details>
  </div>
</div>
