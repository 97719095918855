<div class="card mb-5" *ngIf="!isLoading">
  <div class="card-body">
    <p *ngIf="!selectedChapter" class="text-center text-muted my-3">
      {{ "COL.COURSES.EXERCISES.NO_CHAPTER_AVAILABLE" | translate }}
    </p>
    <ng-container *ngIf="selectedChapter">
      <div class="row align-items-end g-0 mb-3">
        <div class="stage-list-container col">
          <div class="stage-list">
            <app-chapter-progress-circle
              *ngFor="let avChapter of getAvailabileChapters()"
              [chapter]="avChapter"
              [availability]="getChapterAvailability(avChapter)"
              (chapterClick)="onChapterClick(avChapter)"
              [ngClass]="getChapterSelectionClass(avChapter)"
            >
            </app-chapter-progress-circle>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="row g-0 align-items-center">
          <div class="col-2 text-end">
            <a
              [hidden]="!hasPrev()"
              class="cursor-pointer"
              (click)="onFooterInteract('left')"
            >
              <app-icon name="angle-left" class="icon-gray xl-icon"></app-icon>
            </a>
          </div>
          <div class="col text-center">
            <h4 class="mb-0">{{ getChapterName() }}</h4>
          </div>
          <div class="col-2 text-start">
            <a
              [hidden]="!hasNext()"
              class="cursor-pointer"
              (click)="onFooterInteract('right')"
            >
              <app-icon name="angle-right" class="icon-gray xl-icon"></app-icon>
            </a>
          </div>
        </div>

        <app-chapter-details
          [chapter]="getSelectedChapter()"
          [availabilities]="getAvailabilities()"
          [langCode]="langCode"
        ></app-chapter-details>
      </div>
    </ng-container>
  </div>
</div>
