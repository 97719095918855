export class TimeUnits {
  private static SECOND = 1000;
  private static MINUTE = 60 * TimeUnits.SECOND;
  private static HOUR = 60 * TimeUnits.MINUTE;
  private static DAY = 24 * TimeUnits.HOUR;

  private unit: number;
  private no: number;

  public static Seconds(no: number) {
    return new TimeUnits(TimeUnits.SECOND, no);
  }
  public static Minutes(no: number) {
    return new TimeUnits(TimeUnits.MINUTE, no);
  }
  public static Hours(no: number) {
    return new TimeUnits(TimeUnits.HOUR, no);
  }
  public static Days(no: number) {
    return new TimeUnits(TimeUnits.DAY, no);
  }

  public toMilis() {
    return this.no * this.unit;
  }
  private constructor(timeUnit: number, no: number) {
    this.unit = timeUnit;
    this.no = no;
  }
}

export class Dates {
  public static diff(from: Date, to: Date) {
    const toMs = to.getTime();
    const fromMs = from.getTime();
    const res = toMs - fromMs;
    return res;
  }

  public static parse(date: string): Date {
    if (date == null) {
      return null;
    }
    if (date === undefined) {
      return undefined;
    }
    if (date === 'null') {
      return null;
    }
    const regExp =
      /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})(\+|-)?(\d{2}):?(\d{2})/gm;
    const parsed = regExp.exec(date);

    const year = Number(parsed[1]);
    const month = Number(parsed[2]) - 1;
    const day = Number(parsed[3]);
    const hour = Number(parsed[4]);
    const minute = Number(parsed[5]);
    const second = Number(parsed[6]);
    const mili = Number(parsed[7]);
    const timezoneSign = parsed[8];
    const timezoneHour = Number(parsed[9]);
    const timezoneMinute = Number(parsed[10]);

    let offset =
      TimeUnits.Hours(timezoneHour).toMilis() +
      TimeUnits.Minutes(timezoneMinute).toMilis();
    if (timezoneSign === '-') {
      offset = -offset;
    }

    offset = -offset;

    const utcDate =
      Date.UTC(year, month, day, hour, minute, second, mili) + offset;
    const res = new Date(utcDate);
    return res;
  }

  public static diffAbs(from: Date, to: Date) {
    return Math.abs(Dates.diff(from, to));
  }

  public static toTimeStr(
    time: number,
    round = true,
    showDaysNumber = false
  ): string {
    if (!time) {
      return '';
    }

    const timePartsArray = [];

    const days = Math.floor(time / 1000 / 60 / 60 / 24);
    let result = '';
    if (round) {
      if (showDaysNumber) {
        if (days > 2) {
          return days + ' days';
        } else if (days === 1) {
          return '1 day';
        } else {
        }
      } else {
        if (days > 30) {
          return 'more than one month';
        } else if (days > 14) {
          return 'more than two weeks';
        } else if (days > 1) {
          return '' + days + ' days ';
        } else if (days === 1) {
          result = 'one day and ';
        } else {
        }
      }
    } else {
      if (days > 0) {
        result = days.toString().padStart(2, '0') + 'd ';
      }
    }

    time = time - days * 1000 * 60 * 60 * 24;
    const hours = Math.floor(time / 1000 / 60 / 60);
    time = time - hours * 1000 * 60 * 60;
    const minutes = Math.floor(time / 1000 / 60);
    time = time - minutes * 1000 * 60;
    const seconds = Math.floor(time / 1000);

    timePartsArray.push(hours.toString().padStart(2, '0'));
    timePartsArray.push(minutes.toString().padStart(2, '0'));
    timePartsArray.push(seconds.toString().padStart(2, '0'));

    return result + timePartsArray.join(':');
  }

  public static roundDateToHalfHour(date: Date): Date {
    // express hour as decimal number. It will allow to use rounding to find nearest hour
    let decimalHour = date.getHours() + date.getMinutes() / 60;
    // multiply twice to increase rounding resolution - now rouding will work for each half an hour
    decimalHour *= 2;
    // round to the nearest half an hour
    decimalHour = Math.round(decimalHour);
    // divide by 2 to back to the hour scale
    decimalHour /= 2;

    const minutes = (decimalHour % 1) * 60;
    const hour = Math.floor(decimalHour);

    const roundedDate = new Date(date);
    roundedDate.setHours(hour);
    roundedDate.setMinutes(minutes);
    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);
    return roundedDate;
  }

  public static roundDateToNextHalfHour(date: Date): Date {
    const movedDate = new Date(date.getTime() + 1000 * 60 * 15);
    return this.roundDateToHalfHour(movedDate);
  }
  public static roundDateIntoMiddleOfClick(date: Date): Date {
    const movedDate = new Date(date.getTime() - 1000 * 60 * 15);
    return this.roundDateToHalfHour(movedDate);
  }

  public static monthStartDate(monthRelative: number): Date {
    const now = new Date();
    const targetMonthStartDate = new Date(
      now.getFullYear(),
      now.getMonth() + monthRelative,
      1,
      0,
      0,
      0,
      0
    );
    return targetMonthStartDate;
  }

  public static monthFinishDate(monthRelative: number): Date {
    const now = new Date();
    const targetMonthEndDate = new Date(
      now.getFullYear(),
      now.getMonth() + monthRelative + 1,
      1,
      0,
      0,
      0,
      0
    );
    return new Date(targetMonthEndDate.getTime() - 1);
  }

  public static dateToWeekNumber(date: Date): number {
    return Math.floor((date.getTime() + 345600000) / (1000 * 60 * 60 * 24 * 7));
  }
}
