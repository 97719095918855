<div class="mb-4 overflow-hidden" [class]="getCourseClass()"
  routerLink="/student/plans" [queryParams]="{language: langCode}">
  <div class="course" [ngClass]="{ 'active-badge': isSupported }">
    <p *ngIf="isSupported" class="fw-bold">
      {{ "COL.COURSES.ACTIVE" | translate }}
    </p>
    <span class="text-white h4">{{ getCourseName() | translate }}</span>
    <a class="btn btn-primary rounded-pill float-end"
      routerLink="/student/plans"
      [queryParams]="{language: langCode}"
    >
      {{ "BUTTON.BUY_PLAN" | translate }}
    </a>
  </div>
</div>
