import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { AuthorizationServiceProvider } from "./authorization-service.provider";

@Injectable({
  providedIn: 'root'
})
/*
Authorization guard
*/
export class AuthGuardService implements CanActivate {

  constructor(private authServiceProv: AuthorizationServiceProvider, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authServiceProv.getCommonRoutines().pipe(
          map(auth => {
            if (auth.isNativeAuthorization() ) return true;
            const tokenValid = auth.isTokenValid();
            if (!tokenValid) {
              this.authServiceProv.getOnWebAuthorization().subscribe(
                webAuth => webAuth.startLogin(state.url)
              )
            }
            return tokenValid;
          })
        );
    }
}
