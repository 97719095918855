import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PropertiesNativeServiceApi } from "../properties-native-api";

@Injectable({
    providedIn: 'root'
})
export class DesktopPropertiesNativeApi implements PropertiesNativeServiceApi {

  constructor() {}

  putValue(key: string, value: any): Observable<any> {
    throw new Error("Method not implemented.");
  }
  getValue(key: string): Observable<any> {
    throw new Error("Method not implemented.");
  }
}
