import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonsPlanerComponent } from './pages/lessons-planer/lessons-planer.component';
import { UtilsModule } from '../utils/utils.module';
import { ProfileModule } from '../auth_profile/profile.module';
import { AppRoutingModule } from '../app-routing.module';
import { SchedulesListComponent } from './components/schedules-list/schedules-list.component';
import { RoomDetailsComponent } from './components/room-details/room-details.component';
import { TemplatesListComponent } from './components/templates-list/templates-list.component';
import { RoomsListComponent } from './components/rooms-list/rooms-list.component';
import { ScheduleDetailsPageComponent } from './pages/schedule-details-page/schedule-details-page.component';
import { RoomDetailsPageComponent } from './pages/room-details-page/room-details-page.component';
import { TeacherLessonsPlanerComponent } from './pages/teacher-lessons-planer/teacher-lessons-planer.component';
import { TeacherRoomDetailsPageComponent } from './pages/teacher-room-details-page/teacher-room-details-page.component';
import { TeacherScheduleDetailsPageComponent } from './pages/teacher-schedule-details-page/teacher-schedule-details-page.component';
import { UnderDevelopmentComponent } from './pages/under-development/under-development.component';
import { LessonsListComponent } from './components/lessons-list/lessons-list.component';
import { LessonsCalendarComponent } from './components/lessons-calendar/lessons-calendar.component';
import { TeacherGroupsPageComponent } from './pages/teacher-groups-page/teacher-groups-page.component';
import {FormsModule} from "@angular/forms";
import { TeacherGroupDetailsPageComponent } from './pages/teacher-group-details-page/teacher-group-details-page.component';
import { GroupProgressHistoryComponent } from './components/group-progress-history/group-progress-history.component';
import { TeacherGroupRoomDetailsPageComponent } from './pages/teacher-group-room-details-page/teacher-group-room-details-page.component';
import { TeacherGroupScheduleDetailsPageComponent } from './pages/teacher-group-schedule-details-page/teacher-group-schedule-details-page.component';
import { TeacherRoomDetailsComponent } from './components/teacher-room-details/teacher-room-details.component';
import { AccessLessonsButtonComponent } from './components/access-lessons-button/access-lessons-button.component';
import {DocumentsBrowserPageComponent} from "./pages/documents-browser-page/documents-browser-page.component";

@NgModule({
  declarations: [
    LessonsPlanerComponent,
    SchedulesListComponent,
    RoomDetailsComponent,
    TemplatesListComponent,
    RoomsListComponent,
    ScheduleDetailsPageComponent,
    RoomDetailsPageComponent,
    TeacherLessonsPlanerComponent,
    TeacherRoomDetailsPageComponent,
    TeacherScheduleDetailsPageComponent,
    UnderDevelopmentComponent,
    LessonsListComponent,
    LessonsCalendarComponent,
    TeacherGroupsPageComponent,
    TeacherGroupDetailsPageComponent,
    GroupProgressHistoryComponent,
    TeacherGroupRoomDetailsPageComponent,
    TeacherGroupScheduleDetailsPageComponent,
    TeacherRoomDetailsComponent,
    AccessLessonsButtonComponent,
    DocumentsBrowserPageComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    ProfileModule,
    AppRoutingModule,
    FormsModule
  ]
})
export class LessonsModule { }
