import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Authorization } from "src/app/auth_profile/model/prototypes";
import { environment } from "src/environments/environment";
import { ContactUs, Device, Ownership, PasswordChangeRequest, ProductShare } from "../../../model/book-model";
import {CacheService} from "../../../../utils/services/cache.service";

@Injectable({
    providedIn: 'root'
})

export class AppRestService {

    constructor(
      private http: HttpClient,
      private cache: CacheService) {}

    private url(method: string) {
        return `${environment.appEndpoint}/api/mobile/v3${method}`
    }

    requestDeviceId(): Observable<Device> {
        return this.http.post<Device>(this.url('/public/device-key-id'), {},
        {params: new HttpParams().append('platform', this.getPlatform())});
    }

    assignDeviceId(): Observable<any> {
      return this.http.post(this.url("/devices"),null)
    }

    getPlatform(): string {
        return environment.platform.name;
    }

    getProducts(): Observable<Ownership[]> {
      return this.cache.cache(`books/products`,
        () => this.http.get<Ownership[]>(this.url('/products'))
        );
    }

    getProductById(productId: number): Observable<Ownership> {
        return this.http.get<Ownership>(this.url(`/products/${productId}`))
    }

    getImageUrl(releaseId: number): Observable<string> {
        return of(this.url(`/public/covers/${releaseId}`));
    }

    login(authorization: Authorization, deviceId: string): Observable<string> {
        return this.http.post<string>(this.url('/public/authorize'),
            authorization, {headers: {'X-Device-Id': deviceId}});
    }

    public reserve(productId: number) {
        return this.http.post<ProductShare>(this.url(`/products/${productId}/reservation`), {});
    }

    changePassword(request: PasswordChangeRequest, lang?: string) {
        let params = new HttpParams();
        if (lang) params = params.append('lang', lang);
        return this.http.post<void>(this.url(`/public/password-change`), request, {params});
    }

    contactUs(request: ContactUs) {
        return this.http.post<void>(this.url(`/public/contact-us`),request);
    }
}
