import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../../../model/cspa/personal";
import { Question, AnswerDefinitionBase } from "../../../../model/cspa/questions";
import { ExerciseSet, Chapter } from "../../../../model/cspa/struct";
import {CacheService} from "../../../../../utils/services/cache.service";
import {CspaApi} from "../../cspa.api";

  export interface CspaFullRestApi extends CspaApi {
    listQuestions(pathPrefixes: string[], updatedAfter?: number): Observable<Question<any, any>>;
    submitSessions(sessions: ExerciseSession[]): Observable<void>;
    customListAvailabilities(path: string, depth: number): Observable<ItemAvailability[]>
  }

  @Injectable({
    providedIn: 'root'
  })
  export class CspaRestServiceNew implements CspaFullRestApi {

    private apiEndpoint = environment.cspaEndpoint;
    constructor(
      private http: HttpClient,
      private cache: CacheService) {
    }

    private buildPath(path: string) {
      return `${this.apiEndpoint}/api${path}`;
    }

    public customListAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
      const params = new HttpParams().append('path', path).append('depth', depth.toString());
      return this.cache.cache(
        `cspa/availabilities/${params.get("path")}?depth=${params.get("depth")}`,
        () => this.http.get<ItemAvailability[]>(this.buildPath('/availability'), {params})
      )
    }

    public listExerciseSets(): Observable<ExerciseSet[]> {
      return this.cache.cache(
        "cspa/exerciseSets",
        () => this.http.get<ExerciseSet[]>(this.buildPath('/sets'))
      )
    }

    public getChapters(path: string): Observable<Chapter[]> {

      return this.cache.cache(`cspa/sets/${path}`,
        () => this.http.get<Chapter[]>(this.buildPath(`/sets/${path}`))
      )
    }

    public listQuestions(pathPrefixes: string[], updatedAfter?: number): Observable<Question<any, any>> {
      let params = new HttpParams();
      if (updatedAfter != null) {
        params = params.append('updatedAfter', updatedAfter.toString());
      }
      for (const pathPrefix of pathPrefixes) {
        params = params.append('pathPreffix', pathPrefix);
      }

      return this.http.get<Question<any, any>>(this.buildPath('/questions'), {params});
    }

    public startSession(exercisePath: string): Observable<ExerciseSession> {
      return this.http.post<ExerciseSession>(this.buildPath('/sessions/create'), exercisePath);
    }

    public getSession(uuid: string): Observable<ExerciseSession> {
      return this.http.get<ExerciseSession>(this.buildPath(`/sessions/${uuid}`));
    }

    public restartSession(uuid: string): Observable<ExerciseSession> {
      return this.http.post<ExerciseSession>(this.buildPath(`/sessions/${uuid}/recreate`), {});
    }

    public postSessionQuestionAnswer<A extends AnswerDefinitionBase>(
      uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExerciseSession> {
      return this.http.post<ExerciseSession>(this.buildPath(`/sessions/${uuid}/questions/${questionNb}`), sessionQuestion);
    }

    public submitSessions(sessions: ExerciseSession[]): Observable<void> {
      return this.http.post<void>(this.buildPath('/sessions'), sessions);
    }

    public finishSession(uuid: string): Observable<ExerciseSession> {
      return this.http.post<ExerciseSession>(this.buildPath(`/sessions/${uuid}/finish`), {});
    }

    listTopAvailabilities(): Observable<ItemAvailability[]> {
      return this.listAvailabilities('',2);
    }

    listChapterAvailabilities(chapterPath: string): Observable<ItemAvailability[]> {
      return this.listAvailabilities(`${chapterPath}_`, 2);
    }

    private listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
      const params = new HttpParams().append('path', path).append('depth', depth.toString());
      return this.cache.cache(
        `cspa/availabilities/${params.get("path")}?depth=${params.get("depth")}`,
        () => this.http.get<ItemAvailability[]>(this.buildPath('/availability'), {params})
      )
    }

    public closeSession(sessionUuid: string): Observable<void>{
      return this.http.post<void>(this.buildPath(`/sessions/${sessionUuid}/close`), {});
    }

    public getExamSession(sessionUuid: String): Observable<ExamSession> {
      return this.http.get<ExamSession>(this.buildPath(`/exam-sessions/${sessionUuid}`));
    }

    public startExamSession(path: string): Observable<ExamSession> {
      return this.http.post<ExamSession>(this.buildPath(`/exam-sessions/create`), path);
    }

    public startExamSessionPart(sessionUuid: string, partNumber: number): Observable<ExamSession> {
      let params = new HttpParams().append("localTime", (new Date()).getTime())
      return this.http.post<ExamSession>(this.buildPath(`/exam-sessions/${sessionUuid}/create/${partNumber}`), {}, {params});
    }

    public finishExamSession(sessionUuid: string): Observable<ExamSession> {
      return this.http.post<ExamSession>(this.buildPath(`/exam-sessions/${sessionUuid}/finish`), {});
    }

    public finishExamSessionPart(sessionUuid: string): Observable<ExamSession> {
      return this.http.post<ExamSession>(this.buildPath(`/exam-sessions/${sessionUuid}/finish-part`), {});
    }

    public postExamSessionQuestionAnswer<A extends AnswerDefinitionBase>(
      sessionUuid: string,
      questionNb: number,
      sessionQuestion: ExerciseSessionQuestion<A, any>
    ): Observable<ExamSession> {
      return this.http.post<ExamSession>(this.buildPath(`/exam-sessions/${sessionUuid}/questions/${questionNb}`), sessionQuestion);
    }
  }
