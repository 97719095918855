import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  catchError,
  Observable,
  shareReplay,
  switchMap,
  throwError
} from "rxjs";
import { environment } from "src/environments/environment";
import { AuthorizationServiceProvider } from "./authorization-service.provider";
import { DeviceIdService } from "./device-id.service";
import { WebAuthService} from "./api/authorization.api";

@Injectable()
export class JwtInterceptorService  implements HttpInterceptor {
  private appEndpoint = environment.appEndpoint;
  private authServerEndpoint = environment.authEndpoint;
  private casaEndpoint = environment.casaEndpoint;
  private lessonEndpoint = environment.lessonEndpoint;

  constructor(private authServiceProv: AuthorizationServiceProvider,
    private deviceIdService: DeviceIdService) { }

  filter(req: HttpRequest<any>): boolean {
    // if (req.url.startsWith(this.apiEndpoint)) { return true; }
    if (req.url.startsWith(this.authServerEndpoint)) { return true; }
    if (req.url.startsWith(environment.cspaEndpoint)) {return true; }
    if (req.url.startsWith(environment.videoEndpoint)) {return true; }
    if (req.url.startsWith(environment.lessonEndpoint)) {return true; }
    if (req.url.startsWith(environment.bookingEndpoint)) {return true; }
    if (req.url.startsWith(environment.appEndpoint)) {return true; }
    return req.url.startsWith(this.casaEndpoint);
  }

  requiresDeviceId(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.appEndpoint) && !req.url.endsWith("/api/mobile/v3/public/device-key-id");
  }

  isUnprotectedRequest(req: HttpRequest<any>): boolean {
    return req.url.startsWith(this.appEndpoint + '/api/mobile/v3/public/')
      || (req.url.startsWith(this.authServerEndpoint) &&
        (req.url.endsWith('/authorize') || req.url.endsWith("/token"))
      );
  }

  applyHeaders(auth: WebAuthService, next: HttpHandler, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (!auth.isTokenValid() || !auth.getAccessToken()) {
      auth.clear();
      return next.handle(req);
    }
    const headers = { Authorization: `Bearer ${auth.getAccessToken()}`};
    if (this.requiresDeviceId(req)) {
      return this.deviceIdService.getDeviceId().pipe(
        switchMap( deviceId => {
          headers['X-Device-Id'] = deviceId;
          const newRequest = req.clone({setHeaders: headers});
          return next.handle(newRequest);
        })
      );
    } else {
      return next.handle(req.clone({setHeaders: headers}))
    }
  }

  lastRefreshed = 0;
  lastRefreshShare: Observable<any>;
  tryRefreshToken(auth: WebAuthService, next: HttpHandler): Observable<any> {
    if (Date.now() - this.lastRefreshed < 2000) return this.lastRefreshShare;
    this.lastRefreshed = Date.now();
    this.lastRefreshShare = auth.refreshToken().pipe(shareReplay(1));
    return this.lastRefreshShare;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    if (!this.filter(req)) return next.handle(req);

    if(this.isUnprotectedRequest(req)) {
      if (this.requiresDeviceId(req)) {
        return this.deviceIdService.getDeviceId().pipe(
          switchMap( deviceId => {
            const newRequest = req.clone({setHeaders: {'X-Device-Id': deviceId}});
            return next.handle(newRequest);
          })
        )
      }
      return next.handle(req);
    }

    return this.authServiceProv.getOnWebAuthorization().pipe(
      switchMap( auth =>
        this.applyHeaders(auth, next, req).pipe(
          catchError(error => {
            // handle 401 responses only  - requires a refresh token
            if (!(error instanceof HttpErrorResponse) || error.status != 401) return throwError(() => error);
            return this.tryRefreshToken(auth, next).pipe(
              switchMap( _ => this.applyHeaders(auth, next, req))
            )
          }),
          catchError( error => {
            if (!(error instanceof HttpErrorResponse) || error.status != 401) return throwError(() => error);
            auth.logout();
            return throwError(() => error);
          })
        )),
    );

    // if(this.requiresDeviceId(req)) {
    //   return this.authServiceProv.get().pipe(
    //     map( auth => auth.isTokenValid() ? auth.getAccessToken() : null),
    //     mergeMap ( token => token ?
    //       this.deviceIdService.getDeviceId().pipe(
    //         switchMap( deviceId => {
    //           req = req.clone({
    //             setHeaders: {
    //               Authorization: `Bearer ${token}`,
    //               'X-Device-Id': deviceId
    //             }
    //           })
    //           return next.handle(req)
    //         })
    //       )
    //       : next.handle(req)
    //     )
    // )
    // }
    // else if(this.filter(req)) {
    //   return this.authServiceProv.get().pipe(
    //     switchMap( auth => {
    //       if (!auth.isTokenValid()) return next.handle(req);
    //       const accessToken = auth.getAccessToken();
    //       req = req.clone({
    //                     setHeaders: {
    //                         Authorization: `Bearer ${accessToken}`
    //                     }
    //                 });
    //         return next.handle(req);
    //     })
    //   );
    // }
    // else
    //   return null
  }
}
