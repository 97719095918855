import {Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {
  ApiLessonEventModel,
  LessonScheduleEventReference, SimpleLessonScheduleRequest,
  SimpleScheduleEvents,
  SimpleTeacherProductAvailability
} from "../../model/rest-model-v2";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";


export interface StudentBookRestService {
  getLessonScheduleEventReference(studentId: number, scheduleId: number): Observable<LessonScheduleEventReference<ApiLessonEventModel>>
  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]>
  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<SimpleScheduleEvents[]>
  reserveSchedule(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents>
}

@Injectable({
  providedIn: 'root'
})
export class StudentBookRestServiceImpl implements StudentBookRestService {
  constructor(private provider: NativeServiceApiProvider) { }

  getLessonScheduleEventReference(studentId: number, scheduleId: number):
  Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getLessonScheduleEventReference(studentId, scheduleId)));
  }

  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string):
  Observable<SimpleTeacherProductAvailability[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.listStudentSchoolTeachersAvailability(studentId, focusDate, productCode)));
  }

  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string):
  Observable<SimpleScheduleEvents[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.listStudentLessonSchedules(studentId, focusDate, productCode)));
  }

  reserveSchedule(studentId: number, request: SimpleLessonScheduleRequest):
  Observable<SimpleScheduleEvents> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.reserveScheduleV2(studentId, request)));
  }
}
