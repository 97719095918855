<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'LESSONS.TITLE'"
           [redirectLink]="'../../../../../'"
           [defaultRedirectTranslationCodes]="true"
           class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LESSONS.TITLE" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-teacher-lessons
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Upcoming"
    ></app-teacher-lessons>
    <app-teacher-lessons
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Incomplete"
    ></app-teacher-lessons>
    <app-teacher-lessons
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Past"
    ></app-teacher-lessons>
  </div>
</div>
