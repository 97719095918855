import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {finalize, Observable, switchMap, take, tap} from 'rxjs';
import { ToastNotification, ToastNotificationsService } from 'src/app/utils/services/toast-notification.service';
import { PasswordChangeRequest, Person, PersonDetails, StudentPersonDetails } from '../../model/casa/casa-model';
import { ProfileService } from '../../services/student/profile.service';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../services/authorization-service.provider";

@Component({
  selector: 'student-profile-page',
  templateUrl: './student-profile-page.component.html',
  styleUrls: ['./student-profile-page.component.scss']
})
export class StudentProfilePageComponent implements OnInit {

  profile: Person<StudentPersonDetails>
  profileUnderEdit: StudentPersonDetails;
  busy = true;
  saved = false
  locale: String

  profileOption?: string | null

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private toasts: ToastNotificationsService,
    private provider: AuthorizationServiceProvider) {
    route.paramMap.subscribe(params => {
      this.profileOption = params.get("profileOption");
    })
   }

  ngOnInit(): void {
    this.loadProfile().subscribe()
  }

  loadProfile(): Observable<Person<StudentPersonDetails>> {
    this.busy = true
    return this.profileService.getSelfPerson().pipe(
      take(1),
      tap(self => {
        this.keepStudentProfileDetails(self)
      }),
      finalize( () => this.busy = false)
    )
  }

  private keepStudentProfileDetails(self: Person<StudentPersonDetails>) {
    this.profile = self
    this.profileUnderEdit = self.details
    this.locale = self.details.locale
  }

  saveProfile(details: PersonDetails | StudentPersonDetails) {
    this.busy = true;
      this.profileService.updateSelf(details as StudentPersonDetails).pipe(
        finalize( () => {
          this.busy = false
          this.saved = true
          setTimeout(() => {
            this.saved = false
          }, 2000);
        })
      ).subscribe( self => {
        if(this.locale != details.locale)
          location.reload()
        this.keepStudentProfileDetails(self)
        }
      )
  }

  changePassword(request: PasswordChangeRequest) {
    this.busy = true;
    this.provider.getCommonRoutines().pipe(
      switchMap( api => api.changePassword(request)),
      finalize( () => {
        this.busy = false
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 2000);
      })
    ).subscribe()
  }
}
