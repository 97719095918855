<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'CONTACT_US.TITLE'"
           [redirectLink]="'../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            {{ "CONTACT_US.TITLE" | translate }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="container text-center">
    <ng-template loadComponent></ng-template>
  </div>
</div>
