import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Page, Pageable } from 'src/app/utils/pageable';
import { IdentifiedRoomTemplate } from '../../models/video.model';
import {Observable, Subscription, tap} from "rxjs";
import {SpinnerService} from "../../../utils/services/spinner.service";

export interface TemplatesListProvider {
  listGroups(pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>>
}

@Component({
  selector: 'templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit, OnDestroy {

  templates: Page<IdentifiedRoomTemplate>
  _provider: TemplatesListProvider
  private loadSubscription: Subscription

  @Input()
  set templatesProvider(provider: TemplatesListProvider) {
    this._provider = provider;
    this.loadTemplates()
  }

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.loadSubscription?.unsubscribe()
  }

  loadTemplates() {
    if (!this._provider) return;
    this.loadSubscription = this.spinnerService.trace(
      this._provider.listGroups(Pageable.default()).pipe(
        tap(templates =>
          this.templates = templates)
      )
    ).subscribe()
  }

  hasTemplates() {
    return !!this.getTemplates()?.length
  }

  getTemplates() {
    return this.templates?.content
  }

  getTemplateName(template: IdentifiedRoomTemplate) {
    return template.details.name
  }

  getTemplateTeacher(template: IdentifiedRoomTemplate) {
    return template.participants.find(participant =>
      participant.details.role === 'Teacher').details.name
  }
}
