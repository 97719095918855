export class Address {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    postal: string;
    city: string;
    county: string;
    country: string;
  }

  export class ContactType {
    static PHONE = "PHONE";
    static EMAIL = "EMAIL";
    static SKYPE = "SKYPE";
    static WEBSITE = "WEBSITE";
    static CUSTOM = "CUSTOM"
  }

  export class ContactEntry {
    name: string;
    value: string;
    contactPerson: string;
    entryType: string;
    isDefault: boolean;
  }

  export enum Gender {
    Male= 'Male',
    Female = 'Female',
    Other = 'Other'
  }

  export class PersonDetails {
    name: string;
    surname: string;
    address: Address;
    contacts: ContactEntry[];
    gender: Gender;
    nationality: string;
    birthDate: number;
    birthPlace: string;
    locale: string;
    photo: FileItem;
    biography: string;
  }

  export class StudentPersonDetails extends PersonDetails{
    studyReason: string;
  }

  export class FileItem {
    id: number;
    createDate: number;
    name: string;
    originalName: string;
    size: number;
    publicAccessUrl: string;
    secretHash: string;
  }

  export class Person<T extends PersonDetails> {
      id: number;
      details: T;
      accountId: number;
      registrationEmail: string;
  }

  export class SchoolDetails {
      name: string;
      address: Address;
      contacts: ContactEntry[];
  }

  export class CorpDetails {
      corpEntityName: string;
      corpNumber: string;
      corpDetails: string;
      corpAddress: string;
      nameOfPartners: string;
      bankReference: string;
      capital: string;
  }

  export class SchoolStudentsEstimate {
      active: number;
      inactive: number;
      total: number;
  }

  export class School {
      id: number;
      oldId: string;
      details: SchoolDetails;
      corpDetails: CorpDetails;
      expDate: number;
      labels: string;
      estimate: SchoolStudentsEstimate;
      created: number;
      status: string;
  }

  export class SchoolStudentStatus {
      static ACTIVE = "ACTIVE";
      static REMOVED = "REMOVED";
      static INACTIVE = "INACTIVE";
  }

  export class Student {
      id:  number;
      status: string;
      expiryDate: number;
      person: Person<StudentPersonDetails>;
      created: number;
      modified: number;
  }

  export class SchoolStudent extends Student {
      schoolId: number;
  }

  export enum Language {
    en ='English',
    es ='Español',
    it ='Italiano',
    pl ='Polski',
    de ='Deutsch',
    ru ='Pусский',
    hr ='Hrvatski',
    ja ='日本語',
    pt ='Português',
    uk ='Українська',
  }

  export class ApiCountry {
    code: string;
    name: string;
    isoCode: number;
}

export class PasswordChangeRequest {
  email: string;
  password: string;
}
