import {Injectable, Injector} from "@angular/core";
import {EMPTY, map, Observable, of} from "rxjs";
import { AuthorizationServiceImpl } from "./api/impl/authorization.service";
import {environment} from "../../../environments/environment";
import {PlatformDescriptor} from "../../utils/platform-descriptor";
import {
  AuthorizedUserDetailsService, CommonAuthorizationRoutines, EmulatedAuthService, MobileAuthorizationApiInterface,
  WebAuthService
} from "./api/authorization.api";
import {defaultIfEmpty} from "rxjs/operators";
import {AndroidAuthService} from "./api/impl/android-auth.service";
import {IosAuthService} from "./api/impl/ios-auth.service";

@Injectable({
    providedIn: 'root'
  })
export class AuthorizationServiceProvider {

    private webImplementation: WebAuthService;
    private userDetailsService: AuthorizedUserDetailsService;
    private commonRoutines: CommonAuthorizationRoutines;
    private emulated: EmulatedAuthService;
    private mobileService: MobileAuthorizationApiInterface

    constructor(private injector: Injector
    ) {}

  /**
   * Request for api used in emulated mode
   */
  getEmulatedAuthService(): Observable<EmulatedAuthService> {
      if (this.userDetailsService) return of(this.emulated);
      return this.buildService().pipe(
        defaultIfEmpty(""),
        map(_ => this.emulated)
      );
    }

  getMobile(): Observable<MobileAuthorizationApiInterface> {
    if (this.mobileService) return of(this.mobileService)
    return this.buildService().pipe(
      defaultIfEmpty(""),
      map(_ => this.mobileService)
    )
  }

  /**
   * Request for api related to the authorized user details
   */
  getAuthDetailsService(): Observable<AuthorizedUserDetailsService> {
      if (this.userDetailsService) return of(this.userDetailsService);
      return this.buildService().pipe(
        defaultIfEmpty(""),
        map(_ => this.userDetailsService)
      );
    }

  /**
   * Request for website specific authorization. Not for mobiles.
   */
  getOnWebAuthorization(): Observable<WebAuthService> {
      if (this.webImplementation) return of(this.webImplementation);
      return this.buildService().pipe(
        defaultIfEmpty(""),
        map(_ => this.webImplementation)
      );
    }

  /**
   * Request for common api interface, Implemented on all platforms
   */
  getCommonRoutines(): Observable<CommonAuthorizationRoutines> {
      if (this.commonRoutines) return of(this.commonRoutines);
      return this.buildService().pipe(
        defaultIfEmpty(""),
        map(_ => this.commonRoutines)
      );
    }

    private buildService(): Observable<void> {
      const platform = environment.platform;
      if (platform.name === PlatformDescriptor.web.name
        || platform.name === PlatformDescriptor.emulated.name
      ) {
        const result = this.injector.get(AuthorizationServiceImpl)
        result.initialize();
        this.webImplementation = result;
        this.userDetailsService = result;
        this.commonRoutines = result;
        this.emulated = result;
        this.mobileService = result;
        if (platform.name === PlatformDescriptor.web.name) {
          this.emulated = null;
          this.mobileService = null;
        }
        if (platform.name === PlatformDescriptor.emulated.name) {
          result.setEmulated();
        }
        return EMPTY;
      } else if (platform.name === PlatformDescriptor.Android.name) {
        const result = this.injector.get(AndroidAuthService);
        const service = result
        this.userDetailsService = result
        this.commonRoutines = result
        this.mobileService = result;
        return EMPTY;
      } else if (platform.name === PlatformDescriptor.iOS.name) {
        const result = this.injector.get(IosAuthService);
        const service = result
        this.userDetailsService = result
        this.commonRoutines = result
        this.mobileService = result;
        return EMPTY;
      } else throw Error(`unsupported platform ${platform.name}`)

    }
}
