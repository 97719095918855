import { NgZone, Injectable } from '@angular/core';
import {LogsService} from "../../../utils/services/logs.service";
import {SentryService} from "../../../services/sentry-service.service";
import {Observable, Observer} from "rxjs";

class CallbackResponse<T> {
    static NextResponseType = 'next';
    static ErrorResponseType = 'error';
    static CompleteResponseType = 'complete';
    id: number;
    responseType: string;
    responseBody: T | string;
}

export class CallbackRequest<T> {
    id: number;
    args: any;
}

@Injectable({
    providedIn: 'root'
})
export class PendingRequestsQueue {

    constructor(
      private ngZone: NgZone, private logger: LogsService,
      private sentryService: SentryService
      ) {}
    pendingRequestById: { [requestId: number]: Observer<any>} = {};
    private lastPendingRequestLimitReported = 0;
    private pendingRequests = 0;

    registerPendingRequest(id: number, observer: Observer<any>) {
        this.pendingRequestById[id] = observer;
        this.pendingRequests++;
    }

    deletePendingRequest(id: number) {
        delete this.pendingRequestById[id];
        this.pendingRequests--;
        if (this.pendingRequests < 50) return;
        const now = new Date().getTime();
        if (now - this.lastPendingRequestLimitReported > 5 * 60 * 1000) {
          this.lastPendingRequestLimitReported = now
          try {
            this.sentryService.reportError(new Error("too many pending requests report"));
          } catch (e) {
            let errorText = "FATAL: Sentry service is missing, sentryService" + this.sentryService;
            if (this.sentryService != null) {
              errorText = errorText + ", reportError: " + this.sentryService.reportError
            }
            this.logger.forceLog( errorText)
          }
        }
    }

    handlePendingRequest(data: any) {
        const response = data as CallbackResponse<any>;
        this.logger.log(`got pending request response[${response.id}]: `);
        this.logger.log(JSON.stringify(data));
        const pendingRequest = this.pendingRequestById[response.id];
        if (!pendingRequest) {
            this.logger.forceLog('missing pending request for id ' + response.id);
            try {
              this.sentryService.reportError(new Error('missing pending request for id ' + response.id));
            } catch (e) {
              let errorText = "FATAL: Sentry service is missing, sentryService" + this.sentryService;
              if (this.sentryService != null) {
                errorText = errorText + ", reportError: " + this.sentryService.reportError
              }
              this.logger.forceLog( errorText)
            }
        }
        if (response.responseType === CallbackResponse.NextResponseType) {
            this.logger.log('invoking next in ngzone');
            this.ngZone.run( () => pendingRequest.next(response.responseBody));
        } else if (response.responseType === CallbackResponse.CompleteResponseType) {
            this.logger.log('invoking complete in ngzone & deleting pending request');
              this.ngZone.run(() => {
                try {
                  pendingRequest.complete();
                } catch (e) {
                  this.logger.forceLog("error on complete", e);
                }
                finally {
                  try {
                    this.deletePendingRequest(response.id);
                  } catch (e) {
                    this.logger.forceLog("error on delete in complete", e);
                  }
                }
              });

        } else if (response.responseType === CallbackResponse.ErrorResponseType) {
            this.logger.log('invoking error in ngzone & deleting pending request');

              this.ngZone.run(() => {
                try {
                  pendingRequest.error(new Error(this.extractErrorObject(response.responseBody)))
                } catch (e) {
                  this.logger.forceLog("request error not handled", e);
                } finally {
                  this.deletePendingRequest(response.id);
                }
              });
        }
    }

    private extractErrorObject(errorResponseBody: any): any {
      if (errorResponseBody instanceof String) return errorResponseBody;
      return JSON.stringify(errorResponseBody);
    }
}

