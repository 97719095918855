import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { ExerciseSessionQuestion } from "../cspa/model/cspa/personal";
import { AnswerDefinitionBase, QuestionDefinitionBase } from "../cspa/model/cspa/questions";
import {QuestionActionRequest, QuestionActionRequestWithValue} from "./prototypes";
import {LogsService} from "../utils/services/logs.service";
import { AuthorizationServiceProvider } from "../auth_profile/services/authorization-service.provider";

@Component({
    template:''
})
export abstract class QuestionComponentBase<A extends AnswerDefinitionBase, Q extends QuestionDefinitionBase<A>> implements OnDestroy {

    _question
    answer: A
    questionSubscription: Subscription;
    hintSubscription: Subscription
    imeSubscription: Subscription

    @Output() inProgressEmitter = new EventEmitter<boolean>()
    @Output() questionEmitter = new EventEmitter<ExerciseSessionQuestion<AnswerDefinitionBase,
    QuestionDefinitionBase<AnswerDefinitionBase>>>()

    @Output()
    public updateActionMenu = new EventEmitter<(QuestionActionRequest|QuestionActionRequestWithValue)[]>();

    @Input()
    set question(value: ExerciseSessionQuestion<A, Q>) {
      this.logs.log("got question def:", JSON.stringify(value))
      this._question = value
      this.setUpAnswer(this._question.answer)
    }

    protected constructor(
      protected logs: LogsService,
      protected authProvider: AuthorizationServiceProvider
      ) {
        this.authProvider.getMobile().subscribe( mobileApi => {
          if (mobileApi)
            this.imeSubscription = mobileApi.listenOnImeButton().subscribe(
              _ => this.imeInput()
            );
        });
    }

    get question() {
      return this._question
    }

    public abstract getAnswerForSubmit();
    public abstract setUpAnswer(answer: A);

    sendAnswer() {
      this._question.answer = this.answer
      this.questionEmitter.emit(this._question)
    }

    imeInput() {
      this.hideKeyboard()
    }

    hideKeyboard() {
      this.authProvider.getMobile().subscribe( mobileApi => {
        mobileApi.hideKeyboard().subscribe();
      });
    }

    ngOnDestroy(): void {
      if (this.questionSubscription) {
          this.questionSubscription.unsubscribe();
      }
      if (this.hintSubscription) {
          this.hintSubscription.unsubscribe();
      }
      if (this.imeSubscription) {
          this.imeSubscription.unsubscribe();
      }
    }
}
