import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TeacherSchoolGuardRedirectService {

  private schoolId
  constructor(private authService: AuthorizationServiceProvider,
              private router: Router) {
    this.authService.getAuthDetailsService().pipe(
      switchMap(authDetailsApi => authDetailsApi.getSelfSchoolId())
    ).subscribe(schoolId =>
      this.schoolId = schoolId
    )
  }

  canActivate(): Observable<boolean> {
    // for callanonline teachers
    if(this.schoolId === 1 && environment.colFunctionality)
      this.router.navigateByUrl('/teacher/panel/worktimes').then()
    // for other teachers
    else if(this.schoolId !== 1 || !environment.colFunctionality)
      this.router.navigateByUrl('/teacher/lessons').then()
    return of(false)
  }
}
