import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FilterDefinition} from "../../../model/CourseFilters";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {switchMap, tap} from "rxjs";
import { CourseFilterComponent } from 'src/app/utils/course-filter/course-filter.component';
import {ApiCourseProduct, ApiLessonProgress} from "../../model/rest-model";
import {LangProductMapper} from "../../utils/lang-mappers";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {StudentRestServiceImpl} from "../../services/student/student-rest-impl.service";

@Component({
  selector: 'app-courses-page',
  templateUrl: './courses-page.component.html',
  styleUrls: ['./courses-page.component.scss']
})
export class CoursesPageComponent implements OnInit, AfterViewInit {

  chosenCourse: FilterDefinition
  studentId: number
  CourseFilterComponent = CourseFilterComponent;
  supportedLangs: string[] = [];
  langs: ApiCourseProduct[];

  constructor(private authService: AuthorizationServiceProvider,
              private spinner: SpinnerService,
              private studentRest: StudentRestServiceImpl,
              private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authService
      .getAuthDetailsService()
      .pipe(
        switchMap(authDetailsApi => authDetailsApi.getSelfStudentId()),
        tap(studentId => this.studentId = studentId),
        switchMap(_ => this.prepareProgress())
      ).subscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  isLangSupported(lang: string) {
    return this.supportedLangs?.includes(lang);
  }

  prepareProgress() {
    return this.spinner.trace<ApiLessonProgress[]>(
      this.studentRest
        .getStudentProgress(this.studentId)
        .pipe(tap((progress) => this.mergeSupportedLangsFromProgress(progress)))
    );
  }

  mergeSupportedLangsFromProgress(progresses: ApiLessonProgress[]): void {
    if (progresses == null) {
      return;
    }
    const langArray: string[] = [];

    progresses
      .filter((p) => p.productCode != null)
      .forEach((p) => langArray.push(p.productCode));

    this.mergeSupportedLangs(langArray);
    this.langs = this.getLangs();
  }

  private mergeSupportedLangs(langs: string[]) {
    const correctLangs = langs.filter(
      (l) => LangProductMapper.langCodes.indexOf(l) >= 0
    );
    const langsToAdd = this.supportedLangs.filter(
      (l) => correctLangs.indexOf(l) < 0
    );
    langsToAdd.forEach((l) => correctLangs.push(l));
    this.supportedLangs = correctLangs;
  }

  private getLangs() {
    return LangProductMapper.getCourseProducts();
  }
}
