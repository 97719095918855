import { Component, Input, OnInit } from '@angular/core';
import { Person, PersonDetails } from 'src/app/auth_profile/model/casa/casa-model';
import { ProfileService } from 'src/app/auth_profile/services/student/profile.service';
import { environment } from 'src/environments/environment';
import {ParticipantDef, RoomDef} from '../../models/video.model';
import { switchMap, take, tap} from "rxjs";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'room-details',
  templateUrl: './room-details.component.html',
  styleUrls: ['./room-details.component.scss']
})
export class RoomDetailsComponent implements OnInit {
  @Input() room: RoomDef
  @Input()
  self: Person<PersonDetails>
  isCopied = false
  schoolId: number;

  constructor(private profile: ProfileService,
              private spinnerService: SpinnerService,
              private apiProvider: NativeServiceApiProvider,
              private authProvider: AuthorizationServiceProvider,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadIds()
    this.loadSelfPerson()
  }

  loadIds() {
    this.authProvider.getAuthDetailsService().pipe(
      switchMap(service => service.getSelfSchoolId()),
      tap(schoolId => {
        this.schoolId = schoolId
      }),
      switchMap(_ => this.route.paramMap),
      tap( params => {
        this.schoolId = Number(params.get('schoolId'))
      })
    ).subscribe()
  }

  loadSelfPerson() {
    this.spinnerService.trace(
      this.profile.getSelfPerson().pipe(take(1))
    ).subscribe(  self => this.self = self)
  }

  getScheduleName() {
    return this.room?.name
  }

  getScheduleDateCreated() {
    return new Date(this.room?.createDate)
    .toLocaleString('en-uk',
      { day: '2-digit', month: 'short', year: 'numeric',
        hour: 'numeric', minute: 'numeric' })
  }

  getScheduleDatePlanned() {
    if(this.room)
      return new Date(this.room?.plannedDate)
      .toLocaleString('en-uk',
        { day: '2-digit', month: 'short', year: 'numeric',
          hour: 'numeric', minute: 'numeric' })
    else
      return ''
  }

  getScheduleDuration() {
    return this.getRoomDuration(this.room?.provisioning.duration || 0)
  }

  getTeacher() {
    return this.room?.participants
    .find(participant => participant.role === 'Teacher')
  }

  getInitials(parti: ParticipantDef) {
    return parti?.name.match(/(\b\S)?/g).join("").toUpperCase()
  }

  getName(parti: ParticipantDef) {
    return parti?.name
  }

  getScheduleState() {
    return this.room?.provisioning?.status ? `LESSONS.LESSON_STATUS.${this.room.provisioning.status}` : null
  }

  getRoomDuration(time: number) {
    return (time/60).toFixed(0)
  }

  copyToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopied = true
  }

  createLink(parti: ParticipantDef): string {
    if (!parti) {
      return '';
    }
    const origin = environment.videoWebBase;
    if(parti?.uuid)
      if (parti.role === 'Teacher') {
        return `${origin}/participants/${parti.uuid}/teacher-room`;
      } else {
        return `${origin}/participants/${parti.uuid}/student-room`;
      }
    else
      return null
  }

  getSelf() {
    return this.room?.participants.find(parti =>
      parti.name ===  this.self?.details.name + ' ' + this.self?.details.surname)
  }

  getButtonContent() {
    return this.isCopied? 'BUTTON.COPIED' : 'BUTTON.COPY_LINK'
  }
}
