import { HttpClient, HttpEvent, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiCountry, PasswordChangeRequest, Person, PersonDetails, School, SchoolStudent, Student, StudentPersonDetails } from "../../../../model/casa/casa-model";
import {CacheService} from "../../../../../utils/services/cache.service";

@Injectable({
    providedIn: 'root'
  })
export class CasaRestService {

    constructor(
      private cache: CacheService,
      private http: HttpClient) {}

    private url(method: string) {
        return `${environment.casaEndpoint}/api/v2${method}`;
    }

    getSelf(): Observable<Person<PersonDetails>> {
        return this.http.get<Person<PersonDetails>>(this.url('/self'))
    }

    getSelfStudent(): Observable<Person<StudentPersonDetails>> {
        return this.http.get<Person<StudentPersonDetails>>(this.url('/self'))
    }

    getStudent(studentId: number): Observable<Student> {
        return this.http.get<SchoolStudent>(this.url(`/self/student/${studentId.toString()}`))
    }
    getTeacher(teacherId: number): Observable<Student> {
        return this.http.get<SchoolStudent>(this.url(`/self/teacher/${teacherId.toString()}`))
    }

    getSchool(studentId: number) {
        return this.cache.cache(`casa/student/${studentId}/school`,
          () => this.http.get<School>(this.url(`/self/student/${studentId.toString()}/school`)),
          60000
          );
    }

    getTeacherSchool(teacherId: number) {
        return this.http.get<School>(this.url(`/self/teacher/${teacherId.toString()}/school`))
    }

    getCountries(): Observable<ApiCountry[]> {
        return this.http.get<ApiCountry[]>(`${environment.casaEndpoint}/api/public/countries`);
    }

    saveSelfPersonalProfile(newSelf: PersonDetails): Observable<PersonDetails> {
        return this.http.put<PersonDetails>(this.url('/self'), newSelf)
    }

    saveSelfStudentPersonalProfile(newSelf: StudentPersonDetails): Observable<StudentPersonDetails> {
        return this.http.put<StudentPersonDetails>(this.url('/self/student-role'), newSelf)
    }

    public saveFile(fileToUpload: File): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        const req = new HttpRequest('POST', this.url('/files'), formData, {reportProgress: true});
        return this.http.request(req);
    }

    // changePassword(request: PasswordChangeRequest, lang?: string) {
    //     let params = new HttpParams();
    //     if (lang) params = params.append('lang', lang);
    //     return this.http.post<void>(`${environment.appEndpoint}/api/mobile/v3/public/password-change`, request, {params});
    // }

    deleteSelf(locale?: string) {
      return this.http.delete(this.url('/self'), { params: { locale: locale}})
    }
}
