import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QuestionActionRequest} from 'src/app/model/prototypes';
import {QuestionComponentBase} from 'src/app/model/QuestionComponentBase';
import {environment} from 'src/environments/environment';
import {Qa, QaAnswer} from '../../../model/cspa/questions';
import {Recorder} from '../../../model/recorder';
import {AudioRecorderService, RecorderState} from '../../../services/api/impl/rest/audio-recorder.service';
import {QuestionService} from '../../../services/api/impl/rest/question.service';
import {ShortcutService} from '../../../services/shortcut.service';
import {LogsService} from "../../../../utils/services/logs.service";
import {Subscription, take} from "rxjs";
import {ProfileService} from 'src/app/auth_profile/services/student/profile.service';
import {AuthorizationServiceProvider} from 'src/app/auth_profile/services/authorization-service.provider';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QaComponent extends QuestionComponentBase<QaAnswer, Qa> implements OnInit, OnDestroy {

  isQuestionHidden = true
  isAnswerHidden = true
  isAnswerBlockHidden = true
  isQuestionPulsing = true
  isAnswerPulsing = true
  canPlayAnswer = false
  isPlaying = false
  showPlay = false;
  recorder: Recorder;
  recording = false;
  readyToPlayNative = false;
  profilePhoto: string
  @ViewChild('audioTarget', {static: false}) audioTarget: ElementRef;
  qaRequestStart: QuestionActionRequest[] = [
    {
      text: 'microphone',
      assetUrl: 'mic'
    },
    {
      text: 'play',
      assetUrl: 'play-arrow-filled'
    }
  ]
  qaRequestStop: QuestionActionRequest[] = [
    {
      text: 'stop',
      assetUrl: 'stop-circle-blue'
    },
    {
      text: 'play',
      assetUrl: 'play-arrow-filled'
    }
  ]

  constructor(
    private audioRecorder: AudioRecorderService,
    private shortcut: ShortcutService,
    private questionService: QuestionService,
    private profileService: ProfileService,
    logs: LogsService,
    authProvider: AuthorizationServiceProvider) {
      super(logs, authProvider);
  }

  sendRequest(qaRequest: QuestionActionRequest[]) {
    this.updateActionMenu.emit(qaRequest)
  }

  public setUpAnswer(_: QaAnswer) {
  }


  private shortcutButtonSubscription: Subscription;
  private recorderStateSubscription: Subscription;
  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.shortcutButtonSubscription) this.shortcutButtonSubscription.unsubscribe()
    if (this.recorderStateSubscription) this.recorderStateSubscription.unsubscribe()

  }

  ngOnInit(): void {
    this.sendRequest(this.qaRequestStart)
    this.profileService.getSelfPerson().pipe(take(1)).subscribe({
      next: self => {
        this.profilePhoto = (self.details.photo && self.details.photo.publicAccessUrl) || null
      },
      error: _ => {
        this.profilePhoto = null
      }
    })
    this.audioRecorder.init().subscribe( recorder => {
      this.recorder = recorder;
      this.recorderStateSubscription = recorder.getRecorderState().subscribe(state =>
        this.recording = state === RecorderState.Recording);
      this.shortcutButtonSubscription = this.shortcut.btnRequest.subscribe(btn => {
        btn === 'microphone' && this.recordClicked()
        btn === 'stop' && this.stopRecording()
        btn === 'play' && this.play()
        })
    })

    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer => {
      sendAnswer &&
      sendAnswer['answerType'] === 'qa' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    })
  }

  getAnswerForSubmit() {
    this.sendAnswer()
  }

  getQuestion() {
    return this.question.question.definition.question
  }

  getPrompt() {
    let prompt = ''
    this.question.question.definition.prompt.forEach(item => prompt += item)
    return prompt
  }

  getAnswer() {
    return this.question.question.definition.questionAnswer
  }

  isCFK() {
    return this.question.question.path.startsWith("encfk")
  }

  getCFKImageSrc() {
    return `${environment.awsBase}${this.question.question.definition.img}`
  }

  toggleQuestion() {
    this.isQuestionHidden = !this.isQuestionHidden
  }

  toggleAnswer() {
    this.isAnswerHidden = !this.isAnswerHidden
  }

  playUrl(url: string, onFinish: () => void) {
    if (this.isPlaying) return;
    this.isPlaying = true;
    const audio = new Audio();
    audio.src = url;
    audio.onended = () => {
      this.isPlaying = false;
      onFinish();
    };
    audio.load();
    audio.play().then(
      null,
      () => this.isPlaying = false
    );
  }

  playQuestion() {
    if (!this.question) {
      return;
    }
    this.isQuestionPulsing = false
    this.playUrl(
      environment.awsBase + this.question.question.definition.src + 'question.mp3',
      () => this.isAnswerBlockHidden = false
      );
    this.canPlayAnswer = true
  }

  playAnswer() {
    this.isAnswerPulsing = false
    this.playUrl(environment.awsBase + this.question.question.definition.src + 'answer.mp3',
    () => {
      if (!this.answer) {
        this.answer = new QaAnswer();
      }
      this.inProgressEmitter.emit(false)
      this.question.answer = this.answer
    })
  }

  stopRecording() {
    this.recorder.stop().subscribe( blob => {
      this.sendRequest(this.qaRequestStart)
      if (blob == null) {
        this.readyToPlayNative = true;
      } else {
        this.audioTarget.nativeElement.src = URL.createObjectURL(blob);
      }
      this.showPlay = true;
      this.question.answer = this.answer
    })
  }

  recordClicked() {
    this.recorder.stop()
    .subscribe(_ => {
      this.sendRequest(this.qaRequestStop)
      this.recorder.clear()
        this.showPlay = false;
        this.recorder.record();
      }
    );
  }

  play() {
    this.recorder.stop().subscribe( blob => {
      if (blob == null) {
        this.recorder.play();
      } else {
        this.audioTarget.nativeElement.load();
        this.audioTarget.nativeElement.play();
      }
    });
  }
}
