<div class="row">
  <div *ngIf="hasImage()" class="col-12 col-md-4">
    <div class="question-image p-2 mb-2">
      <img [src]="getImageSrc()" class="card-img-top pe-none" />
    </div>
  </div>
  <div class="col mb-4 ios-correction-margin" id="scrollPoint">
    <div class="text-center fs-4 mb-5">
      <mat-form-field id="answer" class="w-100 text-center rounded-3 p-3">
        <textarea
          matInput
          autofocus
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          rows="1"
          [(ngModel)]="content"
          (ngModelChange)="answerChanged($event)"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div *ngIf="changedContent"
         class="correction-changes-bg opacity-75 rounded-3 p-3 pe-none">
      <p class="text-muted text-center xsmall mb-1">{{ "EXERCISES.HOST.CORRECTION.YOUR_CHANGE"| translate}}</p>
      <div class="row justify-content-center g-0" [innerHTML]="changedContent"></div>
    </div>
  </div>
</div>
