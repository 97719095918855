<div class="top-bar">
    <ng-content select="content"></ng-content>
    <ng-container *ngIf="titleTranslationCode">
      <content class="mb-md-4">
        <button
          *ngIf="redirectTranslationCode && !isHighestLevel()"
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
          [routerLink]="redirectLink"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3"
            [translate]="redirectTranslationCode"></span>
        </button>
        <div class="text-center">
          <h4 [translate]="titleTranslationCode"></h4>
        </div>
      </content>
    </ng-container>
    <ng-content select="footer"></ng-content>
</div>
