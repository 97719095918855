import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {map, Observable, of, take} from "rxjs";
import {Injectable} from "@angular/core";
import {AccessList, FunctionalAccessService} from "../../utils/services/functional-access.service";

@Injectable({
  providedIn: 'root'
})
export class AccessListGuardService implements CanActivate{
  constructor(private functionalAccessService: FunctionalAccessService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const functionalities = route.data['require'] as Array<string>
    if (!functionalities) return of(true);
    return this.functionalAccessService.getAccessState().pipe(
      take(1),
      map( (accessState: AccessList) => accessState.disabledList()),
      map( (disabled: Array<string>) => !functionalities.find( requested => disabled.find(found => found === requested)))
    );
  }

}
