/*
 * Exercise set groups all single course exercises. "English Core" may be a good example.
It contains all exercises starting from Stage 1 up to Stage 12. We name each state
as "chapter". Student may have an access only to a part of ExerciseSet chapters.
Student access rights are called "availability".
 */
export class ExerciseSet {
    /**
     * old exercise set idnetification - for backward comaptibility
     */
    langCode: string;
    /**
     * exercise set path. It is prefixing all exercise set chapters. I.e. "en" points to all
     * "English Core" chapters.
     */
    path: string;
    name: string;
    shortName: string;
    passScore: number;
    imageUrl: string;
}

/**
 * Definition base is used as a base class for all structure of cspa exercises.
 * Cspa exercises are structured into a tree. On the top is a exercise set. It groups
 * all of exercises related to the overal course, like "English Core".
 * Next is a chapter which usually corresponds to the "Stage" of Callan Method.
 * Chapter contains few (about 3) groups of exercises called "Sections". Usually section
 * means an exercise type like "question & answer", "dictation" or other "language exercises".
 * Inside a Section we have bunch of Exercise. Exercise contains few questions which has
 * to be finished to complete the Exercise.
 * Each of them extends a DefinitionBase, which contains a common part of each level data.
 * We use a path. Path is used to identify elements in the hierarhical structure. I.e.
 * en_1_qa_1 - means a path to the "1" Exercise in "qa" Section in "1" chapter in "en" ExerciseSet.
 *
 */
export class DefinitionBase {
    /**
     * depricated - RDB row id - should not be used
     */
    id: number;
    /**
     * path - hierarchical elelment identifier
     */
    path: string;
    name: string;
    shortName: string;
    /**
     * for future use - optional information about element requirements for the scoring to pass it
     */
    passScore: number;
    /**
     * depricated - old element identifier
     */
    code: string;
    /**
     * last update time in long unix format
     */
    lastUpdate: number;
}

export class Exercise extends DefinitionBase {
}

export class SectionType {
    public static QA = 'QA';
    public static DICT = 'DICT';
    public static LANG = 'LANG';
    public static MIXED = 'MIXED';
    public static S_EXAM = 'S_EXAM';
}

export class Section extends DefinitionBase {
    type: string;
    exercises: Exercise[];
}

export class Chapter extends DefinitionBase {
    /**
     * server side version - is increased each time on new exercise structure upload.
     */
    version: number;
    /**
     * ordering number - warning: may be empty or the same for few chapters - should be used for chapters
     * sorting
     */
    orderNumber: number;
    sections: Section[];
}

export class Exam extends Exercise {
  parts: ExamPart[];
}

export class ExamPart extends DefinitionBase {
  questions: number;
}

export enum ExerciseSetEnum {
    en = 'General English',
    en3 = 'General English rev. 3',
    sp = 'Callan Español',
    encfk = 'Callan for Kids',
    enbus = 'Callan for Business'
}
