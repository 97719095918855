import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, take, tap, finalize, switchMap } from 'rxjs';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { ToastNotificationsService, ToastNotification } from 'src/app/utils/services/toast-notification.service';
import { Person, PersonDetails, PasswordChangeRequest } from '../../model/casa/casa-model';
import { ProfileService } from '../../services/teacher/profile.service';
import {AuthorizationServiceProvider} from "../../services/authorization-service.provider";

@Component({
  selector: 'app-teacher-profile-page',
  templateUrl: './teacher-profile-page.component.html',
  styleUrls: ['./teacher-profile-page.component.scss']
})
export class TeacherProfilePageComponent implements OnInit {

  profile: Person<PersonDetails>
  profileUnderEdit: PersonDetails;
  busy = true;
  saved = false
  locale: String

  profileOption?: string | null

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private toasts: ToastNotificationsService,
    private provider: AuthorizationServiceProvider) {
    route.paramMap.subscribe(params => {
      this.profileOption = params.get("profileOption");
    })
   }

  ngOnInit(): void {
    this.loadProfile().subscribe()
  }

  loadProfile(): Observable<Person<PersonDetails>> {
    this.busy = true
    return this.profileService.getSelfPerson().pipe(
      take(1),
      tap(self => {
        this.keepTeacherProfileDetails(self)
      }),
      finalize( () => this.busy = false)
    )
  }

  private keepTeacherProfileDetails(self: Person<PersonDetails>) {
    this.profile = self
    this.profileUnderEdit = self.details
    this.locale = self.details.locale
  }

  saveProfile(details: PersonDetails | PersonDetails) {
    this.busy = true;
      this.profileService.updateSelf(details as PersonDetails).pipe(
        finalize( () => {
          this.busy = false
          this.saved = true
          setTimeout(() => {
            this.saved = false
          }, 2000);
        })
      ).subscribe( self => {
        if(this.locale != details.locale)
          location.reload()
        this.keepTeacherProfileDetails(self)
        }
      )
  }

  changePassword(request: PasswordChangeRequest) {
    this.busy = true;
    this.provider.getCommonRoutines().pipe(
      switchMap( api => api.changePassword(request)),
      finalize( () => {
        this.busy = false
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 2000);
      })
    ).subscribe()
  }

}
