export class PatternItem {
    constructor(public isPlaceholder: boolean, public content: string) { }
}

export class PatternParser {
    public static parse(input: string): PatternItem[] {
        const placeholderExp = /\{([^\}]+)\}/gm;
        const res: PatternItem[] = [];
        let matched: RegExpExecArray | null = placeholderExp.exec(input);
        let lastPos = 0;
        while (matched) {
            if (matched.index > lastPos) {
                res.push(new PatternItem(false, input.substring(lastPos, matched.index)));
            }
            res.push(new PatternItem(true, matched[1]));
            lastPos = matched.index + matched[0].length;
            matched = placeholderExp.exec(input);
        }
        if (lastPos < input.length ) {
            res.push(new PatternItem(false, input.substring(lastPos, input.length)));
        }
        return res;
    }
}