import { Injectable } from '@angular/core';
import {CasaNativeApi, UploadEvent} from "../casa-native-api";
import {CasaRestService} from "./rest/casa-rest.service";
import {Observable, throwError} from "rxjs";
import {
  ApiCountry,
  FileItem,
  Person,
  PersonDetails,
  School,
  StudentPersonDetails
} from "../../../model/casa/casa-model";
import {LogsService} from "../../../../utils/services/logs.service";

@Injectable({
  providedIn: 'root'
})
export class CasaEmulatedService implements CasaNativeApi{

  constructor(
    private rest: CasaRestService,
              private logger: LogsService,
  ) {}

  deleteSelf(locale?: string) {
        throw new Error('Method not implemented.');
    }

  getCountries(): Observable<ApiCountry[]> {
    this.logger.log("getting countries list")
    return this.rest.getCountries();
  }

  getSelf(): Observable<Person<PersonDetails>> {
    this.logger.log("getting self")
    return this.rest.getSelf();
  }

  getSelfStudent(): Observable<Person<StudentPersonDetails>> {
    this.logger.log("getting self student")
    return this.rest.getSelfStudent();
  }

  getStudentsSchool(studentId: number): Observable<School> {
    this.logger.log(`getting school of student with id=${studentId}`)
    return this.rest.getSchool(studentId);
  }

  getTeachersSchool(teacherId: number): Observable<School> {
    this.logger.log(`getting school of teacher with id=${teacherId}`)
    return this.rest.getTeacherSchool(teacherId);
  }

  isImplemented(): boolean {
    return true;
  }

  supportsFileSending(): boolean {
    return false;
  }

  updateSelfStudentPersonalProfile(details: StudentPersonDetails): Observable<StudentPersonDetails> {
    this.logger.log(`updating student personal profile`)
    return this.rest.saveSelfStudentPersonalProfile(details);
  }

  updateSelfTeacherPersonalProfile(details: PersonDetails): Observable<PersonDetails> {
    this.logger.log(`updating teacher personal profile`)
    return this.rest.saveSelfPersonalProfile(details);
  }

  uploadFile(file: File): Observable<UploadEvent<FileItem>> {
    return throwError(() => new Error("Unsupported API"));
  }

  isSupportsProfileEdit(): Boolean {
    return false;
  }
}
