<div class="card">
  <div class="card-header">
    <div class="row justify-content-between">
      <div class="col-6 order-md-1">
        <h4 class="align-self-center">{{ getTeacherName() }}</h4>
        <p class="text-muted small mb-0">
          {{ getLessonDate() | dateTranslation : "dd MMM yyyy HH:mm" }}
        </p>
        <p class="text-muted small mb-0">
          <span *ngIf="!isProva()">{{ getCourseName() }}</span>
          <span *ngIf="isProva()">
            <span>{{ getCourseLangName() | translate }}</span>
            <span>{{ "COL.LESSONS.DETAILS.PROVA" | translate }}</span>
          </span>
          -
          <span>{{ getStatusDescription() | translate }}</span>
        </p>
      </div>
      <div class="col-4 col-md-auto d-flex order-md-3 justify-content-end">
        <div
          *ngIf="getLessonTeacherPhotoUrl()"
          class="profile-photo rounded-circle initials-circle-lg my-auto"
          [style.background-image]="'url(' + getLessonTeacherPhotoUrl() + ')'"
        ></div>
        <div
          *ngIf="!getLessonTeacherPhotoUrl()"
          class="d-flex rounded-circle text-center bg-gray me-2 initials-circle-lg my-auto"
        >
          <span class="text-muted m-auto">
            {{ getInitials() }}
          </span>
        </div>
      </div>
      <div *ngIf="cancelable || (cancelable && isComing)" class="col d-flex order-md-2 align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0">
        <button type="button" class="btn btn-primary rounded-pill py-2"
          (click)="cancelLessonModal.show()">
          {{ 'BUTTON.CANCEL_LESSON' | translate }}
        </button>
      </div>
      
    </div>
    <ng-container *ngIf="displayClassroomInfo()">
      <hr class="dropdown-divider my-3"/>
      <div class="row gy-2 justify-content-md-between">
        <div *ngIf="isLessonGoingToStartRegularly()" class="col-12 col-sm-6 col-md-auto text-center">
          <span>
            <span>{{ "COL.COURSES.COMING_LESSON.WILL_START" | translate}}</span><br>
            <strong class="text-success">{{timePhrase}}</strong>
          </span>
        </div>
        <div *ngIf="isTeacherMissingToStartLesson()" class="col-12 col-sm-6 col-md-auto text-center">
          <span>
            <span>{{ "COL.COURSES.COMING_LESSON.WAITING_FOR_TEACHER" | translate}}</span><br/>
            <strong class="text-danger">{{timePhrase}}</strong>
          </span>
        </div>
        <div *ngIf="isLessonReadyToStart()" class="col-12 col-sm-6 col-md-auto d-flex flex-column text-center text-muted">
          <span>{{ "COL.COURSES.COMING_LESSON.TEACHER_READY" | translate}}</span>
          <span *ngIf="mayEnterRoomBySkype()"><span>{{ "COL.COURSES.COMING_LESSON.CALL_ME_BY" | translate}}</span> <strong> {{getLessonSkypeAddress()}}</strong></span>
          <span *ngIf="mayEnterVideoRoom()">{{ "COL.COURSES.COMING_LESSON.JOIN_ROOM" | translate}}</span>
        </div>
        <div class="col-12 col-sm-6 col-md-auto d-flex">
          <div class="m-auto">
            <div id="comming-lesson-actions" class="row text-center flex-column">
              <div class="text-lg-right" *ngIf="mayEnterVideoRoom()" >
                <a class="btn btn-primary rounded-pill"
                   id="comming-lesson-actions-classroom"
                   target="_blank"
                   tabindex=""
                   [href]="getRoomUrl()"
                >
                  {{ "COL.COURSES.COMING_LESSON.ENTER_ROOM" | translate}}
                </a>
                <a (click)="openSkypeHelp()"
                   tabindex=""
                   class="d-block"
                   target="_blank"
                >
                  <small>{{ "COL.COURSES.COMING_LESSON.CONNECTION_PROBLEMS" | translate }}</small>
                </a>
              </div>
              <div class="text-lg-right" *ngIf="mayEnterRoomBySkype()">
                <a
                  id="comming-lesson-actions-skype"
                  class="btn btn-primary rounded-pill"
                  [href]="getRoomUrl()"
                  tabindex=""
                  >{{ "BUTTON.CALL_SKYPE" | translate }}</a>
                <a
                  (click)="moveToInstructions()"
                  tabindex=""
                  class="d-block"
                  target="_blank"
                ><small>{{ "COL.COURSES.COMING_LESSON.WHAT_NOW" | translate }}</small></a>
              </div>
              <span
                class="d-block w-100 text-muted"
                *ngIf="!mayEnterClassroom()"
                tabindex=""
              ><span>{{ "COL.COURSES.COMING_LESSON.ROOM_CLOSED" | translate }}</span></span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-body lh-sm">
    <div *ngIf="!isFinished() && isProva()">
      <h5 class="text-primary">
        {{ "COL.LESSONS.DETAILS.LESSON_PLAN.TITLE" | translate }}
      </h5>
      <p *ngIf="isEnglishCore()">
        {{ "COL.LESSONS.DETAILS.LESSON_PLAN.EN_PROVA_BODY" | translate }}
      </p>
      <p *ngIf="isSpanish()">
        {{ "COL.LESSONS.DETAILS.LESSON_PLAN.ES_PROVA_BODY" | translate }}
      </p>
      <p *ngIf="isBusiness()">
        {{ "COL.LESSONS.DETAILS.LESSON_PLAN.BS_PROVA_BODY" | translate }}
      </p>
    </div>

    <!-- !(invalidated || cancelled ) && !studentNoShow -->
    <div
      *ngIf="
        !(isInvalidated() || isCancelled()) && !isStudentNoShow()
      "
      class="mb-4"
    >
      <ng-container *ngIf="!isProva()">
        <h5 class="text-primary">
          {{ "COL.LESSONS.DETAILS.LESSON_PLAN.TITLE" | translate }}
        </h5>
        <!-- future && mt 12 && !next -->
        <p *ngIf="isFuture() && !isClose()">
          {{ "COL.LESSONS.DETAILS.LESSON_PLAN.HIDDEN_FOR_LATE" | translate }}
        </p>
        <!-- future && lt 12 && !next-->
        <p *ngIf="isFuture() && isClose() && !isNext()">
          {{ "COL.LESSONS.DETAILS.LESSON_PLAN.HIDDEN_FOR_NOT_NEXT" | translate }}
        </p>
        <!-- (isClose && isNext) || (!isFuture && !isComplete) -->
        <div *ngIf="(isClose() && isNext()) || (!isFuture() && !isComplete())">
          <div class="row mb-3" *ngIf="progress">
            <div class="col-12 col-lg-8 col-xl-6 col-xxl-4">
              <p class="mb-2">
                <span>{{
                  "COL.LESSONS.DETAILS.LESSON_PLAN.START_PAGE" | translate
                }}</span>
                <span class="float-end">{{ progress.start }}</span>
              </p>
              <p class="mb-2">
                <span>{{
                  "COL.LESSONS.DETAILS.LESSON_PLAN.NEW_WORK_PAGE" | translate
                }}</span>
                <span class="float-end">{{ progress.nwp }}</span>
              </p>
              <p class="mb-0">
                <span>{{
                  "COL.LESSONS.DETAILS.LESSON_PLAN.READING_START_PAGE" | translate
                }}</span>
                <span class="float-end">{{ getRecommendedReading() }}</span>
              </p>
            </div>
          </div>
          <p>
            {{ "COL.LESSONS.DETAILS.LESSON_PLAN.MAY_BE_MODIFIED" | translate }}
          </p>
        </div>
      </ng-container>
      <!-- complete && !studentNoShow -->
      <div *ngIf="isComplete() && !isStudentNoShow()">
        <div class="row mb-3" *ngIf="lesson.progressCommited">
          <div class="col-12 col-lg-8 col-xl-6 col-xxl-4">
            <p class="mb-2">
              <span>{{
                "COL.LESSONS.DETAILS.LAST_PROGRESS.REVISION_START_PAGE"
                  | translate
              }}</span>
              <span class="float-end">{{ lesson.progressCommited.start }}</span>
            </p>
            <p class="mb-2">
              <span>{{
                "COL.LESSONS.DETAILS.LAST_PROGRESS.NEW_WORK_PAGE" | translate
              }}</span>
              <span class="float-end">{{ lesson.progressCommited.nwp }}</span>
            </p>
            <p class="mb-2">
              <span>{{
                "COL.LESSONS.DETAILS.LAST_PROGRESS.LAST_WORD" | translate
              }}</span>
              <span class="float-end">{{ lesson.progressCommited.hw }}</span>
            </p>
            <p class="mb-0">
              <span>{{
                "COL.LESSONS.DETAILS.LAST_PROGRESS.READING_PAGE" | translate
              }}</span>
              <span class="float-end">{{
                lesson.progressCommited.reading
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- !( invalidated || cancelled) -->
    <div *ngIf="!(isInvalidated() || isCancelled() || isStudentNoShow())">
      <h5 class="text-primary">{{ 'COL.LESSONS.DETAILS.IMPORTANT.TITLE' | translate }}</h5>
      <!-- !isFinished && !inProgress -->
      <p *ngIf="!(isComplete() || isInProgress())">
       {{ 'COL.LESSONS.DETAILS.IMPORTANT.FOLLOW_INSTRUCTIONS' | translate }}
      </p>
      <!-- inProgress-->
      <p *ngIf="isInProgress()">
        {{ 'COL.LESSONS.DETAILS.IMPORTANT.LESSON_IN_PROGRESS' | translate }}
      </p>
      <!-- complete -->
      <p *ngIf="isComplete()">
        {{ 'COL.LESSONS.DETAILS.IMPORTANT.COMPLETE_EXERCISES' | translate }}
      </p>
    </div>
    <!-- (!finished && !inProgress) || cancelled - to reduce -->
    <div *ngIf="!(isFinished() || isInProgress()) || isCancelled()">
      <h5 class="text-primary">{{ 'COL.LESSONS.DETAILS.CANCELLATION.TITLE' | translate }}</h5>
      <!-- future && mt 12  -->
      <p *ngIf="isFuture() && !isClose() && !isCancelled()">
        {{ 'COL.LESSONS.DETAILS.CANCELLATION.ALLOWED' | translate }}
      </p>
      <!-- !finished && lt 12 !inProgress -->
      <p *ngIf="isClose() && !isCancelled()">
        {{ 'COL.LESSONS.DETAILS.CANCELLATION.NOT_ALLOWED' | translate }}
      </p>
      <div *ngIf="isCancelled() && !hasCancellationDetails()">
        <p>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_NO_REASON.CONTENT_1' | translate }}</p>
        <p>
          {{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_NO_REASON.CONTENT_2' | translate }}
        </p>
        <p>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_NO_REASON.CONTENT_3' | translate }}</p>
      </div>
      <!-- cancelled && byStudent-->
      <div *ngIf="isCancelled() && hasCancellationDetails() && isByStudent()">
        <p>
         {{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED.CONTENT_1' | translate }}
        </p>
        <p><strong>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED.CONTENT_2' | translate }}</strong> {{ getCancellationReason() }}</p>
        <p>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED.CONTENT_3' | translate }}</p>
      </div>
      <!-- cancelled && byTutor -->
      <div *ngIf="isCancelled() && hasCancellationDetails() && !isByStudent()">
        <p>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_BY_TEACHER.CONTENT_1' | translate }}</p>
        <p><strong>{{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_BY_TEACHER.CONTENT_2' | translate }}</strong> {{ getCancellationReason() }}</p>
        <p>
          {{ 'COL.LESSONS.DETAILS.CANCELLATION.LESSON_CANCELLED_BY_TEACHER.CONTENT_3' | translate }}
        </p>
      </div>
    </div>
    <!-- complete && !studentNoShow -->
    <div *ngIf="isComplete() && !isStudentNoShow() && getMessage()">
      <h5 class="text-primary">{{ 'COL.LESSONS.DETAILS.COMPLETE.MESSAGE' | translate }}</h5>
      <p>
        {{ getMessage() }}
      </p>
    </div>
    <!-- (complete && studentNoShow) || (invalidated && tutorNoShow) -->
    <div
      *ngIf="
        (isComplete() && isStudentNoShow()) ||
        (isInvalidated() && isTutorNoShow())
      "
    >
      <h5 class="text-primary">{{ 'COL.LESSONS.DETAILS.LESSON.TITLE' | translate }}</h5>
      <!-- invalidated && tutorNoShow-->
      <div *ngIf="isInvalidated() && isTutorNoShow()">
        <p>{{ 'COL.LESSONS.DETAILS.LESSON.TUTOR_NO_SHOW.CONTENT_1' | translate }}</p>
        <p>{{ 'COL.LESSONS.DETAILS.LESSON.TUTOR_NO_SHOW.CONTENT_2' | translate }}</p>
        <p>{{ 'COL.LESSONS.DETAILS.LESSON.TUTOR_NO_SHOW.CONTENT_3' | translate }}</p>
      </div>
      <!-- complete && studentNoShow -->
      <div *ngIf="isComplete() && isStudentNoShow()">
        <p>{{ 'COL.LESSONS.DETAILS.LESSON.STUDENT_NO_SHOW.CONTENT_1' | translate }}</p>
        <p>{{ 'COL.LESSONS.DETAILS.LESSON.STUDENT_NO_SHOW.CONTENT_2' | translate }}</p>
        <p>
          {{ 'COL.LESSONS.DETAILS.LESSON.STUDENT_NO_SHOW.CONTENT_3' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<app-modal #classroomFallback>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        {{ "COL.COURSES.COMING_LESSON.CONNECTION_PROBLEMS" | translate }}
      </h5>
      <button type="button" class="btn-close" (click)="classroomFallback.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <p>
        <span>{{ "COL.COURSES.COMING_LESSON.ABOUT_REFRESH" | translate }}</span>
        <i class="fas fa-redo ms-2"></i>
      </p>
      <p>
        <span>
          {{ "COL.COURSES.COMING_LESSON.ABOUT_TEACHER_SKYPE" | translate }}
        </span>
        <a class="ms-2" [href]="getTeacherSkypeUrl()" >
          {{getTeacherSkype()}}
        </a>
      </p>
      <div class="text-end">
        <button type="button" class="btn btn-primary rounded-pill" (click)="classroomFallback.hide()">
          {{ "BUTTON.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal #cancelLessonModal>
  <div class="modal-content" *ngIf="lesson">
    <div class="modal-header border-0">
      <h5 class="modal-title">{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.TITLE' | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="cancelLessonModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
      <div class="modal-body">
        <p>{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.INFO' | translate }}</p>
        <p
          *ngIf="lesson.lessonStatus == 'Due' || shouldStart"
          class="text-danger fw-bold"
        >
          {{ 'COL.TEACHER.MODAL.CANCEL_LESSON.INFO_DUE' | translate }}
        </p>
        <div class="row">
          <div class="col-12">
            <div class="form-floating">
              <textarea
                #reason="ngModel"
                name="reason"
                placeholder="reason"
                class="form-control textarea-height"
                required
                [(ngModel)]="cancelReason"
                [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
              ></textarea>
              <label for="reason">{{ 'COL.TEACHER.MODAL.CANCEL_LESSON.REASON' | translate }}</label>
              <div
                *ngIf="f.submitted && reason.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="reason.errors?.['required']">
                  {{ 'FORM_ERROR.FIELD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-danger rounded-pill">
          {{ 'BUTTON.CANCEL_LESSON' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>