import {Component, ComponentRef, ViewChild} from '@angular/core';
import {HostBaseComponent} from "../../model/hostBase/host-base.component";
import {ExamSession} from "../../model/cspa/personal";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {of, switchMap, tap} from "rxjs";
import {ModalComponent} from "../../../utils/modal/modal.component";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {catchError} from "rxjs/operators";
import {ConfirmDialogService} from "../../../utils/services/confirm-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {OffcanvasMenuService} from "../../../services/offcanvas-menu.service";
import {ShortcutService} from "../../services/shortcut.service";
import {QuestionService} from "../../services/api/impl/rest/question.service";
import {SessionInfoService} from "../../../services/session-info.service";
import {DictComponent} from "../../components/exercises/dict/dict.component";
import { FontSizeService } from 'src/app/services/font-size.service';

@Component({
  selector: 'app-exam-host',
  templateUrl: './exam-host.component.html',
  styleUrls: ['../../model/hostBase/host-base.component.scss']
})
export class ExamHostComponent extends HostBaseComponent<ExamSession> {
  isExam: boolean = false
  examPartId: number
  redirectCountdown: number = null;
  timeLimitIntervalId: any
  countdownIntervalId: any
  timeLimit: number = null;

  @ViewChild('examModal', {static: true}) examModal: ModalComponent;
  @ViewChild('timeLimitModal', {static: true}) timeLimitModal: ModalComponent;
  @ViewChild('dictTimeLimitModal', {static: true}) dictTimeLimitModal: ModalComponent;
  @ViewChild('lockedExamPartSessionModal', {static: true}) lockedExamPartSessionModal: ModalComponent

  constructor(
    route: ActivatedRoute,
    confirmDialogService: ConfirmDialogService,
    translateService: TranslateService,
    offcanvasMenuService: OffcanvasMenuService,
    shortcut: ShortcutService,
    questionService: QuestionService,
    progressInfoService: SessionInfoService,
    private provider: NativeServiceApiProvider,
    private router: Router,
    fontSizeService: FontSizeService
  ) {
    super(
      route,
      confirmDialogService,
      translateService,
      offcanvasMenuService,
      shortcut,
      questionService,
      progressInfoService,
      fontSizeService
    )
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.examModal.hide()
    this.timeLimitModal.hide()
    this.dictTimeLimitModal.hide()
    clearInterval(this.timeLimitIntervalId)
    clearInterval(this.countdownIntervalId)
  }

  retrieveFromParamMap(params: ParamMap) {
    this.examPartId = +params.get("sessionPartId") - 1;
    this.isExam = !!params.get("sessionPartId");
  }

  getQuestions() {
    return this.session?.currentPart?.questions
  }

  getExerciseName() {
    return this.session?.examName
  }

  getSession() {
    return this.provider.cspa().pipe(
      switchMap(api =>
        api.getExamSession(this.uuId).pipe(
          switchMap(session =>
            session.currentPart ?
              of(session)
              : api.startExamSessionPart(this.uuId, this.examPartId))
        )
      ),
      tap(session => {
        this.session = session
        this.question = this.getQuestion()
        this.question && this.setupQuestionTimings(this.question)
        this.goToQuestion(this.questionNb)
        this.startCountdown()
      })
    )
  }

  goToQuestion(index: number | string) {
    if (+index > this.getQuestions().length) {
      this.examModal.show()
    } else {
      this.onLoadQuestion(this.getQuestions()[+index - 1])
      this.router.navigate(
        [`../${index}`],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    }
  }

  finishSession() {
    return this.provider.cspa().pipe(
      switchMap(api =>
        api.finishExamSessionPart(this.uuId).pipe(
          switchMap(session =>
            session.partsDone == session.nbOfParts ? api.finishExamSession(this.uuId) : of(session))
        )
      )
    )
  }

  goBackFromExam() {
    this.lockedExamPartSessionModal.hide()
    this.router.navigate(
      ['../../../../../../../'], {
        relativeTo: this.route,
        queryParamsHandling: 'merge'
      }
    ).then()
  }

  startCountdown() {
    const limit = (this.session as ExamSession)?.currentPart?.timeLimitDate
    if (!limit) return
    if (new Date(limit).getTime() <= new Date().getTime()) {
      this.lockedExamPartSessionModal.show()
    }
    if (!this.timeLimitIntervalId)
      this.timeLimitIntervalId = setInterval(() => {
        this.timeLimit = new Date(limit).getTime() - new Date().getTime()
        if (this.timeLimit <= 0) {
          this.showTimeLimitModal()
          clearInterval(this.timeLimitIntervalId)
          this.timeLimitIntervalId = null
        }
      }, 1000)
  }

  showTimeLimitModal() {
    this.redirectCountdown = 5
    this.timeLimitModal.show(false)
    if (!this.countdownIntervalId)
      this.countdownIntervalId = setInterval(() => {
        if (this.redirectCountdown == 0) {
          this.timeLimitModal.hide()
          clearInterval(this.countdownIntervalId)
          this.countdownIntervalId = null
          this.goToNextPart()
        }
        this.redirectCountdown -= 1
      }, 1000)
  }

  showDictTimeLimitModal() {
    this.redirectCountdown = 5
    this.dictTimeLimitModal.show(false)
    if (!this.countdownIntervalId)
      this.countdownIntervalId = setInterval(() => {
        if (this.redirectCountdown == 0) {
          this.dictTimeLimitModal.hide()
          clearInterval(this.countdownIntervalId)
          this.countdownIntervalId = null
          this.isLastDictation() ? this.goToNextPart() : this.onCheck()
        }
        this.redirectCountdown -= 1
      }, 1000)
  }

  goBack() {
    this.finishSession().pipe(
      catchError(_ => {
        return of(null)
      })
    ).subscribe(_ => {
      let redirectPath = this.questionNb !== 'finish' ?
        '../../../finish'
        : '../../../../../../../'
      this.router.navigate(
        [`${redirectPath}`],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    });
  }

  getExamNextPartName() {
    return this.session?.parts ? this.session?.parts[this.examPartId + 1]?.partName : ''
  }

  getExamName() {
    return this.session?.examName
  }

  getExamPartName() {
    return this.session?.currentPart?.partName
  }

  getCurrentPartNr() {
    return this.session?.parts.findIndex(it => it.path == this.session.currentPart?.path) + 1
  }

  isSampleExam() {
    return this.session?.examName.split(" ")[1] == "0"
  }

  isExamFinished() {
    return this.session?.partsDone + 1 == this.session?.nbOfParts
  }

  goToNextPart() {
    this.examModal.hide()
    this.timeLimitModal.hide()
    let path = this.isExamFinished() ? `../../../finish` : `../../${+this.examPartId + 2}/1`
    this.finishSession().subscribe(_ => {
      this.router.navigate(
        [path],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    })
  }

  postAnswer() {
    this.provider.cspa().pipe(
      switchMap(api =>
        api.postExamSessionQuestionAnswer(
          this.uuId, +this.questionNb - 1, this.question)
      )
    ).subscribe(_ => {
      this.goToQuestion(+this.questionNb + 1)
    })
  }

  prepareAudioProgressEmitter(componentRef: ComponentRef<DictComponent>) {
    componentRef.instance.audioProgressEmitter.subscribe((progress: number) => {
        if (progress == -1) {
          this.showDictTimeLimitModal()
        }
        else
          this.progressInfoService.setSessionInfo({
            questionNb: this.questionNb,
            session: this.session,
            progressPercent: progress
          })
      }
    )
  }

  isLastDictation() {
    const currentPart = this.session?.currentPart
    return currentPart?.partName.includes("Dict") && currentPart?.questions.length == +this.questionNb
  }

  isAudio(){
    return this.question?.question?.definition['@type'] === "audio"
  }
}
