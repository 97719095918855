<div class="card">
  <div class="card-body">
    <p class="mb-4">
      {{ (isSampleExam ? "EXAM.SUMMARY.SAMPLE.INFO_1" : "EXAM.SUMMARY.INFO_1") | translate: {stageName: session?.chapterName} }}
    </p>
    <div class="table-responsive mb-2">
      <table class="table white-text table-borderless text-center">
        <thead>
        <tr class="table-row table-row-head">
          <th class="small">{{ 'EXAM.TABLE_HEADER.PART' | translate }}</th>
          <th class="small">{{ 'EXAM.TABLE_HEADER.TASK' | translate }}</th>
          <th class="small">{{ 'EXAM.TABLE_HEADER.SCORE' | translate }}</th>
        </tr>
        </thead>
        <tbody class="table-body">
        <tr *ngFor="let part of sessionParts; index as i" class="table-row">
          <td class="small text-nowrap">{{ i + 1 }}</td>
          <td class="small">{{ part.partName }}</td>
          <td class="small">{{ part.score ? (part.score | percent:'1.0-0') : '-' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p>
      {{ (isSampleExam ? "EXAM.SUMMARY.SAMPLE.INFO_2" : "EXAM.SUMMARY.INFO_2") | translate }}
    </p>
  </div>
  <div class="card-footer border-0 text-center px-4 pb-3">
    <button type="button"
            routerLink="../../../../../"
            class="btn btn-primary rounded-pill mx-auto py-2 px-5"
           >
      {{ 'BUTTON.FINISH' | translate }}
    </button>
  </div>
</div>
