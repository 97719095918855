import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {switchMap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../../auth_profile/services/authorization-service.provider";
import {FilterDefinition} from "../../../../model/CourseFilters";
import {CourseFilterComponent} from "../../../../utils/course-filter/course-filter.component";

@Component({
  selector: 'app-progress-page',
  templateUrl: './progress-page.component.html',
  styleUrls: ['./progress-page.component.scss']
})
export class ProgressPageComponent implements OnInit, AfterViewInit {
  chosenCourse: FilterDefinition
  studentId: number
  CourseFilterComponent = CourseFilterComponent;

  constructor(private authService: AuthorizationServiceProvider,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
        switchMap(api => api.getSelfStudentId())
      ).subscribe((studentId) => this.studentId = studentId)
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
