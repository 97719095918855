<div class="card calendar-bg">
  <div class="card-header d-flex align-items-center">
    <h5 class="mb-0">{{ 'LESSONS.LESSON_CALENDAR.TITLE' | translate }}</h5>
    <app-access-lessons-button class="ms-auto"></app-access-lessons-button>
  </div>
  <div class="card-body">
    <div class="table-responsive table-height"  *ngIf="!dataIsEmpty(); else noResults">
      <div class="table-responsive table-height calendar-bg rounded-3" id="calendar">
        <table class="table table-sm white-text">
          <thead class="position-sticky top-0 text-center z-index-10" id="table-header">
            <tr class="sticky-border-bottom calendar-bg">
              <th class="position-sticky sticky-border-bottom-end start-0 top-0"></th>
              <th *ngFor="let dayDate of getWeekDates()">
                <p class="xsmall mb-0">
                  {{dayDate | date: "EEE"}}
                </p>
                <span class="small text-muted fw-normal">{{dayDate | date: "d"}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let x of [].constructor(24); let hour = index"
                class="border-bottom border-secondary">
              <td class="p-3 position-sticky start-0 sticky-border-bottom-end calendar-bg">
                <span class="small">{{hour}}:00</span>
              </td>
              <td *ngFor="let dayDate of getWeekDates(); let dayIndex = index"
                  class="border-start border-secondary align-top">
                <ng-container *ngFor="let schedule of getCalendarDisplayViewDay(dayDate)">
                  <div class="bg-calendar class rounded p-3 d-block h-100" *ngIf="isScheduleInDateTime(schedule, hour, dayIndex)">
                    <a [ngClass]="getBadgeColorClassByStatus(schedule)"
                       class="badge rounded px-2 py-1 w-fit-content mb-2 text-decoration-none"
                       routerLink="../schedule/{{schedule.schedule.id}}"
                       data-bs-toggle="tooltip"
                       title="{{ getTooltipContent(schedule) | translate}}">
                      <span class="text-white">
                        {{schedule.schedule.details.startDate | date: 'HH:mm'}}
                      </span>
                      <span class="text-white" *ngIf="getEndDate(schedule)">
                      - {{getEndDate(schedule) | date: "HH:mm"}}
                      </span>
                    </a>
                    <br>
                    <p class="text-muted class mb-0">
                      {{getPlace(schedule)}}
                      <app-icon name="school" *ngIf="hasAnyOffline(schedule)" class="icon-secondary icon-xs"></app-icon>
                    </p>
                    <a class="group text-decoration-none" routerLink="/teacher/groups/school/{{schoolId}}/group/{{schedule.template.id}}">
                      {{getGroupName(schedule.template)}}
                    </a>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ng-template #noResults>
      <div class="row">
        <p class="text-center fs-5 text-muted m-0">
          {{ "LESSONS.NO_RESULTS" | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
