import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LessonsPageComponent} from './pages/lessons-page/lessons-page.component';
import {UtilsModule} from '../utils/utils.module';
import {StudentLessonsComponent} from './components/student/student-lessons/student-lessons.component';
import {FormsModule} from '@angular/forms';
import {
  StudentLessonDetailsPageComponent
} from './pages/student-lesson-details-page/student-lesson-details-page.component';
import {
  StudentLessonDetailsComponent
} from './components/student/student-lesson-details/student-lesson-details.component';
import {AppRoutingModule} from '../app-routing.module';
import {CoursesListPageComponent} from './pages/courses-list-page/courses-list-page.component';
import {CourseCardComponent} from './components/student/course-card/course-card.component';
import {CourseProductsPageComponent} from './pages/course-products-page/course-products-page.component';
import {SpanishProductsComponent} from './components/student/products/spanish-products/spanish-products.component';
import {EnglishProductsComponent} from './components/student/products/english-products/english-products.component';
import {
  EnglishBusinessProductsComponent
} from './components/student/products/english-business-products/english-business-products.component';
import {ProductBoxComponent} from './components/student/products/product-box/product-box.component';
import {CourseDetailsComponent} from './components/student/course-details/course-details.component';
import {CourseDetailsPageComponent} from './pages/course-details-page/course-details-page.component';
import {ExerciseSetHostComponent} from './components/student/exercise-set-host/exercise-set-host.component';
import {
  ChapterProgressCircleComponent
} from './components/student/chapter-progress-circle/chapter-progress-circle.component';
import {ChapterDetailsComponent} from './components/student/chapter-details/chapter-details.component';
import {CspaModule} from '../cspa/cspa.module';
import {StudentCreditsComponent} from './components/student/student-credits/student-credits.component';
import {TransactionsComponent} from './components/student/transactions/transactions.component';
import {PurchaseHistoryComponent} from './components/student/purchase-history/purchase-history.component';
import {ProgressCardComponent} from './components/student/progress-card/progress-card.component';
import {ComingLessonComponent} from './components/student/coming-lesson/coming-lesson.component';
import {ReserveLessonComponent} from './components/student/reserve-lesson/reserve-lesson.component';
import {StudentMainCalendarComponent} from './components/student/student-main-calendar/student-main-calendar.component';
import {CalendarTeacherListComponent} from './components/student/calendar-teacher-list/calendar-teacher-list.component';
import {WeekCalendarComponent} from './components/week-calendar/week-calendar.component';
import {TeachersListComponent} from './components/student/teachers-list/teachers-list.component';
import {
  StudentCourseLessonDetailsPageComponent
} from './pages/student-course-lesson-details-page/student-course-lesson-details-page.component';
import {TeacherDashboardPageComponent} from './pages/teacher/teacher-dashboard-page/teacher-dashboard-page.component';
import {TeacherLessonsComponent} from './components/teacher/teacher-lessons/teacher-lessons.component';
import {
  TeacherDashboardLessonsDetailsPageComponent
} from './pages/teacher/teacher-dashboard-lessons-details-page/teacher-dashboard-lessons-details-page.component';
import {
  TeacherLessonDetailsHeaderComponent
} from './components/teacher/teacher-lesson-details-header/teacher-lesson-details-header.component';
import {
  LessonCommitedProgressComponent
} from './components/teacher/lesson-commited-progress/lesson-commited-progress.component';
import {LessonHistoryComponent} from './components/teacher/lesson-history/lesson-history.component';
import {TeacherLessonsPageComponent} from './pages/teacher/teacher-lessons-page/teacher-lessons-page.component';
import {
  TeacherLessonDetailsPageComponent
} from './pages/teacher/teacher-lesson-details-page/teacher-lesson-details-page.component';
import {
  TeacherLessonDetailsComponent
} from './components/teacher/teacher-lesson-details/teacher-lesson-details.component';
import {TeacherMainCalendarComponent} from './components/teacher/teacher-main-calendar/teacher-main-calendar.component';
import {
  TeacherQuickLessonCounterComponent
} from './components/teacher/teacher-quick-lesson-counter/teacher-quick-lesson-counter.component';
import {ComingLessonListComponent} from './components/student/coming-lesson-list/coming-lesson-list.component';
import {ShoppingCartComponent} from './components/shopping-cart/shopping-cart/shopping-cart.component';
import {
  ShoppingCartProductComponent
} from './components/shopping-cart/shopping-cart-product/shopping-cart-product.component';
import {
  ShoppingCartHeaderComponent
} from './components/shopping-cart/shopping-cart-header/shopping-cart-header.component';
import {OrderFailComponent} from './pages/order-fail/order-fail.component';
import {OrderSuccessComponent} from './pages/order-success/order-success.component';
import { ProgressPageComponent } from './pages/student/progress-page/progress-page.component';
import { ReserveLessonPageComponent } from './pages/student/reserve-lesson-page/reserve-lesson-page.component';
import { CreditsPageComponent } from './pages/credits-page/credits-page.component';
import { LessonCardComponent } from './components/lesson-card/lesson-card.component';
import { TeacherListPageComponent } from './pages/teacher-list-page/teacher-list-page.component';
import {
  EnglishBusinessCourseComponent
} from "./components/courses/english-business-course/english-business-course.component";
import {EnglishCourseComponent} from "./components/courses/english-course/english-course.component";
import {SpanishCourseComponent} from "./components/courses/spanish-course/spanish-course.component";
import { CoursesPageComponent } from './pages/courses-page/courses-page.component';
import { ProgressCardShortComponent } from './components/student/progress-card-short/progress-card-short.component';
import { ProgressCardListComponent } from './components/student/progress-card-list/progress-card-list.component';
import { StudentCreditsShortComponent } from './components/student/student-credits-short/student-credits-short.component';
import { StudentLessonsCalendarComponent } from './components/student/student-lessons-calendar/student-lessons-calendar.component';
import { CalendarTeacherFilterModalComponent } from './components/student/calendar-teacher-filter-modal/calendar-teacher-filter-modal.component';


@NgModule({
  declarations: [
    LessonsPageComponent,
    StudentLessonsComponent,
    StudentLessonDetailsPageComponent,
    StudentLessonDetailsComponent,
    CoursesListPageComponent,
    CourseCardComponent,
    CourseProductsPageComponent,
    SpanishProductsComponent,
    EnglishProductsComponent,
    EnglishBusinessProductsComponent,
    ProductBoxComponent,
    CourseDetailsComponent,
    CourseDetailsPageComponent,
    ExerciseSetHostComponent,
    ChapterProgressCircleComponent,
    ChapterDetailsComponent,
    StudentCreditsComponent,
    TransactionsComponent,
    PurchaseHistoryComponent,
    ProgressCardComponent,
    ComingLessonComponent,
    ReserveLessonComponent,
    StudentMainCalendarComponent,
    CalendarTeacherListComponent,
    WeekCalendarComponent,
    TeachersListComponent,
    StudentCourseLessonDetailsPageComponent,
    TeacherDashboardPageComponent,
    TeacherLessonsComponent,
    TeacherDashboardLessonsDetailsPageComponent,
    TeacherLessonDetailsHeaderComponent,
    TeacherLessonDetailsComponent,
    LessonCommitedProgressComponent,
    LessonHistoryComponent,
    TeacherLessonsPageComponent,
    TeacherLessonDetailsPageComponent,
    TeacherMainCalendarComponent,
    TeacherQuickLessonCounterComponent,
    ComingLessonListComponent,
    ShoppingCartComponent,
    ShoppingCartProductComponent,
    ShoppingCartHeaderComponent,
    OrderFailComponent,
    OrderSuccessComponent,
    ProgressPageComponent,
    ReserveLessonPageComponent,
    CreditsPageComponent,
    LessonCardComponent,
    TeacherListPageComponent,
    EnglishBusinessCourseComponent,
    EnglishCourseComponent,
    SpanishCourseComponent,
    CoursesPageComponent,
    ProgressCardShortComponent,
    ProgressCardListComponent,
    StudentCreditsShortComponent,
    StudentLessonsCalendarComponent,
    CalendarTeacherFilterModalComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    UtilsModule,
    FormsModule,
    CspaModule
  ],
    exports: [
        LessonCardComponent,
        ProgressCardShortComponent,
        ProgressCardComponent,
        ProgressCardListComponent,
        StudentCreditsShortComponent,
        StudentMainCalendarComponent,
        StudentLessonsCalendarComponent
    ]
})
export class ColModule { }
