<div class="mx-xl-5 px-xl-5">
  <div
    class="row row-cols-3 row-cols-md-4 text-dark mb-5 mb-md-0 g-2 g-md-2 g-xl-1 align-items-stretch mx-lg-5 px-xl-5">
    <div
      *ngFor="let pictureItem of pictures"
      class="col px-1 p-lg-2"
    >
      <picture-card
        (cardClick)="onCardClick(pictureItem)"
        [data]="pictureItem"
      ></picture-card>
    </div>
  </div>
</div>
