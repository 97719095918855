import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile,} from "../model/rest-model";
import {SimpleScheduleEvents} from "../model/rest-model-v2";

@Injectable({
  providedIn: 'root'
})
export class AppEventsService {
  public scheduleReservedSubject = new Subject<SimpleScheduleEvents>();
  public lessonsUpdated = new Subject<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>();
  public creditsUpdate = new  Subject<any>();
  private _credits: { [lang: string]: BehaviorSubject<number> } = {};

  constructor() { }

  public credits(lang: string): BehaviorSubject<number> {
    let result = this._credits[lang];
    if (!result) {
      result = new BehaviorSubject<number>(0);
      this._credits[lang] = result;
    }
    return result;
  }
}
