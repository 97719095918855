import {MobileAuthorizationApiInterface, UserRole} from "../authorization.api";
import {map, Observable, of, tap} from "rxjs";
import {ContactUs, PasswordChangeRequest} from "../../../../books/model/book-model";
import {LogsService} from "../../../../utils/services/logs.service";

/**
 * This is a base class for mobile authorization service implementation.
 * It contains methods which are unsupported on mobiles.
 * (Unsupported because their functionalities are implemented in native way)
 */
export abstract class MobileAuthBaseService implements MobileAuthorizationApiInterface {
  protected constructor(protected logger: LogsService) {
  }

  getSelfSchoolId(): Observable<number> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getSubjectFromClaims(token)),
      map( subject => {
        if (subject[0]=='school') {
          this.logger.log(`schoolId=${Number(subject[1])}`)
          return Number(subject[1])
        }
        throw new Error(`invalid token role for searching school id: ${subject}`);
      })
    )
  }

  getSelfStudentId(): Observable<number> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getSubjectFromClaims(token)),
      map( subject => {
        if (subject[0]=='school' && subject[2]=='student') {
          this.logger.log(`studentId=${Number(subject[3])}`)
          return Number(subject[3])
        }
        throw new Error(`invalid token role for searching student id: ${subject}`);
      })
    )
  }

  getSelfTeacherId(): Observable<number> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getSubjectFromClaims(token)),
      map( subject => {
        if (subject[0]=='school' && subject[2]=='teacher') {
          this.logger.log(`teacherId=${Number(subject[3])}`)
          return Number(subject[3])
        }
        throw new Error(`invalid token role for searching teacher id: ${subject}`);
      })
    )
  }

  getUserRole(): Observable<UserRole> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getSubjectFromClaims(token)),
      map(subject => this.mapSubjectToRole(subject)),
      tap(role => this.logger.log(`recognized role: ${role}`))
    );
  }

  isNativeAuthorization(): boolean {
    return true;
  }

  isTokenValid(): boolean {
    return true;
  }

  abstract changePassword(request: PasswordChangeRequest, lang?: string): Observable<void>;

  abstract contactUs(request: ContactUs): Observable<void>;

  abstract getUserName(): Observable<string>;

  abstract logout();

  abstract ssoLogin(): Observable<any>;

  abstract getAccessTokenObservable(): Observable<string>;

  abstract getDeviceId(): Observable<string>;

  closeApp(): Observable<any> {
    return of(null);
  }

  hideKeyboard(): Observable<any> {
    return of(null);
  }

  abstract listenOnBackButton(): Observable<any>;

  abstract listenOnImeButton(): Observable<any>;

  abstract getNativeApiVersion(): Observable<string>;

  private getTokenClaims(token: string): string[] {
    this.logger.log(`got access token: ${token}`);
    const tokenContent = token.split("\.")[1];
    this.logger.log(`token content part ${tokenContent}`);
    const tokenBody = atob(tokenContent);
    this.logger.log(`token body decoded ${tokenBody}`)
    return  JSON.parse(tokenBody);
  }

  private getSubjectFromClaims(token: string): string[] {
    const claims = this.getTokenClaims(token)
    return claims["sub"].split("/");
  }

  private getUserEmailFromClaims(token: string): string {
    const claims = this.getTokenClaims(token)
    return claims["userEmail"];
  }

  private mapSubjectToRole(subject: string[]): UserRole {
    if (subject[2] === 'student')  return UserRole.Student;
    if (subject[2] === 'teacher')  return UserRole.Teacher;
    if (subject[2] === 'manager')  return UserRole.Manager;
    if (subject[0] === 'user') return UserRole.Unaffiliated;
    return UserRole.Unknown;
  }

  getSubject(): Observable<string> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getSubjectFromClaims(token)),
      map( subject => {
        if (subject != null) {
          return subject.join()
        }
        throw new Error(`cannot get subject from token`);
      })
    )
  }

  getUserEmail(): Observable<string> {
    return this.getAccessTokenObservable().pipe(
      map(token => this.getUserEmailFromClaims(token)),
      map( email => {
        if (email != null) {
          return email
        }
        throw new Error(`cannot get user email from token`);
      })
    )
  }

}
