<div class="row justify-content-center py-4 my-2">
  <div *ngIf="hasImage()" class="col-12 col-md-4">
    <div class="question-image p-2">
      <img [src]="getImageSrc()" class="card-img-top pe-none" />
    </div>
  </div>
  <div class="question d-block col">
    <div class="text-center mt-4 mt-md-0">
      <span *ngFor="let token of questionTokens">
        <span
          (click)="unassignAnswer(token)"
          class="cursor-pointer badge fw-normal rounded-pill bg-primary fs-5"
          *ngIf="token.isPlaceholder() && getAnswer(token)"
          role="button"
        >
          {{ getAnswer(token).content }}
        </span>
        <span
          class="badge rounded-pill bg-secondary fs-5"
          *ngIf="token.isPlaceholder() && !getAnswer(token)"
          role="button"
          >...</span
        >
        <span
          class="fs-5"
          *ngIf="!token.isPlaceholder()"
          [innerHtml]="getContent(token)"
        >
        </span>
      </span>
      <div class="mt-2">
        <button
          type="button"
          class="btn btn-secondary rounded-pill mb-2 me-2 px-2 px-md-4"
          (click)="assignAnswer(token)"
          *ngFor="let token of questionAnswers"
        >
          {{ getContent(token) }}
        </button>
      </div>
    </div>
  </div>
</div>
