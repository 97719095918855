import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-lesson-details-page',
  templateUrl: './student-lesson-details-page.component.html',
  styleUrls: ['./student-lesson-details-page.component.scss'],
})
export class StudentLessonDetailsPageComponent implements OnInit {
  studentId: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params) => (this.studentId = +params.get('studentId'))
    );
  }
}
