<app-shopping-cart></app-shopping-cart>
<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <button
          type="button"
          routerLink="../../../"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3">{{
            "NAVIGATION.COURSES" | translate
          }}</span>
        </button>
        <div class="text-center">
          <h4>{{ getCourseName() | translate }}</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../../">
                  {{ "NAVIGATION.COURSES" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getCourseName() | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <app-course-details [langCode]="langCode" [studentId]="studentId"></app-course-details>
  </div>
</div>
