import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-development',
  templateUrl: './under-development.component.html',
  styleUrls: ['./under-development.component.scss']
})
export class UnderDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
