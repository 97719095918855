import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of, tap } from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ApiTeacherProfile } from '../../../model/rest-model';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import {LogsService} from "../../../../utils/services/logs.service";

@Component({
  selector: 'app-teachers-list',
  templateUrl: './teachers-list.component.html',
  styleUrls: ['./teachers-list.component.scss'],
})
export class TeachersListComponent implements OnInit {
  _langCode: string;
  _studentId: number;
  contextTeachers: ApiTeacherProfile[];
  selectedTeacher: ApiTeacherProfile;

  @ViewChild('teacherDetailsModal', { static: true })
  teacherDetailsModal: ModalComponent;
  @Input()
  set langCode(langCode: string) {
    this._langCode = langCode;
    this.loadTeachers().subscribe();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadTeachers().subscribe();
  }
  constructor(
    private spinner: SpinnerService,
    private studentRest: StudentRestServiceImpl,
    private sanitizer: DomSanitizer,
    private logger: LogsService
  ) {}

  ngOnInit(): void {}

  loadTeachers(): Observable<ApiTeacherProfile[] | null> {
    this.logger.log(`loading teachers for studentId=${this._studentId} and language=${this._langCode}`)
    if (this._langCode == null || !this._studentId) return of(null);
    return this.spinner.trace<ApiTeacherProfile[]>(
      this.studentRest
        .getTeachers(this._studentId)
        .pipe(
          tap(
            (teachers) =>
              (this.contextTeachers = teachers?.filter((teacher) =>
                teacher.competences.some(
                  (c) => c.product.code === this._langCode || this._langCode == ''
                )
              ))
          )
        )
    );
  }

  getName(teacherProfile: ApiTeacherProfile) {
    return PersonUtils.getPersonName(teacherProfile.teacher.person);
  }

  getPhoto(teacherProfile: ApiTeacherProfile) {
    return PersonUtils.getPersonProfilePhoto(teacherProfile.teacher.person);
  }
  getInitials(teacherProfile: ApiTeacherProfile) {
    return PersonUtils.getInitials(teacherProfile.teacher.person);
  }

  hasImage(teacherProfile: ApiTeacherProfile) {
    return !!teacherProfile.teacher.person.personalProfile.profilePhoto;
  }

  showTeacherDetails(teacherProfile: ApiTeacherProfile) {
    this.selectedTeacher = teacherProfile;
    console.log(this.selectedTeacher);
    this.teacherDetailsModal.show();
  }

  getCompetence() {
    return this.selectedTeacher.competences[0];
  }

  hasSafeVideoUrl() {
    const videoUrl = this.getCompetence().videoUrl;
    return videoUrl && videoUrl?.startsWith('https');
  }

  getVideoUrl() {
    const videoUrl = this.getCompetence().videoUrl.replace(
      'watch?v=',
      'embed/'
    );
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }
}
