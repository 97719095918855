<div *ngIf="exerciseType">
  <div *ngIf="isExamType()">
    <p>{{ "EXAM.QUESTION_SET_DESCRIPTION.PART_1" | translate}}</p>
    <div class="d-flex mb-3">
      <div class="d-flex align-items-center me-4">
        <div class="exam-square rounded-2 align-content-center text-center text-white me-3">0</div>
        <span>{{ "EXAM.QUESTION_SET_DESCRIPTION.EXAMPLE_EXAM" | translate}}</span>
      </div>
      <div class="d-flex align-items-center">
        <div class="exam-square rounded-2 align-content-center text-center text-white me-3">1</div>
        <span>{{ "EXAM.QUESTION_SET_DESCRIPTION.REAL_EXAM" | translate}}</span>
      </div>
    </div>
    <p>{{ "EXAM.QUESTION_SET_DESCRIPTION.PART_2" | translate}}</p>
  </div>
  <h4 class="modal-title mb-2">
    {{ "EXERCISES.INSTRUCTIONS.TITLE" | translate}}
  </h4>
  <span class="instruction-icon" [innerHTML]="htmlContent"></span>
  <ng-container *ngIf="this.exerciseName == 'COR'">
    <span>Are you going </span>
    <span id="animatedText">to</span>
    <span> home now?</span>
  </ng-container>
  <div *ngIf="progressBarType == 'PROGRESS_BAR_DEFAULT'" class="my-4">
    <span>
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DEFAULT.INSTR_PROGRESS" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
    </div>
    <span class="d-flex justify-content-center small">
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DEFAULT.PROGRESS_0" | translate}} 
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-50"></div>
    </div>
    <span class="d-flex justify-content-center small">
       {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DEFAULT.PROGRESS_50" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-100"></div>
    </div>
    <span class="d-flex justify-content-center small">
       {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DEFAULT.PROGRESS_100" | translate}} 
    </span>
  </div>
  <div *ngIf="progressBarType == 'PROGRESS_BAR_DICTATION'" class="my-4">
    <span>
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DICTATION.INSTR_PROGRESS" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
    </div>
    <span class="d-flex justify-content-center small">
       {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DICTATION.PROGRESS_0" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-50"></div>
    </div>
    <span class="d-flex justify-content-center small">
       {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DICTATION.PROGRESS_50" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-100"></div>
    </div>
    <span class="d-flex justify-content-center small">
       {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DICTATION.PROGRESS_100" | translate}} 
    </span>
    <br/>
    <span class="d-flex justify-content-center">
      <span> {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_DICTATION.OPTIONAL" | translate}} </span>
    </span>
  </div>
  <div *ngIf="progressBarType == 'PROGRESS_BAR_EXAM'" class="my-4">
    <p>
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_EXAM.INSTR_PROGRESS" | translate}}
    </p>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
    </div>
    <span class="d-flex justify-content-center small">
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_EXAM.PROGRESS_0" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-50"></div>
    </div>
    <span class="d-flex justify-content-center small">
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_EXAM.PROGRESS_50" | translate}}
    </span>
    <div class="progress bg-white rounded-pill mt-2 border border-primary" role="progressbar">
      <div class="progress-bar w-100"></div>
    </div>
    <span class="d-flex justify-content-center small">
      {{ "EXERCISES.INSTRUCTIONS.PROGRESS_BAR_EXAM.PROGRESS_100" | translate}}
    </span>
  </div>
</div>
<ng-container *ngIf="withSettings">
  <h4>
    {{ "EXERCISES.OPTIONS.SETTINGS" | translate}}
  </h4>
  <div class="d-flex align-content-center" *ngIf="theme !== 'light'" >
    <span class="text-white p-2">{{ 'NAVIGATION.LIGHT_MODE' | translate }}</span>
    <button type="button" class="btn d-flex align-items-center lh-1 text-decoration-none p-2" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('light')">
      <app-icon class="icon-white" [name]="'light-mode'"></app-icon>
    </button>
  </div>
  <div class="d-flex align-content-center" *ngIf="theme === 'light'">
    <span class="text-black p-2">{{ 'NAVIGATION.DARK_MODE' | translate }}</span>
    <button type="button" class="btn d-flex align-items-center lh-1 text-decoration-none p-2" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('dark')">
      <app-icon [name]="'dark-mode'"></app-icon>
    </button>
  </div>
  <div class="d-flex align-content-center">
    <span class="p-2">
    Font size
   </span>
    <button class="btn" [disabled]="fontSize === 1" (click)="changeFontSize(-1)">
      <app-icon class="" name="minus-icon"></app-icon>
    </button>
    <button class="btn" [disabled]="fontSize === 4" (click)="changeFontSize(1)">
      <app-icon class="" name="plus-icon"></app-icon>
    </button>
  </div>
</ng-container>
