import { Injectable } from "@angular/core";
import { catchError, map, mergeMap, Observable, of, range, switchMap, tap, throwError, take, timer, delay, share, EMPTY, Subject } from "rxjs";
import { Authorization } from "../../../../auth_profile/model/prototypes";
import { AuthorizationServiceProvider } from "../../../../auth_profile/services/authorization-service.provider";
import { OwnershipLocalState, ContactUs, PasswordChangeRequest } from "../../../model/book-model";
import { AppRestService } from "./app-rest.service";
import { LogsService } from "../../../../utils/services/logs.service";
import {BooksNativeServiceApi} from "../books-native-api";

@Injectable({
    providedIn: 'root'
})
export class WebNativeServiceApi implements BooksNativeServiceApi {
    modifiedOwnershipStates: OwnershipLocalState[] = [];
    refreshingSubject = new Subject<Observable<void>>();
    constructor(private appRestService: AppRestService,
        private authService: AuthorizationServiceProvider,
        private logger: LogsService) {}
    isNativeBasedSecurity(): boolean {
        return false
    }
    getImageUrl(productId: number, releaseId: number): Observable<string> {
        return this.appRestService.getImageUrl(releaseId)
    }
    getProducts(): Observable<OwnershipLocalState[]> {
        return this.appRestService.getProducts().pipe(
            map(ownerships => ownerships.map(ownership => {
                const OLS = new OwnershipLocalState()
                OLS.ownership = ownership
                return OLS }))
        )
    }
    getProductById(productId: number): Observable<OwnershipLocalState> {
        return this.appRestService.getProductById(productId).pipe(
            map(ownership => {
                const OLS = new OwnershipLocalState()
                OLS.ownership = ownership
                return OLS
            })
        )
    }
    login(auth: Authorization): Observable<any> {
        return this.getDeviceId().pipe(
            switchMap( deviceId => this.appRestService.login(auth, deviceId)),
            tap( token => this.authService.getEmulatedAuthService().subscribe(service => service.loginWithPlainToken(token))),
            catchError( httpError => throwError(() => new Error(httpError.message)))
        );
    }
    register(productId: number, releaseId: number): Observable<number> {
        return EMPTY
    }
    download(productId: number, releaseId: number): Observable<number> {
        this.logger.log(`downloading ${productId}`);

        return this.ensureExisting(productId).pipe(
            take(1),
            tap( ls => ls.downloaded = new Date().getTime()),
            switchMap( () => this.progressSim())
        );
    }
    open(productId: number, releaseId: number): Observable<void> {
        const existingState = this.modifiedOwnershipStates.find( o => o.ownership.eBook.id === productId);
        if (!existingState) {
            return throwError(() => new Error('not existing on the device'));
        }
        this.logger.log(`open ${productId}`);
        return EMPTY;
    }
    progressSim(withError: boolean = false): Observable<number> {
        return range(0, 10).pipe(
            mergeMap( n => {
                if (!withError || n < 9) {
                    return of(n / 10).pipe(delay(n * 200));
                } else {
                    return timer((n - 1) * 200).pipe(
                        mergeMap(() => throwError(new Error('error message')).pipe(delay((n - 1) * 200)))
                        );
                }
            })
        );
    }
    private ensureExisting(productId: number): Observable<OwnershipLocalState> {
        const existingState = this.modifiedOwnershipStates.find( o => o.ownership.eBook.id === productId);
        if (existingState) {
            return of(existingState);
        }
        return this.getProductById(productId).pipe(
            tap( ls => this.modifiedOwnershipStates.push(ls))
        );
    }
    downloadAudio(productId: number): Observable<number> {
        return this.ensureExisting(productId).pipe(
            tap( ls => ls.audioDownloaded = new Date().getTime()),
            switchMap( () => this.progressSim(true))
        );
    }
    deleteAudio(productId: number): Observable<any> {
        return this.ensureExisting(productId).pipe(
            tap( ls => ls.audioDownloaded = null),
            map( () => 1)
        );
    }
    downloadDict(productId: number): Observable<number> {
        return this.ensureExisting(productId).pipe(
            tap( ls => ls.dictDownloaded = new Date().getTime()),
            switchMap( () => this.progressSim(true))
        );
    }
    deleteDict(productId: number): Observable<any> {
        return this.ensureExisting(productId).pipe(
            tap( ls => ls.dictDownloaded = null),
            map( () => 1)
        );
    }
    getDeviceId(): Observable<string> {
        const deviceId = localStorage.getItem('X-Device-Id')
        if(!deviceId)
            this.storeDeviceId('none')
        return of('none')
    }
    storeDeviceId(deviceId: string) {
        localStorage.setItem('X-Device-Id', deviceId)
    }
    listenForDataUpdates(): Observable<void> {
        return EMPTY
    }
    downloadingAudioProgress(productId: number, releaseId: number): Observable<number> {
        return of(0)
    }
    downloadingDictationProgress(productId: number, releaseId: number): Observable<number> {
        return of(0)
    }
    close(): Observable<void> {
        return EMPTY
    }
    getUserName(): Observable<string> {
        return of("Static User Name (very_long_user_email.long_server_name.long_domain_name");
    }
    subscribeForRefreshingEvents(): Observable<Observable<void>> {
        return this.refreshingSubject.asObservable();
    }
    refreshData(frequencyMs:number): Observable<void> {
        const result = of(null).pipe(
            share<void>()
        )
        this.refreshingSubject.next(result)
        return result
        }
    openCspa(): Observable<void> {
        this.logger.log("opening cspa");
        return EMPTY;
    }
    doLogout(): Observable<void> {
        this.logger.log("logging out");
        return EMPTY;
    }

    changePassword(request: PasswordChangeRequest, lang?: string): Observable<void> {
        return this.appRestService.changePassword(request, lang);
    }

    contactUs(request: ContactUs): Observable<void> {
        return this.appRestService.contactUs(request)
    }

  ssoLogin(): Observable<any> {
    return throwError(() => new Error("unsupported operation"))
  }
}
