<div class="card mb-4 mb-md-5">
  <div class="card-header">
    <h6 class="mb-0">{{ 'COL.TEACHER.REPORT.TITLE' | translate }}</h6>
  </div>
  <div class="card-body">
    <form>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select id="productCode" name="productCode" (change)="updateReport()" class="form-select" [(ngModel)]="productCode">
              <option *ngFor="let langCode of langCodes" [value]="langCode">{{getLangName(langCode) | translate }}</option>
            </select>
            <label for="productCode">{{ 'COL.TEACHER.REPORT.PRODUCT_LABEL' | translate }}</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-4">
          <div class="form-floating">
            <select id="preselectedPeriod" (change)="updateReport()" name="preselectedPeriod" class="form-select" [(ngModel)]="preselectedPeriod">
              <option value="0">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.CURRENT' | translate }}</option>
              <option value="-1">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.PREVIOUS' | translate }}</option>
              <option value="-2">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.2_AGO' | translate }}</option>
              <option value="-3">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.3_AGO' | translate }}</option>
              <option value="-4">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.4_AGO' | translate }}</option>
              <option value="-5">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.5_AGO' | translate }}</option>
              <option value="-6">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.6_AGO' | translate }}</option>
              <option value="-7">{{ 'COL.TEACHER.REPORT.MONTH_PERIOD.7_AGO' | translate }}</option>
            </select>
            <label for="preselectedPeriod">{{ 'COL.TEACHER.REPORT.DATE_LABEL' | translate }}</label>
          </div>
        </div>
      </div>
    </form>
    <div class="table-responsive">
    <table class="table-sm white-text w-100">
      <thead>
        <tr class="table-row table-row-head">
          <th class="small">{{ 'COL.TEACHER.REPORT.TABLE_HEAD.COMPLETE' | translate }}</th>
          <th class="small">{{ 'COL.TEACHER.REPORT.TABLE_HEAD.STUDENT_NO_SHOW' | translate }}</th>
          <th class="small">{{ 'COL.TEACHER.REPORT.TABLE_HEAD.INVALIDATED_BY_STUDENT' | translate }}</th>
          <th class="small">{{ 'COL.TEACHER.REPORT.TABLE_HEAD.INVALIDATED_BY_TEACHER' | translate }}</th>
          <th class="small">{{ 'COL.TEACHER.REPORT.TABLE_HEAD.CANCELLED' | translate }}</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-row" *ngIf="reportData">
          <td class="small">{{reportData.complete}}</td>
          <td class="small">{{reportData.studentNoShow}}</td>
          <td class="small">{{reportData.invalidatedByStudent}}</td>
          <td class="small">{{reportData.invalidatedByTeacher}}</td>
          <td class="small">{{reportData.cancelled}}</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>
