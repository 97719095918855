<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
          routerLink="../../"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3">
            {{ "GROUPS.GROUP" | translate }} {{ schedule?.template.details.name }}
          </span>
        </button>
        <div class="text-center">
          <h4>{{ "NAVIGATION.GROUPS" | translate }}</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../../../">
                  {{ "NAVIGATION.GROUPS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../">
                  {{ "GROUPS.GROUP" | translate }} {{ schedule?.template.details.name }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getScheduleName() || "Room not created" }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <section *ngIf="!room">
      <div class="text-center">
        <p class="fs-4 text-muted mb-1">
          {{ "LESSONS.SCHEDULE_DETAILS.CONTENT_1" | translate }}
        </p>
        <p class="fs-2 mb-1">{{ schedule.schedule.details.startDate | date: 'dd MMM yyyy, HH:mm' }}</p>
        <p class="fs-4 text-muted mb-5">
          {{ "LESSONS.SCHEDULE_DETAILS.CONTENT_2" | translate }}
        </p>
      </div>
      <div class="card mb-5">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0">{{ 'LESSONS.PROGRESS.PROGRESS_HISTORY' | translate}} <span class="small text-muted">({{ progressHistory?.totalElements }})</span></h5>
            </div>
            <div class="col d-inline-flex">
              <div class="col d-flex justify-content-end">
                <button (click)="groupNotes.show()" type="button" class="btn btn-primary rounded-pill py-2">
                {{ "GROUPS.GROUP_NOTES" | translate }}
                </button>
              </div>
              <div class="col-auto ms-auto">
              <paginator
                [data]="progressHistory"
                [pageable]="progressPageable"
                (newPage)="loadTemplateProgressHistory($event)"
              ></paginator>
            </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <app-group-progress-history [progressHistory]="progressHistory"></app-group-progress-history>
        </div>
      </div>
    </section>
    <teacher-room-details *ngIf="room" [room]="room"></teacher-room-details>
  </div>
</div>

<app-modal #groupNotes>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h6 class="modal-title"> {{ "GROUPS.GROUP_NOTES" | translate }} </h6>
      <button
        type="button"
        class="btn-close"
        (click)="groupNotes.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      {{ schedule.template?.details?.notes  || "GROUPS.NO_GROUP_NOTES" | translate}}
    </div>
    <div class="modal-footer text-right border-0 rounded-bottom">
      <button type="button" class="btn btn-primary rounded-pill" (click)="groupNotes.hide()">
        {{ "BUTTON.CLOSE" | translate }}
      </button>
    </div>
  </div>
</app-modal>