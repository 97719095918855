<form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
  <section class="card">
    <div class="card-header text-start mb-3">
      <h4 class="mb-0">{{ "PROFILE.CHANGE_PASSWORD.TITLE" | translate }}</h4>
      <span class="xsmall text-muted">{{
        "PROFILE.CHANGE_PASSWORD.SUBTITLE" | translate
      }}</span>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-4 col-12 col-md-6">
          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Placeholder Text"
              #password="ngModel"
              [(ngModel)]="formData.password"
              name="password"
              required
              minlength="7"
              [disabled]="isBusy()"
              [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
              autocomplete="new-password"
            />
            <label for="password">{{
              "PROFILE.CHANGE_PASSWORD.NEW" | translate
            }}</label>
            <div
              *ngIf="f.submitted && password.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="password.errors?.['required']"
                ><app-icon class="sm-icon me-2" name="warning"></app-icon
                >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
              >
              <span *ngIf="password.errors?.['minlength']"
                ><app-icon class="sm-icon me-2" name="warning"></app-icon
                >{{ "FORM_ERROR.PASSWORD_SHORT" | translate }}</span
              >
            </div>
          </div>
        </div>
        <div class="mb-5 col-12 col-md-6">
          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              placeholder="Placeholder Text"
              #confirmPassword="ngModel"
              [(ngModel)]="formData.passwordVerify"
              name="confirmPassword"
              [ngClass]="{
                'is-invalid':
                  f.submitted &&
                  (confirmPassword.invalid ||
                    formData.password !== formData.passwordVerify)
              }"
              required
              minlength="7"
              [disabled]="isBusy()"
            />
            <label for="confirmPassword">{{
              "PROFILE.CHANGE_PASSWORD.CONFIRM" | translate
            }}</label>
            <div
              *ngIf="f.submitted && confirmPassword.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="confirmPassword.errors?.['required']"
                ><app-icon class="sm-icon me-2" name="warning"></app-icon
                >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
              >
              <span *ngIf="confirmPassword.errors?.['minlength']"
                ><app-icon class="sm-icon me-2" name="warning"></app-icon
                >{{ "FORM_ERROR.PASSWORD_SHORT" | translate }}</span
              >
            </div>
            <div
              *ngIf="
                f.submitted && formData.password !== formData.passwordVerify
              "
              class="invalid-feedback"
            >
              <span
                ><app-icon class="sm-icon me-2" name="warning"></app-icon
                >{{ "FORM_ERROR.PASSWORDS_DIFFERENT" | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto">
          <button
            type="submit"
            [disabled]="isBusy()"
            class="btn btn-primary btn-lg px-3 fs-6 text-uppercase"
          >
            {{ getButtonContent() | translate }}
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="busy"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </section>
</form>
