<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="row text-center mb-md-4">
      <content class="mb-md-4">
        <div class="row">
          <button
            type="button"
            class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
            routerLink="../../"
          >
            <app-icon name="arrow-back"></app-icon>
            <span class="d-none d-lg-block ms-3">
            {{ "NAVIGATION.GROUPS" | translate }}
          </span>
          </button>
          <div class="text-center">
            <h4>{{ "NAVIGATION.GROUPS" | translate }}</h4>
          </div>
        </div>
      </content>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="../../">
                {{ "NAVIGATION.GROUPS" | translate }}
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "GROUPS.GROUP" | translate }} {{ group?.details?.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
<!--    SCHEDULES-->
    <div class="mb-4">
      <div class="row mb-3" *ngIf="group">
        <div class="col-auto">
          <span class="h5 my-md-auto">{{ 'LESSONS.WEEKLY_PLANNED_LESSONS' | translate }}</span>
        </div>
        <div class="col-auto ms-auto">
          <div class="btn-group h-100" role="group">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-start-3 btn-focus-none"
              (click)="getPreviousWeek()"
            >
              <app-icon name="angle-left" class="icon-blue"></app-icon>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-focus-none"
              (click)="goToToday()"
            >
              <span class="text-muted">{{ getDate() }}</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary rounded-end-3 btn-focus-none"
              (click)="getNextWeek()"
            >
              <app-icon name="angle-right" class="icon-blue"></app-icon>
            </button>
          </div>
        </div>
      </div>
      <schedules-list [schedulesProvider]="scheduleListsProvider"
                      [dayStart]="dayStart"
                      [dayEnd]="dayEnd"
                      [redirectPathPrefix]="'./schedule'">
      </schedules-list>
    </div>
<!--    PARTICIPANTS-->
    <div class="card mb-5">
      <div class="card-header d-flex align-items-center">
        <div class="col">
          <h5 class="mb-0">{{ "GROUPS.PARTICIPANTS" | translate }} <span class="small text-muted">({{ group?.participants.length }})</span></h5>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button (click)="groupNotes.show()" type="button" class="btn btn-primary rounded-pill py-2">
          {{ "GROUPS.GROUP_NOTES" | translate }}
          </button>
        </div>
      </div>
      <div class="card-body">
        <div *ngIf="group?.participants.length; else noResults" class="table-responsive">
          <table class="table white-text mb-0">
            <thead class="small">
            <tr class="table-row table-row-head">
              <th scope="col">
                {{ "GROUPS.PARTICIPANT_ROLE" | translate }}
              </th>
              <th scope="col">
                {{ "GROUPS.PARTICIPANT_NAME" | translate }}
              </th>
            </tr>
            </thead>
            <tbody class="table-body small">
            <tr
              class="table-row table-row-body align-middle"
              *ngFor="let participant of group.participants"
            >
              <td>{{ getRoleTranslationKey(participant.details.role) | uppercase | translate }}</td>
              <td>{{ participant.details.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noResults>
          <div class="row">
            <p class="text-center fs-5 text-muted m-0">
              {{ "LESSONS.NO_RESULTS" | translate }}
            </p>
          </div>
        </ng-template>
      </div>
    </div>
<!--    PROGRESS HISTORY-->
    <div class="card mb-5">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h5 class="mb-0">{{ "LESSONS.PROGRESS.PROGRESS_HISTORY" | translate }} <span class="small text-muted">({{ progressHistory?.totalElements }})</span></h5>
          </div>
          <div class="col-auto ms-auto">
            <paginator
              [data]="progressHistory"
              [pageable]="progressPageable"
              (newPage)="loadTemplateProgressHistory($event)"
            ></paginator>
          </div>
        </div>
      </div>
      <div class="card-body">
        <app-group-progress-history [progressHistory]="progressHistory"></app-group-progress-history>
      </div>
    </div>
<!--    LESSONS LIST-->
    <div class="card mb-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="mb-0">{{ "GROUPS.LESSON_LIST.TITLE" | translate }} <span class="small text-muted">({{ roomsList.rooms?.totalElements }})</span></h5>
          </div>
          <div class="col-auto ms-auto">
            <paginator
              [data]="roomsList.rooms"
              [pageable]="roomsList.pageable"
              (newPage)="roomsList.loadRooms($event)"
            ></paginator>
          </div>
        </div>
      </div>
      <div class="card-body">
        <rooms-list
          #roomsList
          [roomsProvider]="roomListsProvider"
          [dayStart]="dayStart"
        ></rooms-list>
      </div>
    </div>
  </div>
</div>

<app-modal #groupNotes>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h6 class="modal-title"> {{ "GROUPS.GROUP_NOTES" | translate }} </h6>
      <button
        type="button"
        class="btn-close"
        (click)="groupNotes.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      {{ group?.details?.notes  || "GROUPS.NO_GROUP_NOTES" | translate}}
    </div>
    <div class="modal-footer text-right border-0 rounded-bottom">
      <button type="button" class="btn btn-primary rounded-pill" (click)="groupNotes.hide()">
        {{ "BUTTON.CLOSE" | translate }}
      </button>
    </div>
  </div>
</app-modal>