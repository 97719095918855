import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-reserve-lesson',
  templateUrl: './reserve-lesson.component.html',
  styleUrls: ['./reserve-lesson.component.scss'],
})
export class ReserveLessonComponent implements OnInit {
  private _studentId: number;
  private _lang: string;
  availableTeachersIds: number[];
  filterTeachersIds: number[];
  @Output() scheduleDateEmitter = new EventEmitter<Date>()
  @Output() activationEmitter = new EventEmitter()

  @Input()
  set studentId(value: number) {
    this._studentId = value;
  }
  get studentId() {
    return this._studentId;
  }

  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
  }
  get langCode(): string {
    return this._lang;
  }

  constructor() {}

  ngOnInit(): void {}

  updateTeachersFilter(teachersIds: number[]) {
    this.filterTeachersIds = Array.from(teachersIds);
  }

  updateAvailableTeachers(teachersIds: number[]) {
    this.availableTeachersIds = teachersIds;
  }
}
