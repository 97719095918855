import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoadComponentDirective} from 'src/app/utils/load-component/load-component.directive';
import {LessonType} from '../../../model/calendar';
import {ExerciseSetHostComponent} from '../exercise-set-host/exercise-set-host.component';
import {ProgressCardComponent} from '../progress-card/progress-card.component';
import {PurchaseHistoryComponent} from '../purchase-history/purchase-history.component';
import {ReserveLessonComponent} from '../reserve-lesson/reserve-lesson.component';
import {StudentCreditsComponent} from '../student-credits/student-credits.component';
import {StudentLessonsComponent} from '../student-lessons/student-lessons.component';
import {TeachersListComponent} from '../teachers-list/teachers-list.component';
import {TransactionsComponent} from '../transactions/transactions.component';
import {ComingLessonListComponent} from "../coming-lesson-list/coming-lesson-list.component";
import {CourseProductsPageComponent} from "../../../pages/course-products-page/course-products-page.component";

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit {
  @Input() set langCode(value: string) {
    this._langCode = value;
    if (this.studentId) this.onLoadComponent();
  }
  get langCode() {
    return this._langCode;
  }

  @Input()
  set studentId(value: number) {
    this._studentId = value;
    if (this.langCode) this.onLoadComponent();
  }
  get studentId() {
    return this._studentId;
  }

  chosenSection: string;
  private _studentId: number;
  private _langCode: string;
  @ViewChild(LoadComponentDirective, { static: true })
  loadComponent!: LoadComponentDirective;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.chosenSection = params.get('chosenSection');
      this.onLoadComponent();
    });
  }

  onLoadComponent() {
    this.loadComponent.viewContainerRef.clear();
    let componentRef;
    if (this.chosenSection === 'progress') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        ProgressCardComponent
      );
      componentRef.instance.studentId = this.studentId;
      componentRef.instance.langCode = this.langCode;
      componentRef.instance.isShowReserveProva = false;
    } else if (this.chosenSection === 'credits') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        StudentCreditsComponent
      );
      componentRef.instance.studentId = this.studentId;
      componentRef.instance.langCode = this.langCode;
      let componentRef1 = this.loadComponent.viewContainerRef.createComponent(
        PurchaseHistoryComponent
      );
      componentRef1.instance.studentId = this.studentId;
      componentRef.instance.langCode = this.langCode;
      let componentRef2 = this.loadComponent.viewContainerRef.createComponent(
        TransactionsComponent
      );
      componentRef2.instance.studentId = this.studentId;
      componentRef2.instance.langCode = this.langCode;
    } else if (this.chosenSection === 'lessons') {
      let componentRef3 = this.loadComponent.viewContainerRef.createComponent(
        ComingLessonListComponent
      );
      componentRef3.instance.studentId = this.studentId;
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        StudentLessonsComponent
      );
      componentRef.instance.lessonType = LessonType.Upcoming;
      componentRef.instance.langCode = this.langCode;
      componentRef.instance.studentId = this.studentId;
      let componentRef2 = this.loadComponent.viewContainerRef.createComponent(
        StudentLessonsComponent
      );
      componentRef2.instance.lessonType = LessonType.Past;
      componentRef2.instance.langCode = this.langCode;
      componentRef2.instance.studentId = this.studentId;
    } else if (this.chosenSection === 'exercises') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        ExerciseSetHostComponent
      );
      componentRef.instance.langCode = this.langCode;
    } else if (this.chosenSection === 'product') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        CourseProductsPageComponent
      );
      componentRef.instance.productCode = this.langCode;
    } else if (this.chosenSection === 'reserve') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        ReserveLessonComponent
      );
      componentRef.instance.langCode = this.langCode;
      componentRef.instance.studentId = this.studentId;
    } else if (this.chosenSection === 'teachers') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(
        TeachersListComponent
      );
      componentRef.instance.langCode = this.langCode;
      componentRef.instance.studentId = this.studentId;
    }
  }
}
