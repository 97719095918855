<ng-container *ngIf="hasResults(); else noResults">
  <app-progress-card-short *ngFor="let context of getContextList()"
                           [langCode]="context[0]"
                           [progress]="context[1]"
  ></app-progress-card-short>
</ng-container>
<ng-template #noResults>
  <div class="row justify-content-center justify-content-sm-between gy-3">
    <div class="col-auto d-flex">
      <p class="my-auto">
        {{ "LESSONS.BOOK_FIRST_LESSON" | translate }}
      </p>
    </div>
    <div class="col-auto">
      <button routerLink="../reserve-lesson"
              class="btn btn-primary rounded-pill">
        {{ "BUTTON.BOOK_LESSON" | translate }}
      </button>
    </div>
  </div>
</ng-template>
