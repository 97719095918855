<div class="single-chart" (click)="onClick()">
  <svg class="circular-chart" [ngClass]="getColorClass()" viewBox="0 0 36 36">
    <path
      class="circle-bg"
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
    ></path>
    <path
      class="circle"
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      [attr.stroke-dasharray]="getProgress() + ',100'"
      stroke-dasharray="0,100"
    ></path>
    <text class="percentage" style="fill: var(--white)" x="18" y="20">
      {{ getShortName() }}
    </text>
    <text class="second-line" x="19" y="26">{{ getProgress() }}%</text>
  </svg>
</div>
