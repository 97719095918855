<div class="workspace bg-radial">
  <top-bar [ngClass]="getAccent()" class="bg-white border-none">
    <content class="mb-md-4 flex-column">
      <div class="row mb-md-3 w-100">
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
          routerLink="../../"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3">{{
            "NAVIGATION.EXERCISES" | translate
          }}</span>
        </button>
        <div class="text-center">
          <h4>{{ getExerciseSet() }}</h4>
          <span class="text-muted">{{ getChapter() }}</span>
        </div>
      </div>
      <div class="row d-none d-md-block w-100 mb-3">
        <p class="text-muted text-start xsmall mb-0">
          {{ "EXERCISES.DETAILS.HEADER" | translate }}
        </p>
      </div>
    </content>
    <footer class="d-none d-md-flex">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="../../">{{ "NAVIGATION.EXERCISES" | translate }}</a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="goToExerciseSetsWithFilter()">
              {{ getExerciseSet() }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ getChapter() }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <button
      class="btn btn-secondary btn-circle refresh"
      (click)="refreshData()"
    >
      <app-icon name="refresh"></app-icon>
    </button>
    <div class="questions-sets mb-4" *ngFor="let section of getSections()">
      <question-set
        [section]="section"
        [availabilitiesMap]="getAvailabilities(section)"
        class="mb-3"
      >
      </question-set>
    </div>
  </div>
</div>
