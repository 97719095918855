import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-entry-point',
  templateUrl: './forgot-password-entry-point.component.html',
  styleUrls: ['./forgot-password-entry-point.component.scss']
})
export class ForgotPasswordEntryPointComponent implements OnInit {

  constructor(private router: Router) {
    this.router.navigate(['in-app/forgot-password'])
  }

  ngOnInit(): void {
  }

}
