import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { PersonalProfileFormComponent } from './components/personal-profile-form/personal-profile-form.component';
import { PersonalProfilePasswordChangeComponent } from './components/personal-profile-password-change/personal-profile-password-change.component';
import { StudentProfilePageComponent } from './pages/student-profile-page/student-profile-page.component';
import { OauthComponent } from './pages/oauth/oauth.component';
import { RootComponent } from './pages/root/root.component';
import { FormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { TeacherProfilePageComponent } from './pages/teacher-profile-page/teacher-profile-page.component';

@NgModule({
  declarations: [
    LoginPageComponent,
    PersonalProfileFormComponent,
    PersonalProfilePasswordChangeComponent,
    StudentProfilePageComponent,
    OauthComponent,
    RootComponent,
    ForgotPasswordComponent,
    LogoutPageComponent,
    TeacherProfilePageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    RouterModule
  ],
  exports: [
    LoginPageComponent,
    PersonalProfileFormComponent,
    PersonalProfilePasswordChangeComponent,
    StudentProfilePageComponent,
    OauthComponent,
    RootComponent,
    ForgotPasswordComponent
  ]
})
export class ProfileModule {
  static forRoot(config: any): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [{provide: 'config', useValue: config}]
    };
  }
  environment :any
  constructor(@Inject('config') config)
  {
      if (config) {
      this.environment = config;
    }
  }
}
