import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription, tap} from 'rxjs';
import { Page, Pageable } from 'src/app/utils/pageable';
import { RoomDef } from '../../models/video.model';
import {SpinnerService} from "../../../utils/services/spinner.service";

export interface RoomsListProvider {
  listRooms(pageable: Pageable): Observable<Page<RoomDef>>
}

@Component({
  selector: 'rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.scss']
})
export class RoomsListComponent implements OnInit, OnDestroy {

  rooms: Page<RoomDef>
  pageable: Pageable
  _provider: RoomsListProvider
  private loadSubscription: Subscription

  @Input() dayStart: Date
  @Input()
  set roomsProvider(provider: RoomsListProvider) {
    this._provider = provider;
    this.loadRooms(this.pageable);
  }

  constructor(private spinnerService: SpinnerService) {
    this.pageable = new Pageable(0,5,['createDate,desc'])
   }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.loadSubscription?.unsubscribe()
  }

  loadRooms(pageable: Pageable) {
    this.pageable = pageable
    if (!this._provider) return;
    this.loadSubscription = this.spinnerService.trace(
      this._provider.listRooms(pageable).pipe(
        tap(rooms => this.rooms = rooms)
      )
    ).subscribe()
  }

  getPathToDetails(room: RoomDef) {
    return `./room/${room.uuid}`
  }

  hasRooms() {
    return !!this.getRooms()?.length

  }
  getRooms() {
    return this.rooms?.content
    .sort((a,b) => b.plannedDate - a.plannedDate)
  }

  getRoomName(room: RoomDef) {
    return room.name
  }

  getRoomTeacher(room: RoomDef) {
    return room.participants.find(participant =>
      participant.role === 'Teacher').name
  }

  getRoomStatus(room: RoomDef) {
    switch(room.provisioning.status) {
      case 'COMPLETE': return 'LESSONS.LESSON_STATUS.COMPLETE';
      case 'PLANED': return 'LESSONS.LESSON_STATUS.PLANED';
      case 'PENDING': return 'LESSONS.LESSON_STATUS.PENDING';
      case 'IN_PROGRESS': return 'LESSONS.LESSON_STATUS.IN_PROGRESS';
    }
    return '';
  }

  getRoomAttendees(room: RoomDef) {
    return room.participants.length - 1
  }

  getRoomDuration(room: RoomDef) {
    return (room.provisioning.duration/60).toFixed(0)
  }
}
