import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { ErrorBase } from "../../model/ErrorBase";
import { ToastNotification, ToastNotificationsService } from "./toast-notification.service";
import {LogsService} from "./logs.service";

@Injectable({
    providedIn: 'root'
    })
export class HttpErrorsInterceptorService implements HttpInterceptor {

    constructor(
    private toasts: ToastNotificationsService,
    private logs: LogsService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => {
            let error = err.error as ErrorBase;
            if (err.status === 0 ) {
              error = new ErrorBase("Communication Error");
              error.userMessage = `Connection error for ${req.method} ${req.url}. Check your Internet connection or try again later.`;
              error.errorCode = 'CONNECTION_ERROR';
              error.interactive = false;
              error.developerMessage = 'Request failed. Check the connection or server status.';
            } else if (!error) {
              this.logs.error(req, err)
              error = new ErrorBase("Communication Error");
              error.userMessage = 'Server Error';
              error.developerMessage = err.error;
              error.errorCode = `HTTP:${err.status}`;
            } else if (error && error.interactive) {
              // skip regular error processing
              return throwError(error);
            } else if(err.status == 503) {
              error = new ErrorBase('No internet connection');
              error.errorCode = `HTTP:${err.status}`;
              error.userMessage = "Check your Internet connection or try again later.";
              error.developerMessage = err.error;
            }
            this.toasts.display(new ToastNotification(`${error.message}`,`${error.userMessage}`))
            return throwError(error);
          }));
    }
}
