import { ApiCourseProduct } from '../model/rest-model';

export class LangProductMapper {
  static langCodes = ['en', 'sp', 'en.bs', 'en.ch'];
  static langCodesCspa = ['en', 'sp', 'enbus', 'encfk'];
  static langCodeName = [
    'COL.COURSES.EN',
    'COL.COURSES.SP',
    'COL.COURSES.ENBUS',
    'COL.COURSES.ENCFK',
  ];
  static activeLangCodes = [
    LangProductMapper.langCodes[0],
    LangProductMapper.langCodes[1],
    LangProductMapper.langCodes[2]
  ];

  public static getCourseProducts(): ApiCourseProduct[] {
    const res: ApiCourseProduct[] = [];
    for (let i = 0; i < this.activeLangCodes.length; i++) {
      const prod = new ApiCourseProduct();
      prod.code = this.langCodes[i];
      prod.name = this.langCodeName[i];
      res.push(prod);
    }
    return res;
  }
  public static mapLangCodeToLangName(lang: string): string {
    return this.langCodeName[this.langCodes.indexOf(lang)];
  }

  public static mapLangCodeToLangCodeCspa(lang: string): string {
    return this.langCodesCspa[this.langCodes.indexOf(lang)];
  }
}
