import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, switchMap, take, tap} from "rxjs";
import {
  ApiProduct, Cart, Currencies,
  PriceList,
  Product,
  SimpleOrder,
  SimpleOrderEntry, SimpleOrderEntryMetadata,
  VariantBase,
  VariantPrice
} from "../model/products";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";
import {StudentRestServiceImpl} from "./student/student-rest-impl.service";
import {ToastNotification, ToastNotificationsService} from "../../utils/services/toast-notification.service";

@Injectable({
  providedIn: 'root'
})
export class StudentShoppingCartService {

  orderComplete = new Subject<any>();
  isCartHidden = new BehaviorSubject<boolean>(true)
  private currency = new BehaviorSubject<string>(Currencies.values[0]);
  private cart = new Cart();
  private cartState = new BehaviorSubject<Cart>(this.cart)
  private products: ApiProduct[];
  private locale: string
  private studentId: number
  private initialized = false;

  constructor(
    private studentRest: StudentRestServiceImpl,
    private http: HttpClient,
    private authService: AuthorizationServiceProvider,
    private toastNotificationService: ToastNotificationsService
  ) {

    this.authService.getAuthDetailsService().pipe(
      switchMap(service => service.getSelfStudentId())
    ).subscribe(id => {
      this.studentId = id
      this.initializeCart()
    })
  }

  initializeCart() {
    if (this.initialized) return;
    this.initialized = true;
    if(localStorage.getItem("cart")) {
      this.cart = JSON.parse(localStorage.getItem("cart"))
      this.cartState.next(this.cart)
    }
    if(localStorage.getItem("currency")) {
      this.setCurrency(localStorage.getItem("currency"))
    }
  }

  openCart() {
    this.isCartHidden.next(false)
  }

  closeCart() {
    this.isCartHidden.next(true)
  }

  getCart(): Observable<any> {
    return this.cartState
  }

  setCurrency(currency: string) {
    localStorage.setItem("currency", currency)
    this.currency.next(currency)
    if (!this.locale) {
      this.locale = 'en';
    }
    this.studentRest.getProductsList(this.locale, this.currency.getValue()).subscribe(
      products => {
        this.products = products
        this.updatePrices()
        this.updateTotal()
        this.updateCart()
      })
  }

  getCurrency(): Observable<string> {
    return this.currency.pipe(
      take(1)
    )
  }

  getCurrencySign(): string {
    const currency = this.currency.getValue()
    if(currency === 'eur')
      return '€'
    else if(currency === 'pln')
      return 'zł'
    else if(currency === 'usd')
      return '$'
    else if(currency === 'gbp')
      return '£'
    else if(currency === 'jpy')
      return '¥'
    return ''
  }

  addItem(product: ApiProduct) {
    this.cart.items.find(variant => variant.product.code === product.code)?
      this.cart.items.find(variant => variant.product.code === product.code).quantity +=1
      : this.cart.items.push(new Product(product, 1))
    this.updateTotal()
    this.updateCart()
    this.openCart()
  }

  updateCart() {
    this.cartState.next(this.cart)
    localStorage.setItem("cart", JSON.stringify(this.cart))
  }

  increment(_product: Product) {
    this.cart.items.find(variant => variant.product.code === _product.product.code).quantity +=1
    this.updateTotal()
    this.updateCart()
  }

  decrement(_product: Product) {
    const product = this.cart.items.find(variant => variant.product.code === _product.product.code)
    product.quantity > 1?
      product.quantity -=1 :
      this.delete(_product)
    this.updateTotal()
    this.updateCart()
  }

  delete(product: Product) {
    this.cart.items.splice(this.cart.items.indexOf(product,0),1)
    this.updateTotal()
    this.updateCart()
  }

  deleteCart() {
    localStorage.removeItem("cart")
    this.cart = new Cart()
    this.updateTotal()
    this.updateCart()
  }

  updateTotal() {
    let total = 0
    this.cart.items.forEach( el =>
    { return total += el.product.defaultPrice.price * el.quantity})
    this.cart.total = total
  }

  updatePrices() {
    this.cart.items.map(item => {
      item.product.defaultPrice.price = this.products?.find(product => item.product.code === product.code).defaultPrice.price
      return item
    })
  }

  checkout() {
    this.changeOrderStatus()
    const order = new SimpleOrder()
    order.entries = new Array<SimpleOrderEntry>()

    this.cart.items.forEach(item => {
      const mappedItem = new SimpleOrderEntry()
      mappedItem.price = new VariantPrice()
      mappedItem.price.priceList = new PriceList()
      mappedItem.price.variant = new VariantBase()
      mappedItem.metadata = new SimpleOrderEntryMetadata()
      mappedItem.price.currency = item.product.defaultPrice.currency
      mappedItem.price.priceList.name = item.product.defaultPrice.pricelist
      mappedItem.price.value = item.product.defaultPrice.price
      mappedItem.price.variant.code = item.product.code
      mappedItem.quantity = item.quantity
      mappedItem.metadata.quantityAdjustable = true
      order.entries.push(mappedItem)
    })

    this.sendOrder(order).subscribe({
      next: res => {
        this.closeCart()
        this.deleteCart()
        this.changeOrderStatus()
        window.location.href = res;
      },
      error: err => {
        this.closeCart()
        this.toastNotificationService.display(new ToastNotification("Communication Error", `${err.userMessage}`))
      }
    })
  }

  sendOrder(order: SimpleOrder): Observable<string> {
    const successUrl = `${environment.serverBase}/order-success`
    const cancelUrl = `${environment.serverBase}/order-fail`
    const params = new HttpParams()
      .set('successUrl', successUrl)
      .set('cancelUrl', cancelUrl);

    return this.http.post(
      `${environment.lessonEndpoint}/student/${this.studentId}/checkouts`,
      order, { params: params, responseType: 'text' })
  }

  changeOrderStatus() {
    this.orderComplete.next(null)
  }
}
