<div class="workspace bg-linear-radial">
  <top-bar [titleTranslationCode]="'EBOOKS.TITLE'"
           [redirectLink]="'../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <!-- filters for mobile -->
      <div class="d-flex d-md-none gap-2">
        <button
          class="btn btn-secondary btn-circle refresh"
          (click)="refreshData()"
        >
          <app-icon name="refresh"></app-icon>
        </button>
      </div>
      <!-- breadcrumb -->
      <div class="d-none d-md-block">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a class="pe-none">{{ "EBOOKS.TITLE" | translate }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ getLanguageTranslation(chosenCourse) | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row">
      <div class="col-md-10">
        <app-course-filter [showAll]="true" (languageEmitter)="setChosenCourse($event)"></app-course-filter>
      </div>
      <div class="col-auto ms-auto d-none d-md-block">
        <button
          class="btn btn-outline-secondary rounded ms-auto px-2"
          (click)="refreshData()"
        >
          <app-icon name="refresh"></app-icon>
        </button>
      </div>
    </div>
    <div
      class="row row-cols-lg-3 row-cols-xl-5 row-cols-xxl-6 placeholder-glow pt-3 position-relative"
    >
      <!-- spinner -->
      <spinner class="w-100 spinner"></spinner>
      <!-- ebooks -->
      <div *ngFor="let ownershipLS of ownershipsLS; trackBy: bookTracker" class="col mb-5">
        <book-list-item
          [ownershipLS]="ownershipLS"
          class="d-flex flex-column align-items-center"
        ></book-list-item>
      </div>
    </div>
    <p *ngIf="ownershipsLS?.length === 0" class="text-center fs-3 text-muted my-3">
      {{ 'EBOOKS.NO_EBOOKS' | translate }}
    </p>
  </div>
</div>
