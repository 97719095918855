import {Component, Input, OnInit} from '@angular/core';
import {CalendarEntry, ClickEvent, DateRange} from "../../../model/calendar";
import {finalize, tap} from "rxjs";
import {SimpleScheduleEvents} from "../../../model/rest-model-v2";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";

@Component({
  selector: 'app-student-lessons-calendar',
  templateUrl: './student-lessons-calendar.component.html',
  styleUrls: ['./student-lessons-calendar.component.scss']
})
export class StudentLessonsCalendarComponent implements OnInit {

  loading: boolean = true;
  events: CalendarEntry[];
  schedules: SimpleScheduleEvents[];
  private _focusDate: Date;
  private _studentId: number;

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadCalendarEvents();
  }

  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadCalendarEvents();
  }

  constructor(private studentRest: StudentRestServiceImpl) { }

  ngOnInit(): void {}

  loadCalendarEvents() {
    if (!this._studentId || !this._focusDate) {
      return;
    }
    this.loading = true;

    this.getStudentWeekSchedules().pipe(
      tap(() => this.remapCalendarEntries()),
      finalize(() => (this.loading = false))
    ).subscribe();
  }

  getStudentWeekSchedules() {
      return this.studentRest.listStudentLessonSchedules(this._studentId, this._focusDate, null).pipe(
        tap((schedules) => this.schedules = schedules
      ))
  }

  remapCalendarEntries() {
    if (this.schedules) {
      this.events = this.schedules.map((schedule) =>
        this.mapScheduleToCalendarEntry(schedule)
      );
    }
  }

  mapScheduleToCalendarEntry(schedule: SimpleScheduleEvents): CalendarEntry {
    const dateTo = new Date();
    const dateFrom = schedule.events[0].eventDate
    const duration = schedule.events[0].duration
    dateTo.setTime(dateFrom.getTime() + duration);
    const title = schedule.schedule.name.split('[')[0].trim();
    const langClass = schedule.schedule.name.substring(schedule.schedule.name.indexOf('[') + 1, schedule.schedule.name.indexOf(']'))
    return new CalendarEntry(schedule.schedule.id, 1, dateFrom, dateTo, () => title, null, schedule, `schedule ${langClass}`);

  }
}
