<div class="d-flex flex-column justify-content-between h-100">
  <ul class="d-flex navbar-nav flex-column h-100" #main>
    <div>
      <a
        class="navbar-brand d-none d-md-flex flex-column mb-5 align-items-center"
      >
        <app-icon name="callan-logo" class="mb-3 logo-sm"></app-icon>
      </a>
      <div
        class="d-md-none header d-flex align-items-center justify-content-between mb-4"
      >
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-primary rounded-circle btn-circle me-3 pe-none p-0"
            [class]="profilePhoto ? 'avatar' : ''"
            [ngStyle]="getAvatar()"
          >
            <app-icon *ngIf="!profilePhoto" name="smile"></app-icon>
            <!-- (click)="onOpen(profileMenu)" -->
          </button>
          <div class="d-flex flex-column pe-4 right-border">
            <span class="small">{{ getStudentName() }}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary rounded-circle btn-circle text-reset p-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <app-icon name="close" class="icon-white"></app-icon>
        </button>
      </div>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('ebooks') }"
        routerLink="./ebooks"
      >
        <app-icon name="book"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EBOOKS" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('contact-us') }"
        routerLink="./contact-us"
      >
        <app-icon name="email"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.CONTACT_US" | translate
        }}</a>
      </li>
    </div>

    <div class="mt-auto">
      <hr class="dropdown-divider" />
      <li
        *ngIf="theme !== 'light'"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="themeService.changeTheme('light')"
      >
        <app-icon [name]="'light-mode'"></app-icon>
        <span class="nav-link">{{ "NAVIGATION.LIGHT_MODE" | translate }}</span>
      </li>
      <li
        *ngIf="theme === 'light'"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        (click)="themeService.changeTheme('dark')"
      >
        <app-icon [name]="'dark-mode'"></app-icon>
        <span class="nav-link">{{ "NAVIGATION.DARK_MODE" | translate }}</span>
      </li>

      <hr class="dropdown-divider" />
      <li class="nav-item" (click)="onLogout()">
        <app-icon name="log-out"></app-icon>
        <span class="nav-link">{{ "BUTTON.LOG_OUT" | translate }}</span>
      </li>
    </div>
  </ul>
  <span class="text-muted text-end xsmall opacity-50 pe-sm-1">
    v: {{ appVersionService.getVersion() | async }}
  </span>
</div>
