import {combineLatest, EMPTY, forkJoin, from, iif, mergeMap, Observable, of, throwError} from "rxjs";
import {catchError, defaultIfEmpty, map, switchMap, tap} from "rxjs/operators";
import {OldCasaQuestionsScoringService} from "./old-cspa-question-scoring";
import {ScoreAvailabilityApplyService} from "./score-availability-apply.service";
import {LogsService} from "../../../utils/services/logs.service";
import {Chapter, Exercise, ExerciseSet, Section} from "../../model/cspa/struct";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../model/cspa/personal";
import {AnswerDefinitionBase, Dictation, Question} from "../../model/cspa/questions";
import {CspaApi, CspaMobileBridgeServicesApi, CspaMobileNativeApi} from "./cspa.api";

export class NewMobileBridgeService implements CspaApi, CspaMobileBridgeServicesApi {
  isDataValid(exerciseSet: string): Observable<boolean> {
    return forkJoin(
      this.newMobileNativeApi.getQuestions(exerciseSet),
      this.newMobileNativeApi.getChapters(exerciseSet),
      this.newMobileNativeApi.getAvailabilities(exerciseSet)
    ).pipe(
      map( ([questions, chapters, availabilities]) =>
        questions.length > 0 && chapters.length > 0  && availabilities.length > 0
      ),
      catchError( _ => of(false))
    )
  }

  /*
  values passed to the native part during data synchronization
   */
  public CHAPTERS_SYNC_FREQUENCY_MS = 1000 * 60 * 15;
  public AVAILABILITY_SYNC_FREQUENCY = 1000 * 60 * 15;
  public QUESTIONS_SYNC_FREQUENCY_MS = 1000 * 60 * 15;
  public EXERCISE_SET_SYNC_FREQUENCY_MS = 1000 * 60 * 60 * 24 * 2;
  private scoringService = new OldCasaQuestionsScoringService();
  private availabilityUpdate = new ScoreAvailabilityApplyService();
  private DICT_PASS_SCORE = 0.6;
  private DEFAULT_PASS_SCORE = 0.9;

  constructor(private newMobileNativeApi: CspaMobileNativeApi, private logger: LogsService) {
  }

  /**
   * ask the app to send stored sessions to the server
   */
  syncSessions(): Observable<void> {
    this.logger.log("Asking mobile to send current sessions queue");
    return this.newMobileNativeApi.sendStoredSessions();
  }

  syncTopStructure(): Observable<any> {
    this.log("synchronizing top structure")
    return this.syncAvailabilities("").pipe(
      switchMap( _ => this.listExerciseSets()),
      switchMap( (sets: ExerciseSet[]) =>
        forkJoin(
          sets.map( set => this.syncChapters(set.path))
        )
      )
    )
  }

  /**
   * complex synchronization flow:
   * - questions
   * - chapters
   * - availability
   * @param exerciseSet
   */
  syncStructure(exerciseSet: string): Observable<void> {
    this.logger.log(`Synchronizing ${exerciseSet} availabilities.`)
    return this.syncQuestions(exerciseSet).pipe(
      defaultIfEmpty( _ => 'question synced'),
      switchMap( _ => this.syncAvailabilities(exerciseSet))
    );
  }

  forceToSyncStructure(exerciseSet: string): Observable<void> {
    this.logger.log(`Forcing to synchronize ${exerciseSet} availabilities.`)
    return this.syncQuestions(exerciseSet).pipe(
      defaultIfEmpty( _ => 'question synced'),
      switchMap( _ => this.forceToSyncAvailabilities(exerciseSet))
    );
  }

  /**
   * load chapters by path, simply passed to the mobile
   * @param path exercise set path ending with '_'
   */
  getChapters(path: string): Observable<Chapter[]> {
    this.logger.log(`loading ${path} chapters.`)
    return this.newMobileNativeApi.getChapters(path);
  }

  /**
   * Synchronize chapters structure definition from server for specific exercise set
   * @param exerciseSet
   */
  private syncChapters(exerciseSet: string): Observable<any> {
    return this.newMobileNativeApi.syncChapters(`${exerciseSet}_`, this.CHAPTERS_SYNC_FREQUENCY_MS);
  }

  /**
   * synchronize availabilities from server for specific exercise set
   * @param exerciseSet
   */
  private syncAvailabilities(exerciseSet: string): Observable<any> {
    if (!exerciseSet || exerciseSet.length == 0) {
      return this.newMobileNativeApi.syncTopAvailabilities(this.AVAILABILITY_SYNC_FREQUENCY);
    }
    return this.newMobileNativeApi.syncAvailabilities(`${exerciseSet}_`, this.AVAILABILITY_SYNC_FREQUENCY);
  }

  private forceToSyncAvailabilities(exerciseSet: string): Observable<any> {
    if (!exerciseSet || exerciseSet.length == 0) {
      return this.newMobileNativeApi.syncTopAvailabilities(0);
    }
    return this.newMobileNativeApi.syncAvailabilities(`${exerciseSet}_`, 0);
  }

  listTopAvailabilities(): Observable<ItemAvailability[]> {
    return this.newMobileNativeApi.getTopAvailabilities();
  }

  listChapterAvailabilities(chapterPath: string): Observable<ItemAvailability[]> {
    this.logger.log(`getting availabilities for chapter ${chapterPath}`)
    const pathSplatted = chapterPath.split("_")
    if (pathSplatted[pathSplatted.length - 1] === '') pathSplatted.splice(pathSplatted.length - 1, 1)
    this.logger.log(`querying mobile for current availabilities for chapter ${chapterPath}`)
    if (pathSplatted.length === 1) return this.newMobileNativeApi.getAvailabilities(chapterPath);
    return this.newMobileNativeApi.getAvailabilities(`${pathSplatted[0]}_`).pipe(
      map( availabilities => availabilities.filter( availability =>
        availability.path.startsWith(chapterPath) && availability.path.split("_").length <= 2 + pathSplatted.length
      ))
    );
  }

  /**
   * sync questions structure for exercise set
   * @param exerciseSet
   */
  private syncQuestions(exerciseSet: string): Observable<any> {
    this.logger.log(`forcing to sync questions definitions for exercise set ${exerciseSet}`);
    return this.newMobileNativeApi.syncQuestions(`${exerciseSet}_`, this.QUESTIONS_SYNC_FREQUENCY_MS);
  }

  /**
   * load list of exercise sets
   */
  listExerciseSets(): Observable<ExerciseSet[]> {
    this.logger.log(`Getting all exercise sets with sync freq=${this.EXERCISE_SET_SYNC_FREQUENCY_MS}`)
    return this.newMobileNativeApi.listExerciseSets(this.EXERCISE_SET_SYNC_FREQUENCY_MS);
  }

  startSession(exercisePath: string, prevSession?: ExerciseSession): Observable<ExerciseSession> {
    this.logger.log(`starting a new session for exercise ${exercisePath}.`)
    const exercise = this.findExerciseByPath(exercisePath);
    const sessionQuestions = this.readSessionQuestions(exercisePath);
    return forkJoin(exercise, sessionQuestions).pipe(
      map<[[Chapter, Section, Exercise], Question<any, any>[]], ExerciseSession>( ([[chapter, section, exercise], questions]) =>
        this.createSession(chapter, section, exercise, questions, prevSession)),
      tap(session => this.logger.log(`Asking mobile to store the session ${session.deviceUUID}`)),
      switchMap( session =>
        this.newMobileNativeApi.storeCurrentSession(session)
      )
    )
  }

  private filterQuestionsByPrevSession(baseQuestions: Question<any, any>[],
                                       prevSession: ExerciseSession) {
    if (prevSession != null) {
      this.logger.log(`prev session was provided during new session create with ${prevSession.questions.length} answered questions`);
      this.logger.log('prev session scores:' + prevSession.questions.map(q => q.score).join(","));
    }
    else this.logger.log("no prev session");
    if (!prevSession) return baseQuestions;
    const incorrectQuestionIds = new Set(prevSession.questions
      .filter( q => !this.isQuestionCorrect(q))
      .map( q => q.question.id))
    this.logger.log(`found ${incorrectQuestionIds.size} incorrect questions in prev session`);
    return baseQuestions.filter( q => incorrectQuestionIds.has(q.id))
  }

  private createSession(
    chapter: Chapter,
    section: Section,
    exercise: Exercise,
    baseQuestions: Question<any, any>[],
    prevSession?: ExerciseSession): ExerciseSession {
    const questions = this.filterQuestionsByPrevSession(baseQuestions, prevSession)
    const session = new ExerciseSession();
    session.baseQuestionNumber = baseQuestions.length;
    session.deviceUUID = this.uuidv4();
    session.chapterName = chapter.shortName; //check
    session.exerciseName = exercise.name; //check
    session.lastUpdateDate = new Date().getTime();
    session.path = `${chapter.path}_${section.path}_${exercise.path}`;
    session.score = 0;
    session.sectionName = section.name; //check;
    session.questions = questions.map( q => {
      const sq = new ExerciseSessionQuestion();
      sq.answered = false;
      sq.correct = false;
      sq.question = q;
      sq.score = 0;
      sq.updateDate = new Date().getTime();
      return sq;
    });
    session.startDate = new Date().toUTCString();
    session.prevSession = prevSession;
    return session;
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private findExerciseByPath(targetPath: string): Observable<[Chapter, Section, Exercise]> {
    const [exerciseSet, chapterPath, sectionPath, exercisePath] = targetPath.split("_");
    const chapterFullPath = `${exerciseSet}_${chapterPath}`;
    return this.newMobileNativeApi.getChapters(`${exerciseSet}_`).pipe(
      map( chapters => {
        const chapter = chapters.find( ch => ch.path === chapterFullPath);
        const section = chapter.sections.find( sec => sec.path === sectionPath);
        const exercise = section.exercises.find( ex => ex.path === exercisePath);
        return [chapter, section, exercise]
      }),
    )
  }

  private readSessionQuestions(exercisePath: string): Observable<Question<any, any>[]> {
    const exerciseSet = exercisePath.split('_')[0];
    const exercisePathForSearch = `${exercisePath}_`;
    this.logger.log(`asking mobile for questions for path ${exercisePath}`)
    return this.newMobileNativeApi.getQuestions(`${exerciseSet}_`).pipe(
      map(allQuestions =>
        allQuestions
          .filter(question => question.path.startsWith(exercisePathForSearch))
          .sort((lEl ,rEl) => {
              const [l,r] = [lEl.orderNumber, rEl.orderNumber];
              if (l != null && r != null) return l - r;
              if (l == null && r == null) return 0;
              if (l == null) return -1;
              return 1;
            }
          )
      ),
    )
  }

  postSessionQuestionAnswer<A extends AnswerDefinitionBase>(uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExerciseSession> {
    return this.getSession(uuid).pipe(
      tap( session => this.saveSessionQuestionAnswer(session, questionNb, sessionQuestion)),
      switchMap( session => this.newMobileNativeApi.storeCurrentSession(session))
    )
  }

  private saveSessionQuestionAnswer<A extends AnswerDefinitionBase>(session: ExerciseSession, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>) {
    const answeredQuestion = session.questions[questionNb];
    answeredQuestion.answer = sessionQuestion.answer;
    answeredQuestion.answered = true;
    answeredQuestion.updateDate = new Date().getTime();
    answeredQuestion.submitDate = new Date().getTime();
    session.lastUpdateDate = new Date().getTime();
  }

  finishSession(uuid: string): Observable<ExerciseSession> {
    this.log(`finishing session ${uuid}`);
    return this.getSession(uuid).pipe(
      switchMap( session =>
        this.scoreSession(session)
      ),
      tap(_ => this.logger.log(`storing current session state on mobile.`)),
      switchMap( (session:ExerciseSession) => this.newMobileNativeApi.storeCurrentSession(session)),
      tap( _ => this.logger.log("asking mobile to push the latest session to the sync list")),
      switchMap( (session: ExerciseSession) => this.newMobileNativeApi.pushSession(session)),
      switchMap( session => this.recalculateAvailability(session)),
      tap( session => {
        this.syncAfterSessionFinish(session);
      })
    )
  }

  private scoreSession(session: ExerciseSession): Observable<ExerciseSession> {
    for (const question of session.questions) {
      if (!question.answered) {
        question.score = 0;
        continue;
      }
      const scoreResult = this.scoringService.score(question);
      question.score = scoreResult.score;
      question.correct = this.isQuestionCorrect(question);
    }
    session.score = session.questions
      .reduce((acc, item) => acc + item.score, 0)
     / session.questions.length;
    session.finishDate = new Date().getTime();
    return of(session);
  }

  private recalculateAvailability(session: ExerciseSession): Observable<ExerciseSession> {
    const exerciseSet = `${session.path.split('_')[0]}_`;
    this.logger.log(`getting availabilities, chapters, questions and top availabilities from mobile for exercise set ${exerciseSet}`)
    return combineLatest(
      this.newMobileNativeApi.getAvailabilities(exerciseSet),
      this.newMobileNativeApi.getChapters(exerciseSet),
      this.newMobileNativeApi.getQuestions(exerciseSet),
      this.newMobileNativeApi.getTopAvailabilities()
    ).pipe(
      switchMap< [ ItemAvailability[], Chapter[], Question<any, any>[], ItemAvailability[] ]
        , any >(
        ([availability,chapters, questions, topAvailabilities]) =>
        {
          this.availabilityUpdate.updateAvailabilityWithScoredSession(chapters, questions, availability,topAvailabilities, session);
          this.log(`Asking mobile to store availabilities and top availabilities for exercise set ${exerciseSet}`)
          return forkJoin([
            this.newMobileNativeApi.storeAvailability(exerciseSet, availability),
            this.newMobileNativeApi.storeTopAvailabilities(topAvailabilities)
          ])
        }),
      map( _ => session)
    );
  }

  private isQuestionCorrect(question: ExerciseSessionQuestion<any, any>) {
    if (question.question.definition instanceof Dictation) {
      return question.score > this.DICT_PASS_SCORE;
    }
    return question.score > this.DEFAULT_PASS_SCORE;
  }

  private syncAfterSessionFinish(session: ExerciseSession) {
    const exerciseSet = session.path.split('_')[0];
    this.syncSessions().pipe(
      switchMap( _ => this.syncStructure(exerciseSet))
    ).subscribe();
  }

  restartSession(uuid: string): Observable<ExerciseSession> {
    return this.getSession(uuid).pipe(
      switchMap( prevSession => this.startSession(prevSession.path, prevSession))
    )
  }

  getSession(uuid: string): Observable<ExerciseSession> {
    this.logger.log(`querying mobile for the latest session ${uuid}`);
    return this.newMobileNativeApi.getCurrentSession().pipe(
      switchMap( session =>
        iif(() => session && session.deviceUUID === uuid,
          of(session),
          throwError(new Error('invalid session'))))
    );
  }

  close(): void {
    this.log('calling native api for close the app');
    this.newMobileNativeApi.close();
  }


  listQuestions(pathPreffixes: string[], updatedAfter?: number): Observable<Question<any, any>> {
    this.log(`listing questions for ${pathPreffixes} updated after ${updatedAfter}`);
    return throwError(new Error('Operation not supported'));
  }

  submitSessions(sessions: ExerciseSession[]): Observable<void> {
    this.log(`submitting ${sessions.length} sessions`)
    return throwError(new Error('Operation not supported'));
  }

  private log(...args: any[]) {
    this.logger.log('[MOB]',...args);
  }

  finishExamSession(sessionUuid: string): Observable<ExamSession> {
    return undefined;
  }

  finishExamSessionPart(sessionUuid: string): Observable<ExamSession> {
    return undefined;
  }

  getExamSession(sessionUuid: String): Observable<ExamSession> {
    return undefined;
  }

  postExamSessionQuestionAnswer<A extends AnswerDefinitionBase>(sessionUuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExamSession> {
    return undefined;
  }

  startExamSession(path: string): Observable<ExamSession> {
    return undefined;
  }

  startExamSessionPart(sessionUuid: string, partNumber: number): Observable<ExamSession> {
    return undefined;
  }
}
