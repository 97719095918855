import { Component, OnInit } from '@angular/core';
import {RoomDef} from "../../models/video.model";
import {ActivatedRoute} from "@angular/router";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {map, switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
  selector: 'app-teacher-group-room-details-page',
  templateUrl: './teacher-group-room-details-page.component.html',
  styleUrls: ['./teacher-group-room-details-page.component.scss']
})
export class TeacherGroupRoomDetailsPageComponent implements OnInit {

  teacherId: number;
  schoolId: number;
  roomId: string;
  room: RoomDef

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private authProvider: AuthorizationServiceProvider,
              private spinnerService: SpinnerService) {
    this.authProvider.getAuthDetailsService().pipe(
      switchMap(service => service.getSelfTeacherId()),
      tap(teacherId => this.teacherId = teacherId),
      switchMap(_ => this.route.paramMap),
      map(params => {
        this.schoolId = +params.get('schoolId')
        this.roomId = params.get('roomId')
      })
    ).subscribe()
  }

  ngOnInit(): void {
    this.loadTeacherRoomDetails()
  }

  loadTeacherRoomDetails() {
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getTeacherRoomDetails(
          this.schoolId, this.teacherId, this.roomId)
        ),
        tap<RoomDef>(room => this.room = room)
      )
    ).subscribe()
  }

  getScheduleName() {
    return this.room?.name
  }

}
