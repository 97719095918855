import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Subject, switchMap} from 'rxjs';
import {NativeServiceApiProvider} from "./native-api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private lang = new Subject<string>()
  private supportedLangs: Array<string> = ['pt', 'de', 'en', 'es', 'hr', 'it', 'ja', 'pl', 'ru', 'uk'];
  constructor(
    public translate: TranslateService,
    private provider: NativeServiceApiProvider) { }

  initialize() {
    if(this.lang.observed) return
    this.translate.addLangs(this.supportedLangs)
    this.translate.setDefaultLang('en');
    this.lang.subscribe(lang => {
      this.translate.use(lang)
      console.log('current language: ' + lang)
    })
    this.provider.casa().pipe(
      switchMap(api => api.getSelf())
    ).subscribe(profile => {
      if(profile?.details?.locale)
        this.changeLang(profile.details.locale)
      else
        this.changeLang(this.translate.getBrowserLang())
    })
  }

  changeLang(lang: string) {
    if(lang) {
      if (this.supportedLangs.indexOf(lang) >= 0) {
        this.lang.next(lang)
      } else {
        this.lang.next("en")
      }
    }

  }
}
