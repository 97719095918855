<div class="card mb-5" *ngIf="!isLoading">
  <div class="card-header">
    <div class="row">
      <div class="col d-flex">
        <h4 class="my-auto">
          {{ "COL.COURSES.CREDITS.PURCHASE_HISTORY.TITLE" | translate }}
        </h4>
      </div>
      <div class="col-auto ms-auto">
        <paginator
          [data]="results"
          [pageable]="pageable"
          (newPage)="onPageChange($event)"
          class="mb-4"
        ></paginator>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive" *ngIf="getResults().length != 0; else noResults">
      <table class="table white-text table-borderless pe-none">
        <thead>
          <tr class="table-row table-row-head">
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.DATE" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.COURSE" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.NAME" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.PRICE" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.CREDITS" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.EXPIRY_DATE" | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let bundle of getResults()" class="table-row">
            <td class="small">
              {{ bundle.date | dateTranslation : "dd MMM yyyy HH:mm" }}
            </td>
            <td class="small">
              {{ bundle.product.name }}
            </td>
            <td class="small">
              {{ bundle.name }}
            </td>
            <td class="small">
              {{ bundle.price }}
            </td>
            <td class="small">
              {{ bundle.available }} / {{ bundle.registeredLessons }}
            </td>
            <td class="small">
              {{ bundle.expiryDate | dateTranslation : "dd/MM/yyyy" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ng-template #noResults>
      <p>
        {{ "COL.COURSES.CREDITS.PURCHASE_HISTORY.NO_RESULTS" | translate }}
      </p>
    </ng-template>
  </div>
</div>
