<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.DOCUMENTS'"
           [redirectLink]="'../../'"
           [defaultRedirectTranslationCodes]="true"
          class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" [ngClass]="{active: item == last()}" aria-current="page" *ngFor="let item of documentsPath">
                <a *ngIf="item != last()" routerLink="/teacher/documents/{{item.key}}">{{item.shortName}}</a>
                <span *ngIf="item == last()">{{item.shortName}}</span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <a class="btn btn-primary btn-return rounded-circle cursor-pointer my-auto mt-sm-0 lh-lg" *ngIf="documentRoot && documentRoot !== last()" (click)="up()">
          <app-icon name="angle-left" class="icon-white icon-md"></app-icon>
        </a>
        <div class="btn-return" *ngIf="!documentRoot || documentRoot === last()"></div>
        <h4 class="my-auto mx-2 text-center">Documents repository
        </h4>
        <button class="btn btn-primary rounded-circle my-auto mt-sm-0 lh-lg" (click)="refresh()" data-bs-toggle="tooltip" title="Refresh">
          <app-icon name="refresh" class="icon-sm-2 icon-white"></app-icon>
        </button>
      </div>
      <div class="card-body">
        <ul *ngIf="lastAsDir()" class="list-group w-100 gap-2">
          <div *ngFor="let item of lastAsDir().innerEntries">
            <!-- dir -->
            <a *ngIf="isDir(item)"
               class="list-group-item d-inline-flex row w-100 p-3 p-md-4 gx-0"
               routerLink="/teacher/documents/{{item.key}}"
            >
              <button class="btn border-2 circle rounded-circle p-2 lh-1 me-2 me-md-3">
                <app-icon name="folder" class="icon-blue icon-sm"></app-icon>
              </button>
              <span class="my-auto col-auto">{{item.name}}</span>
              <button class="btn border-2 circle rounded-circle d-none d-sm-block ms-auto">
                <app-icon name="angle-right" class="icon-blue icon-md"></app-icon>
              </button>
            </a>
            <!-- file -->
            <a *ngIf="!isDir(item)"
               class="list-group-item d-inline-flex row w-100 p-3 p-md-4 gx-0"
               (click)="download(item)"
            >
              <button class="btn border-2 circle rounded-circle p-2 lh-1 me-2 me-md-3">
                <app-icon name="file" class="icon-blue icon-sm"></app-icon>
              </button>
              <span class="my-auto text-primary col-8 col-lg-10">
                {{item.name}}
                <span *ngIf="getExtension(item) && getSize(item)"
                  class="badge bg-secondary rounded p-1 xsmall my-auto">
                  <span class="text-white xsmall" *ngIf="getExtension(item)">
                    {{getExtension(item)}} /
                  </span>
                  {{getSize(item)}}
                </span>
              </span>
              <button class="btn border-2 circle rounded-circle d-none d-sm-block ms-auto px-0">
                <app-icon name="download" class="icon-blue"></app-icon>
              </button>
            </a>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
