<div class="workspace bg-radial">
  <top-bar [ngClass]="getAccent()">
    <content class="mb-md-4">
      <button
        type="button"
        class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        routerLink="../../../../"
      >
        <app-icon name="arrow-back"></app-icon>
        <span class="d-none d-lg-block ms-3">
          {{ "NAVIGATION.EXERCISE_SET" | translate }}
        </span>
      </button>
      <div class="text-center">
        <h4>{{ getExerciseSet() }}</h4>
        <span class="d-md-none text-muted">
          {{ getChapter() }}
        </span>
      </div>
    </content>
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="getExerciseRouterLink()">
              {{ "NAVIGATION.EXERCISES" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="getExerciseRouterLink()">{{ getExerciseSet() }}</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="getChapterRouterLink()">{{
                getChapter()
              }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ getExamName() }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="container mt-md-3 mb-5">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-exam-start *ngIf="!isSummary; else summary" [session]="session" [isSampleExam]="isSampleExam()"></app-exam-start>
    <ng-template #summary>
      <app-exam-summary [session]="session" [isSampleExam]="isSampleExam()"></app-exam-summary>
    </ng-template>
  </div>
</div>
