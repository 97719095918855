import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, switchMap, take } from 'rxjs';
import { NativeServiceApiProvider } from './native-api-provider.service';
import {LogsService} from "../utils/services/logs.service";
import {CourseFilter, FilterDefinition} from "../model/CourseFilters";

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private courseSource = new ReplaySubject<FilterDefinition>(1);
  private courseSource$ = this.courseSource.asObservable();

  constructor(private provider: NativeServiceApiProvider,
              private logger: LogsService) {
    this.courseSource.next(this.getCourseByCode("ALL"))
    provider
      .properties()
      .pipe(
        switchMap((api) => api.getValue('defaultCourse')),
        map((value) => { return value || 'ALL'}),
        take(1)
      )
      .subscribe(courseCode => {
        this.logger.log(`current course: ${courseCode}`)
        this.courseSource.next(this.getCourseByCode(courseCode))
      });
  }

  getCourse(): Observable<FilterDefinition> {
    return this.courseSource$;
  }

  changeCourse(course: FilterDefinition) {
    this.logger.log("switching to course:", course.code);
    this.provider
      .properties()
      .pipe(
        switchMap((api) => api.putValue('defaultCourse', course.code)),
      )
      .subscribe((courseCode: string) => {
        this.courseSource.next(this.getCourseByCode(courseCode))
      });
  }

  private getCourseByCode(courseCode: string) {
    return CourseFilter.filters.find(c => c.code == courseCode)
  }
}
