<section class="mb-5">
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">
        {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.TITLE" | translate }}
      </h4>
    </div>
    <div class="card-body">
      <p class="small mb-4">
        {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.DESC" | translate }}
      </p>
      <div class="row">
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/ajWzt6qOBjU"
width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_1.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_1.SUBTITLE" | translate }}
          </p>
        </div>
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/2WGTSiSNg74"
              width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_4.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_4.SUBTITLE" | translate }}
          </p>
        </div>
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/mJlCw3am6qY"
              width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_6.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_6.SUBTITLE" | translate }}
          </p>
        </div>
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/D3_06N463N4"
              width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_8.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_8.SUBTITLE" | translate }}
          </p>
        </div>
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/R0b4L0lUrUk"
              width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_12.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_12.SUBTITLE" | translate }}
          </p>
        </div>
        <div class="col-10 col-sm-9 col-md-6 col-xl-4 mx-auto mb-4">
          <div class="video-container mb-2">
            <iframe
              src="https://www.youtube.com/embed/UH9ohPW3pPw"
              width="560"
              height="315"
              allowfullscreen=""
            ></iframe>
          </div>
          <p class="text-primary xsmall mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_17.TITLE" | translate }}
          </p>
          <p class="fs-5 mb-0">
            {{ "COL.COURSES.PRODUCTS.USEFUL_CONTENT.LESSON_17.SUBTITLE" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pb-5">
  <div class="row text-center justify-content-center">
    <div class="col-12 col-md-6 col-xl-4">
      <p class="fw-bold">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.SCHEDULE.TITLE" | translate }}
      </p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.SCHEDULE.DESC" | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <p class="fw-bold">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.TUTORS.TITLE" | translate }}
      </p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.TUTORS.DESC" | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <p class="fw-bold">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.REQUIREMENTS.TITLE" | translate }}
      </p>
      <p class="text-muted">
        {{ "COL.COURSES.PRODUCTS.ABOUT_ENBUS.REQUIREMENTS.DESC" | translate }}
      </p>
    </div>
  </div>
</section>
