<div class="container-fluid vh-100 bg-dark bg-watermark">
  <div class="row vh-100 mx-0">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto">
      <div class="text-center m-auto p-4 position-relative">
        <p class="lead">{{ "LOGIN.HEADER.CONTENT_1" | translate }}</p>
        <h1 class="mb-5">{{ "LOGIN.HEADER.CONTENT_2" | translate }}</h1>

        <div class="d-flex flex-column mb-4">
          <button class="btn btn-primary btn-lg" (click)="ssoLogin()">
            {{ "BUTTON.LOG_IN" | translate }}
          </button>
        </div>
        <a [routerLink]="'../forgot-password'" class="text-decoration-none my-2">{{
          "PROFILE.CHANGE_PASSWORD.FORGOT_PASSWORD" | translate
          }}</a>


        <div class="d-flex flex-column mb-4">
          <p class="form-link">
            {{ "PROFILE.CHANGE_PASSWORD.ANY_PROBLEM" | translate }}
            <a [routerLink]="'../contact-us'" class="text-decoration-none">
              {{ "PROFILE.CHANGE_PASSWORD.CONTACT_US_LINK" | translate }}
            </a>
          </p>
        </div>
        <app-icon name="callan-logo" class="logo"></app-icon>
      </div>
    </div>
  </div>
</div>
