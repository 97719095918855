<div class="workspace bg-radial">
  <top-bar class="accent" [titleTranslationCode]="'LESSONS.TITLE'">
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LESSONS.TITLE" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row mb-4">
      <div class="col-auto">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active' : isActive('upcoming')}" aria-current="page" routerLink="../upcoming">{{ 'LESSONS.TAB.UPCOMING' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active' : isActive('lessons-list')}" routerLink="../lessons-list">{{ 'LESSONS.LESSON_LIST.TITLE' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active' : isActive('lesson-calendar')}" routerLink="../lesson-calendar">{{ 'LESSONS.LESSON_CALENDAR.TITLE' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-auto ms-auto">
        <div class="btn-group h-100" role="group">
          <button
            type="button"
            class="btn btn-outline-secondary rounded-start-3 btn-focus-none"
            (click)="getPreviousWeek()"
          >
            <app-icon name="angle-left" class="icon-blue"></app-icon>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-focus-none"
            (click)="goToToday()"
          >
            <span class="text-muted">{{ getDate() }}</span>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary rounded-end-3 btn-focus-none"
            (click)="getNextWeek()"
          >
            <app-icon name="angle-right" class="icon-blue"></app-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <div class="row position-relative pb-3">
        <spinner class="w-100 spinner"></spinner>
      </div>
      <ng-container loadComponent></ng-container>
    </div>
  </div>
</div>
