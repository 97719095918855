import {ApiPerson, ApiPersonalProfile, ApiPersonalProfileBaseWithPhoto} from 'src/app/col/model/rest-model';
import { environment } from 'src/environments/environment';
import { Gender, PersonDetails, StudentPersonDetails } from '../model/casa/casa-model';

export class PersonUtils {
  static getPersonName(
    person: PersonDetails | StudentPersonDetails | ApiPerson<ApiPersonalProfileBaseWithPhoto>
  ): string {
    const fullName: string[] = [];
    if (person.name != null) fullName.push(person.name);
    if (person.surname != null) fullName.push(person.surname);
    return fullName.join(' ');
  }

  static getInitials(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return person.name[0] + person.surname[0].toUpperCase();
  }

  public static getProfilePhoto(personalProfile: ApiPersonalProfileBaseWithPhoto) {
    return environment.fileEndpoint + '/img/'
    + personalProfile.profilePhoto.id
    + '?fileAccessToken='
    + personalProfile.profilePhoto.accessToken ;
}

  public static getPersonProfilePhoto(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    if (!person || !person.personalProfile || !person.personalProfile.profilePhoto) {
        return null;
    }
    return this.getProfilePhoto(person.personalProfile);
}
}
