import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, switchMap, tap} from "rxjs";
import {Cart, Currencies} from "../../../model/products";
import {StudentShoppingCartService} from "../../../services/student-shopping-cart.service";
import {ProfileService} from "../../../../auth_profile/services/student/profile.service";

@Component({
  selector: 'app-shopping-cart-header',
  templateUrl: './shopping-cart-header.component.html',
  styleUrls: ['./shopping-cart-header.component.scss']
})
export class ShoppingCartHeaderComponent implements OnInit {

  _studentId: number;
  currencies = Currencies.values;
  _currency: string;
  currencySubject = new BehaviorSubject<string>(Currencies.values[0]);
  cart: Cart

  constructor(
    private profileService: ProfileService,
    private shoppingCart: StudentShoppingCartService
  ) {
  }

  @Output()
  currencyChange = new EventEmitter();

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }

  get studentId() {
    return this._studentId;
  }

  set currency(currency: string) {
    if (this.currencies.indexOf(currency) >= 0) {
      this._currency = currency;
    } else {
      this._currency = this.currencies[0];
    }
    this.shoppingCart.setCurrency(this._currency);
    this.currencyChange.emit(this._currency);
    this.currencySubject.next(this._currency);
  }

  getCurrencyIcon(cur: string) {
    if(cur === 'eur')
      return '€'
    else if(cur === 'pln')
      return 'zł'
    else if(cur === 'usd')
      return '$'
    else if(cur === 'gbp')
      return '£'
    else if(cur === 'jpy')
      return '¥'
    else
      return null
  }

  get currency() {
    return this._currency;
  }

  ngOnInit() {
    this.shoppingCart.getCurrency().pipe(
      tap(curr =>
        this.currency = curr
      ),
      switchMap(_ => this.shoppingCart.getCart())
    ).subscribe(cart =>
      this.cart = cart
    )
  }

  getItems() {
    return this.cart.items.length
  }

  getTotal() {
    return `${this.cart.total} ${this.shoppingCart.getCurrencySign()}`
  }
}
