import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { NativeServiceApiProvider } from './native-api-provider.service';
import {LogsService} from "../utils/services/logs.service";

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeSource = new ReplaySubject<string>(1);
  private themeSource$ = this.themeSource.asObservable();

  constructor(private provider: NativeServiceApiProvider,
              private logger: LogsService) {
    provider
      .properties()
      .pipe(
        switchMap((api) => api.getValue('defaultTheme')),
        map((value) => { return value || 'dark' }),
        take(1)
      )
      .subscribe((theme: string) => this.applyTheme(theme));
  }

  getTheme(): Observable<string> {
    return this.themeSource$;
  }

  changeTheme(theme: string) {
    this.logger.log("switching to theme", theme);
    this.provider
      .properties()
      .pipe(
        switchMap((api) => api.putValue('defaultTheme', theme)),
        tap((theme) => {this.applyTheme(theme)})
      )
      .subscribe((theme: string) => {
        this.themeSource.next(theme);
      });
  }

  private applyTheme(theme: string) {
    if (theme === 'dark') document.body.classList.remove('light-theme');
    else document.body.classList.add('light-theme');
    this.themeSource.next(theme);
  }
}
