import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonType } from 'src/app/col/model/calendar';

@Component({
  selector: 'app-teacher-lessons-page',
  templateUrl: './teacher-lessons-page.component.html',
  styleUrls: ['./teacher-lessons-page.component.scss']
})
export class TeacherLessonsPageComponent implements OnInit {

  teacherId
  lessonTypes = LessonType;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.teacherId = params.get("teacherId");
    })
  }

}
