<div class="card-header border-0 px-3 px-sm-4">
  <div class="row gx-1 py-2">
    <div *ngIf="showFilter" class="col-auto">
      <div class="btn btn-outline-secondary rounded-pill d-sm-flex p-2 px-sm-3" (click)="openFilterModal.emit()">
        <app-icon name="filter-calendar" class="icon-gray"></app-icon>
        <span class="d-none d-sm-block">{{ "EBOOKS.FILTER" | translate }}</span>
      </div>
    </div>
    <div class="col-auto position-relative d-flex justify-content-center ms-auto">
      <div class="btn-group my-auto">
        <div
          class="btn btn-outline-secondary rounded-start-3"
          (click)="moveDays(-7)"
        >
          <app-icon name="angle-left" class="lg-icon icon-blue"></app-icon>
        </div>
        <div class="btn btn-outline-secondary w-100" (click)="today()">
          {{getWeekStartDate() | dateTranslation: 'dd MMM'}} - {{getWeekEndDate() | dateTranslation: 'dd MMM yyyy'}}
        </div>
        <div
          class="btn btn-outline-secondary rounded-end-3"
          (click)="moveDays(7)"
        >
          <app-icon name="angle-right" class="lg-icon icon-blue"></app-icon>
        </div>
      </div>
      <p class="text-muted xsmall position-absolute top-100">
        {{ "COL.COURSES.CALENDAR.TIMEZONE_INFO" | translate }}
      </p>
    </div>
  </div>
</div>
<div class="card-body px-3 px-sm-4">
  <div
    #calendar
    class="position-relative w-100"
    [ngClass]="{ 'opacity-25': loading }"
  >
    <div class="wcal-hours-col wc-head-width">
      <div
        class="wc-head-height border-end border-bottom border-secondary"
      ></div>
      <div #hoursScroll class="overflow-hidden wc-height">
        <div class="wcal-hours-panel border-end border-secondary">
          <div
            *ngFor="let hour of getHours()"
            class="wcal-hour wc-col-height position-relative border-bottom border-secondary"
          >
            <span class="xsmall me-1 me-sm-2">{{ hour }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden w-auto">
      <div #daysScroll class="overflow-hidden w-100">
        <div
          class="wcal-days-panel wc-head-height wc-width d-flex border-secondary"
        >
          <div
            *ngFor="let weekDate of getWeekDates()"
            class="wcal-day wc-col-width border-bottom border-inline border-secondary"
          >
            <div class="d-flex flex-column align-items-center">
              <span class="xsmall text-muted">{{
                weekDate | dateTranslation : "EE"
              }}</span>
              <span class="small text-white">{{ weekDate | date : "dd" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div #gridScroll class="wcal-grid-scroll-wrapper wc-height">
        <div class="wc-width d-flex">
          <div
            *ngFor="let x of [].constructor(7); let i = index"
            class="wcal-day-col wc-col-width border-inline"
            (click)="dayClick(i, $event)"
            [ngClass]="{ active: getActiveDay() == i }"
          >
            <div
              *ngFor="let i of [].constructor(24*2)"
              class="wcal-day-hour wc-col-height border-bottom border-secondary"
            ></div>
            <div
              *ngFor="let event of _dayEvents[i]"
              class="event position-absolute bordered rounded"
              [ngClass]="event.classes"
              [ngStyle]="{
                top: event.start + '%',
                height: 'calc(' + event.height + '% - 2px)',
                left: event.left + '%',
                width: event.width + '%'
              }"
              (click)="eventClick(i, event, $event)"
            >
              <span *ngIf="event.title" class="xsmall ms-1">{{
                event.title()
              }}</span>
              <span *ngIf="event.subtitle" class="xsmall ms-1">{{
                event.subtitle
              }}</span>
            </div>
            <div
              *ngIf="getActiveDay() == i"
              class="currentTime"
              [ngStyle]="{ top: getCurrentTimeAsProgress() + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="loading"
      class="spinner-position spinner-border text-primary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
