<div class="d-flex flex-column justify-content-between h-100 flex-grow-1">
  <ul
    class="navbar-nav flex-column h-100"
    #main
    [ngClass]="isHidden(main) ? 'd-none' : 'd-flex'"
  >
    <div>
      <a
        class="navbar-brand d-none d-md-flex flex-column align-items-center"
      >
        <app-icon name="callan-logo" class="mb-3 logo-sm"></app-icon>
      </a>
      <div
        class="d-md-none header d-flex align-items-center justify-content-between mb-4"
      >
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-primary rounded-circle btn-circle me-3 pe-none p-0"
            [class]="profilePhoto ? 'avatar' : ''"
            [ngStyle]="getAvatar()"
          >
            <app-icon *ngIf="!profilePhoto" name="smile"></app-icon>
            <!-- (click)="onOpen(profileMenu)" -->
          </button>
          <div class="d-flex flex-column mb-2 pe-4 right-border">
            <span class="small">{{ getStudentName() }}</span>
            <span class="xsmall muted">{{ getStudentRole() }}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary rounded-circle btn-circle text-reset p-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          (click)="onClose()"
        >
          <app-icon name="close" class="icon-white"></app-icon>
        </button>
      </div>
      <li
        class="nav-item"
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('dashboard') }"
        routerLink="./dashboard"
      >
        <app-icon name="dashboard"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.DASHBOARD" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('reserve-lesson') }"
        routerLink="./reserve-lesson"
      >
        <app-icon name="calendar-edit"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.RESERVE_LESSON" | translate
          }}</a>
      </li>
      <li
        class="nav-item"
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('progress') }"
        routerLink="./progress"
      >
        <app-icon name="progress"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.PROGRESS" | translate
        }}</a>
      </li>
      <li
        *ngIf="!disableVideo && (!isColFunctionalityOn() || !isCallanOnlineStudent())"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('lessons') }"
        routerLink="./lessons/school/{{this.schoolId}}/student/{{this.studentId}}"
      >
        <app-icon name="calendar"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.LESSONS" | translate
        }}</a>
      </li>
      <li
        *ngIf="isColFunctionalityOn() && isCallanOnlineStudent()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('lessons') }"
        [routerLink]="getLessonLink()"
      >
        <app-icon name="calendar"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.LESSONS" | translate
        }}</a>
      </li>
      <li
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('credits') }"
        routerLink="./credits"
      >
        <app-icon name="credits"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.CREDITS" | translate
        }}</a>
      </li>
      <li
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('plans') }"
        routerLink="./plans"
      >
        <app-icon name="plans"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.PLANS" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('exercises') }"
        routerLink="./exercises"
      >
        <app-icon name="exercises"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EXERCISES" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('ebooks') }"
        routerLink="./ebooks"
      >
        <app-icon name="book"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EBOOKS" | translate
        }}</a>
      </li>
      <li
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('teachers') }"
        routerLink="./teachers"
      >
        <app-icon name="teachers"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.TEACHERS" | translate
        }}</a>
      </li>
      <!-- <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('notifications') }"
        routerLink="./notifications"
      >
        <app-icon name="notification"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.NOTIFICATIONS" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('faq') }"
        routerLink="./faq"
      >
        <app-icon name="faq"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.FAQ" | translate
        }}</a>
      </li> -->
      <li
        class="nav-item"
        *ngIf="isCallanOnlineStudent() && isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('courses') }"
        routerLink="./courses"
      >
        <app-icon name="school"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.COURSES" | translate
        }}</a>
      </li>
    </div>

      <div class="mt-auto">
        <li class="nav-item"
            [attr.data-bs-dismiss]="getDismiss()"
            [ngClass]="{ active: isActivePath('contact-us') }"
            routerLink="./contact-us"
        >
          <app-icon name="email"></app-icon>
          <a class="nav-link" aria-current="page">{{
            "NAVIGATION.CONTACT_US" | translate
            }}</a>
        </li>
        <hr class="dropdown-divider my-1 d-md-none">
        <li *ngIf="theme !== 'light'" class="nav-item d-md-none" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('light')">
          <app-icon [name]="'light-mode'"></app-icon>
          <span class="nav-link">{{ 'NAVIGATION.LIGHT_MODE' | translate }}</span>
        </li>
        <li *ngIf="theme === 'light'" class="nav-item d-md-none" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('dark')">
          <app-icon [name]="'dark-mode'"></app-icon>
          <span class="nav-link">{{ 'NAVIGATION.DARK_MODE' | translate }}</span>
        </li>

      <ng-container *ngIf="casaSupported">
        <hr class="dropdown-divider d-md-none my-1" />
        <li
          class="nav-item d-md-none"
          [attr.data-bs-dismiss]="getDismiss()"
          [ngClass]="{ active: isActivePath('profile') }"
          routerLink="./profile/profile-form"
        >
          <app-icon name="profile"></app-icon>
          <a class="nav-link" aria-current="page">{{
            "NAVIGATION.EDIT_PROFILE" | translate
          }}</a>
        </li>
      </ng-container>

      <hr class="dropdown-divider my-1 d-md-none" />
      <li class="nav-item d-md-none" (click)="onLogout()">
        <app-icon name="log-out"></app-icon>
        <span class="nav-link">{{ "BUTTON.LOG_OUT" | translate }}</span>
      </li>
    </div>
  </ul>
  <span class="text-muted text-end xsmall opacity-50 pe-sm-1">
    v: {{ getVersion() }}
  </span>
</div>
