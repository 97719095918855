import { Injectable } from '@angular/core';
import {ColNativeServiceApi} from "../col-native-api";
import {ItemAvailability} from "../../../../cspa/model/cspa/personal";
import {
  ApiCompetence, ApiCountry,
  ApiCourse, ApiLearningUnitStudent,
  ApiLearningUnitTeacher, ApiLessonBundle,
  ApiLessonCommit,
  ApiLessonFlag, ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress, ApiPerson, ApiPersonalProfile,
  ApiPersonTechnicalProfile, ApiProductContext,
  ApiProductGift, ApiProvaContext, ApiStudentProductContext, ApiStudentSchedule,
  ApiTeacherProfile,
  ApiTeacherWorktime, ApiWorktimeDefinition,
  EntityRelatedRow,
  LessonBundleFilter,
  StudentCommitsFilter
} from "../../../model/rest-model";
import {Page, Pageable} from "../../../../utils/pageable";
import {Chapter, ExerciseSet} from "../../../../cspa/model/cspa/struct";
import {ApiProduct} from "../../../model/products";
import {map, Observable, of, tap} from "rxjs";
import {LessonType} from "../../../model/calendar";
import {Constraints, Platform} from "../../../../constraints";
import {LogsService} from "../../../../utils/services/logs.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AndroidBridgeBase} from "../../../../mobile/api-services/bridge/android-bridge-base";
import {environment} from "../../../../../environments/environment";
import {Dates} from "../../../../utils/calendar-utils";
import {
  ApiLessonEventModel, LessonScheduleEventReference,
  ProductAvailabilityRequest,
  ScheduleReference, SimpleLessonScheduleRequest,
  SimpleProductAvailability,
  SimpleScheduleEvents, SimpleTeacherProductAvailability,
} from "../../../model/rest-model-v2";
import {Utils} from "../../../utils/lesson-utils";

@Injectable({
  providedIn: 'root'
})
export class ColAndroidService implements ColNativeServiceApi{

  private apiEndpoint = Constraints.androidGenericInternalApiEndpoint;

  constructor(
    private logger: LogsService,
    private http: HttpClient,
    private bridgeBase: AndroidBridgeBase
  ) {}

  private static buildRequestIdHeader(id: number){
    return new HttpHeaders({"X-Request-Id" : id.toString()});
  }

  private nextRequestIdHeader(){
    return ColAndroidService.buildRequestIdHeader(this.bridgeBase.getNextRequestId());
  }

  private getUrlLessonStudent(path: string, studentId?: number) {
    return `${this.apiEndpoint}/platform/${Platform.Lessons}/student` +
      (studentId ? `/${studentId}` : '') +
      path
  }
  private getUrlLessonTeacher(path: string, teacherId?: number) {
    return `${this.apiEndpoint}/platform/${Platform.Lessons}/teacher` +
      (teacherId ? `/${teacherId}` : '') +
      path
  }

  private getUrlCspa(path: string) {
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/api${path}`;
  }
  private getUrlBookingStudent(studentId: number, path: string) {
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/student/${studentId}${path}`;
  }

  private getUrlBookingV2Student(studentId: number, path: string) {
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/v2/student/${studentId}${path}`;
  }

  private getUrlBookingTeacher(teacherId: number, path: string) {
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/teacher/${teacherId}${path}`;
  }

  private getUrlBookingV2Teacher(teacherId: number, path: string) {
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/v2/teacher/${teacherId}${path}`;
  }

  cancelLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`cancelling lesson with lessonId=${lessonId}`)
    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}/cancel`, studentId),
      reason,
      {
      headers: this.nextRequestIdHeader()
    })
  }

  cancelLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`cancelling lesson by teacher with lessonId=${lessonId}`)
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/cancel`, teacherId),
      reason,
      {
      headers: this.nextRequestIdHeader()
    })
  }

  commitLessonBooking(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`committing lesson booking with lessonId=${lessonId}`)
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/due`, teacherId), {}, {
        headers: this.nextRequestIdHeader()
      })
  }

  createSkypeClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`creating skype classroom with lessonId=${lessonId}`)
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/skype`, teacherId), {}, {
        headers: this.nextRequestIdHeader()
      })
  }

  createVideoClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`creating video classroom with lessonId=${lessonId}`)
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/classroom`, teacherId), {}, {
        headers: this.nextRequestIdHeader()
      })
  }

  createWorktime(teacherId: number, definition: ApiWorktimeDefinition): Observable<ApiWorktimeDefinition> {
    this.logger.log(`creating worktime for teacherId=${teacherId}`)
    return this.http.put<ApiWorktimeDefinition>(
      this.getUrlLessonTeacher(`/worktimes`, teacherId), definition, {
        headers: this.nextRequestIdHeader()
      })
  }

  deleteWorktime(teacherId: number, worktimeId: number, applyRecurring: boolean): Observable<void> {
    this.logger.log(`deleting worktime with worktimeId=${worktimeId} for teacherId=${teacherId}`)
    return this.http.delete<void>(
      this.getUrlLessonTeacher(`/worktimes/${worktimeId}`, teacherId), {
        headers: this.nextRequestIdHeader(),
        params: {
          applyRecuring: applyRecurring.toString()
        }
      })
  }

  estimateNextProgress(teacherId: number, studentId: number, baseProgress: ApiLessonProgress, limit?: number): Observable<ApiLessonProgress[]> {
    this.logger.log(`estimating next progress for studentId=${studentId}`)
    let params = new HttpParams();
    if (limit !== undefined) {
      params = params.append('limit', limit.toString());
    }
    return this.http.post<ApiLessonProgress[]>(
      this.getUrlLessonTeacher(`/students/${studentId}/progress/estimate`, teacherId),
      baseProgress,
      {
        headers: this.nextRequestIdHeader(),
        params: params
      })
  }

  findStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<ApiProductGift> {
    this.logger.log(`finding student product gift for studentId=${studentId}`)
    return this.http.get<ApiProductGift>(
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId), {
      headers: this.nextRequestIdHeader()
    })
  }

  findUpcomingNextLesson(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`finding upcoming next lesson for studentId=${studentId}`)
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent('/lessons/upcoming/next', studentId), {
        headers: this.nextRequestIdHeader()
      })
  }

  findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    this.logger.log(`finding upcoming next lesson (multiple) for studentId=${studentId}`)
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(
      this.getUrlLessonTeacher('/lessons/upcoming/next-multiple', studentId), {
        headers: this.nextRequestIdHeader()
      })
  }

  finishLesson(teacherId: number, lessonId: number, progress: ApiLessonProgress, finishDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`finishing lesson with lessonId=${lessonId}`)
    let params = new HttpParams();
    if (finishDate) {
      params = params.append('finishDate', finishDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/finish`, teacherId),
      progress, {
        headers: this.nextRequestIdHeader(),
        params: params
      })
  }

  getAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    this.logger.log(`getting availabilities for path=${path}`)
    const params = new HttpParams()
      .append('path', path)
      .append('depth', depth.toString());

    return this.http.get<ItemAvailability[]>(
      this.getUrlCspa('/availability'), {
        headers: this.nextRequestIdHeader(),
        params
      })
  }

  getChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    this.logger.log(`getting chapters for path=${path}`)
    let params = new HttpParams();
    if (updatedAfter != null) {
      params = params.append('updatedAfter', updatedAfter.toString());
    }

    return this.http.get<Chapter[]>(
      this.getUrlCspa(`/sets/${path}`), {
        headers: this.nextRequestIdHeader(),
        params
      })
  }

  getCommits(studentId: number, filter: StudentCommitsFilter, pageable: Pageable): Observable<Page<ApiLessonCommit>> {
    this.logger.log(`getting commits for studentId=${studentId}`)

    return this.http.get<Page<ApiLessonCommit>>(
      this.getUrlLessonStudent('/lessons/commits', studentId), {
        headers: this.nextRequestIdHeader(),
        params: filter.apply(
          Pageable.appendPageableParams(new HttpParams(), pageable)
        )
      })
  }

  getCourseAllowedCompetences(courseCode: string): Observable<ApiCompetence[]> {
    this.logger.log(`getting course allowed competences for courseCode=${courseCode}`)

    return this.http.get<ApiCompetence[]>(
      this.getUrlLessonStudent(`/courses/${encodeURIComponent(courseCode)}/competences`), {
        headers: this.nextRequestIdHeader()
      })
  }

  getExerciseSets(): Observable<ExerciseSet[]> {
    this.logger.log(`getting exercise sets`)

    return this.http.get<ExerciseSet[]>(
      this.getUrlCspa('/sets'), {
        headers: this.nextRequestIdHeader()
      })
  }

  getLesson(studentId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`getting lesson with lessonId=${lessonId}`)

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}`, studentId), {
        headers: this.nextRequestIdHeader()
      })
  }

  getLessonById(teacherId: number, lessonId: number, studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`getting lesson with lessonId=${lessonId} for studentId=${studentId}`)

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}`, teacherId), {
        headers: this.nextRequestIdHeader()
      })
  }

  getLessons(studentId: number, pageable: Pageable, lessonType: LessonType, isTeacher: boolean, lang?: string): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.logger.log(`getting lessons for studentId=${studentId}`)

    let url = isTeacher
      ? this.getUrlLessonTeacher('/lessons', studentId)
      : this.getUrlLessonStudent('/lessons', studentId)
    switch (lessonType) {
      case LessonType.Upcoming: {
        url += '/upcoming';
        break;
      }
      case LessonType.Incomplete: {
        url += '/incomplete';
        break;
      }
      case LessonType.Past: {
        url += '/past';
        break;
      }
    }
    let params = Pageable.appendPageableParams(new HttpParams(), pageable);
    if (lang) params = params.append('productCode', lang);
    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      url, {
        headers: this.nextRequestIdHeader(),
        params: params
      })
  }

  getProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    this.logger.log(`getting product context for studentId=${studentId}`)

    return this.http.get<ApiProductContext>(
      this.getUrlLessonStudent(`/progress/${productCode}/context`, studentId),{
        headers: this.nextRequestIdHeader()
          .append(Constraints.cacheNameHeader, `student-${studentId}-progress-${productCode}-context`)
          .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString())
      })
  }

  getProductCourses(productCode: string): Observable<ApiCourse[]> {
    this.logger.log(`getting product courses for productCode=${productCode}`)

    return this.http.get<ApiCourse[]>(
      this.getUrlLessonTeacher(`/product/${productCode}/courses`),{
        headers: this.nextRequestIdHeader()
      })
  }

  getProductProgressEstimation(studentId: number, productCode: string): Observable<ApiLessonProgress[]> {
    this.logger.log(`getting product progress estimation for productCode=${productCode}`)

    return this.http.get<ApiLessonProgress[]>(
      this.getUrlLessonStudent(`/progress/${productCode}/promote-estimate`, studentId),{
        headers: this.nextRequestIdHeader()
      })
  }

  getProductsList(lang: string, currency: string): Observable<ApiProduct[]> {
    this.logger.log(`getting products list for lang=${lang}`)

    const params = new HttpParams()
      .append('lang', lang)
      .append('currency', currency);

    return this.http.get<ApiProduct[]>(
      this.getUrlLessonStudent('/products'),{
        headers: this.nextRequestIdHeader(),
        params: params
      })
  }

  getProfile(teacherId: number): Observable<ApiTeacherProfile> {
    this.logger.log(`getting profile for teacherId=${teacherId}`)

    return this.http.get<ApiTeacherProfile>(
      this.getUrlLessonTeacher('/profile', teacherId),{
        headers: this.nextRequestIdHeader()
      })
  }

  getStudentLessonBundles(studentId: number, filter: LessonBundleFilter, pageable: Pageable): Observable<Page<ApiLessonBundle>> {
    this.logger.log(`getting lesson bundle for studentId=${studentId}`)

    return this.http.get<Page<ApiLessonBundle>>(
      this.getUrlLessonStudent('/bundles', studentId),{
        headers: this.nextRequestIdHeader()
          .append(Constraints.cacheNameHeader, `student-${studentId}-bundles`)
          .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString()),
        params: filter.apply(
          Pageable.appendPageableParams(new HttpParams(), pageable)
        ),
      })
      .pipe(
        tap((bundlesPage) =>
          bundlesPage.content.forEach((bundle) => {
            bundle.date = Dates.parse(String(bundle.date));
            bundle.expiryDate = Dates.parse(String(bundle.expiryDate));
          })
        )
      )
  }

  getStudentProductContext(teacherId: number, studentId: number, productCode: string): Observable<ApiStudentProductContext> {
    this.logger.log(`getting product context for studentId=${studentId}`)

    return this.http.get<ApiStudentProductContext>(
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId),{
        headers: this.nextRequestIdHeader()
      })
  }

  getStudentProgress(studentId: number, teacherId: number, isTeacher: boolean): Observable<ApiLessonProgress[]> {
    this.logger.log(`getting progress for studentId=${studentId}`)

    const url = isTeacher
      ? this.getUrlLessonTeacher(`/students/${studentId}/progress`, teacherId)
      : this.getUrlLessonStudent('/progress', studentId);

    return this.http.get<ApiLessonProgress[]>(
      url,{
        headers: this.nextRequestIdHeader()
      })
  }

  getStudentTechnicalProfile(teacherId: number, studentId: number): Observable<ApiPersonTechnicalProfile> {
    this.logger.log(`getting technical profile for studentId=${studentId}`)

    return this.http.get<ApiPersonTechnicalProfile>(
      this.getUrlLessonTeacher(`/students/${studentId}/person/technical-profile`, teacherId),{
        headers: this.nextRequestIdHeader()
      })
  }

  listStudentWeekSchedules(studentId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    this.logger.log(`getting week schedules for studentId=${studentId}`)

    return this.http.get<ApiStudentSchedule[]>(
      this.getUrlBookingStudent(studentId, '/schedules'),{
        headers: this.nextRequestIdHeader(),
        params: {
          focusDate: focusDate.toISOString(),
        }
      })
  }

  getTeachers(studentId: number, teacherIds?: number[]): Observable<ApiTeacherProfile[]> {
    this.logger.log(`getting teachers`)
    let teacherIdsParam = new HttpParams();
    let headers = this.nextRequestIdHeader();

    if (teacherIds?.length > 0) {
      teacherIds.forEach(
        (id) => (teacherIdsParam = teacherIdsParam.append('id', id.toString()))
      )
    } else {
      headers = headers
        .append(Constraints.cacheNameHeader, `student-${studentId}-teachers`)
        .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString())
    }
    return this.http.get<ApiTeacherProfile[]>(
      this.getUrlLessonStudent('/teachers', studentId), {
      headers: headers,
      params: teacherIdsParam,
    });
  }

  getLessonScheduleEventReference(
    studentId: number,
    scheduleId: number
  ): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {

    const url = this.getUrlBookingV2Student( studentId, `/schedule/${scheduleId}/event`);
    return this.http.get<LessonScheduleEventReference<ApiLessonEventModel>>(url)
  }

  listTeachersWeekAvailability(studentId: number, focusDate: Date, productCode: string): Observable<ApiTeacherWorktime[]> {
    this.logger.log(`getting week availability for studentId=${studentId}`)

    return this.http.get<ApiTeacherWorktime[]>(
      this.getUrlBookingStudent(studentId, `/availability/product/${productCode}`),{
        headers: this.nextRequestIdHeader(),
        params: {
          focusDate: focusDate.toISOString(),
        }
      })
  }


  listStudentSchoolTeachersAvailability(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<SimpleTeacherProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Student(studentId,'/teacher-availability');
    return this.http.get<SimpleTeacherProductAvailability[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        productCode: productCode
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map(worktime => Object.assign(new SimpleTeacherProductAvailability(), worktime)))
    );
  }

  listStudentLessonSchedules(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<SimpleScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Student( studentId , '/schedules');
    return this.http.get<SimpleScheduleEvents[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        productCode: productCode,
        type: "lesson"
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  giveStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<void> {
    this.logger.log(`giving student product gift for studentId=${studentId}`)

    return this.http.post<void>(
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId),
      {},
      {
        headers: this.nextRequestIdHeader(),
      })
  }

  listLessonHistory(teacherId: number, lessonId: number, studentId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.logger.log(`listing lesson history for lessonId=${lessonId}`)

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/history`, teacherId),
      {
        headers: this.nextRequestIdHeader(),
        params: Pageable.appendPageableParams(new HttpParams(), pageable)
      })
  }

  public listAvailabilities(
    teacherId: number,
    focusDate: Date
  ): Observable<SimpleProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Teacher( teacherId , '/availabilities/events');
    return this.http.get<SimpleProductAvailability[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      }
    }).pipe(
      tap(schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map( wt => Object.assign(new SimpleProductAvailability(), wt)))
    );
  }

  public listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url =this.getUrlBookingV2Teacher( teacherId , '/lessons');

    return this.http.get<SimpleScheduleEvents[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  public deleteAvailability(
    teacherId: number,
    availabilityId: number,
    eventId: number,
    applyRecurring: boolean
  ): Observable<void> {
    const url =
      this.getUrlBookingV2Teacher(
      teacherId ,
      `/availabilities/${availabilityId}/events/${eventId}`)

    return this.http.delete<void>(url, {
      params: {
        'applyFuture': applyRecurring.toString()
      }
    });
  }

  public createAvailability(
    teacherId: number,
    request: ProductAvailabilityRequest
  ): Observable<ScheduleReference> {
    const url =
      this.getUrlBookingV2Teacher( teacherId , '/availabilities');
    return this.http.post<ScheduleReference>(url, request)
  }

  listLessonTypesReportForTeacher(teacherId: number, dateFrom: Date, dateTo: Date, productCode: string): Observable<EntityRelatedRow<ApiTeacherProfile>[]> {
    this.logger.log(`listing lesson types report for teacherId=${teacherId}`)

    const url =
      environment.lessonEndpoint +
      '/reports/teacher/' +
      teacherId +
      '/lesson-types';

    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (productCode) {
      params = params.append('productCode', productCode);
    }

    return this.http.get<EntityRelatedRow<ApiTeacherProfile>[]>(
      url,
      {
        headers: this.nextRequestIdHeader(),
        params: params
      })
  }

  listWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    this.logger.log(`listing week schedules for teacherId=${teacherId}`)

    return this.http.get<ApiStudentSchedule[]>(
      this.getUrlBookingTeacher(teacherId, '/schedules'),
      {
        headers: this.nextRequestIdHeader(),
        params: {
          focusDate: focusDate.toISOString(),
        }
      })
  }

  listWorktimeWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiTeacherWorktime[]> {
    this.logger.log(`listing worktime week schedules for teacherId=${teacherId}`)

    return this.http.get<ApiTeacherWorktime[]>(
      this.getUrlBookingTeacher(teacherId, '/worktimes'),
      {
        headers: this.nextRequestIdHeader(),
        params: {
          focusDate: focusDate.toISOString(),
        }
      })
  }

  notifyStudentAboutLessonStart(teacherId: number, lessonId: number): Observable<void> {
    this.logger.log(`notifying student about lesson start for lessonId=${lessonId}`)

    return this.http.post<void>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/notifications/lesson-start`, teacherId),
      {},
      {
        headers: this.nextRequestIdHeader(),
      })
  }

  postLessonComment(teacherId: number, lessonId: number, message: ApiLessonMessage): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`posting lesson comment for lessonId=${lessonId}`)

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/messages`, teacherId),
      message,
      {
        headers: this.nextRequestIdHeader(),
      })
  }

  postLessonMessageForStudent(teacherId: number, lessonId: number, studentId: number, message: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`posting lesson message for lessonId=${lessonId}`)

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/messages`, teacherId),
      message,
      {
        headers: this.nextRequestIdHeader(),
      })
  }

  queryForStudentsById(teacherId: number, studentIds: number[]): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
    this.logger.log(`querying for students`)

    if (!studentIds || studentIds.length === 0) {
      return of(Page.empty<null>());
    }

    let params = new HttpParams();
    studentIds.forEach((id) => (params = params.append('id', id.toString())));

    return this.http.post<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>>(
      this.getUrlLessonTeacher('/students', teacherId),
      {
        headers: this.nextRequestIdHeader(),
        params: params,
      })
  }

  registerLessonFlag(teacherId: number, lessonId: number, lessonFlag: ApiLessonFlag): Observable<ApiLessonFlag> {
    this.logger.log(`querying for students`)

    return this.http.post<ApiLessonFlag>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/flags`, teacherId),
      lessonFlag,
      {
        headers: this.nextRequestIdHeader()
      })
  }

  reserveSchedule(studentId: number, schedule: ApiStudentSchedule): Observable<ApiStudentSchedule> {
    this.logger.log(`reserving schedule for studentId=${studentId}`)

    return this.http.put<ApiStudentSchedule>(
      this.getUrlBookingStudent(studentId, '/schedules'), schedule,
      {
        headers: this.nextRequestIdHeader()
      });
  }

  saveStudentProductContext(teacherId: number, studentId: number, productCode: string, context: ApiProductContext): Observable<ApiProductContext> {
    this.logger.log(`saving student product context studentId=${studentId}`)

    return this.http.put<ApiProductContext>(
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId),
      context,
      {
        headers: this.nextRequestIdHeader()
      });
  }

  squanderLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`squandering lesson with lessonId=${lessonId} for studentId=${studentId}`)

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}/squander`, studentId),
      reason,
      {
        headers: this.nextRequestIdHeader()
      });
  }

  squanderLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`squandering lesson with lessonId=${lessonId} by teacherId=${teacherId}`)

    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/squander`, teacherId),
      reason,
      {
        headers: this.nextRequestIdHeader()
      });
  }

  startLesson(teacherId: number, lessonId: number, startDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`starting lesson with lessonId=${lessonId}`)

    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', startDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/start`, teacherId),
      {}, {
        headers: this.nextRequestIdHeader(),
        params: params }
    );
  }

  updateLessonProgress(teacherId: number, lessonId: number, progress: ApiLessonProgress): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`updating lesson progress with lessonId=${lessonId}`)

    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/progress`, teacherId),
      progress,{
        headers: this.nextRequestIdHeader()
      });
  }

  updateLessonType(teacherId: number, lessonId: number, lessonType: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`updating lesson type with lessonId=${lessonId}`)

    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/type`, teacherId),
      lessonType,{
        headers: this.nextRequestIdHeader()
      });
  }

  getCountries(locale?: string): Observable<ApiCountry[]> {
    this.logger.log(`listing countries`)

    let params = new HttpParams();
    if (locale) {
      params = params.append('locale', locale);
    }

    return this.http.get<ApiCountry[]>(
      environment.lessonEndpoint + '/public/countries',
      {
        headers: this.nextRequestIdHeader(),
         params: params
      })
  }

  updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string): Observable<ApiStudentProductContext> {
    this.logger.log(`updating product version to ${version}`)

    return this.http.patch<ApiStudentProductContext>(
      `${environment.lessonEndpoint}/teacher/${teacherId}/students/${studentId}/product-context/${productCode}/product-version`,
      {
        version
      }, {
        headers: this.nextRequestIdHeader()
      }
    );
  }

  getProvaContext(studentId: number, productCode?: string): Observable<ApiProvaContext[]> {
    this.logger.log(`getting prova context for studentId=${studentId}`)

    let params = new HttpParams();
    if (productCode) {
      params = params.append('productCode', productCode);
    }
    return this.http.get<ApiProvaContext[]>(
      this.getUrlLessonStudent(`/prova-context`, studentId),
      {
        headers: this.nextRequestIdHeader(),
        params: params
      });
  }

  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    this.logger.log(`adding product context (${productCode}) for studentId=${studentId}`)

    const url = this.getUrlLessonStudent('/progress/' + productCode + '/context', studentId)

    return this.http.post<ApiProductContext>(url, {},
      {
        headers: this.nextRequestIdHeader()
      });
  }

  chargeFreeCredit(studentId: number, productCode: string): Observable<ApiLessonBundle> {
    this.logger.log(`adding free credit (${productCode}) for studentId=${studentId}`)

    const url = this.getUrlLessonStudent('/bundle/' + productCode + '/free', studentId);
    return this.http.post<ApiLessonBundle>(url, {},
      {
        headers: this.nextRequestIdHeader()
      });
  }

  reserveScheduleV2(studentId: number, request: SimpleLessonScheduleRequest): Observable<SimpleScheduleEvents> {
    this.logger.log(`reserving schedule for studentId=${studentId}`)

    return this.http.post<SimpleScheduleEvents>(
      this.getUrlBookingV2Student(studentId, '/schedules'), request,
      {
        headers: this.nextRequestIdHeader(),
        params: {
          type: "col_lesson"
        }
      }).pipe(
      tap( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate)))
      )
    )
  }
}
