import { Injectable } from '@angular/core';
import {map, Observable, of, switchMap} from 'rxjs';
import { AndroidBridgeBase } from 'src/app/mobile/api-services/bridge/android-bridge-base';
import { PropertiesNativeServiceApi } from '../properties-native-api';
import {LogsService} from "../../../utils/services/logs.service";

@Injectable({
  providedIn: 'root',
})
export class AndroidPropertiesNativeApi implements PropertiesNativeServiceApi {
  constructor(private bridge: AndroidBridgeBase,
              private logger: LogsService) {}

  putValue(key: string, value: any): Observable<any> {
    this.logger.log(`[android] storing property value for ${key}`, value);
    return this.bridge
      .callForResponse<string>(
        `readProperties()`,
        (id) => window.nativeBridge.readProperties(id))
      .pipe(
        map((properties) => {
          const propertiesParsed = properties ? properties : {}
          propertiesParsed[key] = value;
          return JSON.stringify(propertiesParsed);
        }),
        switchMap((properties) =>
          this.bridge.callForResponse<void>(
            `saveProperties()`,
            (id) =>
            window.nativeBridge.saveProperties(id, properties)
          )
        ),
        map( _ => value)
      );
  }

  getValue(key: string): Observable<any> {
    return this.bridge
      .callForResponse<string>(
        "readProperties",
        (id) => window.nativeBridge.readProperties(id))
      .pipe(
        map((properties) => {
          const propertiesParsed = properties ? properties : {};
          this.logger.log(`[android] getting property value for ${key}`, propertiesParsed[key]);
          return propertiesParsed[key] ? propertiesParsed[key] : null
        })
      );
  }
}
