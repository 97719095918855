<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'LESSONS.TITLE'"
           [redirectLink]="'../../../../../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a class="pe-none">
                  {{ "NAVIGATION.LESSONS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page" *ngIf="chosenCourse">
                {{ CourseFilterComponent.getLanguageTranslation(chosenCourse) | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <app-course-filter (languageEmitter)="chosenCourse = $event"></app-course-filter>
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-activate-course-modal
      [studentId]="studentId"
      [langCode]="chosenCourse?.filter"
    ></app-activate-course-modal>
    <!--FUTURE LESSONS-->
    <div class="row mb-4">
      <div class="col-auto d-flex align-items-center mb-3 mb-sm-0">
        <app-icon name="group" class="icon-bg bg-gray icon-blue ms-2"></app-icon>
        <h6 class="m-0">{{ "COL.LESSONS.LIST.FUTURE_LESSONS" | translate }}</h6>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-primary d-block w-100 rounded-pill"
                routerLink="/student/reserve-lesson"
                [queryParams]="{language: chosenCourse.filter}">
          {{ "BUTTON.RESERVE_LESSON" | translate}}
        </button>
      </div>
    </div>

    <div class="row" *ngIf="lessons?.content.length > 0; else noResults">
      <div
        class="col-12 col-lg-6 col-xxl-4 mb-3"
        *ngFor="let lesson of lessons?.content"
      >
        <app-lesson-card [lesson]="lesson" [redirectPathPrefix]="'./lesson'"></app-lesson-card>
      </div>
    </div>
    <ng-template #noResults>
      <div class="row">
        <p class="text-center fs-5 text-muted m-0">
          {{ "LESSONS.NO_RESULTS" | translate }}
        </p>
      </div>
    </ng-template>
    <!--PAST LESSONS-->
    <app-student-lessons
      [studentId]="studentId"
      [lessonType]="lessonTypes.Past"
      [langCode]="chosenCourse?.filter"
    ></app-student-lessons>
  </div>
</div>
