import { Component, Input, OnInit } from '@angular/core';
import { LangProductMapper } from '../../../utils/lang-mappers';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() langCode: string;
  @Input() isSupported: boolean;

  constructor() {}

  ngOnInit(): void {}

  getCourseName() {
    return LangProductMapper.mapLangCodeToLangName(this.langCode);
  }

  getCourseClass() {
    return this.langCode === 'en.bs'
      ? 'course-enbus'
      : `course-${this.langCode}`;
  }
}
