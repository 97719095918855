<!-- spinner -->
<div
  [ngClass]="{'d-none' : !loading }"
  class="row justify-content-center pt-4">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div
  [ngClass]="{ 'd-none' : loading }"
  class="row justify-content-center flex-wrap pt-4 mb-5"
>
  <app-modal #summaryModal [scrollable]="false">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h5 class="modal-title"
       >
          {{ "EXERCISES.HOST.DICTATION.SUMMARY.SUMMARY" | translate }} ({{
            getScore()
          }}%)
        </h5>
        <button
          type="button"
          class="btn btn-close btn-close-white"
          (click)="closeHostContract()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <span [ngClass]="item.kind" *ngFor="let item of getSummary()"
          >{{ item.content || "..." }}
        </span>
        <div *ngIf="isAnswerShown" class="user-select-none">
          <hr class="text-primary" />
          <p class="text-muted text-uppercase fs-6 mb-1" 
         >
            {{ "EXERCISES.HOST.DICTATION.SUMMARY.CORRECT" | translate }}
          </p>
          <span>{{ getAnswer() }}</span>
        </div>
      </div>
      <div class="modal-footer text-right border-0 rounded-bottom">
        <button
          (click)="toggleAnswer()"
          class="btn btn-outline-primary rounded-circle p-2 me-auto ms-0"
        >
          <app-icon
            [name]="isAnswerShown ? 'visibility-off' : 'visibility'"
            class="icon-blue"
          ></app-icon>
        </button>
        <button
          *ngIf="mayCorrect()"
          class="btn btn-secondary rounded-pill py-2 px-3"
          (click)="closeHostContract()"
        >
          {{ "BUTTON.CORRECT_MISTAKES" | translate }}
        </button>
        <button
          class="btn btn-primary rounded-pill py-2 px-3 text-capitalize"
          (click)="submitAnswer()"
        >
          {{ "BUTTON.CONTINUE" | translate }}
        </button>
      </div>
    </div>
  </app-modal>

  <!-- video -->

  <ng-container *ngIf="!isMobileView">
    <div
      *ngIf="videoSource; else audio"
      class="col-md-7 video-panel d-flex me-xl-4"
    >
      <div class="ratio ratio-16x9">
        <video #videoPlayer
               (loadedmetadata)="loading = false" (timeupdate)="updateProgress($event)"
               [attr.disablePictureInPicture]="isExam ? true : null"
               playsinline
        >
          <source (error)="errorMessageVideo()" [src]="videoSource" type="video/mp4"/>
        </video>
      </div>
    </div>
    <!-- alternative audio -->
    <ng-template #audio>
      <div class="col-7 col-lg-5 d-none d-md-block text-center order-1">
        <audio #audioPlayer (timeupdate)="updateProgress($event)">
          <source (loadedmetadata)="loading = false" [src]="getAudioSource()"
                  type="audio/mp3"/>
        </audio>
      </div>
    </ng-template>
  </ng-container>

  <!-- space for answer -->
  <div class="col-10 col-md-9 col-lg-8 col-xl bubble tri-right mb-4"
  [ngClass]="{ 'pb-2' : isSpanish }">
    <textarea
      *ngIf="question?.answer"
      type="text"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      #answer="ngModel"
      [(ngModel)]="question.answer.answer"
      class="answer w-100 border-0 caret-black"
      [ngClass]="{ 'h-100' : !isSpanish }"
      placeholder="{{ 'EXERCISES.HOST.DICTATION.TYPE_HERE' | translate }}"
    >
    </textarea>
    <!-- spanish keyboard -->
    <div *ngIf="isSpanish">
      <button
        *ngFor="let letter of spanishLetters"
        type="button"
        (click)="addLetter(letter)"
        class="btn btn-secondary btn-spanish-letter me-1 my-1 rounded-circle"
      >
        {{ letter }}
      </button>
    </div>
  </div>

  <!-- audio for mobile -->
  <ng-container *ngIf="isMobileView;">
    <div class="col-10 text-center">
      <audio #audioPlayer (timeupdate)="updateProgress($event)" class="w-100">
        <source (loadedmetadata)="loading = false" [src]="getAudioSource()" playsinline
                type="audio/mp3"/>
      </audio>
    </div>
  </ng-container>
</div>
