import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { of, tap } from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import {
  ApiLearningUnitTeacher,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiPersonalProfile
} from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { Page, Pageable } from 'src/app/utils/pageable';

@Component({
  selector: 'app-lesson-history',
  templateUrl: './lesson-history.component.html',
  styleUrls: ['./lesson-history.component.scss'],
})
export class LessonHistoryComponent implements OnInit {

  private _teacherId: number;
  private _lessonId: number;
  private _studentId: number;

  pageable: Pageable;
  results: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;

  @Input()
  set teacherId(id: number) {
    this._teacherId = id;
    this.loadLessonHistoryPage(this.pageable).subscribe();
  }
  @Input()
  set lessonId(id: number) {
    this._lessonId = id;
    this.loadLessonHistoryPage(this.pageable).subscribe();
  }
  @Input()
  set studentId(id: number) {
    this._studentId = id;
    this.loadLessonHistoryPage(this.pageable).subscribe();
  }

  constructor(private teacherRest: TeacherRestServiceImpl) {
    this.pageable = new Pageable(0, 5, [
      'lessonStudent.metricDetails.plannedStartDate,DESC',
    ]);
  }

  ngOnInit(): void {}

  loadLessonHistoryPage(pageable: Pageable) {
    if (!this._teacherId || !this._lessonId || !this._studentId)
      return of(null);
    return this.teacherRest
      .listLessonHistory(
        this._teacherId,
        this._lessonId,
        this._studentId,
        pageable
      )
      .pipe(
        tap((lessonHistoryPage) => {
          this.results = lessonHistoryPage;
        })
      );
  }

  getHistoryDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.lessonMetric) {
      return null;
    }
    if (!lesson.lessonMetric.started) {
      return lesson.lessonMetric.plannedStartDate;
    }
    return lesson.lessonMetric.started;
  }

  getTeacherName(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.teacher || !lesson.teacher.person) {
      return '';
    }
    return PersonUtils.getPersonName(lesson.teacher.person);
  }

  getFlagDescription(type: string) {
    return ApiLessonFlag.getFLagDescription(type);
  }

  onPageChange(pageable: Pageable) {
    this.loadLessonHistoryPage(pageable).subscribe();
  }

  getHistory() {
    return this.results?.content;
  }
}
