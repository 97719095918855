<h4 class="mb-3">{{ "CONTACT_US.RESULT.HEADER" | translate }}</h4>
<p class="text-muted mb-2">{{ "CONTACT_US.RESULT.CONTENT_1" | translate }}</p>
<p class="text-muted mb-5">{{ "CONTACT_US.RESULT.CONTENT_2" | translate }}</p>
<div class="bg-img py-5">
  <div class="position-relative">
    <button
      type="button"
      class="btn btn-primary btn-lg rounded-pill my-5"
      [routerLink]="getPath()"
    >
      <app-icon class="icon-white" name="angle-left"></app-icon>
      <span class="ms-3">{{
        "CONTACT_US.RESULT.BACK_TO_" + getDestination() | translate
      }}</span>
    </button>
  </div>
</div>
