<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'LESSONS.TITLE'"
           [redirectLink]="'../../../../'"
           [redirectTranslationCodes]="'NAVIGATION.LESSONS'"
           class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LESSONS.TITLE" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row position-relative mb-2 mb-md-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-teacher-lesson-details></app-teacher-lesson-details>
  </div>
</div>
