import {Injectable} from '@angular/core';
import {Observable, switchMap, tap} from 'rxjs';
import {ApiCountry} from 'src/app/auth_profile/model/casa/casa-model';
import {NativeServiceApiProvider} from 'src/app/services/native-api-provider.service';
import {Page, Pageable} from 'src/app/utils/pageable';
import {LessonType} from '../../model/calendar';
import {
  ApiCourse,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiProductGift, ApiStudentProductContext,
  ApiTeacherProfile,
} from '../../model/rest-model';
import {TeacherRestService} from "./teacher-rest.service";
import {Dates} from "../../../utils/calendar-utils";
import {
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../model/rest-model-v2";
import {TeacherBookRestBridgeService} from "./teacher-book-rest-bridge.service";
import {environment} from "../../../../environments/environment";
import {TeacherBookRestServiceImpl} from "./teacher-book-rest.service";

@Injectable({
  providedIn: 'root',
})
export class TeacherRestServiceImpl implements TeacherRestService {
  private bookingRest: TeacherBookRestBridgeService | TeacherBookRestServiceImpl

  constructor(public provider: NativeServiceApiProvider,
              private bookingBridge: TeacherBookRestBridgeService,
              private bookingRestV2: TeacherBookRestServiceImpl) {
    this.bookingRest = environment.scheduleBridgeEnabled?
      bookingBridge : bookingRestV2
  }

  createVideoClassroom(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.createVideoClassroom(teacherId, lessonId)));
  }

  createSkypeClassroom(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.createSkypeClassroom(teacherId, lessonId)));
  }

  notifyStudentAboutLessonStart(
    teacherId: number,
    lessonId: number
  ): Observable<void> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.notifyStudentAboutLessonStart(teacherId, lessonId)
        )
      );
  }

  commitLessonBooking(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.commitLessonBooking(teacherId, lessonId)));
  }

  startLesson(
    teacherId: number,
    lessonId: number,
    startDate: Date
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.startLesson(teacherId, lessonId, startDate))
      );
  }

  getLessonById(
    teacherId: number,
    lessonId: number,
    studentId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getLessonById(teacherId, lessonId, studentId)),
        tap((lesson) => {
          if (!lesson) {
            return;
          }
          lesson.createDate = Dates.parse(String(lesson.createDate));
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        })
      );
  }

  getLessons(
    teacherId: number,
    pageable: Pageable,
    lessonType: LessonType,
    langCode: string
  ) {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getLessons(teacherId, pageable, lessonType, true, langCode)
        ),
        tap((lessonsPage) =>
          lessonsPage.content.forEach((lesson) => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(
              String(lesson.lessonMetric.plannedStartDate)
            );
            if (lessonType === LessonType.Past)
              lesson.lessonMetric.started = Dates.parse(
                String(lesson.lessonMetric.started)
              );
          })
        )
      );
  }

  getStudentProgress(
    teacherId: number,
    studentId: number
  ): Observable<ApiLessonProgress[]> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getStudentProgress(studentId, teacherId, true))
      );
  }

  estimateNextProgress(
    teacherId: number,
    studentId: number,
    baseProgress: ApiLessonProgress,
    limit?: number
  ): Observable<ApiLessonProgress[]> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.estimateNextProgress(teacherId, studentId, baseProgress, limit)
        )
      );
  }

  getStudentProductContext(
    teacherId: number,
    studentId: number,
    productCode: string
  ): Observable<ApiStudentProductContext> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getStudentProductContext(teacherId, studentId, productCode)
        )
      );
  }

  getProductCourses(productCode: string): Observable<ApiCourse[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getProductCourses(productCode)));
  }

  getCountries(): Observable<ApiCountry[]> {
    return this.provider.casa().pipe(switchMap((api) => api.getCountries()));
  }

  getStudentTechnicalProfile(
    teacherId: number,
    studentId: number
  ): Observable<ApiPersonTechnicalProfile> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getStudentTechnicalProfile(teacherId, studentId))
      );
  }

  cancelLesson(
    teacherId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.cancelLessonByTeacher(teacherId, lessonId, reason)
        ),
        tap((lesson) => {
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        })
      );
  }

  squanderLesson(
    teacherId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.squanderLessonByTeacher(teacherId, lessonId, reason)
        ),
        tap((lesson) => {
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        })
      );
  }

  updateLessonType(
    teacherId: number,
    lessonId: number,
    newType: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.updateLessonType(teacherId, lessonId, newType))
      );
  }

  finishLesson(
    teacherId: number,
    lessonId: number,
    progress: ApiLessonProgress,
    finishDate: Date
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.finishLesson(teacherId, lessonId, progress, finishDate)
        )
      );
  }

  updateLessonProgress(
    teacherId: number,
    lessonId: number,
    progress: ApiLessonProgress
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.updateLessonProgress(teacherId, lessonId, progress)
        )
      );
  }

  saveStudentProductContext(
    teacherId: number,
    lessonId: number,
    productCode: string,
    context: ApiProductContext
  ): Observable<ApiProductContext> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.saveStudentProductContext(
            teacherId,
            lessonId,
            productCode,
            context
          )
        )
      );
  }

  postLessonComment(
    teacherId: number,
    lessonId: number,
    message: ApiLessonMessage
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.postLessonComment(teacherId, lessonId, message))
      );
  }

  postLessonMessageForStudent(
    teacherId: number,
    lessonId: number,
    studentId: number,
    message: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.postLessonMessageForStudent(
            teacherId,
            lessonId,
            studentId,
            message
          )
        )
      );
  }

  findStudentProductGift(
    teacherId: number,
    lessonId: number,
    courseCode: string
  ): Observable<ApiProductGift> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.findStudentProductGift(teacherId, lessonId, courseCode)
        )
      );
  }

  giveStudentProductGift(
    teacherId: number,
    lessonId: number,
    courseCode: string
  ): Observable<void> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.giveStudentProductGift(teacherId, lessonId, courseCode)
        )
      );
  }

  registerLessonFlag(
    teacherId: number,
    lessonId: number,
    flag: ApiLessonFlag
  ): Observable<ApiLessonFlag> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.registerLessonFlag(teacherId, lessonId, flag))
      );
  }

  listLessonHistory(
    teacherId: number,
    lessonId: number,
    studentId: number,
    pageable: Pageable
  ): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.listLessonHistory(teacherId, lessonId, studentId, pageable)
        )
      );
  }

  listAvailabilities(
    teacherId: number,
    focusDate: Date
  ): Observable<SimpleProductAvailability[]> {
    return this.provider
      .col()
      .pipe(
        switchMap(_ => this.bookingRest.listAvailabilities(teacherId, focusDate))
      );
  }
  listLessons(
    teacherId: number,
    focusDate: Date
  ): Observable<SimpleScheduleEvents[]> {
    return this.provider
      .col()
      .pipe(switchMap(_ => this.bookingRest.listLessons(teacherId, focusDate)));
  }

  queryForStudentsById(
    teacherId: number,
    studentIds: number[]
  ): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.queryForStudentsById(teacherId, studentIds))
      );
  }

  getProfile(teacherId: number): Observable<ApiTeacherProfile> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getProfile(teacherId)));
  }

  deleteAvailability(
    teacherId: number,
    availabilityId: number,
    eventId: number,
    applyRecurring: boolean
  ): Observable<void> {
    return this.provider
      .col()
      .pipe(
        switchMap(_ =>
          this.bookingRest.deleteAvailability(teacherId, availabilityId, eventId, applyRecurring)
        )
      );
  }
  public createAvailability(
    teacherId: number,
    request: ProductAvailabilityRequest
  ): Observable<ScheduleReference> {
    return this.provider
      .col()
      .pipe(switchMap(_ => this.bookingRest.createAvailability(teacherId, request)));
  }

  listLessonTypesReportForTeacher(
    teacherId: number,
    dateFrom: Date,
    dateTo: Date,
    productCode: string
  ) {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.listLessonTypesReportForTeacher(
            teacherId,
            dateFrom,
            dateTo,
            productCode
          )
        )
      );
  }

  updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string)  {
    return this.provider.col().pipe(
      switchMap(api => api.updateProductVersion(teacherId, studentId, productCode, version))
    )
  }
}
