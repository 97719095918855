import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {Observable, of, switchMap, tap} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import {LogsService} from "../../utils/services/logs.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SchoolGuardService {

  private schoolId
  constructor(private authService: AuthorizationServiceProvider,
              private router: Router,
              private logger: LogsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const schoolId = route.data['schoolId'] || null
    this.logger.log(`route schoolId=${schoolId}`)
    return this.getSchoolId().pipe(
      switchMap(_ => {
        // for callanonline students
        if(this.schoolId === 1)
          if(schoolId === 1 && environment.colFunctionality)
            return of(true)
          else
           this.router.navigateByUrl('/forbidden').then()
        // for other students
        else if(this.schoolId !== 1)
          if(schoolId !== 1)
            return of(true)
          else
            this.router.navigateByUrl('/forbidden').then()
        return of(false)
      })
    )
  }

  private getSchoolId(): Observable<Number> {
    if(this.schoolId)
      return of(this.schoolId)
    else
      return this.authService.getAuthDetailsService().pipe(
        switchMap(authDetailsApi => authDetailsApi.getSelfSchoolId()),
        tap(schoolId => {
          this.schoolId = schoolId
          this.logger.log(`user schoolId=${this.schoolId}`)
        })
      )
  }
}
