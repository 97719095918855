import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

class AnswerRequest {
  constructor(
  public getNewAnswer: boolean,
  public answerType: string
  ) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class QuestionService {
    answerRequest = new ReplaySubject(1)
    answer = new ReplaySubject(1);
    
    askForAnswer(getNewAnswer: boolean, answerType: string) {
      this.answerRequest.next(new AnswerRequest(getNewAnswer, answerType))
    }
  
    constructor() { }
  }