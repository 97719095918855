<div class="card mb-4 mb-md-5">
  <div class="card-header">
    <div class="row">
      <div class="col d-flex">
        <h6 class="my-auto">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.TITLE' | translate }}</h6>
      </div>
      <div class="col-auto ms-auto">
        <paginator
          [data]="results"
          [pageable]="pageable"
          (newPage)="onPageChange($event)"
          class="mb-4"
        ></paginator>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm white-text">
        <thead>
          <tr class="table-row table-row-head">
            <th></th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.DATE' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.START' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.NWP' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.READ' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.HEADWORD' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.TUTOR' | translate }}</th>
            <th class="small">{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.COMMENT' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let lesson of getHistory()" class="table-row">
            <td class="small">{{ lesson.lessonType }}</td>
            <td class="small">
              {{ getHistoryDate(lesson) | date : "dd/MM/yy HH:mm" }}
            </td>
            <td class="small">
              {{
                lesson.progressCommited?.start
                  ? lesson.progressCommited?.start
                  : "-"
              }}
            </td>
            <td class="small">
              {{
                lesson.progressCommited?.nwp
                  ? lesson.progressCommited?.nwp
                  : "-"
              }}
            </td>
            <td class="small">
              {{
                lesson.progressCommited?.reading
                  ? lesson.progressCommited?.reading
                  : "-"
              }}
            </td>
            <td class="small">
              {{
                lesson.progressCommited?.hw ? lesson.progressCommited?.hw : "-"
              }}
            </td>
            <td class="small">{{ getTeacherName(lesson) }}</td>
            <td
              [ngClass]="{
                'text-center': !(
                  lesson.message.lessonComment ||
                  lesson.message.privateLessonComment
                )
              }"
            >
              <span
                *ngIf="
                  !(
                    lesson.message.lessonComment ||
                    lesson.message.privateLessonComment
                  )
                "
                >-</span
              >
              <span class="text-break" *ngIf="lesson.message.lessonComment"
                >{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.LESSON_COMMENT' | translate }} {{ lesson.message.lessonComment }}<br
              /></span>
              <span
                class="text-break"
                *ngIf="lesson.message.privateLessonComment"
                >{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.PRIVATE_COMMENT' | translate }} {{ lesson.message.privateLessonComment
                }}<br
              /></span>
            </td>
            <td class="text-end">
              <div *ngIf="lesson.flags.length > 0" class="lesson-table-tooltip">
                <button
                  type="button"
                  class="lesson-table-tooltip btn border-0 btn-sm p-0"
                >
                  <app-icon name="flag" class="icon-blue"></app-icon>
                </button>
                <div class="lesson-table-tooltip-content p-2 rounded shadow">
                  <ul class="list-unstyled mb-0">
                    <li *ngFor="let flag of lesson.flags">
                      <strong>{{ getFlagDescription(flag.flagType) }}</strong
                      ><span *ngIf="flag.description"
                        >:<br />
                        {{ 'COL.TEACHER.LESSON_DETAILS.COMMITED_PROGRESS.FLAG_DESCRIPTION' | translate }} {{ flag.description }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="lesson.studentMessage" class="lesson-table-tooltip">
                <button class="btn btn-comment px-0 border-0" type="button">
                  <i class="fas fa-comments"></i>
                </button>
                <div class="lesson-table-tooltip-content p-2 rounded shadow">
                  <span
                    >{{ 'COL.TEACHER.LESSON_DETAILS.HISTORY.MSG_TO_STUDENT' | translate }} {{ lesson.studentMessage }}</span
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
