import { HttpParams } from "@angular/common/http";

export class Pageable {

  size: number;
  page: number;
  sort: string[];

  constructor(page: number, size: number, sort: string[]) {
    this.size = size;
    this.page = page;
    this.sort = sort;
  }

  public getHttpParams(): HttpParams {
    return this.appendParams(new HttpParams());
  }

  public appendParams(params: HttpParams): HttpParams {
    let result = params;
    if (this.page != null) {
      result = result.append('page', this.page.toString());
    }
    if (this.size != null) {
      result = result.append('size', this.size.toString());
    }
    if (this.sort != null) {
      this.sort.forEach( s => {
        result = result.append('sort', s );
      })
    }
    return result;
  }
  
    /**
  return the http params with pagination applied. Method works even if pageable from
  argument is an empty value. Params will be unchanged then.
    */
  public static appendPageableParams(params: HttpParams, pageable: Pageable) {
      return pageable.appendParams(params);
  }

  public static of(page: number, size: number, sort: string[]): Pageable {
    return new Pageable(page, size, sort);
  }

  public static default(): Pageable {
      return new Pageable(0, 10, null);
  }

  public static withSize(size: number): Pageable {
      return new Pageable(0, size, null);
  }

  next(): Pageable {
    return Pageable.of(this.page + 1, this.size, this.sort);
  }

  prev() {
    return Pageable.of(this.page - 1, this.size, this.sort);
  }

  first() {
    return Pageable.of(0, this.size, this.sort);
  }
}
  
export class ResultPageableSort {
  sorted: boolean;
  unsorted: boolean;
}

export class ResultPageable {
  sort: ResultPageableSort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

export class Page<T> {
  content: T[];
  totalElements: number;
  pageable: ResultPageable;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  first: boolean;
  sort: ResultPageableSort;
  numberOfElements: number;
  empty: boolean;

  public static empty<E>(): Page<E> {
    const res = new Page<E>();
    res.content = [];
    res.totalElements = 0;
    res.totalPages = 0;
    res.last = true;
    res.size = 10;
    res.number = 0;
    res.first = true;
    res.numberOfElements = 0;
    return res;
  }
}

export class QueryBase {
  constructor(public pageable: Pageable) {
  }
}

// specific query for the schedule example
export class ScheduleQuery extends QueryBase {
    public queryString: string

    constructor(pageable: Pageable) {
        super(pageable);
    }
}