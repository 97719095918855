import { Injectable } from '@angular/core';
import {map, Observable, of, switchMap} from 'rxjs';
import { IosBridgeBase } from 'src/app/mobile/api-services/bridge/ios-bridge-base';
import { PropertiesNativeServiceApi } from '../properties-native-api';
import {LogsService} from "../../../utils/services/logs.service";

@Injectable({
  providedIn: 'root',
})
export class IosPropertiesNativeApi implements PropertiesNativeServiceApi {
  constructor(private bridge: IosBridgeBase,
              private logger: LogsService) {}

  putValue(key: string, value: any): Observable<any> {
    this.logger.log(`[ios] storing property value for ${key}`, value);
    return this.bridge.callForResponse<string>('getProperties', {}).pipe(
      map((properties) => {
        const propertiesParsed = properties ? properties : {};
        propertiesParsed[key] = value;
        return JSON.stringify(propertiesParsed);
      }),
      switchMap((properties) => {
        this.logger.log("[ios] saving properties to file: ", properties)
          return this.bridge.callForResponse<void>('setProperties', {properties})
        }
      ),
      map( _ => value)
    );
  }

  getValue(key: string): Observable<any> {
    return this.bridge.callForResponse<string>('getProperties', {}).pipe(
      map((properties) => {
        const propertiesParsed = properties ? properties : {};
        this.logger.log(`[ios] getting property value for ${key}`, propertiesParsed[key]);
        return propertiesParsed[key] ? propertiesParsed[key] : null
      })
    );
  }
}
