<form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
  <section class="card mb-5">
    <div class="card-header row text-start mb-3 g-0">
      <h4 class="mb-0">{{ "CONTACT_US.TITLE" | translate }}</h4>
    </div>
    <div class="card-body row justify-content-center justify-content-md-start">
      <div class="col-12 col-md-12 col-xl-12">
        <div class="form-floating mb-4">
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Email"
            #email="ngModel"
            email
            required
            name="email"
            [(ngModel)]="contactUs.email"
            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
          />
          <label for="email">{{
            "PROFILE.FORM.PERSONAL_INFO.EMAIL" | translate
          }}</label>
          <div *ngIf="f.submitted && email.invalid">
            <span
              *ngIf="email.errors?.['email']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.EMAIL_INCORRECT" | translate }}</span
            >
            <span
              *ngIf="email.errors?.['required']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="form-floating mb-4">
          <textarea
            type="text"
            class="form-control textarea-height"
            id="message"
            placeholder="Message"
            #msg="ngModel"
            minlength="1"
            required
            name="message"
            [(ngModel)]="contactUs.content"
            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
          >
          </textarea>
          <label for="message">{{ "CONTACT_US.MESSAGE" | translate }}</label>
          <div *ngIf="f.submitted && email.invalid">
            <span
              *ngIf="email.errors?.['required']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="row justify-content-center justify-content-md-end">
      <div class="col-6 col-xl-4 justify-content-center">
        <button
          type="button"
          [disabled]="isBusy()"
          (click)="cancel.emit()"
          class="btn btn-secondary btn-lg w-100 mb-3 mb-md-5 fs-6 text-uppercase"
        >
          {{ "BUTTON.CANCEL" | translate }}
        </button>
      </div>
      <div class="col-6 col-xl-4 justify-content-center">
        <button
          type="submit"
          [disabled]="isBusy()"
          class="btn btn-primary btn-lg w-100 mb-3 mb-md-5 fs-6 text-uppercase"
        >
          {{ "BUTTON.SEND" | translate }}
          <span
            *ngIf="isBusy()"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
  </section>
</form>
