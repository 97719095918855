<div class="workspace bg-linear">
  <top-bar [titleTranslationCode]="'EXERCISES.TITLE'"
           [redirectLink]="'../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <!-- filters for mobile -->
      <div class="d-flex d-md-none gap-2">
        <button
          class="btn btn-secondary btn-circle refresh"
          (click)="refreshData()"
        >
          <app-icon name="refresh"></app-icon>
        </button>
      </div>
      <!-- breadcrumb -->
      <div class="d-none d-md-block">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a class="pe-none">
                {{ "EXERCISES.TITLE" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ getLanguageTranslation(chosenCourse) | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row">
      <div class="col-md-10">
        <app-course-filter [showAll]="true" (languageEmitter)="setChosenCourse($event)"></app-course-filter>
      </div>
      <div class="col-auto ms-auto d-none d-md-block">
        <button
          class="btn btn-outline-secondary rounded ms-auto px-2"
          (click)="refreshData()"
        >
          <app-icon name="refresh"></app-icon>
        </button>
      </div>
    </div>
    <div
      class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 position-relative pt-3"
    >
      <spinner class="w-100 spinner"></spinner>
      <div *ngFor="let chapter of chapters" class="col">
        <card
          class="d-flex w-100 mb-4"
          [chapter]="chapter"
          [exerciseSet]="getExerciseSet(chapter.path)"
          [availability]="getAvailability(chapter.path)"
        ></card>
      </div>
    </div>
    <p *ngIf="chapters?.length === 0" class="text-center fs-3 text-muted my-3">
      {{ 'EXERCISES.NO_RESULTS' | translate }}
    </p>
  </div>
</div>
