<div class="card bg-linear text-center border-0 h-100">
  <ng-container *ngIf="!isPlanFlexible() && !isEnbus()">
    <div class="card-header border-0 text-uppercase">
      <span class="name">
        <span>{{ getProductType() | translate }}</span>
        - {{ getWeeksAmount() }}
        {{ getWeeksAmountTranslation() | translate }}
        (<span>{{getCourseName() | translate }}</span>)
      </span>
    </div>
    <div class="card-body d-flex flex-column py-3">
      <h3 class="mb-0 mt-auto">
        <span class="price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
      <p class="text-muted description mb-0">
        {{ getLessonsAmount() }}
        {{ getLessonsAmountTranslation() | translate }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="isPlanFlexible()">
    <div class="card-header border-0 text-uppercase">
      <span class="name" *ngIf="isPlanFlexible()">
        {{ getCreditsAmount() }}
        <span>{{'COL.COURSES.PRODUCTS.PLANS.CREDITS' | translate }}</span>
        (<span>{{ getCourseName() | translate }}</span>)
      </span>
    </div>
    <div class="card-body d-flex flex-column py-3">
      <h3 class="mb-0 mt-0">
        <span class="price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
    </div>
  </ng-container>
  <ng-container *ngIf="isEnbus()">
    <div class="card-header border-0 text-uppercase">
      <span class="name">
        <span>{{'COL.COURSES.ENBUS' | translate }} - </span>
        <span>{{ getEnbusType() | translate }}</span>
      </span>
    </div>
    <div class="card-body d-flex flex-column py-3">
      <h3 class="mb-0 mt-0">
        <span class="price">
          {{ product.defaultPrice.price }}
        </span>
        <span class="currency">
          {{ product.defaultPrice.currency }}
        </span>
      </h3>
      <p class="text-muted description">
        {{ getLessonsAmount() }}
        {{ getLessonsAmountTranslation() | translate }}
        + eBook
      </p>
    </div>
  </ng-container>

  <div class="card-footer border-0 bg-transparent">
    <button
      *ngIf="!notAvailable"
      class="btn btn-primary mb-2"
      (click)="addItem()"
      style="min-width: 120px"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasCart"
    >
      {{ 'BUTTON.BUY' | translate }}
    </button>
    <span *ngIf="!notAvailable">{{ notAvailable }}</span>
    <p class="text-muted small mb-0">
      {{ getPricePerLesson() }} {{  'COL.COURSES.PRODUCTS.PLANS.PER_LESSON' | translate }}
    </p>
  </div>
</div>
