import {CasaNativeApi, UploadEvent} from "../casa-native-api";
import {IosBridgeBase} from "../../../../mobile/api-services/bridge/ios-bridge-base";
import {LogsService} from "../../../../utils/services/logs.service";
import {iif, map, Observable, of, switchMap, throwError} from "rxjs";
import {
  ApiCountry,
  FileItem,
  Person,
  PersonDetails,
  School, SchoolDetails,
  StudentPersonDetails
} from "../../../model/casa/casa-model";
import {CachedHttpRequest, Constraints, HttpMethod, HttpRequest, HttpResponse, Platform} from "../../../../constraints";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CasaIosService implements CasaNativeApi {
  constructor(private bridge: IosBridgeBase,
              private logger: LogsService) {}

  deleteSelf(locale?: string) {
        throw new Error("Method not implemented.");
    }

  getCountries(): Observable<ApiCountry[]> {
    this.logger.log("getting countries list")
    const request = new CachedHttpRequest(
      Platform.Casa, HttpMethod.GET,
      "/public/countries",
      null,
      null,
      "casa-countries",
      Constraints.syncWeek * 2
    )
    return this.bridge.callForResponse<ApiCountry[]>('exchange',{request});
  }

  getSelf(): Observable<Person<PersonDetails>> {
    this.logger.log("getting self")
    const request = new CachedHttpRequest(
      Platform.Casa, HttpMethod.GET,
      "/v2/self",
      null,
      null,
      "casa-self",
      Constraints.syncDay
    )
    return this.bridge.callForResponse<Person<PersonDetails>>('exchange',{request});
  }

  getSelfStudent(): Observable<Person<StudentPersonDetails>> {
    this.logger.log("getting self student")
    const request = new CachedHttpRequest(
      Platform.Casa, HttpMethod.GET,
      "/v2/self",
      null,
      null,
      "casa-self-student",
      Constraints.syncDay
    )
    return this.bridge.callForResponse<Person<StudentPersonDetails>>('exchange',{request});
  }

  getStudentsSchool(studentId: number): Observable<School> {
    this.logger.log(`getting school of student with id=${studentId}`)
    const request = new CachedHttpRequest(
      Platform.Casa, HttpMethod.GET,
      `/v2/self/student/${studentId.toString()}/school`,
      null,
      null,
      `casa-student-${studentId}-school`,
      Constraints.syncDay
    )
    return this.bridge.callForResponse<School>('exchange',{request});
  }

  getTeachersSchool(teacherId: number): Observable<School> {
    this.logger.log(`getting school of teacher with id=${teacherId}`)
    const request = new CachedHttpRequest(
      Platform.Casa, HttpMethod.GET,
      `/v2/self/teacher/${teacherId.toString()}/school`,
      null,
      null,
      `casa-student-${teacherId}-school`,
      Constraints.syncDay
    )
    return this.bridge.callForResponse<School>('exchange',{request});
  }

  isImplemented(): boolean {
    return true;
  }

  supportsFileSending(): boolean {
    return false;
  }

  updateSelfStudentPersonalProfile(details: StudentPersonDetails): Observable<StudentPersonDetails> {
    this.logger.log(`updating student personal profile`)
    const request = new HttpRequest(
      Platform.Casa, HttpMethod.GET,
      `/v2/self/student-role`,
      null,
      JSON.stringify(details),
    )
    return this.bridge.callForResponse<StudentPersonDetails>('exchange',{request});
  }

  updateSelfTeacherPersonalProfile(details: PersonDetails): Observable<PersonDetails> {
    this.logger.log(`updating teacher personal profile`)
    const request = new HttpRequest(
      Platform.Casa, HttpMethod.GET,
      `/v2/self`,
      null,
      JSON.stringify(details),
    )
    return this.bridge.callForResponse<PersonDetails>('exchange',{request});
  }

  uploadFile(file: File): Observable<UploadEvent<FileItem>> {
    return throwError(() => new Error("Unsupported API"));
  }

  isSupportsProfileEdit(): Boolean {
    return false;
  }


}
