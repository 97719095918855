import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { CourseFilterComponent } from '../../../utils/course-filter/course-filter.component';
import {FilterDefinition} from "../../../model/CourseFilters";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {switchMap} from "rxjs";

@Component({
  selector: 'app-credits-page',
  templateUrl: './credits-page.component.html',
  styleUrls: ['./credits-page.component.scss']
})
export class CreditsPageComponent implements OnInit, AfterViewInit {

  chosenCourse: FilterDefinition
  CourseFilterComponent = CourseFilterComponent;
  studentId: number

  constructor(private authService: AuthorizationServiceProvider,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfStudentId())
    ).subscribe((studentId) => {
      this.studentId = studentId
    })
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
