<div class="card mb-5" *ngIf="!isLoading">
  <div class="card-header">
    <div class="row">
      <div class="col d-flex">
        <h4 class="my-auto">
          {{ "COL.COURSES.CREDITS.BALANCE_HISTORY.TITLE" | translate }}
        </h4>
      </div>
      <div class="col-auto ms-auto">
        <paginator
          [data]="allListedResults"
          [pageable]="pageable"
          (newPage)="onPageChange($event)"
          class="mb-4"
        ></paginator>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive" *ngIf="viewRows?.length != 0; else noResults">
      <table class="table table-borderless white-text pe-none">
        <thead>
          <tr class="table-row table-row-head">
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.DATE" | translate }}</th>
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.NAME" | translate }}</th>
            <th class="small">{{ "COL.COURSES.CREDITS.TABLE_HEAD.PLAN" | translate }}</th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.AMOUNT" | translate }}
            </th>
            <th class="small">
              {{ "COL.COURSES.CREDITS.TABLE_HEAD.BALANCE" | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let viewRow of viewRows" class="table-row">
            <td class="small">{{ viewRow.date | dateTranslation : "dd MMM yyyy HH:mm" }}</td>
            <td class="small">
              {{ getActionName(viewRow) | translate }}
            </td>
            <td class="small">{{ viewRow.bundleName }}</td>
            <td class="small">{{ viewRow.amount }}</td>
            <td class="small">{{ viewRow.balance }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noResults>
      <p>
        {{ "COL.COURSES.CREDITS.PURCHASE_HISTORY.NO_RESULTS" | translate }}
      </p>
    </ng-template>
  </div>
</div>
