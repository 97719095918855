import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {CasaNativeApi, UploadEvent} from "../../casa-native-api";
import {ApiCountry, FileItem, Person, PersonDetails, School, StudentPersonDetails} from "../../../../model/casa/casa-model";

@Injectable({
  providedIn: 'root'
})
export class UnsupportedCasaNativeApi implements CasaNativeApi {
  getCountries(): Observable<ApiCountry[]> {
    throw new Error("Unsupported API");
  }

  getSelf(): Observable<Person<PersonDetails>> {
    return throwError(() => new Error("Unsupported API"));
  }

  getSelfStudent(): Observable<Person<StudentPersonDetails>> {
    return throwError(() => new Error("Unsupported API"));
  }

  getStudentsSchool(studentId: number): Observable<School> {
    return throwError(() => new Error("Unsupported API"));
  }

  getTeachersSchool(teacherId: number): Observable<School> {
    return throwError(() => new Error("Unsupported API"));
  }

  isImplemented(): boolean {
    return false;
  }

  supportsFileSending(): boolean {
    return false;
  }

  updateSelfStudentPersonalProfile(details: StudentPersonDetails): Observable<StudentPersonDetails> {
    return throwError(() => new Error("Unsupported API"));
  }

  updateSelfTeacherPersonalProfile(details: PersonDetails): Observable<PersonDetails> {
    return throwError(() => new Error("Unsupported API"));
  }

  uploadFile(file: File): Observable<UploadEvent<FileItem>> {
    return throwError(() => new Error("Unsupported API"));
  }

  isSupportsProfileEdit(): Boolean {
    return false;
  }

  deleteSelf(locale?: string) {
    return throwError(() => new Error("Unsupported API"));
  }
}
