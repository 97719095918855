<section class="mb-5" *ngIf="!isLoading">
  <div class="row mb-4">
    <div class="col-auto d-flex align-items-center mb-3 mb-sm-0">
      <app-icon name="group" class="icon-bg bg-gray icon-blue ms-2"></app-icon>
      <h6 class="m-0">{{ getLessonTypeName() | translate }}</h6>
    </div>
    <div class="col-auto ms-auto">
      <paginator
        [data]="lessons"
        [pageable]="pageable"
        (newPage)="onPageChange($event)"
      ></paginator>
    </div>
  </div>
  <div>
    <div class="card">
      <div class="card-body">
        <p
          *ngIf="hasResults() && getTotalResults() == 0"
          class="text-muted text-center my-3"
        >
          {{ "COL.LESSONS.LIST.NO_RESULTS_FOUND" | translate }}
        </p>
        <ul *ngIf="hasResults()" class="list-unstyled lessons-list">
          <li
            class="row gy-2 px-2 py-3"
            *ngFor="let lesson of getLessonsToDisplay()"
          >
            <div class="col">
              <div class="row">
                <div class="col-12 col-lg-3 col-xl-2 mb-2 mb-lg-0 d-flex ps-lg-0">
                  <div
                    *ngIf="getLessonTeacherPhotoUrl(lesson)"
                    class="profile-photo rounded-circle initials-circle-lg my-auto"
                    [style.background-image]="
                      'url(' + getLessonTeacherPhotoUrl(lesson) + ')'
                    "
                  ></div>
                  <div
                    *ngIf="!getLessonTeacherPhotoUrl(lesson)"
                    class="d-flex rounded-circle text-center bg-gray me-2 initials-circle-lg my-auto"
                  >
                    <span class="text-muted m-auto">
                      {{ getInitials(lesson.teacher?.person) }}
                    </span>
                  </div>
                  <span
                    *ngIf="lesson.lessonStatus"
                    class="badge d-lg-none my-auto ms-auto lesson-status"
                    [ngClass]="lessonBadgeClass(lesson)"
                    >{{ getLessonStatus(lesson) | translate }}</span
                  >
                </div>
                <div class="col-12 col-lg-4 mb-2 mb-lg-0">
                  <p class="text-muted small mb-0">
                    {{ "COL.LESSONS.LIST.TEACHER_LABEL" | translate }}
                  </p>
                  <h6 class="my-0">{{ getLessonTeacher(lesson) }}</h6>
                </div>
                <div class="col-12 col-lg-5 col-xl-6 mb-2 mb-lg-0">
                  <p class="text-muted small mb-0">
                    {{ "COL.LESSONS.LIST.DATE_LABEL" | translate }}
                  </p>
                  <p class="mb-0">
                    {{ getLessonDate(lesson) | dateTranslation : "EEEE, dd MMM yyyy" }}
                    {{ getLessonDate(lesson) | date : "HH:mm" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 col-lg-2 d-none d-lg-flex">
              <span
                *ngIf="lesson.lessonStatus"
                class="badge m-auto lesson-status"
                [ngClass]="lessonBadgeClass(lesson)"
                >{{ getLessonStatus(lesson) | translate }}</span
              >
            </div>
            <div class="col-12 col-lg-3 d-flex flex-column">
              <button
                (click)="openDetails(lesson)"
                class="btn btn-outline-secondary text-white rounded-pill d-block w-100 my-auto"
              >
                {{ "BUTTON.VIEW_DETAILS" | translate }}
              </button>
              <button
                *ngIf="mayBeCancelled(lesson)"
                (click)="showLessonCancellation(lesson)"
                class="btn btn-outline-danger text-white rounded-pill d-block w-100 mt-2"
              >
                {{ "BUTTON.CANCEL" | translate }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-modal #cancelLessonModal>
  <div class="modal-content" *ngIf="_clLessonToCancel">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        {{ "COL.LESSONS.CANCEL_MODAL.TITLE" | translate }}
      </h5>
      <button
        type="button"
        class="btn btn-close btn-close-white"
        (click)="cancelLessonModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form #f="ngForm" (ngSubmit)="f.valid && cancelLesson()">
      <div class="modal-body">
        <p>
          {{ "COL.LESSONS.CANCEL_MODAL.CONTENT_1" | translate }}
          {{
            getLessonPlannedDate(_clLessonToCancel)
              | dateTranslation : "EEEE, dd MMM yyyy HH:mm"
          }}
          {{
            "COL.LESSONS.CANCEL_MODAL.CONTENT_2"
              | translate : getCancellationTeacher(_clLessonToCancel)
          }}
        </p>
        <p
          *ngIf="_clLessonToCancel.lessonStatus == 'Due'"
          class="text-danger fw-bold"
        >
          {{ "COL.LESSONS.CANCEL_MODAL.WARNING" | translate }}
        </p>
        <div class="form-floating">
          <textarea
            #reason="ngModel"
            name="reason"
            placeholder="reason"
            class="form-control"
            required
            [(ngModel)]="_clReason"
            [ngClass]="{ 'is-invalid': f.submitted && reason.invalid }"
          ></textarea>
          <label>{{
            "COL.LESSONS.CANCEL_MODAL.CANCELLATION_REASON" | translate
          }}</label>
          <div *ngIf="f.submitted && reason.invalid" class="invalid-feedback">
            <div *ngIf="reason.errors['required']">
              {{ "FROM_ERROR.FIELD_REQUIRED" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-danger rounded-pill">
          {{ "COL.LESSONS.CANCEL_MODAL.CANCEL_LESSON_BUTTON" | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
