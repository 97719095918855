<div class="card mb-5">
  <app-student-main-calendar
    [filterTeachersIds]="filterTeachersIds"
    (availableTeachersIdsUpdate)="updateAvailableTeachers($event)"
    (scheduleDateEmitter)="scheduleDateEmitter.emit($event)"
    (activationEmitter)="activationEmitter.emit($event)"
    (openFilterModal)="teacherFilter.open()"
    [studentId]="studentId"
    [langCode]="langCode"
  ></app-student-main-calendar>
  <app-calendar-teacher-filter-modal
    #teacherFilter
    [availableTeachersIds]="availableTeachersIds"
    [studentId]="studentId"
    (selectedTeachersUpdate)="updateTeachersFilter($event)"
  ></app-calendar-teacher-filter-modal>
</div>
