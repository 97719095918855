<form *ngIf="profile" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
  <section class="card mb-5">
    <div class="card-header row mb-3 g-0">
      <div class="col align-items-start">
        <h4 class="mb-0 w-100">
          {{ "PROFILE.FORM.PERSONAL_INFO.TITLE" | translate }}
        </h4>
        <span class="xsmall text-muted">
          {{ "PROFILE.FORM.PERSONAL_INFO.SUBTITLE" | translate }}
        </span>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-primary rounded-pill btn-sm"
          routerLink="../password-change"
        >
          {{ "PROFILE.FORM.PERSONAL_INFO.CHANGE_PASSWORD" | translate }}
        </button>
      </div>
    </div>
    <div
      class="card-body row g-2 justify-content-center justify-content-md-start"
    >
      <div class="mb-4 col-12">
        <div class="form-floating">
          <input
            type="text"
            class="form-control pe-none"
            id="email"
            placeholder="Email"
            [disabled]="isDisabled()"
            [(ngModel)]="email"
            name="email"
            required
          />
          <label for="email">{{
            "PROFILE.FORM.PERSONAL_INFO.EMAIL" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Name"
            [disabled]="isDisabled()"
            #name="ngModel"
            [(ngModel)]="profile.name"
            name="name"
            [ngClass]="{ 'is-invalid': f.submitted && name.invalid }"
            required
          />
          <label for="name">{{
            "PROFILE.FORM.PERSONAL_INFO.NAME" | translate
          }}</label>
          <div *ngIf="f.submitted && name.invalid">
            <span
              *ngIf="name.errors?.['required']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="surname"
            placeholder="Surname"
            [disabled]="isDisabled()"
            #surname="ngModel"
            [(ngModel)]="profile.surname"
            name="surname"
            [ngClass]="{ 'is-invalid': f.submitted && surname.invalid }"
            required
          />
          <label for="surname">{{
            "PROFILE.FORM.PERSONAL_INFO.SURNAME" | translate
          }}</label>
          <div *ngIf="f.submitted && surname.invalid">
            <span
              *ngIf="surname.errors?.['required']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <div class="form-floating">
          <select
            class="form-select mb-3"
            [disabled]="isDisabled()"
            id="language"
            #language="ngModel"
            [(ngModel)]="profile.locale"
            [ngClass]="{ 'select-not-empty' : profile.locale }"
            name="language"
          >
            <option
              *ngFor="let language of getLanguages()"
              [selected]="language === profile.locale"
              [value]="language"
            >
              {{ getLanguageByCode(language) }}
            </option>
          </select>
          <label for="language">{{
            "PROFILE.FORM.PERSONAL_INFO.LANGUAGE" | translate
          }}</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <div class="form-floating">
          <select
            class="form-select mb-3"
            [disabled]="isDisabled()"
            id="country"
            #country="ngModel"
            [(ngModel)]="profile.address.country"
            [ngClass]="{ 'select-not-empty' : profile.address.country}"
            name="country"
          >
            <option
              *ngFor="let country of getCountries()"
              [selected]="country === profile.address.country"
              [value]="country"
            >
              {{ country }}
            </option>
          </select>
          <label for="country">{{
            "PROFILE.FORM.PERSONAL_INFO.COUNTRY" | translate
          }}</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <div class="form-floating">
          <select
            class="form-select mb-3"
            [disabled]="isDisabled()"
            id="nationality"
            #nationality="ngModel"
            [(ngModel)]="profile.nationality"
            [ngClass]="{ 'select-not-empty' : profile.nationality }"
            name="nationality"
          >
            <option
              *ngFor="let country of getCountries()"
              [selected]="country === profile.nationality"
              [value]="country"
            >
              {{ country }}
            </option>
          </select>
          <label for="nationality">{{
            "PROFILE.FORM.PERSONAL_INFO.NATIONALITY" | translate
          }}</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-4">
        <div
          class="gender-input"
          [class]="{ 'form-file-disabled': isDisabled() }"
        >
          <h6 class="text-start gender-header text-muted">
            {{ "PROFILE.FORM.PERSONAL_INFO.GENDER" | translate }}
          </h6>
          <div class="d-inline-flex">
            <div class="form-check me-2" *ngFor="let gender of getGenders()">
              <input
                class="form-check-input"
                type="radio"
                name="gender"
                id="gender"
                [checked]="gender === profile.gender"
                [disabled]="isDisabled()"
                #gender="ngModel"
                [(ngModel)]="profile.gender"
                name="gender"
                [value]="gender"
              />
              <label class="form-check-label small" for="gender">
                {{ gender }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto ms-auto">
        <button
          type="submit"
          [disabled]="isBusy()"
          class="btn btn-primary rounded-pill mt-0 ms-auto"
        >
          {{ "BUTTON.SAVE" | translate }}
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="busy"
          ></span>
        </button>
      </div>
    </div>
  </section>

  <section class="card mb-5" *ngIf="isImageUploadSupported() | async">
    <div class="card-header row text-start mb-3 g-0">
      <h4 class="mb-0">{{ "PROFILE.FORM.PHOTO_AVATAR.TITLE" | translate }}</h4>
      <span class="xsmall text-muted">{{
        "PROFILE.FORM.PHOTO_AVATAR.SUBTITLE" | translate
      }}</span>
    </div>
    <div class="card-body">
      <div
        class="row mx-auto form-file mb-4"
        [ngClass]="isDisabled() ? 'form-file-disabled' : ''"
      >
        <label for="photo" class="form-label d-flex">
          <div
            class="photo-square me-4"
            [ngClass]="profile.photo?.publicAccessUrl ? 'border-0' : ''"
          >
            <div
              class="photo-circle img-thumbnail d-flex"
              [style.background-image]="getProfilePhoto()"
            >
              <app-icon
                *ngIf="!profile.photo?.publicAccessUrl"
                name="photo-add"
                class="icon-gray m-auto"
              ></app-icon>
            </div>
          </div>
          <div class="d-flex flex-column" *ngIf="!profile.photo">
            <span class="xsmall text-muted">{{
              "PROFILE.FORM.PHOTO_AVATAR.NO_PHOTO" | translate
            }}</span>
            <h4 class="mb-0">
              {{ "PROFILE.FORM.PHOTO_AVATAR.UPLOAD" | translate }}
            </h4>
          </div>
          <div class="progress mb-2" *ngIf="uploadProgress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploadProgress + '%' }"
            ></div>
          </div>
        </label>
        <input
          class="form-control d-none"
          (change)="onImageUpload($event)"
          accept="image/svg, image/png, image/jpg, image/gif"
          type="file"
          id="photo"
          [disabled]="isDisabled()"
        />
      </div>
      <div class="row">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            [disabled]="isBusy()"
            class="btn btn-primary rounded-pill mt-0 ms-auto"
          >
            {{ "BUTTON.SAVE" | translate }}
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
              *ngIf="busy"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="card mb-5">
    <div class="card-header row text-start mb-3 g-0">
      <h4 class="mb-0">{{ "PROFILE.FORM.CONTACT_INFO.TITLE" | translate }}</h4>
      <span class="xsmall text-muted">{{
        "PROFILE.FORM.CONTACT_INFO.SUBTITLE" | translate
      }}</span>
    </div>
    <div
      class="card-body row g-2 justify-content-center justify-content-md-start"
    >
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="address"
            placeholder="Address"
            [disabled]="isDisabled()"
            #address="ngModel"
            [(ngModel)]="profile.address.line1"
            name="address"
          />
          <label for="address">{{
            "PROFILE.FORM.CONTACT_INFO.ADDRESS" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="city"
            placeholder="City"
            [disabled]="isDisabled()"
            #city="ngModel"
            [(ngModel)]="profile.address.city"
            name="city"
          />
          <label for="city">{{
            "PROFILE.FORM.CONTACT_INFO.CITY" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="state"
            placeholder="State"
            [disabled]="isDisabled()"
            #state="ngModel"
            [(ngModel)]="profile.address.county"
            name="state"
          />
          <label for="state">{{
            "PROFILE.FORM.CONTACT_INFO.STATE" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="postCode"
            placeholder="profile.address.postal"
            [disabled]="isDisabled()"
            #postCode="ngModel"
            [(ngModel)]="profile.address.postal"
            name="postal"
          />
          <label for="postCode">{{
            "PROFILE.FORM.CONTACT_INFO.POST_CODE" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="phone"
            placeholder="Phone"
            [disabled]="isDisabled()"
            #phone="ngModel"
            [(ngModel)]="phoneNumber"
            name="phone"
          />
          <label for="phone">{{
            "PROFILE.FORM.CONTACT_INFO.PHONE" | translate
          }}</label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="skype"
            placeholder="Skype"
            [disabled]="isDisabled()"
            #skype="ngModel"
            [(ngModel)]="skypeInfo"
            name="skype"
          />
          <label for="skype">{{
            "PROFILE.FORM.CONTACT_INFO.SKYPE" | translate
          }}</label>
          <app-icon
            name="skype"
            class="position-absolute me-3 mt-3 top-0 end-0"
          ></app-icon>
        </div>
      </div>
      <div class="col-auto ms-auto">
        <button
          type="submit"
          [disabled]="isBusy()"
          class="btn btn-primary rounded-pill mt-0 ms-auto"
        >
          {{ "BUTTON.SAVE" | translate }}
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="busy"
          ></span>
        </button>
      </div>
    </div>
  </section>
  <section class="card mb-5">
    <div class="card-header row text-start mb-3 g-0">
      <h4 class="mb-0">{{ "PROFILE.FORM.OTHER_INFO.TITLE" | translate }}</h4>
      <span class="xsmall text-muted">{{
        "PROFILE.FORM.OTHER_INFO.SUBTITLE" | translate
      }}</span>
    </div>
    <div
      class="card-body row g-2 justify-content-center justify-content-md-start"
    >
      <div class="mb-4 col-12 col-md-6 col-xl-4">
        <div class="form-floating">
          <input
            type="date"
            class="form-control"
            id="birthDate"
            placeholder="Date of Birth"
            [disabled]="isDisabled()"
            #birthDate="ngModel"
            [(ngModel)]="profile.birthDate"
            name="birthDate"
            [ngClass]="{ 'is-invalid': f.submitted && birthDate.invalid }"
          />
          <label for="birthDate">{{
            "PROFILE.FORM.OTHER_INFO.BIRTHDAY" | translate
          }}</label>
          <div *ngIf="f.submitted && birthDate.invalid">
            <span
              *ngIf="name.errors?.['required']"
              class="d-block invalid-feedback xsmall"
              ><app-icon class="sm-icon me-2" name="warning"></app-icon
              >{{ "FORM_ERROR.FIELD_REQUIRED" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-6 col-xl-4" *ngIf="isStudentForm()">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="reason"
            placeholder="Reason For Study"
            [disabled]="isDisabled()"
            #reason="ngModel"
            [(ngModel)]="getStudentProfile().studyReason"
            name="reason"
          />
          <label for="reason">
            {{ "PROFILE.FORM.OTHER_INFO.REASON" | translate }}
          </label>
        </div>
      </div>
      <div class="mb-4 col-12 col-md-12">
        <div class="form-floating">
          <textarea
            type="text"
            class="form-control textarea-height"
            id="biography"
            placeholder="Biography"
            [disabled]="isDisabled()"
            #biography="ngModel"
            [(ngModel)]="profile.biography"
            name="biography"
          >
          </textarea>
          <label for="biography">{{
            "PROFILE.FORM.OTHER_INFO.BIOGRAPHY" | translate
          }}</label>
        </div>
      </div>
      <div class="col-auto ms-auto">
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill"
          (click)="deleteAccount()"
        >
          {{ "BUTTON.DELETE_ACCOUNT" | translate }}
        </button>
      </div>
    </div>
  </section>
  <section>
    <div class="row g-0 justify-content-end">
      <div class="col-auto ms-auto">
        <button
          type="submit"
          [disabled]="isBusy()"
          class="btn btn-primary btn-lg px-3 mb-3 mb-md-5 fs-6 text-uppercase"
        >
          {{ getButtonContent() | translate }}
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            *ngIf="busy"
          ></span>
        </button>
      </div>
    </div>
  </section>
</form>

<app-modal #deleteAccountInfoModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">{{'PROFILE.DELETE_ACCOUNT.TITLE' | translate}}</h5>
    </div>
    <div class="modal-body">
      {{'PROFILE.DELETE_ACCOUNT.CONTENT' | translate}}
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-primary rounded-pill" (click)="logout()">
        {{'BUTTON.OK' | translate}}
      </button>
    </div>
  </div>
</app-modal>
