import {Component, Input, OnInit} from '@angular/core';
import {switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {UserRole} from "../../../auth_profile/services/api/authorization.api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-access-lessons-button',
  templateUrl: './access-lessons-button.component.html',
  styleUrls: ['./access-lessons-button.component.scss']
})
export class AccessLessonsButtonComponent implements OnInit {

  @Input() textDisplayBreakpoint = 'md'
  private role: UserRole = UserRole.Unknown
  private readonly lang: string
  isAppNative: boolean = true;

  constructor(private authServiceProvider: AuthorizationServiceProvider,
              private translate: TranslateService) {
    authServiceProvider.getCommonRoutines().pipe(
      tap(auth => this.isAppNative = auth.isNativeAuthorization()),
      switchMap(_ => authServiceProvider.getAuthDetailsService()),
      switchMap( detailsApi => detailsApi.getUserRole())
    ).subscribe(role =>
      this.role = role
    )
    this.lang = translate.getBrowserLang()
  }

  ngOnInit(): void {}

  getGuideRedirect() {
    if(this.role == UserRole.Teacher)
      return 'https://s3.amazonaws.com/static.casa.callan.co.uk/documents/Teacher_OCC_Guide_2023.pdf'
    else if(this.role == UserRole.Student)
      return `https://s3.amazonaws.com/static.casa.callan.co.uk/documents/Student_OCC_guide_${this.lang.toUpperCase()}.pdf`
    return ''
  }
}
