<div *ngIf="comingLessons?.length">
  <div class="card mb-5">
    <div class="card-header d-flex align-items-center border-0">
      <span class="me-auto">{{ "COL.COURSES.COMING_LESSON.TITLE" | translate }}</span>
    </div>
    <div class="card-body py-0">
      <ng-container *ngFor="let lessonDetails of comingLessons">
        <app-coming-lesson [comingLessonDetails]="lessonDetails" [studentId]="studentId"></app-coming-lesson>
      </ng-container>
    </div>
  </div>
</div>
