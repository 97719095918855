import {Component, Input, OnInit} from '@angular/core';
import {ParticipantDef, RoomDef, RoomProgressReport, ScheduleRow} from "../../models/video.model";
import {Person, PersonDetails} from "../../../auth_profile/model/casa/casa-model";
import {Page, Pageable} from "../../../utils/pageable";
import {ProfileService} from "../../../auth_profile/services/student/profile.service";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {ActivatedRoute} from "@angular/router";
import {switchMap, take, tap} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'teacher-room-details',
  templateUrl: './teacher-room-details.component.html',
  styleUrls: ['./teacher-room-details.component.scss']
})
export class TeacherRoomDetailsComponent implements OnInit {

  @Input() room: RoomDef
  @Input() scheduleRow?: ScheduleRow
  @Input()
  self: Person<PersonDetails>
  isCopied = false
  progressPageable = new Pageable(0, 5, ['createDate,desc'])
  progressHistory: Page<RoomProgressReport>
  schoolId: number;
  groupId: number;

  constructor(private profile: ProfileService,
    private spinnerService: SpinnerService,
    private apiProvider: NativeServiceApiProvider,
    private authProvider: AuthorizationServiceProvider,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadIds()
    this.loadSelfPerson()
    this.loadTemplateProgressHistory()
  }

  loadIds() {
    this.authProvider.getAuthDetailsService().pipe(
      switchMap(service => service.getSelfSchoolId()),
      tap(schoolId => {
        this.schoolId = schoolId
        }),
      switchMap(_ => this.route.paramMap),
      tap( params => {
        this.schoolId = Number(params.get('schoolId'))
        this.groupId = Number(params.get('groupId'))
        if(this.groupId === 0 && this.scheduleRow) {
          this.groupId = this.scheduleRow.template.id
          }
        })
    ).subscribe()
  }

  loadSelfPerson() {
    this.spinnerService.trace(
      this.profile.getSelfPerson().pipe(take(1))
    ).subscribe(  self => this.self = self)
  }

  loadTemplateProgressHistory(pageable?: Pageable) {
    if(pageable) this.progressPageable = pageable
    this.spinnerService.trace(
      this.apiProvider.video().pipe(
        switchMap(api => api.getProgressHistory(this.schoolId, this.groupId, this.progressPageable))
          )
    ).subscribe(progressHistory => this.progressHistory = progressHistory)
  }

  getScheduleName() {
    return this.room?.name
  }

  getScheduleDateCreated() {
    return new Date(this.room?.createDate)
      .toLocaleString('en-uk',
        { day: '2-digit', month: 'short', year: 'numeric',
          hour: 'numeric', minute: 'numeric' })
  }

  getScheduleDatePlanned() {
    if(this.room)
      return new Date(this.room?.plannedDate)
        .toLocaleString('en-uk',
          { day: '2-digit', month: 'short', year: 'numeric',
            hour: 'numeric', minute: 'numeric' })
    else
      return ''
  }

  getScheduleDuration() {
    return this.getRoomDuration(this.room?.provisioning.duration || 0)
  }

  getTeacher() {
    return this.room?.participants
      .find(participant => participant.role === 'Teacher')
  }

  getInitials(parti: ParticipantDef) {
    return parti?.name.match(/(\b\S)?/g).join("").toUpperCase()
  }

  getName(parti: ParticipantDef) {
    return parti?.name
  }

  getScheduleState() {
    return `LESSONS.LESSON_STATUS.${this.room?.provisioning?.status}`
  }

  getRoomDuration(time: number) {
    return (time/60).toFixed(0)
  }

  copyToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopied = true
  }

  createLink(parti: ParticipantDef): string {
    if (!parti) {
      return '';
    }
    const origin = environment.videoWebBase;
    if(parti?.uuid)
      if (parti.role === 'Teacher') {
        return `${origin}/participants/${parti.uuid}/teacher-room`;
      } else {
        return `${origin}/participants/${parti.uuid}/student-room`;
      }
    else
      return null
  }

  getSelf() {
    return this.room.participants.find(parti =>
      parti.name ===  this.self?.details.name + ' ' + this.self?.details.surname)
  }

  getButtonContent() {
    return this.isCopied ? 'BUTTON.COPIED' : 'BUTTON.COPY_LINK';
  }

}
