<div class="container-fluid bg-radial my-0 pt-5 bg-watermark">
  <div class="container mt-0 pt-5 vh-100">
    <contact-us
      [busy]="busy"
      class="position-relative"
      (msgEmitter)="receiveMsg($event)"
      (cancel)="onCancel()"
    ></contact-us>
  </div>
</div>
