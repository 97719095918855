<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <div class="text-center">
          <h4>Teacher Dashboard</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../../../"> Teacher Dashboard </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Lesson details
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row position-relative mb-2 mb-md-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-teacher-lesson-details></app-teacher-lesson-details>
  </div>
</div>
