import {Injectable} from "@angular/core";
import {map, Observable, of, throwError} from "rxjs";
import {
  ApiCountry,
  FileItem,
  Person,
  PersonDetails,
  School,
  StudentPersonDetails
} from "../../../../model/casa/casa-model";
import {CasaRestService} from "./casa-rest.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {
  CasaNativeApi,
  UploadEvent,
  UploadProgressEvent,
  UploadResponse
} from "../../casa-native-api";

@Injectable({
  providedIn: 'root'
})
export class WebCasaNativeService implements CasaNativeApi {
  isImplemented(): boolean {
    return true;
  }

  constructor(private rest: CasaRestService) {
  }

  getCountries(): Observable<ApiCountry[]> {
    return this.rest.getCountries();
  }

  supportsFileSending(): boolean {
    return true;
  }

  uploadFile(file: File): Observable<UploadEvent<FileItem>> {
    return this.rest.saveFile(file).pipe(
      map(event => {
        if (event.type == HttpEventType.UploadProgress) {
          const result = new UploadProgressEvent<FileItem>();
          result.total = event.total
          result.loaded = event.loaded
          return result;
        } else if (event instanceof HttpResponse) {
          const result = new UploadResponse<FileItem>();
          result.body = event.body as FileItem
          return result;
        } else {
          return new UploadEvent<FileItem>();
        }
      })
    )
  }

  getSelfStudent(): Observable<Person<StudentPersonDetails>> {
   return this.rest.getSelfStudent();
  }

  getStudentsSchool(studentId: number): Observable<School> {
    return this.rest.getSchool(studentId);
  }

  updateSelfStudentPersonalProfile(details: StudentPersonDetails): Observable<StudentPersonDetails> {
    return this.rest.saveSelfStudentPersonalProfile(details);
  }

  getSelf(): Observable<Person<PersonDetails>> {
   return this.rest.getSelf();
  }

  getTeachersSchool(teacherId: number): Observable<School> {
    return this.rest.getTeacherSchool(teacherId);
  }

  updateSelfTeacherPersonalProfile(details: PersonDetails): Observable<PersonDetails> {
    return this.rest.saveSelfPersonalProfile(details);
  }

  isSupportsProfileEdit(): Boolean {
    return true;
  }

  deleteSelf(locale?: string) {
    return this.rest.deleteSelf(locale);
  }
}
