/**
 * casa native api is an interface which describes new, casa based services, but still not implemented on mobile
 * platforms
 */
import {Observable} from "rxjs";
import {ApiCountry, FileItem, Person, PersonDetails, School, StudentPersonDetails} from "../../model/casa/casa-model";

export enum UploadEventType {
  Progress = "progress", Result = "result"
}

export class UploadEvent<T> {
  // type of event
  eventType: UploadEventType
}

export class UploadProgressEvent<T> extends UploadEvent<T> {
  override eventType = UploadEventType.Progress
  loaded: number
  total: number
}

export class UploadResponse<T> extends UploadEvent<T> {
  override eventType = UploadEventType.Result
  body: T
}

export interface CasaNativeApi {
  getCountries(): Observable<ApiCountry[]>;
  isImplemented(): boolean;
  supportsFileSending(): boolean;
  uploadFile(file: File): Observable<UploadEvent<FileItem>>
  getSelf(): Observable<Person<PersonDetails>>
  getSelfStudent(): Observable<Person<StudentPersonDetails>>
  deleteSelf(locale?: string)
  updateSelfStudentPersonalProfile(details: StudentPersonDetails):
  Observable<StudentPersonDetails>
  updateSelfTeacherPersonalProfile(details: PersonDetails):
  Observable<PersonDetails>
  getStudentsSchool(studentId: number): Observable<School>
  getTeachersSchool(teacherId: number): Observable<School>
  isSupportsProfileEdit(): Boolean;
}
