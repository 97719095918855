export class ApiBase {
  id: number;
}

export class ScheduleParticipantBase {
  id: number;
  role: string;
  name: string;
  email: string;
  offline: boolean;
}

export class ScheduleDetailsBase<T extends ScheduleParticipantBase> {
  startDate: number;
  participants: T[];
  hybridState: string;
  durationMin: number;
  place: string;
  notes: string;
}
export class ScheduleBase<T extends ScheduleDetailsBase<any>> {
  state: string;
  details: T;
  id: number;
}

export class RoomTemplateDetailsBase {
  name: string;
  hybridState: string;
  place: string;
  notes: string;
}

export class RoomTemplateBase<T extends RoomTemplateDetailsBase> extends ApiBase {
  details: T;
}

export class RoomTemplate<T extends TemplateParticipant> extends RoomTemplateBase<TemplateDetails> {
  created: number;
  deleted: number;
  participants: T[];
}

export class ScheduleRowBase<S extends ScheduleBase<any>, T extends RoomTemplateBase<any>> {
  schedule: S;
  template: T;
}

export class ScheduleRow extends ScheduleRowBase<Schedule, RoomTemplate<TemplateParticipant>>{
  room: RoomDef;
}

export class ScheduleRowSimplified extends ScheduleRowBase<ScheduleBase<ScheduleDetailsBase<ScheduleParticipantBase>>, RoomTemplateBase<RoomTemplateDetailsBase>>{}


export class Schedule extends ScheduleBase<ScheduleDetails>{}

export class ScheduleDetails extends ScheduleDetailsBase<ScheduleParticipant>{
}

  export class ScheduleParticipant {
      id: number;
      eid: string;
      role: string;
      name: string;
      email: string;
      offline: boolean;
      videoOff = false;
      audioOff = false;
      passive = false;
      chartOff = false;
      secret = false;
      muteOff = false;
      chatOff = false;
  }

  export class TemplateDetails extends RoomTemplateDetailsBase {
    region: string;
  }

  export class TemplateParticipantDetailsBase {
    role: "Student" | "Teacher";
    name: string;
    email: string;
    offline = false;
    videoOff = false;
    audioOff = false;
    passive = false;
    chartOff = false;
    secret = false;
    muteOff = false;
    chatOff = false;
  }

  export class TemplateParticipant extends ApiBase {
    details: TemplateParticipantDetailsBase;
  }

  export class IdentifiedTemplateParticipant extends TemplateParticipant {
      eid: String;
  }

  export class IdentifiedRoomTemplate extends RoomTemplate<IdentifiedTemplateParticipant> {
      eid: String;
  }

  export class RoomDef {
      uuid: string;
      name: string;
      mode: string;
      time: Date;
      provisioning: Provisioning;
      lessonStartDate: Date;
      timerStartDate: Date;
      totalTime: number;
      createDate: Date;
      id: string;
      sid: string;
      participants: Array<ParticipantDef>;
      chart: Chart;
      region: string;
      logo: string;
      serverTime: number;
      /**
       * time - serverTime
       */
      clockSkew: number;
      progress: RoomProgressReport;
      plannedDate: number;
      place: string;
      hybridState: string;
      reportStats: boolean;
  }

  export class ParticipantVideoInfo {
      duration: number;
      startTime: number;
      endTime: number;
      status: string;
      pingAge: number;
  }

  export class ParticipantDef {
      id: string;
      uuid: string;
      name: string;
      mail: string;
      accessToken: string;
      role: string;
      muted: boolean;
      focused: boolean;
      hidden: boolean;
      active: boolean;
      activationsNb: number;
      videoInfo: ParticipantVideoInfo;
      offline: boolean;
      passive: boolean;
      chartOff: boolean;
      muteOff: boolean;
      secret: boolean;
      chatOff: boolean;
  }

  export class Chart {
      chartType: string;
      chartId: string;
      chartData: string;
      entryAssigned: ChartEntry;
  }

  export class ChartEntry {
    imageName: string;
    title: string;
  }

  export class Provisioning {
      created: number;
      updated: number;
      duration: number;
      endTime: number;
      status: string;
  }

  export class RoomProgressReport {
      details: ProgressDetails;
      created: number;
      teacher: ParticipantDef;
      attenders: AttendanceDetails[];
  }

  export class ProgressDetails {
      course: string;
      stage: string;
      lastHw: string;
      paragraph: string;
      reading: string;
      dictation: string;
      comment: string;
      readingLesson: string;
      startPage: string;
      lessonType: string;
  }

  export class AttendanceDetails {
      attended: boolean;
      participant: ParticipantDef;
  }

export class HybridState {
  static Online = "Online";
  static Hybrid = "Hybrid";
  static Offline = "Offline";
}

export class ScheduleCalendarDisplay {
  constructor(
    public daysSorted: number[],
    public rowsByDay: Map<number, ScheduleRowSimplified[]>
  ) { }
}
