<div class="container-fluid">
    <div class="row m-auto pt-5">
        <div class="col m-auto">
            <div class="form-floating mb-4">
                <input type="text" class="form-control" id="floatingInputName" placeholder="Placeholder Text">
                <label for="floatingInputName">Name</label>
            </div>
            <div class="form-floating mb-4">
                <input type="text" class="form-control" id="floatingInputName" placeholder="Placeholder Text" disabled>
                <label for="floatingInputName">Name</label>
            </div>
            <div>
                <div class="form-floating is-invalid">
                    <input type="text" class="form-control" id="floatingInputName" placeholder="Placeholder Text" required>
                    <label for="floatingInputName">Name</label> 
                </div>
                <div class="invalid-feedback">
                    <!-- <mat-icon class="warning">warning</mat-icon> -->
                    <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</span>
                </div>
            </div>

            <!--radios-->
            <div class="row mb-3">
                <div class="input-group">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                        Female
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                        <label class="form-check-label" for="flexRadioDefault2">
                        Male
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" disabled>
                        <label class="form-check-label" for="flexRadioDefault3">
                        Female
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" checked disabled>
                        <label class="form-check-label" for="flexRadioDefault4">
                        Male
                        </label>
                    </div>
                </div>
            </div>

            <!--checkboxes-->
            <div class="row mb-3">
                <div class="input-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheck">
                        <label class="form-check-label" for="flexCheck">
                            <span class="form-link">Remember me</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheck" checked>
                        <label class="form-check-label" for="flexCheck">
                            <span class="form-link">Remember me</span>
                        </label>
                    </div>
                </div>
                <div class="input-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheck" disabled>
                        <label class="form-check-label" for="flexCheck">
                            <span class="form-link">Remember me</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheck" checked disabled>
                        <label class="form-check-label" for="flexCheck">
                            <span class="form-link">Remember me</span>
                        </label>
                    </div>
                </div>
            </div>
<!--buttons-->
            <button type="button" class="btn btn-primary btn-lg mb-3">Log In</button>
            <div class="d-flex mb-3">
                <button type="button" class="btn btn-primary btn-rounded">Zapraszamy</button>
                <button type="button" class="btn btn-outline-primary btn-rounded">Zapraszamy</button>
                <button type="button" class="btn btn-link text-decoration-none">Zapraszamy</button>
            </div>
            <div class="d-flex mb-3">
                <!-- <button type="button" class="btn btn-primary btn-rounded btn-sm"><mat-icon class="arrow-circle-right">arrow_forward</mat-icon>continue</button> -->
                <button type="button" class="btn btn-primary btn-rounded btn-sm">continue</button>
                <button type="button" class="btn btn-outline-primary btn-rounded btn-sm">continue</button>
                <!-- <button type="button" class="btn btn-outline-primary btn-rounded btn-sm"><mat-icon class="arrow-right">arrow_forward</mat-icon></button> -->
                <button type="button" class="btn btn-primary btn-sm">1</button>
                <button type="button" class="btn btn-secondary btn-sm">6</button>
            </div>
            <button type="button" class="btn btn-outline-secondary mb-3">Avalible for iOS & Android</button>
            <button type="button" class="btn btn-outline-secondary btn-sm mb-3"><em>i</em></button>
            
        </div>
        <div class="col m-auto">
            <div class="row mb-3">
                <p>Tabs: </p>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link active">Active</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Inactive</a>
                    </li>
                </ul>
            </div>
            <select class="form-select">
                <option selected>Language</option>
                <option value="1">English</option>
                <option value="2">English</option>
                <option value="3">English</option>
            </select>
            
        </div>
    </div>
</div>
