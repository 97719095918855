import {Injectable} from "@angular/core";
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";
import {CasaRestService} from "../../auth_profile/services/api/impl/rest/casa-rest.service";
import {AuthorizedUserDetailsService, UserRole} from "../../auth_profile/services/api/authorization.api";
import {map, Observable, of, ReplaySubject, switchMap} from "rxjs";
import {School} from "../../auth_profile/model/casa/casa-model";

export enum AccessRequired {
  Video = "area.video"
}

export class AccessList {
  constructor(public videoDisabled: boolean = true) {
  }

  public disabledList(): Array<string> {
    const result: Array<string> = [];
    if (this.videoDisabled) result.push("video");
    return result;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FunctionalAccessService {
  private accessStateSubject = new ReplaySubject<AccessList>(1)

  public getAccessState(): Observable<AccessList> {
    return this.accessStateSubject.asObservable();
  }

  constructor(
    private authServiceProvider: AuthorizationServiceProvider,
    private casaRest: CasaRestService
  ) {
    authServiceProvider.getCommonRoutines().subscribe(
      authApi => {
        if (authApi.isNativeAuthorization()) {
          // fill with default values (everything is available)
          this.setAsDisabled(new Set<string>());
          return;
        }
        // whole web service will be reinitialized after login
        // I don't have to trace the authorization then
        if (!authApi.isTokenValid()) return;

        authServiceProvider.getAuthDetailsService().pipe(
          switchMap( detailsApi => detailsApi.getUserRole().pipe(
            switchMap<UserRole, Observable<School>>( role => this.getSchoolByRole(detailsApi, role)),
            map<School, Set<string>>( school => this.mapSchoolToDisabledFunctionalities(school))
          ))
        ).subscribe(
          toDisable => this.setAsDisabled(toDisable)
        );
      }
    )
  }

  private mapSchoolToDisabledFunctionalities(school: School): Set<string> {
    const paramExpr = new RegExp("^\\s*ui_disable\\s*=\\s*(.*)");
    const splitExpr = new RegExp("\\s*;\\s*");

    if (!school || !school.labels) return new Set<string>();
    const functionalitiesLabel = school.labels.split(",").find(it =>
      it.match(paramExpr)
    )
    if (!functionalitiesLabel) return new Set<string>();

    const functionalitiesList = paramExpr.exec(functionalitiesLabel)[1].split(splitExpr);
    return new Set<string>(functionalitiesList.map(it => it.trim()));
  }

  private getSchoolByRole(authApi: AuthorizedUserDetailsService, role: UserRole): Observable<School> {
    if (role == UserRole.Student) return authApi.getSelfStudentId().pipe(
      switchMap(studentId => this.casaRest.getSchool(studentId))
    );
    else if (role == UserRole.Teacher) return authApi.getSelfTeacherId().pipe(
      switchMap( teacherId => this.casaRest.getTeacherSchool(teacherId))
    );
    else return of(null)
  }

  private setAsDisabled(toDisable: Set<string>) {
    const result = new AccessList(
      toDisable.has(AccessRequired.Video)
    )
    this.accessStateSubject.next(result);
  }
}
