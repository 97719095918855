import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OffcanvasMenuService {

  private menuType: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor() { }

  setMenu(type: string): void{
    this.menuType.next(type)
  }

  getCurrentMenu(): Observable<string>{
    return this.menuType.asObservable()
  }
}
