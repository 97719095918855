export class ErrorBase extends Error {
    constructor(message: string) {
      super(message);
      super.message = message;
      this.userMessage = message;
    }
    developerMessage: string;
    userMessage: string;
    errorCode: string;
    interactive: boolean;
  }