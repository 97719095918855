<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'LESSONS.TITLE'"
           [redirectLink]="'../../'"
           [redirectTranslationCodes]="'NAVIGATION.LESSONS'"
           class="accent">
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../">
                  {{ "LESSONS.TITLE" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ 'COL.LESSONS.DETAILS.TITLE' | translate}}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <app-student-lesson-details [studentId]="studentId"></app-student-lesson-details>
  </div>
</div>
