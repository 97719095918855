<div class="options d-flex flex-column justify-content-between h-100">
  <div class="flex-column h-100">
    <div class="d-flex justify-content-between mb-4">
      <div>
          <h4>{{ exerciseType }}</h4>
          <h4>{{ exerciseName }}</h4>
      </div>
      <button
        type="button"
        class="btn btn-primary rounded-circle btn-circle text-reset p-0"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <app-icon name="close" class="icon-white"></app-icon>
      </button>
    </div>
    <div class="mb-4">
      <app-progress-bar>
      </app-progress-bar>
    </div>
    <div class="mb-4">
      <app-instruction [isOffcanvas]="true" [withSettings]="true"></app-instruction>
    </div>
    <div class="text-end mb-4">
      <ng-content select="content"></ng-content>
    </div>
  </div>
</div>
