import { Injectable } from "@angular/core";
import {  Observable, of } from "rxjs";
import { PropertiesNativeServiceApi } from "../properties-native-api";

@Injectable({
    providedIn: 'root'
})
export class WebPropertiesNativeApi implements PropertiesNativeServiceApi {

  constructor() {}

  putValue(key: string, value: any): Observable<any> {
    localStorage.setItem(key,value)
    return of(value)
  }
  getValue(key: string): Observable<any> {
    const value = localStorage.getItem(key)
    if(value)
      return of(value)
    return of(null)
  }
}
