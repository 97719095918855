import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ExerciseListComponent } from './components/exercise-list/exercise-list.component';
import { ExerciseDetailsComponent } from './pages/exercise-details/exercise-details.component';
import { QaComponent } from './components/exercises/qa/qa.component';
import { DictComponent } from './components/exercises/dict/dict.component';
import { JmblComponent } from './components/exercises/additional_exercises/jmbl/jmbl.component';
import { PicComponent } from './components/exercises/additional_exercises/pic/pic.component';
import { ExerciseHostComponent } from './pages/exercise-host/exercise-host.component';
import { UtilsModule } from '../utils/utils.module';
import { QuestionSetComponent } from './components/question-set/question-set.component';
import { CardComponent } from './components/card/card.component';
import { ExerciseSquareComponent } from './components/exercise-square/exercise-square.component';
import { LoadQuestionDirective } from './directives/load-question.directive';
import { FormsModule } from '@angular/forms';
import { QuestionSetSummaryComponent } from './components/question-set-summary/question-set-summary.component';
import { FillMultiComponent } from './components/exercises/additional_exercises/fill-multi/fill-multi.component';
import { PictureCardComponent } from './components/exercises/additional_exercises/pic/picture-card/picture-card.component';
import { CorrectionComponent } from './components/exercises/additional_exercises/correction/correction.component';
import { MatInputModule } from '@angular/material/input';
import { ExamDetailsComponent } from './pages/exam-details/exam-details.component';
import { ExamPartDetailsComponent } from './pages/exam-part-details/exam-part-details.component';
import { ExamSummaryComponent } from './components/exam-summary/exam-summary.component';
import { ExamStartComponent } from './components/exam-start/exam-start.component';
import { AudioComponent } from './components/exercises/additional_exercises/audio/audio.component';
import { InstructionModalComponent } from './components/instruction-modal/instruction-modal.component';
import { ExercisesOptionsComponent } from './components/exercises-options/exercises-options.component';
import { ProgressBarComponent } from './components/exercises/progress-bar/progress-bar.component';
import { InstructionComponent } from './components/instruction/instruction.component';
import { ExamHostComponent } from './pages/exam-host/exam-host.component';

@NgModule({
  declarations: [
    ExerciseListComponent,
    ExerciseDetailsComponent,
    QaComponent,
    DictComponent,
    JmblComponent,
    PicComponent,
    ExerciseHostComponent,
    QuestionSetComponent,
    CardComponent,
    ExerciseSquareComponent,
    LoadQuestionDirective,
    QuestionSetSummaryComponent,
    FillMultiComponent,
    PictureCardComponent,
    CorrectionComponent,
    ExamDetailsComponent,
    ExamPartDetailsComponent,
    ExamSummaryComponent,
    ExamStartComponent,
    AudioComponent,
    InstructionModalComponent,
    ExercisesOptionsComponent,
    ProgressBarComponent,
    InstructionComponent,
    ExamHostComponent ],
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    MatInputModule,
    RouterModule
  ],
  exports: [
    ExerciseListComponent,
    ExerciseDetailsComponent,
    QaComponent,
    DictComponent,
    JmblComponent,
    PicComponent,
    CorrectionComponent,
    ExerciseHostComponent,
    QuestionSetComponent,
    CardComponent,
    QuestionSetSummaryComponent,
    ExerciseSquareComponent,
    ExercisesOptionsComponent
  ]
})
export class CspaModule { }
