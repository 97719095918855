import {Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../model/rest-model-v2";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";


export interface TeacherBookRestService {
  listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]>
  listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]>
  createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference>
  deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void>
}

@Injectable({
  providedIn: 'root'
})
export class TeacherBookRestServiceImpl implements TeacherBookRestService {

  constructor(private provider: NativeServiceApiProvider) { }


  public listAvailabilities(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.listAvailabilities(teacherId, focusDate)));
  }

  public listLessons(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.listLessons(teacherId, focusDate)));
  }

  public createAvailability(teacherId: number, request: ProductAvailabilityRequest): Observable<ScheduleReference> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.createAvailability(teacherId, request)));
  }

  public deleteAvailability(teacherId: number, availabilityId: number, eventId: number, applyRecurring: boolean): Observable<void> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.deleteAvailability(teacherId, availabilityId, eventId ,applyRecurring)));
  }
}
