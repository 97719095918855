import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconComponent } from './icon/icon.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ToastNotificationsComponent } from './toast-notifications/toast-notifications.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { LoadComponentDirective } from './load-component/load-component.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AfterDateValidatorDirective } from './after-date-validator.directive';
import { DateTranslationPipe } from './date-translation.pipe';
import {CourseFilterComponent} from "./course-filter/course-filter.component";
import {ActivateCourseModalComponent} from "./activate-course-modal/activate-course-modal.component";

@NgModule({
  declarations: [
    IconComponent,
    TopBarComponent,
    ModalComponent,
    ConfirmDialogComponent,
    ToastNotificationsComponent,
    PaginatorComponent,
    SpinnerComponent,
    CircularProgressComponent,
    LoadComponentDirective,
    AfterDateValidatorDirective,
    DateTranslationPipe,
    CourseFilterComponent,
    ActivateCourseModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    IconComponent,
    TopBarComponent,
    ModalComponent,
    ConfirmDialogComponent,
    ToastNotificationsComponent,
    PaginatorComponent,
    SpinnerComponent,
    CircularProgressComponent,
    LoadComponentDirective,
    TranslateModule,
    AfterDateValidatorDirective,
    DateTranslationPipe,
    CourseFilterComponent,
    ActivateCourseModalComponent
  ]
})
export class UtilsModule { }
