import { Injectable } from "@angular/core";
import { Observable, EMPTY, of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LocalStateService {

  private localStateMap = new Map<String, any>();

  public get<T>(id: string, initializer?: () => T): Observable<T> {
    let result = this.localStateMap.get(id);
    if (initializer) {
      if (!result) {
        result = initializer();
        this.localStateMap.set(id, result);
      }
    } else return EMPTY;
    return of(result);
  }

  public set<T>(id: string, value: T): Observable<T> {
    this.localStateMap.set(id, value);
    return of(value);
  }

}