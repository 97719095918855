<div class="row" *ngIf="hasSchedules(); else noResults">
  <div
    class="col-12 col-lg-6 col-xxl-4 mb-3"
    *ngFor="let schedule of getSchedules()"
  >
    <div
      class="row g-0 h-100"
      routerLink="{{redirectPathPrefix}}/{{schedule.schedule.id}}"
    >
      <div class="col-6 col-sm-5">
        <div class="p-3 bg-dark rounded-start d-flex flex-column h-100 justify-content-between align-items-start">
          <div>
            <p class="text-muted schedule-time">
              {{ schedule.schedule.details.startDate | date: 'HH:mm' }}
              <span *ngIf="schedule.schedule.details.durationMin" class="text-nowrap schedule-time">- {{ getScheduleEndTime(schedule) | date: 'HH:mm' }}</span>
            </p>
            <p>{{ schedule.schedule.details.startDate | date: 'E' }} • {{ getScheduleDate(schedule) }}</p>
          </div>
          <span class="badge" [ngClass]="getScheduleBgClass(schedule)">
            {{ getScheduleState(schedule) | translate }}
          </span>
        </div>
      </div>
      <div class="col-6 col-sm-7">
        <div
          class="p-3 rounded-end d-flex flex-column h-100 justify-content-between align-items-end"
          [ngClass]="getScheduleBgClass(schedule)"
        >
          <div class="text-end">
            <h5>{{ getScheduleName(schedule) }}</h5>
            <p class="mb-3">{{ getTeacherName(schedule) }}</p>
          </div>
          <p class="mb-0">
            <small class="me-2">{{
              getNumberOfParticipants(schedule)
            }}</small>
            <app-icon name="group" class="icon-white"></app-icon>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-end">
    <app-access-lessons-button class="my-2"></app-access-lessons-button>
  </div>
</div>
<ng-template #noResults>
  <div class="row">
    <p class="text-center fs-5 text-muted m-0">
      {{ "LESSONS.NO_RESULTS" | translate }}
    </p>
  </div>
</ng-template>
