import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ExamSession} from "../../model/cspa/personal";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import { FontSizeService } from 'src/app/services/font-size.service';

@Component({
  selector: 'app-exam-start',
  templateUrl: './exam-start.component.html',
  styleUrls: ['./exam-start.component.scss']
})
export class ExamStartComponent implements OnInit, OnDestroy {
  @Input() session: ExamSession
  @Input() isSampleExam: boolean

  constructor(private provider: NativeServiceApiProvider,
              private route: ActivatedRoute,
              private router: Router,
              public fontSizeService: FontSizeService
  ) {
  }

  ngOnInit(): void {
    this.fontSizeService.setFromLocalStorage()
  }

  ngOnDestroy(){
    this.fontSizeService.setToDefault()
  }

  startExamSessionPart() {
    let routerLink = this.isSampleExam ? 'part/1' : 'part/1/1'
    this.router.navigate(
      [routerLink],
      {
        relativeTo: this.route,
        queryParamsHandling: 'merge'
      }
    ).then()
  }

  getChapter() {
    return this.session?.chapterName
  }
}
