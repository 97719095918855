<app-modal #confirmDialog>
  <div class="modal-content" [ngClass]="getClasses()">
    <div class="modal-header border-0" *ngIf="getTitle()">
      <h5 class="modal-title" >{{ getTitle() }}</h5>
      <button type="button" 
        class="btn btn-close btn-close-white" 
        (click)="onCancel()" 
        aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
        {{ getMessage() }}
    </div>
    <div class="modal-footer text-right border-0 rounded-bottom">
      <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ getConfirmText() }}</button>
      <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ getCancelText() }}</button>
    </div>
  </div>
</app-modal>