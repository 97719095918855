<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
          routerLink="../../"
        >
          <app-icon name="arrow-back"></app-icon>
          <span class="d-none d-lg-block ms-3">
            {{ "GROUPS.GROUP" | translate }} {{ room?.name }}
          </span>
        </button>
        <div class="text-center">
          <h4>{{ "NAVIGATION.GROUPS" | translate }}</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../../../">
                  {{ "NAVIGATION.GROUPS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../../">
                  {{ "GROUPS.GROUP" | translate }} {{ room?.name }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getScheduleName() }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-3">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <teacher-room-details [room]="room"></teacher-room-details>
  </div>
</div>
