import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { TeacherLessonsComponent } from 'src/app/col/components/teacher/teacher-lessons/teacher-lessons.component';
import { TeacherMainCalendarComponent } from 'src/app/col/components/teacher/teacher-main-calendar/teacher-main-calendar.component';
import { TeacherQuickLessonCounterComponent } from 'src/app/col/components/teacher/teacher-quick-lesson-counter/teacher-quick-lesson-counter.component';
import { LessonType } from 'src/app/col/model/calendar';
import { LoadComponentDirective } from 'src/app/utils/load-component/load-component.directive';

@Component({
  selector: 'app-teacher-dashboard-page',
  templateUrl: './teacher-dashboard-page.component.html',
  styleUrls: ['./teacher-dashboard-page.component.scss'],
})
export class TeacherDashboardPageComponent implements OnInit {
  @ViewChild(LoadComponentDirective, { static: true })
  loadComponent!: LoadComponentDirective;

  chosenSection: string;
  langCode: string;
  teacherId: number;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthorizationServiceProvider
  ) {}

  ngOnInit(): void {
    this.auth
      .getAuthDetailsService()
      .pipe(
        switchMap((authDetailsApi) => authDetailsApi.getSelfTeacherId()),
        tap((teacherId) => (this.teacherId = teacherId)),
        switchMap((_) => this.route.paramMap),
        tap((params) => {
          this.chosenSection = params.get('chosenSection');
          this.langCode = params.get('langCode');
        })
      )
      .subscribe((_) => this.onLoadComponent());
  }

  onLoadComponent() {
    this.loadComponent.viewContainerRef.clear();
    if (this.chosenSection === 'lessons') {
      let componentRef = [];
      for (let i = 0; i < 3; i++) {
        componentRef.push(this.loadComponent.viewContainerRef.createComponent(
          TeacherLessonsComponent
        ));
        componentRef[i].instance.lessonType = i;
        componentRef[i].instance.teacherId = this.teacherId;
      }
    }
    else if (this.chosenSection === 'worktimes') {
      this.loadComponent.viewContainerRef.createComponent(
          TeacherMainCalendarComponent
        );
      this.loadComponent.viewContainerRef.createComponent(
          TeacherQuickLessonCounterComponent
        ).instance.teacherId = this.teacherId
    }
  }
}
