<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'LESSONS.TITLE'"
           [redirectLink]="'../../../../../'"
           [defaultRedirectTranslationCodes]="true"
           class="accent">
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                {{ "LESSONS.TITLE" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="row mb-4">
      <div class="col-auto d-flex align-items-center mb-3 mb-sm-0">
        <app-icon
          name="view-week"
          class="icon-bg bg-gray icon-blue ms-2"
        ></app-icon>
        <h6 class="m-0">
          {{ "LESSONS.WEEKLY_PLANNED_LESSONS" | translate }}
        </h6>
      </div>
      <div class="col-auto ms-auto">
        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn btn-outline-secondary rounded-start-3 btn-focus-none"
            (click)="getPreviousWeek()"
          >
            <app-icon name="angle-left" class="icon-blue"></app-icon>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-focus-none"
            (click)="goToToday()"
          >
            <span class="text-muted">{{ getDate() }}</span>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary rounded-end-3 btn-focus-none"
            (click)="getNextWeek()"
          >
            <app-icon name="angle-right" class="icon-blue"></app-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container loadComponent></ng-container>

    <div class="row mb-3">
      <div class="col-12 col-sm-7 d-flex align-items-center mb-3 mb-sm-0">
        <app-icon
          name="group"
          class="icon-bg bg-gray icon-blue ms-2"
        ></app-icon>
        <h6 class="m-0">
          {{ "LESSONS.HEADER_2" | translate }}
        </h6>
      </div>
    </div>
    <section class="mb-5">
      <templates-list
        [templatesProvider]="templateListsProvider"
      ></templates-list>
    </section>

    <section class="mb-4">
      <div class="row mb-4">
        <div class="col-auto d-flex align-items-center mb-3 mb-sm-0">
          <app-icon name="group" class="icon-bg bg-gray icon-blue ms-2"></app-icon>
          <h6 class="m-0">
            {{ "LESSONS.HEADER_3" | translate }}
          </h6>
        </div>
        <div class="col-auto ms-auto">
          <paginator
            [data]="roomsList.rooms"
            [pageable]="roomsList.pageable"
            (newPage)="roomsList.loadRooms($event)"
          ></paginator>
        </div>
      </div>

      <div class="row" >
        <div class="col-12">
          <section class="card accent-info">
            <div class="p-4 mb-2">
              <rooms-list
                #roomsList
                [roomsProvider]="roomListsProvider"
                [dayStart]="dayStart"
              ></rooms-list>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</div>
