import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { QuestionComponentBase } from 'src/app/model/QuestionComponentBase';
import { PatternParser } from 'src/app/utils/placeholder-parser';
import { environment } from 'src/environments/environment';
import { FillMulti, FillMultiAnswer, PlaceholderAnswer } from '../../../../model/cspa/questions';
import { QuestionService } from '../../../../services/api/impl/rest/question.service';
import { ShortcutService } from '../../../../services/shortcut.service';
import {LogsService} from "../../../../../utils/services/logs.service";
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

class AnswerToken {
  public help: string;
  constructor(
    public editable: boolean,
    public content: string,
    public placeholderName: string) {}
}

@Component({
  selector: 'fill-multi',
  templateUrl: './fill-multi.component.html',
  styleUrls: ['./fill-multi.component.scss']
})
export class FillMultiComponent extends QuestionComponentBase<FillMultiAnswer, FillMulti> implements OnInit {

  tokens: AnswerToken[];
  hadInitialFocus = false;
  private isLastToken = false
  @ViewChildren('inputToken', { read: ElementRef}) inputs: QueryList<ElementRef>;

  constructor(private questionService: QuestionService,
    private shortcutService: ShortcutService,
            logs: LogsService,
            authProvider: AuthorizationServiceProvider) {
    super(logs, authProvider);
  }

  ngOnInit() {
    this.prepareQuestionData()
    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer =>
      sendAnswer &&
      sendAnswer['answerType'] === 'ef' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    )
    this.hintSubscription = this.shortcutService.hintRequest.subscribe(_ =>
      this.onMiddleClick()
    )

  }

  ngAfterViewChecked(): void {
    if (!this.hadInitialFocus) {
      this.inputs.toArray()[0].nativeElement.focus();
      this.hadInitialFocus = true;
    }
  }

  override imeInput() {
    if(this.isLastToken)
      this.hideKeyboard()
    else
      this.spaceEvent()
  }

  @HostListener('window:keydown.space', ['$event'])
  @HostListener('window:keydown.control.space', ['$event'])
    spaceEvent() {
      if (!this.inputs) return;
      for (let i = 0; i < this.inputs.length; i++) {
        if(this.inputs.toArray()[i].nativeElement == document.activeElement) {
          this.isLastToken = i+1 === this.inputs.length? true : false
          this.inputs.toArray()[i+1]?.nativeElement.focus();
          return
        }
      }
    }

  getAnswerForSubmit() {
    this.populateTokensOnAnswer();
    this.sendAnswer()
  }

  setUpAnswer(answer: FillMultiAnswer) {
    if (!answer) {
      this.answer = new FillMultiAnswer();
      this.answer.answer = [];
    } else {
      this.answer = answer;
    }
  }

  public getInputStyle(token: AnswerToken) {
    if (!token || !token.content) {
      return {width: '100px'};
    }
    let width = Math.max(100, (token.content.length * 25));
    if (width > 300) {
      width = 300;
    }
    return {width: width + 'px'};
  }

  onMiddleClick() {
    const tokensMayBeHelped = this.tokens
    .filter(token => token.editable)
    .filter(token => !token.help || token.help.indexOf('_') >= 0)
    .map( token => {
      return {token, answer: this.question.question.definition.answer.answer.find( ph => token.placeholderName === ph.name)};
    })
    .filter( e => e.answer != null)
    .filter( e => {
      const answerTrimmed = e.token.content ? e.token.content.trim() : '';
      return e.answer.val.indexOf(answerTrimmed) < 0 && ( !e.answer.alt || e.answer.alt.indexOf(answerTrimmed) < 0);
    });

    if (tokensMayBeHelped.length === 0) {
      return;
    }

    const tokenEntryToHelp = tokensMayBeHelped[Math.round(Math.random() * (tokensMayBeHelped.length - 1))];
    const helpToken = tokenEntryToHelp.token;
    const answer = tokenEntryToHelp.answer.val[0];
    let help = helpToken.help;
    if (!help) {
      help = '_'.repeat(answer.length);
    }
    const availablePositions = [];
    for (let i = 0; i < help.length; i++) {
      if (help.charAt(i) === '_') {
        availablePositions.push(i);
      }
    }
    const helpPosition = availablePositions[Math.round(Math.random() * (availablePositions.length - 1))];
    help = help.substring(0, helpPosition) + answer.charAt(helpPosition) + help.substring(helpPosition + 1, help.length);
    helpToken.help = help;
  }

  populateAnswerOnTokens() {
    if (!this.tokens || !this.answer) {
      return;
    }
    this.tokens.filter(token => token.editable).forEach(token => token.content = '');
    if(this.answer.answer)
      this.answer?.answer
        .filter( entry => entry.name && entry.val && entry.val.length === 1)
        .forEach( entry => {
          const tokenFound = this.tokens.find( token => token.editable && token.placeholderName === entry.name);
          if (tokenFound) {
            tokenFound.content = entry.val[0];
          }
        });
  }

  populateTokensOnAnswer() {
    if (!this.tokens || !this.answer) {
      return;
    }
    this.answer.answer = [];
    this.answer.answer = this.tokens
      .filter( token => token.editable)
      .map( token => {
        const res = new PlaceholderAnswer();
        res.name = token.placeholderName;
        res.val = [token.content];
        return res;
      });
  }

  hasImage() {
    return this.question && this.question.question.definition.img;
  }

  getImageSrc() {
    return this.question ? environment.awsBase + this.question.question.definition.img : null;
  }

  prepareQuestionData(): void {
    if (!this.question) {
      return;
    }
    this.tokens = PatternParser.parse(this.question.question.definition.question)
      .map( pi => new AnswerToken(pi.isPlaceholder,
        pi.isPlaceholder ? '' : pi.content,
        pi.isPlaceholder ? pi.content : null));
    this.populateAnswerOnTokens();
  }
}
