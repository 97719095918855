<section class="mb-5">
  <div class="course-sp">
    <div class="course d-flex justify-content-between align-items-center">
      <h4 class="text-white my-1">{{ getLangName() | translate }}</h4>
      <div class="col-auto d-flex gap-3">
        <app-activate-course-modal
          [studentId]="studentId"
          [langCode]="chosenCourse?.filter"
          [onlyButton]="true"
        ></app-activate-course-modal>
        <a class="btn btn-primary rounded-pill"
           routerLink="/student/courses"
           [queryParams]="{language: 'sp'}"
        >
          {{ "BUTTON.DETAILS" | translate }}
        </a>
      </div>
    </div>
  </div>
</section>

<!-- <section class="mb-5" [hidden]="hideStarterOffers" >
  <div class="container">
    <div class="card">
      <div class="card-body">
        <app-student-starters-offer (stateEvent)="onStarterOfferEvent($event)" [studentId]="studentId" [langCode]="productCode"></app-student-starters-offer>
      </div>
    </div>
  </div>
</section> -->

<section class="mb-5">
  <nav>
    <div
      class="nav nav-tabs justify-content-center text-uppercase"
      id="nav-tab"
      role="tablist"
    >
      <button
        class="nav-link text-uppercase px-2 px-md-4 active"
        id="nav-light-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-light"
        type="button"
        role="tab"
        aria-controls="nav-light"
        aria-selected="true"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.LIGHT.TITLE" | translate }}
      </button>
      <button
        class="nav-link text-uppercase px-2 px-md-4"
        id="nav-standard-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-standard"
        type="button"
        role="tab"
        aria-controls="nav-standard"
        aria-selected="false"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.STANDARD.TITLE" | translate }}
      </button>
      <button
        class="nav-link text-uppercase px-2 px-md-4"
        id="nav-intensive-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-intensive"
        type="button"
        role="tab"
        aria-controls="nav-intensive"
        aria-selected="false"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.INTENSIVE.TITLE" | translate }}
      </button>
      <button
        class="nav-link text-uppercase px-2 px-md-4"
        id="nav-payg-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-payg"
        type="button"
        role="tab"
        aria-controls="nav-payg"
        aria-selected="false"
      >
        {{ "COL.COURSES.PRODUCTS.PLANS.PAYG.TITLE" | translate }}
      </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div
      class="tab-pane fade show active"
      id="nav-light"
      role="tabpanel"
      aria-labelledby="nav-light-tab"
    >
      <p class="text-muted text-uppercase text-center small my-4">
        {{ "COL.COURSES.PRODUCTS.PLANS.LIGHT.DESC" | translate }}
      </p>
      <div class="row justify-content-center align-items-stretch g-3">
        <div
          class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-3"
          *ngFor="let product of lights"
        >
          <app-product-box [product]="product" desc="light"></app-product-box>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="nav-standard"
      role="tabpanel"
      aria-labelledby="nav-standard-tab"
    >
      <p class="text-muted text-uppercase text-center small my-4">
        {{ "COL.COURSES.PRODUCTS.PLANS.STANDARD.DESC" | translate }}
      </p>
      <div class="row justify-content-center align-items-stretch g-3">
        <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-3" *ngFor="let product of std">
          <app-product-box
            [product]="product"
            desc="standard"
          ></app-product-box>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="nav-intensive"
      role="tabpanel"
      aria-labelledby="nav-intensive-tab"
    >
      <p class="text-muted text-uppercase text-center small my-4">
        {{ "COL.COURSES.PRODUCTS.PLANS.INTENSIVE.DESC" | translate }}
      </p>
      <div class="row justify-content-center align-items-stretch g-3">
        <div
          class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-3"
          *ngFor="let product of intensive"
        >
          <app-product-box
            [product]="product"
            desc="intensive"
          ></app-product-box>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="nav-payg"
      role="tabpanel"
      aria-labelledby="nav-payg-tab"
    >
      <div class="row justify-content-center align-items-stretch g-3 mt-5">
        <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-3" *ngFor="let product of pag">
          <app-product-box [product]="product" desc=""></app-product-box>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mb-5">
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">{{ "COL.COURSES.PRODUCTS.EBOOKS.TITLE" | translate }}</h4>
    </div>
    <div class="card-body">
      <p class="small mb-4">
        {{ "COL.COURSES.PRODUCTS.EBOOKS.INTRO" | translate }}
      </p>
      <div
        class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 justify-content-start gy-3 gy-md-4"
      >
        <div class="col" *ngFor="let product of books">
          <div class="d-flex flex-column justify-content-between h-100 mx-auto">
            <img class="w-100" src="{{ getImage(product) }}" [alt]="product.defaultProductName"/>
            <div class="w-100 mt-1 text-center text-muted">
              {{ product.defaultPrice.price }}
              {{ product.defaultPrice.currency }}
            </div>
            <button
            class="btn btn-outline-primary d-block w-100 mt-1 mb-4"
            (click)="addItem(product)"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCart"
          >
            {{ 'BUTTON.BUY' | translate }}
          </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-0 bg-transparent">
      <div
        class="row justify-content-center align-items-stretch gy-2 gy-sm-0 mb-4"
      >
        <div class="col-11 col-sm-6 col-md-4 col-xl-3 text-end">
          <a
            class="btn btn-outline-secondary d-flex py-3 h-100"
            href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks"
            target="_blank"
          >
            <app-icon name="download-google-play" class="m-auto"></app-icon>
          </a>
        </div>
        <div class="col-11 col-sm-6 col-md-4 col-xl-3 text-start">
          <a
            class="btn btn-outline-secondary d-flex py-3 h-100"
            href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360"
            target="_blank"
          >
            <app-icon name="download-app-store" class="m-auto"></app-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<section class="text-center pb-5">-->
<!--  <h4 class="mb-4">{{ "COL.COURSES.PRODUCTS.QUESTIONS.TITLE" | translate }}</h4>-->
<!--  <a-->
<!--    href="contact-us.html#faqs"-->
<!--    class="btn btn-primary rounded-pill px-5 py-3"-->
<!--    >{{ "COL.COURSES.PRODUCTS.QUESTIONS.GO_TO_FAQ" | translate }}</a-->
<!--  >-->
<!--</section>-->
