<div class="summary">
  <div class="row">
    <h4 class="mb-4 mb-md-2">
      {{ "EXERCISES.HOST.SUMMARY.HEADER" | translate
      }}<span class="h4 mb-0 fw-bold">{{ getFractionOfScore() }}</span>
    </h4>
    <small
      class="text-muted d-none d-md-block mb-3"
      *ngIf="getResultState() === resultState.bad"
    >
      {{ "EXERCISES.HOST.SUMMARY.RESULT_BAD" | translate }}
    </small>
    <small
      class="text-muted d-none d-md-block mb-3"
      *ngIf="getResultState() === resultState.poor"
    >
      {{ "EXERCISES.HOST.SUMMARY.RESULT_POOR" | translate }}
    </small>
    <small
      class="text-muted d-none d-md-block mb-3"
      *ngIf="getResultState() === resultState.good"
    >
      {{ "EXERCISES.HOST.SUMMARY.RESULT_GOOD" | translate }}
    </small>
  </div>
  <div class="row mb-2 me-md-3 g-2">
    <div
      class="col-auto"
      *ngFor="let question of getQuestions(); let i = index"
    >
      <div
        [ngClass]="getQuestionScoreClass(question)"
        class="btn exerciseBlock"
      >
      </div>
    </div>
  </div>
  <div class="row my-2 justify-content-end g-2">
    <div class="col-auto ms-auto">
      <button
        type="button"
        [class]="!mayCorrect() ? 'ms-auto btn-primary' : 'btn-secondary'"
        class="btn rounded-pill d-inline-flex align-items-baseline align-items-sm-center"
        (click)="onFinish()"
       
      >
        {{ "BUTTON.FINISH" | translate }}
      </button>
    </div>
    <div class="col-auto" *ngIf="mayCorrect()">
      <button
        type="button"
        class="btn btn-primary rounded-pill d-inline-flex align-items-baseline align-items-sm-center"
        (click)="recreateSession()"
       
      >
        {{ "BUTTON.TRY_AGAIN" | translate }}
      </button>
    </div>
  </div>
</div>
