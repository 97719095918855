import { Injectable } from "@angular/core";
import {
  Subject,
  Observable,
  ReplaySubject,
  tap,
  switchMap,
  map,
  of,
  forkJoin,
  catchError,
  throwError,
  take
} from "rxjs";
import {Person, School, SchoolDetails, StudentPersonDetails} from "../../model/casa/casa-model";
import { AuthorizationServiceProvider } from "../authorization-service.provider";
import { CasaRestService } from "../api/impl/rest/casa-rest.service";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {LogsService} from "../../../utils/services/logs.service";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private _selfPerson: Subject<Person<StudentPersonDetails>>;

    constructor(
      private provider: NativeServiceApiProvider,
      private authService: AuthorizationServiceProvider,
      private logs: LogsService) {}
    /**
     * getting the profile you may subscribe for updates too
     */
    getSelfPerson(): Observable<Person<StudentPersonDetails>> {
        // if not initialized yet - load data
        if (!this._selfPerson) {
            this._selfPerson = new ReplaySubject<Person<StudentPersonDetails>>(1);
            this.reloadSelfPerson().subscribe({
              next: person => {this._selfPerson.next(person)},
              error: err => {
              // is thrown in result
              }});
        }
        return this._selfPerson;
    }

    /**
     * may be used to reload profile data
     */
    reloadSelfPerson(): Observable<Person<StudentPersonDetails>> {
        if (!this._selfPerson) {
            this._selfPerson = new ReplaySubject<Person<StudentPersonDetails>>(1);
        }
      return this.provider.casa().pipe(
        switchMap(api => api.getSelfStudent()),
        tap( person => this._selfPerson.next(person)),
        catchError(err => {
          this.logs.error("got error when asking for self person", err)
          this._selfPerson.error(err);
          return throwError(err);
        })
      )
    }

    /**
     * Save self profile data after profile form update
     * @param details
     */
    updateSelf(details: StudentPersonDetails): Observable<Person<StudentPersonDetails>> {
      return this.provider.casa().pipe(
        switchMap(api => api.updateSelfStudentPersonalProfile(details)),
        switchMap( _ => this.reloadSelfPerson())
      )
    }

    getSelfSchool(): Observable<School> {
      return this.authService.getAuthDetailsService()
        .pipe(
          switchMap(api =>
            api.getSelfStudentId().pipe(
              switchMap( studentId =>
                this.provider.casa().pipe(
                  switchMap( casaApi => casaApi.getStudentsSchool(studentId))
                )
              )
            )
          )
        );
    }
}
