<div class="row justify-content-center py-4 my-2">
  <div class="col">
    <div class="text-center mt-4 mt-md-0">
      <button
        type="button"
        class="btn rounded-pill mb-2 me-2 px-2 px-md-4"
        (click)="assignAnswer(token)"
        *ngFor="let token of questionAnswers"
        [class]="token == selectedAnswer ? 'btn-primary' : 'btn-secondary'"
        [disabled]="isButtonDisabled()"
      >
        {{ getContent(token) }}
      </button>
    </div>
  </div>
</div>
