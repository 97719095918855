export class Mapping {
  id: string;
  platform: string;
}

export class Translation {
  name: string;
  lang: string;
  description: string;
}

export class Price {
  price: number;
  currency: string;
  pricelist: string;
}

export class ApiProduct {
  code: string;
  productCode: string;
  variantLabels: string[];
  defaultVariantId: Mapping;
  defaultVariantName: Translation;
  defaultProductName: Translation;
  defaultPrice: Price;
  productLabels: string[];
}

export class Currencies {
  public static values = ['eur', 'pln', 'usd', 'gbp', 'jpy'];
}

export class Product {
  product: ApiProduct
  quantity: number

  constructor(product: ApiProduct, quantity: number) {
    this.product = product
    this.quantity = quantity
  }
}

export class Cart {
  items: Product[] = [];
  total: number = 0
}

export class ProductsUtils {
  public static EN_PRODUCT_LABEL = 'category:lessons.en';
  public static EN_BS_PRODUCT_LABEL = 'category:lessons.en.bs';
  public static SP_PRODUCT_LABEL = 'category:lessons.sp';

  public static filterByProductLabel(
    products: ApiProduct[],
    label: string
  ): ApiProduct[] {
    return products.filter(
      (p) => p.productLabels && p.productLabels.indexOf(label) >= 0
    );
  }

  public static filterByVariantLabel(
    products: ApiProduct[],
    label: string
  ): ApiProduct[] {
    return products.filter(
      (p) => p.variantLabels && p.variantLabels.indexOf(label) >= 0
    );
  }

  public static orderByOrderLabel(products: ApiProduct[]): ApiProduct[] {
    const res = products.map((p) => {
      let order = 0;
      if (p.variantLabels) {
        const orderLabel = p.variantLabels.find((l) => l.startsWith('order:'));
        order = Number(orderLabel.slice('order:'.length));
      }
      return {
        data: p,
        order: order,
      };
    });
    res.sort((l, r) => l.order - r.order);
    return res.map((d) => d.data);
  }
}

// product variant
export class VariantBase {
  code: string;
}

export class PriceCurrency {
  value: number;
  currency: string;
}

export class PriceList {
  name: String;
}

export class PriceListPrice extends PriceCurrency {
  priceList: PriceList;
}

export class VariantPrice<V extends VariantBase> extends PriceListPrice {
  variant: V;
}

export class TransactionEntryBase<V extends VariantBase, P extends VariantPrice<V>> {
  quantity: number;
  price: P;
}

export class TransactionBase<E extends TransactionEntryBase<any, any>> {
  entries: Array<E>;
}

export class SimpleOrderEntryMetadata {
  quantityAdjustable: boolean;
}

export class SimpleOrderEntry extends TransactionEntryBase<VariantBase, VariantPrice<VariantBase>> {
  metadata: SimpleOrderEntryMetadata;
}

export class SimpleOrder extends TransactionBase<SimpleOrderEntry> {}

