import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {RepoEntry} from "../models/documents";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentRestService {

  constructor(private http: HttpClient) { }

  private url(method: string) {
    return `${environment.casaEndpoint}/api/v2${method}`;
  }

  public listDocuments(schoolId: number): Observable<RepoEntry> {
    return this.http.get<RepoEntry>(this.url(`/schools/${schoolId}/documents`));
  }

  public getDocumentLink(schoolId: number, path: string): Observable<string> {
    return this.http.get(this.url(`/schools/${schoolId}/documents/${path}`), {responseType: "text"});
  }

  public refreshDocumentsRepository(schoolId: number): Observable<any> {
    const params = new HttpParams().append("action", "refresh");
    return this.http.post(this.url(`/schools/${schoolId}/documents`), null, {params});
  }
}
