<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.PROGRESS'"
           [redirectLink]="'../'"
           [defaultRedirectTranslationCodes]="true"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a class="pe-none">
                  {{ "NAVIGATION.PROGRESS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ CourseFilterComponent.getLanguageTranslation(chosenCourse) | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <app-course-filter (languageEmitter)="chosenCourse = $event"></app-course-filter>
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div *ngIf="chosenCourse?.code != 'ALL'; else progressList">
      <app-progress-card [studentId]="studentId"
      [langCode]="chosenCourse?.filter"></app-progress-card>
    </div>
    <ng-template #progressList>
      <div class="d-flex flex-column">
        <app-progress-card *ngFor="let lang of CourseFilterComponent.getFiltersShort()"
                           [studentId]="studentId"
                           [langCode]="lang.filter"
                           [showNoContext]="false"
        ></app-progress-card>
      </div>
    </ng-template>
  </div>
</div>
