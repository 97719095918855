<div class="workspace bg-radial">
  <top-bar class="accent">
    <content class="mb-md-4">
      <div class="row">
        <div class="text-center">
          <h4>Teacher Dashboard</h4>
        </div>
      </div>
    </content>
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                Teacher Dashboard
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <ul class="nav nav-pills pb-3 pb-md-2 nav-pills-overflow">
      <li class="nav-item me-2">
        <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../worktimes" routerLinkActive="active">Worktimes</a>
      </li>
      <li class="nav-item me-2">
        <a class="btn rounded-pill btn-sm nav-link px-3 py-2 mb-2" routerLink="../lessons" routerLinkActive="active">Lessons</a>
      </li>
    </ul>

    <div class="row position-relative mb-2 mb-md-3">
      <spinner class="w-100 spinner"></spinner>
    </div>

    <ng-template loadComponent></ng-template>


  </div>
</div>
