import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FontSizeService {

  FontSizeLevels = ['14px', '16px', '20px', '24px']

  constructor() {
    let b = document.querySelector('body');
    b.style.setProperty('--font-size', this.FontSizeLevels[1])
   }

  setToDefault(){
    let b = document.querySelector('body')
    b.style.setProperty('--font-size', this.FontSizeLevels[1])
  }

  setFromLocalStorage(){
    let localStorageFontSize = localStorage.getItem('fontSize') ? +localStorage.getItem('fontSize') : 2
    this.changeFontSize(localStorageFontSize)
  }

  changeFontSize(changeTo: number){
    let b = document.querySelector('body')
    switch(changeTo){
      case 1: 
        b.style.setProperty('--font-size', this.FontSizeLevels[0])
        localStorage.setItem('fontSize', '1')
        break;
      case 2: 
        b.style.setProperty('--font-size', this.FontSizeLevels[1])
        localStorage.setItem('fontSize', '2')
        break;
      case 3:
        b.style.setProperty('--font-size', this.FontSizeLevels[2])
        localStorage.setItem('fontSize', '3')
        break;
      case 4:
        b.style.setProperty('--font-size', this.FontSizeLevels[3])
        localStorage.setItem('fontSize', '4')
        break;
      default:
         b.style.setProperty('--font-size', this.FontSizeLevels[1])
         localStorage.setItem('fontSize', '2')
    }
  }

}
