import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {AndroidBridgeBase} from "../../../../mobile/api-services/bridge/android-bridge-base";
import {LogsService} from "../../../../utils/services/logs.service";
import {ContactUs, PasswordChangeRequest} from "../../../../books/model/book-model";
import {MobileAuthBaseService} from "./mobile-auth-base.service";
import packageJson from 'package.json';


@Injectable({
  providedIn: 'root'
})
export class AndroidAuthService extends MobileAuthBaseService {

  constructor(private bridge: AndroidBridgeBase, logger: LogsService) {
    super(logger);
  }

  logout() {
    this.bridge.callForResponse<void>( "logout",id => window.nativeBridge.logout(id)).subscribe();
  }

  changePassword(request: PasswordChangeRequest, lang?: string): Observable<void> {
    return this.bridge.callForResponse<void>(
      `changePassword(newPassword, ${lang})`,
      id => window.nativeBridge.changePassword(id, JSON.stringify(request), lang));
  }

  contactUs(request: ContactUs): Observable<void> {
    return this.bridge.callForResponse<void>(
      "contactUs(form)",
      id => window.nativeBridge.contactUs(id, JSON.stringify(request)));
  }

  getUserName(): Observable<string> {
    return this.bridge.callForResponse<string>(
      "getUserName()",
      id => window.nativeBridge.getUserName(id));
  }

  ssoLogin(): Observable<any> {
    return this.bridge.callForResponse<void>(
      "ssoLogin()",
      id => window.nativeBridge.ssoLogin(id));
  }

  getAccessTokenObservable(): Observable<string> {
    return this.bridge.callForResponse<string>(
      "getAccessToken()",
      id => window.nativeBridge.getAccessTokenValue(id));
  }

  getDeviceId(): Observable<string> {
    return this.bridge.callForResponse<string>(
      "getDeviceId()",
      id => window.nativeBridge.getDeviceId(id)
    );
  }

  override closeApp(): Observable<any> {
    return this.bridge.callForResponse<any>(
      "close()",
      id => window.nativeBridge.close(id));
  }

  override hideKeyboard(): Observable<any> {
    return of(null)
  }

  listenOnBackButton(): Observable<any> {
    return this.bridge.listenToBackButton();
  }

  listenOnImeButton(): Observable<any> {
    return this.bridge.listenToIMOButton();
  }

  getNativeApiVersion(): Observable<string> {
    return this.bridge.callForResponse<string>(
      `exchangeVersion(${packageJson.version}}`,
      id => window.nativeBridge.exchangeVersion(id, packageJson.version)
    )
  }
}
