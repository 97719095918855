import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemAvailability } from 'src/app/cspa/model/cspa/personal';
import { Chapter } from 'src/app/cspa/model/cspa/struct';

@Component({
  selector: 'app-chapter-progress-circle',
  templateUrl: './chapter-progress-circle.component.html',
  styleUrls: ['./chapter-progress-circle.component.scss'],
})
export class ChapterProgressCircleComponent implements OnInit {
  @Input()
  chapter: Chapter;
  @Input()
  availability: ItemAvailability;
  @Output()
  chapterClick = new EventEmitter<Chapter>();

  constructor() {}

  getProgress() {
    return this.availability? (this.availability.progress * 100).toFixed() : 0;
  }

  onClick() {
    if (!this.chapter) {
      return;
    }
    this.chapterClick.emit(this.chapter);
  }

  getShortName() {
    return this.chapter ? this.chapter.shortName.split(' ')[1] : '';
  }

  getColorClass() {
    return 'circle-bg-' + this.getShortName();
  }

  ngOnInit(): void {}
}
