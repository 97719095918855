import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { RoomDef, ScheduleRow } from '../../models/video.model';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";

@Component({
  selector: 'app-schedule-details-page',
  templateUrl: './schedule-details-page.component.html',
  styleUrls: ['./schedule-details-page.component.scss']
})
export class ScheduleDetailsPageComponent implements OnInit {

  studentId: number;
  schoolId: number;
  scheduleId: number;
  schedule: ScheduleRow
  room: RoomDef

  constructor(private route: ActivatedRoute,
    private provider: NativeServiceApiProvider) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map(params => {
        this.studentId = +params.get('studentId')
        this.schoolId = +params.get('schoolId')
        this.scheduleId = +params.get('scheduleId')
      }),
      switchMap( _ => this.provider.video()),
      switchMap(api => api.getStudentScheduleDetails(
        this.schoolId, this.studentId, this.scheduleId)
      ),
      tap<ScheduleRow>(schedule => this.schedule = schedule)
    ).subscribe(_ =>
      this.room = this.schedule.room)
  }

  getScheduleDatePlanned() {
    if(this.schedule)
      return new Date(this.schedule.schedule.details.startDate)
      .toLocaleString('en-uk',
        { day: '2-digit', month: 'short', year: 'numeric',
          hour: 'numeric', minute: 'numeric' })
    else
      return ''
  }

  getScheduleName() {
    return this.room?.name
  }
}
