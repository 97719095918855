import {Pageable} from "../../utils/pageable";

export class SortingRule {
  constructor(public sorting: string[]) {
  }
}

export class LocalState<T extends SortingRule>{
  constructor(public term: string, public sortingRule: T, public page: Pageable) {}

  public next(): LocalState<T> {
    this.applySorting();
    return new LocalState<T>(this.term, this.sortingRule, this.page.next());
  }

  public prev(): LocalState<T> {
    this.applySorting();
    return new LocalState(this.term, this.sortingRule, this.page.prev());
  }

  public first(): LocalState<T>{
    this.applySorting();
    return new LocalState(this.term, this.sortingRule, this.page.first());
  }

  public applySorting() {
    this.page.sort = this.sortingRule.sorting;
  }
}
