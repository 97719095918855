export class DateUtils {

    static getTodayDate() {
      return this.getDayStartDate(new Date()).getTime();
    }
  
    static getDayStartDate(date: Date): Date {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }
  
    static getParsedTime(dateToParse: string): Date {
      if (!dateToParse) return null;
      try {
        const hourSplit = dateToParse.split(":")
        if (hourSplit.length != 2) return null;
        const cd = new Date();
        return new Date(cd.getFullYear(), cd.getMonth(), cd.getDate(), Number(hourSplit[0]), Number(hourSplit[1]));
      } catch (e) {
        return null;
      }
    }
  
    static weekStart(queryDateMs: number): number {
      const queryDate = new Date(queryDateMs);
      const dateRolledToFirstWeekDay = new Date(queryDateMs - (queryDate.getDay() - 1)*24*60*60*1000);
      return this.getDayStartDate(dateRolledToFirstWeekDay).getTime();
    }
  
    static weekEnd(queryDateMs: number): number {
      const queryDate = new Date(queryDateMs);
      const dateRolledToLastWeekDay = new Date(queryDateMs + (7 - queryDate.getDay())*24*60*60*1000);
      return this.getDayEndDate(dateRolledToLastWeekDay).getTime();
    }
  
    static queryDate(weekOffset: number): number {
      const currentTime = new Date();
      const currentTimeMs = currentTime.getTime();
  
      return currentTimeMs + weekOffset * 7 * 24 * 60 * 60 * 1000;
    }
  
    static getDayEndDate(date: Date) {
      return new Date(date.getFullYear(),
        date.getMonth(), date.getDate(), 23, 59, 59, 999);
    }
  }