import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { interval, map, Observable, Subscription, tap } from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiLessonStatus,
  ApiPerson, ApiPersonalProfile, ApiPersonalProfileBaseWithPhoto,
  ApiTeacherProfile,
} from '../../../model/rest-model';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';

@Component({
  selector: 'app-coming-lesson',
  templateUrl: './coming-lesson.component.html',
  styleUrls: ['./coming-lesson.component.scss'],
})
export class ComingLessonComponent implements OnInit, OnDestroy {
  _comingLessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  timePhrase: string;
  roomUrl: SafeResourceUrl;
  isSkype: boolean;
  plannedAt: Date;
  startedAt: Date = null;
  isComing: boolean;
  isStarted: boolean;
  shouldStart: boolean;
  mayStart: boolean;
  isAfterPlannedStartDate: boolean;

  private teacherSkype: string;
  private _studentId: number;
  private timeSubscription: Subscription;

  @ViewChild('classroomFallback', { static: true })
  classroomFallbackModal: ModalComponent;

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }
  get studentId() {
    return this._studentId;
  }

  @Input()
  set comingLessonDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._comingLessonDetails = lesson
    this.prepareDetails();
  }
  get comingLessonDetails(): ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher> {
    return this._comingLessonDetails
  }

  constructor(
    private sanitizer: DomSanitizer,
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.timeSubscription = interval(1000)
      .pipe(
        tap(() => this.prepareDetails()),
        map(() => this.prepareTimePhrase())
      )
      .subscribe((phrase) => (this.timePhrase = phrase));
  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
  }

  prepareTimePhrase(): string {
    if (!this.comingLessonDetails) return '';
    const timeReference = this.isStarted ? this.startedAt : this.plannedAt;
    return Dates.toTimeStr(Dates.diffAbs(timeReference, new Date()));
  }

  getCourseName(): string {
    return this.comingLessonDetails.course.name;
  }

  getPlannedStartDate(): Date {
    return this.comingLessonDetails.lessonMetric.plannedStartDate;
  }

  getPersonPhoto(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return PersonUtils.getPersonProfilePhoto(person);
  }

  getPersonName(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return PersonUtils.getPersonName(person);
  }

  getTeacherName(): string {
    return this.getPersonName(this.comingLessonDetails.teacher.person);
  }

  getInitials() {
    return (
      this.comingLessonDetails?.teacher &&
      PersonUtils.getInitials(this.comingLessonDetails.teacher.person)
    );
  }

  getLessonSkypeAddress(): string {
    if (
      this.isSkype &&
      this.comingLessonDetails?.roomUrl?.startsWith('skype:')
    ) {
      return this.comingLessonDetails.roomUrl.substring('skype:'.length);
    }
    return 'missing address';
  }

  getTarget(): string {
    return '_blank';
  }

  getRoomUrl(): SafeResourceUrl {
    return this.roomUrl;
  }

  getTeacherSkype() {
    return this.teacherSkype;
  }

  getTeacherSkypeUrl() {
    if (!this.teacherSkype) return null;
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `skype:${this.teacherSkype}`
    );
  }

  prepareDetails(): any {
    this.startedAt = null;
    this.plannedAt = null;
    this.isComing = false;
    this.isStarted = false;
    this.shouldStart = false;
    this.mayStart = false;
    this.prepareRoomInfo();
    if (!this.comingLessonDetails) {
      return;
    }
    if (this.comingLessonDetails.lessonMetric.started) {
      this.startedAt = new Date(this.comingLessonDetails.lessonMetric.started);
    }
    const lessonStatus = ApiLessonStatus[this.comingLessonDetails.lessonStatus];
    this.plannedAt = new Date(
      this.comingLessonDetails.lessonMetric.plannedStartDate
    );
    this.isComing =
      lessonStatus === ApiLessonStatus.Booked ||
      lessonStatus === ApiLessonStatus.Due;
    this.isStarted = lessonStatus === ApiLessonStatus.InProgress;
    this.shouldStart =
      this.isComing &&
      Dates.diff(this.plannedAt, new Date()) > TimeUnits.Minutes(3).toMilis();
    this.isAfterPlannedStartDate =
      this.isComing && Dates.diff(this.plannedAt, new Date()) > 0;
    this.mayStart =
      this.isComing &&
      Dates.diff(new Date(), this.plannedAt) < TimeUnits.Minutes(5).toMilis();
  }

  prepareRoomInfo(): any {
    this.roomUrl = null;
    this.isSkype = false;
    if (!this.comingLessonDetails || !this.comingLessonDetails.roomUrl) {
      return;
    }
    if (this.comingLessonDetails.roomUrl.startsWith('skype:')) {
      this.isSkype = true;
      const url = this.comingLessonDetails.roomUrl + '?call';
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else if (this.comingLessonDetails.roomUrl.startsWith('video_room_url:')) {
      const url = this.comingLessonDetails.roomUrl.substring(
        'video_room_url:'.length
      );
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  isLessonGoingToStartRegularly(): boolean {
    return (
      this.isComing &&
      !this.shouldStart &&
      !this.isAfterPlannedStartDate &&
      !(this.roomUrl && this.mayStart)
    );
  }

  isTeacherMissingToStartLesson(): boolean {
    return this.isAfterPlannedStartDate && !this.roomUrl;
  }

  isLessonReadyToStart(): boolean {
    return (
      this.roomUrl &&
      ((this.isComing && this.mayStart) || this.shouldStart || this.isStarted)
    );
  }

  isProva() {
    return (
      this.comingLessonDetails &&
      this.comingLessonDetails.lessonType === 'Prova'
    );
  }

  hasComingDetails(): boolean {
      return !!this.comingLessonDetails;
  }

  mayEnterRoomBySkype(): boolean {
    return this.mayEnterClassroom() && this.isSkype;
  }

  mayEnterVideoRoom(): boolean {
    return this.mayEnterClassroom() && !this.isSkype;
  }

  mayEnterClassroom(): boolean {
    return (
      this.roomUrl && (this.mayStart || this.shouldStart || this.isStarted)
    );
  }

  private loadTeacherSkype(): Observable<ApiTeacherProfile> {
    return this.studentRest.getTeachers(this.studentId, [this.comingLessonDetails.teacher.id]).pipe(
      map((teachers) => (teachers && teachers.length > 0 ? teachers[0] : null)),
      tap((teacher) =>
        teacher
          ? (this.teacherSkype = teacher.skype)
          : (this.teacherSkype = null)
      )
    )
  }

  openSkypeHelp() {
    this.spinner.trace<ApiTeacherProfile>(this.loadTeacherSkype()).subscribe();
    this.classroomFallbackModal.show(true);
  }
}
