import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, Subscription, switchMap, tap} from 'rxjs';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { OwnershipLocalState } from '../../model/book-model';
import { defaultIfEmpty, take } from 'rxjs/operators';
import {FilterDefinition} from 'src/app/model/CourseFilters';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss'],
})
export class BookListComponent implements OnInit, OnDestroy, AfterViewInit {

  chosenCourse: FilterDefinition
  allOwnershipsLS: OwnershipLocalState[];
  ownershipsLS: OwnershipLocalState[];
  private BooksRefreshFrequency = 1000 * 60 * 15;
  private updateSubscription: Subscription;
  private refreshSubscription: Subscription;
  private initializeDataSubscription: Subscription;

  constructor(
    private provider: NativeServiceApiProvider,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.refreshSubscription = this.provider
      .books()
      .pipe(
        take(1),
        map((api) => api.subscribeForRefreshingEvents()),
        switchMap((taskProgressEmitter) => taskProgressEmitter),
        switchMap((observable) => this.spinner.trace(observable))
      )
      .subscribe();

    this.updateSubscription = this.provider
      .books()
      .pipe(
        switchMap((api) => api.listenForDataUpdates()),
        defaultIfEmpty('anything'),
        switchMap((_) => this.loadData())
      )
      .subscribe();

    this.initializeDataSubscription = this.provider
      .books()
      .pipe(
        switchMap((api) =>
          api
            .refreshData(this.BooksRefreshFrequency)
            .pipe(switchMap((_) => {
              return this.loadData()
            }))
        )
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  bookTracker(index: number, ownershipLS: OwnershipLocalState): number {
    return ownershipLS.ownership.eBook.release.id;
  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
    this.initializeDataSubscription.unsubscribe();
  }

  filterEbooks(language: FilterDefinition) {
    this.ownershipsLS = this.allOwnershipsLS?.filter(
      (ownershipLS) => {
        let categories = ownershipLS.ownership.eBook.categories
        return categories && categories.includes(language.category) || language.category == ''
      }
    );
  }

  loadData(): Observable<OwnershipLocalState[]> {
    return this.spinner.trace<OwnershipLocalState[]>(
      this.provider.books().pipe(
        switchMap((api) => api.getProducts()),
        map((ownershipsLS) => this.getUserOwnershipsLS(ownershipsLS)),
        map((ownershipsLS) => this.sortOwnerships(ownershipsLS)),
        tap((ownershipsLS) => {
          this.allOwnershipsLS = ownershipsLS;
          this.filterEbooks(this.chosenCourse);
        })
      )
    )
  }

  refreshData() {
    this.spinner
      .trace(this.provider.books().pipe(switchMap((api) => api.refreshData(0))))
      .subscribe();
  }

  getUserOwnershipsLS(ownershipsLS: OwnershipLocalState[]): OwnershipLocalState[] {
    return ownershipsLS
      .filter((ownershipLS) => ownershipLS.ownership.hasProduct)
  }

  sortOwnerships(ownerships: OwnershipLocalState[]) {
    return ownerships.sort((a, b) => a.ownership.eBook.order - b.ownership.eBook.order);
  }

  isActive(filter: FilterDefinition) {
    return filter && this.chosenCourse && filter.code === this.chosenCourse?.code;
  }

  getLanguageTranslation(lang: FilterDefinition) {
    return "EBOOKS.FILTER_LANGS." + lang?.translationCode
  }

  setChosenCourse(lang: FilterDefinition) {
    this.chosenCourse = lang
    this.filterEbooks(this.chosenCourse);
  }
}
