import {Injectable} from "@angular/core";
import {LogsService} from "../../../../utils/services/logs.service";
import {Observable, of, Subject} from "rxjs";
import {ContactUs, PasswordChangeRequest} from "../../../../books/model/book-model";
import {IosBridgeBase} from "../../../../mobile/api-services/bridge/ios-bridge-base";
import {MobileAuthBaseService} from "./mobile-auth-base.service";
import packageJson from 'package.json';


@Injectable({
  providedIn: 'root'
})
export class IosAuthService extends MobileAuthBaseService {
  private noSignal = new Subject<any>();

  constructor(private bridge: IosBridgeBase, logger: LogsService) {
    super(logger);
  }

  logout() {
    this.bridge.callForResponse<any>("logout", {}).subscribe();
  }

  changePassword(request: PasswordChangeRequest, lang?: string): Observable<void> {
    return this.bridge.callForResponse<any>('changePassword', { request, lang});
  }

  contactUs(request: ContactUs): Observable<void> {
    return this.bridge.callForResponse<any>('contactUs', { request});
  }

  getUserName(): Observable<string> {
    return this.bridge.callForResponse<any>("getUserName", {});
  }

  ssoLogin(): Observable<any> {
    return this.bridge.callForResponse<any>("ssoLogin", {});
  }

  getAccessTokenObservable(): Observable<string> {
    return this.bridge.callForResponse<string>("getAccessTokenValue", {})
  }

  getDeviceId(): Observable<string> {
    return this.bridge.callForResponse<string>('getDeviceId', {});
  }

  override closeApp(): Observable<any> {
    return this.bridge.callForResponse<any>("close", {});
  }

  override hideKeyboard(): Observable<any> {
    return of(null)
  }

  listenOnBackButton(): Observable<any> {
    return this.noSignal;
  }

  listenOnImeButton(): Observable<any> {
    return this.bridge.listenForImeButton();
  }

  getNativeApiVersion(): Observable<string> {
    return this.bridge.callForResponse<string>("exchangeVersion", {webAppVersion: packageJson.version})
  }
}
