<a
  routerLink="./{{ getEbookId() }}"
  class="text-center justify-content-center d-flex flex-column gap-3 text-decoration-none"
>
  <span
    *ngIf="isImgLoading"
    class="placeholder bg-primary col-12 book-cover rounded"
  ></span>
  <div class="position-relative" [class]="getEbookStateClass()">
    <img *ngIf="!isImgLoading" [src]="getImage()" class="book-cover rounded" />
  </div>
  <span
    *ngIf="isContentLoading"
    class="placeholder bg-light col-8 rounded mx-auto mb-2"
  ></span>
  <p *ngIf="!isContentLoading" class="d-none d-md-block m-0 white-text">
    {{ getLanguage() }}
  </p>
</a>
<span
  *ngIf="isContentLoading"
  class="placeholder bg-secondary col-8 rounded text-center"
></span>
<p
  *ngIf="!isContentLoading"
  class="d-none d-md-block text-muted m-0 text-center"
>
  {{ getEbookName() }}
</p>
