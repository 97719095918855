<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'FAQ.TITLE'"
           [redirectLink]="'../../'"
           [defaultRedirectTranslationCodes]="true">
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            {{ "FAQ.TITLE" | translate }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="container">
    <div class="row pt-3">
      <p class="text-center fs-3 text-muted m-0">
        {{ "FAQ.WORK_IN_PROGRESS" | translate }}
      </p>
    </div>
  </div>
</div>
